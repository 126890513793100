import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {generateBlackoutZonesRow, getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';
import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {formattedPriceOrEmptyValue, getContent, getYesOrNo} from '@utils/helpers';

export const exportExcel = (dataExport, eventId) => ({
    dataExport: dataExport,
    filename: `event_${eventId}_offerings`,
    parseData: offering => [
        offering.id,
        offering.client.name,
        getContent(offering.distribution.event_content),
        offering.distribution.event_content.event_content_variant.name,
        offering.distribution.product.short_name,
        offering.distribution.distribution_type.name,
        getListOfDeviceCategoriesForGeoRestrictions(offering.offering_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(offering.offering_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(offering.offering_geo_restrictions).length.toString(),
        generateBlackoutZonesRow(
            getBlackoutZonesFromGeoRestrictions(offering.offering_geo_restrictions),
            true
        ),
        _get(offering, 'client_package.name', ''),
        getYesOrNo(offering.is_hq),
        getYesOrNo(offering.is_ll),
        formattedPriceOrEmptyValue(offering.price, ''),
        offering.booking_status,
        offering.creation_datetime,
    ],
    path: 'events.offerings.index.path',
    titles: [
        'ID',
        'Client',
        'Content',
        'Content variant',
        'Product',
        'Distribution type',
        'Device categories',
        'Countries',
        '#Countries',
        'Blackout Zones',
        'Package',
        'HQ',
        'LL',
        'Price',
        'Booking status',
        'Time',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
    eventId: PropTypes.number.isRequired,
};
