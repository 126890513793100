import React from 'react';
import moment from 'moment';
import {isEmpty as _isEmpty} from 'lodash';
import {Icon, Popup} from 'semantic-ui-react';

import {ENCODING_STATUS_ENDED} from '@constants/variables';

/**
 *
 * @param row
 * @returns {XML}
 */
export const renderEncodingStatus = (row) => {
    let encodingStatus = 'Not started',
        drmWarningIcon = null,
        warningIcon = null,
        informationTooltip = null;

    const classNameWarningIcon = '--height-100-percent --marginLeft-0-5-em',
        hasEncodingTargets = row.distributions_by_product.find((element) => {
            return 0 < parseInt(element.encoding_targets_count, 10);
        }) !== undefined,
        encodingConfigurationChanged = [row]
            .filter(content => moment(content.encoding_configuration_update_datetime)
                .isAfter(content.encoding_start_datetime))
            .map(content => content.id);

    if (false === hasEncodingTargets) {
        warningIcon = <Popup
            key={`first-line-${row.id}`}
            trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon} />}
            flowing
            content='No encoding targets'
            hoverable
        />;
    }

    if (!_isEmpty(encodingConfigurationChanged)) {
        if (ENCODING_STATUS_ENDED === row.encoding_status_id) {
            warningIcon = <Popup
                key={`first-line-${row.id}`}
                trigger={<Icon color='orange' name='warning sign' className={classNameWarningIcon} />}
                flowing
                content='The encoding configuration of this content has been modified since its last encoding was started.
                 You can reset and restart the encoding to encode with the new configuration.'
                hoverable
            />;
        }

        if (ENCODING_STATUS_ENDED !== row.encoding_status_id) {
            warningIcon = <Popup
                key={`first-line-${row.id}`}
                trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon} />}
                flowing
                content='The encoding configuration of this content has been modified since its last encoding was started.
                 To encode with the new configuration, you need to stop the encoding, reset it and start it again.'
                hoverable
            />;
        }
    }

    row.distributions_by_product.some((productDistribution) => {
        if (true === productDistribution.has_wrong_drm_configuration) {
            drmWarningIcon = (<Popup
                key={`drm-wrong-configuration-${row.id}`}
                trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon}/>}
                flowing
                content='At least one encoding target has a wrong DRM configuration.'
                hoverable
            />);

            return true;
        }

        return false;
    });

    if (null !== row.encoding_message) {
        const informationIcon = <Icon circular size='tiny' className='--marginLeft-0-5-em' inverted color='blue' name='info' />;
        informationTooltip = (
            <Popup
                key={`first-line-popup-${row.id}`}
                trigger={informationIcon}
                flowing
                content={row.encoding_message}
                hoverable
            />
        );
    }

    if (null !== row.encoding_status) {
        encodingStatus = row.encoding_status;
    }

    let statusElement = <span>{encodingStatus}</span>;

    if (null !== row.encoder) {
        statusElement = <Popup trigger={statusElement} content={`Encoder: ${row.encoder}`}/>;
    }

    return (
        <div className='encodingStatusRow'>
            {drmWarningIcon}
            {statusElement}
            {warningIcon}
            {informationTooltip}
        </div>
    );
};
