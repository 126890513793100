import PropTypes from 'prop-types';

import {getYesOrNo} from '@utils/helpers';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'products',
    parseData: product => [
        product.id,
        product.name,
        product.short_name,
        product.cdn.name,
        product.akamai_cdn_ingest_method.name,
        getYesOrNo(product.has_akamai_rtmp_distr_region_support),
        getYesOrNo(product.has_scoreboard_overlay_support),
        getYesOrNo(product.has_slate_support),
        getYesOrNo(product.has_overlay_support),
        getYesOrNo(product.has_ad_support),
        getYesOrNo(product.has_drm_support),
        getYesOrNo(product.has_hq_support),
        getYesOrNo(product.has_ll_support),
        getYesOrNo(product.has_usage_type_support),
        getYesOrNo(product.has_geolocation_support),
        getYesOrNo(product.has_event_booking_support),
        getYesOrNo(product.is_invoiceable),
    ],
    path: 'products.index.path',
    titles: [
        'ID',
        'Name',
        'Short name',
        'Default CDN',
        'Akamai CDN ingest method',
        'Akamai RTMP distr. regions',
        'Scoreboard overlays',
        'Slates',
        'Overlays',
        'Ads',
        'DRM',
        'High Quality',
        'Low Latency',
        'Usage types',
        'Geolocation',
        'Event booking',
        'Invoiceable',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
