import React from 'react';
import {Icon} from 'semantic-ui-react';

import {TV_STREAMING_PRODUCTION_COMPANY} from '@constants/variables';

export const getWarningsForEntryPoint = (row) => {
    let errors = [];

    if (
        row.event_content_is_main_content
        && row.property_licence_tech_setup_is_tv_streaming
        && row.match_tv_streaming_encoder_id
    ) {
        const url = `rtmp://${row.rtmp_origin_entry_point_host}${row.rtmp_origin_entry_point_application_type_path}`,
            encoderUrl = row.match_tv_streaming_encoder_stream_url,
            encoder = encoderUrl.substr(encoderUrl.lastIndexOf('/') + 1);

        if (!row.match_tv_streaming_encoder_stream_url.includes(url)) {
            errors.push(
                <div className='--warning'>
                    <Icon name='warning sign' color='red'/>
                    RTMP origin entry point application does not match the TV Streaming encoder.
                </div>
            );
        }

        if (
            TV_STREAMING_PRODUCTION_COMPANY !== row.production_company_id
            || encoder !== row.rtmp_origin_stream_name
        ) {
            errors.push(
                <div className='--warning'>
                    <Icon name='warning sign' color='red'/>
                    RTMP origin stream name does not match the TV Streaming encoder.
                </div>
            );
        }
    }

    return errors;
};