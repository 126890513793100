import {FREE_TEXT} from '@constants/variables';

export const IS_TV_STREAMING = [
    {text: 'Is TV Streaming', value: 1},
    {text: 'Is not TV Streaming', value: 0},
];

export const IS_ARCHIVED = [
    {text: 'Archived', value: 1},
    {text: 'Not archived', value: 0},
];

export const STATUS = [
    {text: 'Enabled', value: 1},
    {text: 'Disabled', value: 0},
];

export const LMT = [
    {text: 'LMT support', value: 1},
    {text: 'No LMT support', value: 0},
];

export const HAS_ACCREDITATION = [
    {text: 'Has accreditation', value: 1},
    {text: 'Has no accreditation', value: 0},
];

export const YES_OR_NO = [
    {key: 'true', value: true, text: 'Yes'},
    {key: 'false', value: false, text: 'No'},
];

export const HAS_MULTIAUDIOSUPPORT = [
    {text: 'Multi audio', value: 1},
    {text: 'No multi audio', value: 0},
];

export const MEDIACONNECT_ORIGIN_FEEDS = [
    {text: 'Use both, start with primary', value: '1,2'},
    {text: 'Use both, start with secondary', value: '2,1'},
    {text: 'Use only primary', value: '1'},
    {text: 'Use only secondary', value: '2'},
];

export const HAS_HQ = [
    { key: 1, text: 'HQ', value: 1 },
    { key: 2, text: 'Not HQ', value: 0 },
];

export const HAS_LL = [
    { key: 1, text: 'LL', value: 1 },
    { key: 2, text: 'Not LL', value: 0 },
];

export const YES_OR_NO_FOR_PACKAGE_FORM = [
    {key: 1, value: '1', text: 'Yes'},
    {key: 2, value: '0', text: 'No'},
];

export const GEO_RESTRICTIONS = [
    {key: 'false', text: 'Same as product settings', value: 0},
    {key: 'true', text: 'Specific', value: 1},
];

export const TEST = [
    {text: 'Test', value: 1},
    {text: 'Not test', value: 0},
];

export const FREE_TEXT_DROPDOWN = {
    value: FREE_TEXT,
    text: FREE_TEXT,
};
