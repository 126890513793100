import React from 'react';
import {Divider, Popup, Image} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {isNil as _isNil, isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';

import {Authorization} from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {SECURITY_PRIVILEGES_READ, ORIGIN_STREAM_TYPE_RTMP} from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {
    isWarningVisible,
    shouldRowBeGreen,
    shouldRowBeOrange,
    shouldRowBePurple,
    shouldRowBeRed,
    shouldRowBeYellow,
} from '@modules/liveControl/utils/helpers';

import {authorizationLiveControlUpdateObject} from './LiveControlTable';
import {getEntryPointNameAndWarningText} from './getEntryPointNameAndWarningText';

/**
 *
 * @param rowData
 * @param timezone
 * @returns {string}
 */
export const getRowClassname = (rowData, timezone) => {
    let classModifier = '';

    if (rowData.distributions_by_product) {
        if (shouldRowBeRed(rowData)) {
            classModifier = '--red';
        } else if (shouldRowBeOrange(rowData, timezone)) {
            classModifier = '--orange';
        } else if (shouldRowBeYellow(rowData)) {
            classModifier = '--yellow';
        } else if (shouldRowBeGreen(rowData)) {
            classModifier = '--green';
        } else if (shouldRowBePurple(rowData)) {
            classModifier = '--purple';
        }
    }

    return classModifier;
};

/**
 *
 * @param row
 * @returns {string}
 */
export const renderDistributions = (row) => {
    const distributions = row.distributions_by_product;

    return distributions ? distributions
        .map((distribution) => distribution.product_short_name + ' ('
            + (_sortBy(distribution.distribution_types.map((type) => (
                {
                    name: type.name,
                    distribution_type_id: type.distribution_type_id,
                }
            )), 'distribution_type_id').map(el => el.name)).join(', ') + ')')
        .map((distRow) => (<span key={distRow} style={{whiteSpace: 'nowrap'}}>{distRow}<br/></span>)) : '';
};

/**
 *
 * @param row
 */
export const renderCdns = (row) => {
    return row.distributions_by_product.map((dist) => {
        if (!_isNil(dist.cdns)) {
            return dist.cdns.map((cdn) => {
                if (!_isNil(cdn.logo_filename)) {
                    return <Popup
                        key={`tooltip-cdn-${cdn.id}-${dist.product_short_name}`}
                        trigger={<Image key={'cdn-tt' + cdn.id } className='cdnLogo' size='tiny' src={'/images/' + cdn.logo_filename}/>}
                        flowing
                        hoverable
                    >
                        {cdn.name}
                    </Popup>;
                } else {
                    return <span key={`cdn-${cdn.id}-${dist.product_short_name}`}>{cdn.name}</span>;
                }
            });
        }
    }).map((element) => {
        if (!_isEmpty(element)) {
            return <div key={`cdns-${element[0].key}`} className='cdnsRow'>{element}<Divider hidden={true}/></div>;
        } else {
            return <div key={`cdns-rd-${row.id}`} className='cdnsRow'/>;
        }
    });
};

/**
 *
 * @param row
 * @returns {string}
 */
export const renderBookings = (row) => {
    const distributions = row.distributions_by_product;

    return distributions ? distributions
        .map((distribution) => {
            return (
                <div key={row.event_content_id + '-distr-' + distribution.product_short_name}>
                    <LinkWithAuthorization
                        link={{
                            name: 'events.bookings.index',
                            params: {id: row.event_id},
                        }}
                        newWindow
                        authorization={{
                            [RESOURCES.BOOKING]: SECURITY_PRIVILEGES_READ,
                        }}
                    >
                        {distribution.bookings_count}
                    </LinkWithAuthorization>
                    <Divider hidden={true} />
                </div>
            );
        }) : '0';
};

export const getMatchStartedOrEnded = (value) => ((null === value) ? '-' : (value ? 'Yes' : 'No'));

export const isInFailover = (row) => {
    return !!row.is_srt_origin_entry_point_in_failover;
};

export const entryPointSortValue = (el) => (!isWarningVisible(el) || el.origin_stream_type_id === ORIGIN_STREAM_TYPE_RTMP)
    ? getEntryPointNameAndWarningText(el).name : null;

export const getSrtOriginEntryPointName = (row) => {
    if (isInFailover(row) && row.failover_srt_origin_entry_point_name) {
        return `${row.origin_stream_type_name} - ${row.failover_srt_origin_entry_point_name}`;
    }

    if (row.srt_origin_entry_point_name && row.srt_origin_entry_point_url) {
        return `${row.origin_stream_type_name} - ${row.srt_origin_entry_point_name}`;
    }

    return `${row.origin_stream_type_name}`;
};

export const EncodingModifyButton = (props) => {
    const onClick = () => {
        props.onClick(props.dataId);
    };

    return (
        <Authorization authorization={authorizationLiveControlUpdateObject}>
            <span className={'encodingModifyButton'} onClick={onClick}><IconEdit/></span>
        </Authorization>
    );
};
EncodingModifyButton.propTypes = {
    dataId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
