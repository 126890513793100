import React from 'react';
import {isNil as _isNil} from 'lodash';
import moment from 'moment';

import {refetchQueryByName} from '@utils/apollo';
import {showModal} from '@utils/modal';
import ModifyEncodingEndDatetimeForm from '@modules/liveControl/forms/ModifyEncodingEndDatetimeForm';

import {EncodingModifyButton} from './utils';
import LiveControlEncodingTimePopup from '../LiveControlEncodingTimePopup';

/**
 *
 * @param row
 * @param timezone
 *
 * @returns {XML}
 */
export const renderEncodingEndTime = (row, timezone) => {
    let encodingEndDatetime = 'Manual';
    let displayEditButton = false;

    if (false === _isNil(row.encoding_end_datetime)) {
        const encEndTime = moment.tz(row.encoding_end_datetime, timezone);
        encodingEndDatetime = encEndTime.format('HH:mm');

        if (false === _isNil(row.encoding_end_margin)) {
            encodingEndDatetime += ' (+' + row.encoding_end_margin.toString() + ' min)';
        }

        if (moment.tz(timezone).isBefore(encEndTime)) {
            displayEditButton = true;
        }

    } else if (false === _isNil(row.event_content_end_datetime)) {
        const encEndTime = moment.tz(row.event_content_end_datetime, timezone);
        displayEditButton = true;

        if (false === _isNil(row.encoding_end_margin)) {
            encodingEndDatetime = encEndTime.add(row.encoding_end_margin, 'minutes').format('HH:mm');
            encodingEndDatetime += ' (+' + row.encoding_end_margin.toString() + ' min)';
        }
    } else if (false === _isNil(row.encoding_end_margin)) {
        displayEditButton = true;
        encodingEndDatetime = ' +' + row.encoding_end_margin + ' min';
    } else {
        displayEditButton = true;
    }

    const showStopEncodingModal = data => showModal({
        isVisible: true,
        content: <ModifyEncodingEndDatetimeForm
            formData={row}
            id={data}
            timezone={timezone}
            successCallback={() => refetchQueryByName('LiveControl')}
        />,
    });

    const content = (
        <div>
            <span>{encodingEndDatetime}</span>
            {displayEditButton
                ? <EncodingModifyButton
                    dataId={row.id}
                    onClick={showStopEncodingModal}
                />
                : ''
            }
        </div>
    );

    return (row.encoding_end_datetime || row.encoding_end_margin)
        ? (
            <LiveControlEncodingTimePopup
                content={content}
                row={row}
                type='end'
            />
        )
        : content;
};
