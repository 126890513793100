import React from 'react';
import {get as _get} from 'lodash';
import {Popup} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {getLink} from '@appComponents/Link';
import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {ToggleIsCancelledBooking} from '@graphql/booking/mutation';
import {refetchQueryByName} from '@utils/apollo';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import {formattedPriceOrEmptyValue} from '@utils/helpers';
import {EMPTY_VALUE_DASH} from '@constants/variables';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';
import {IconCheckmark, IconEdit, IconGlobe, IconShield} from '@appComponents/IconCollection';
import EventBookingCancellationIcon from '@modules/booking/views/BookingCancellationIcon';

export const renderInvoiceStatus = (row) => {
    if (_get(row, 'booking_invoice_status.id', null) && row.distribution.product.is_invoiceable) {
        return (
            <Popup
                content={'The invoice status has been set specifically for this booking.'}
                trigger={<b><i>{row.booking_invoice_status.name}</i></b>}
            />
        );
    } else if (_get(row, 'event_invoice_status.id', null) && row.distribution.product.is_invoiceable) {
        return row.event_invoice_status.name;
    } else {
        return null;
    }
};

export const rowRenderer = (column, row, data, eventId) => {
    const bookingGeoRestrictions = _get(row, 'booking_geo_restrictions', []),
        deviceCategoriesData = parseDeviceCategories(data.mappedIsoCodes, bookingGeoRestrictions);

    switch (column) {
        case 'bookingId': {
            return(
                <LinkWithAuthorization
                    resources={RESOURCES.BOOKING}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    link={{
                        name: 'events.bookings.edit',
                        params: {id : eventId, bookingId: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        }
        case 'deviceCategories':
            return renderDeviceCategories(deviceCategoriesData);
        case 'countries':
            return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesData, data.mappedIsoCodes);
        case 'numberOfCountries':
            return renderNumberOfCountries(deviceCategoriesData);
        case 'blackoutZones':
            return renderBlackoutZonesTableRowCell(deviceCategoriesData, false);
        case 'client': {
            return(
                <LinkWithAuthorization
                    resources={RESOURCES.CLIENT}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    link={{
                        name: 'clients.edit',
                        params: {id : row.client.id},
                    }}
                >
                    {row.client.name}
                </LinkWithAuthorization>
            );
        }
        case 'price': {
            return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
        }
        case 'booking_invoice_status': {
            return renderInvoiceStatus(row);
        }
        case 'time': {
            return row.creation_datetime;
        }
        case 'distributionType': {
            return row.distribution.distribution_type.name;
        }
        case 'content': {
            return <EventContentVariantPopupWithName eventContent={row.distribution.event_content}/>;
        }
        case 'client_package': {
            if(row.client_package){
                return(
                    <LinkWithAuthorization
                        resources={RESOURCES.CLIENT_PACKAGE}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.packages.edit',
                            params: {id : row.client.id, packagesId: row.client_package.id},
                        }}
                    >
                        {row.client_package.name}
                    </LinkWithAuthorization>
                );
            } else {
                return '-';
            }
        }
        case 'is_hq':
            return row.is_hq ? <IconCheckmark/> : null;
        case 'is_ll':
            return row.is_ll ? <IconCheckmark/> : null;
        case 'product': {
            return row.distribution.product.short_name;
        }
        case 'actions': {
            return (
                <Authorization
                    resources={RESOURCES.BOOKING}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                >
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={
                            getLink('events.bookings.edit', {id : eventId, bookingId: row.id})
                        } />
                        <IconGlobe size='large' link={
                            getLink(
                                'events.bookings.editGeoRestrictions',
                                {id : eventId, bookingId: row.id}
                            )}
                        />
                        <IconShield
                            link={getLink(
                                'events.bookings.blackoutZones',
                                {id : eventId, bookingId: row.id}
                            )}
                            size='large'
                        />
                        <EventBookingCancellationIcon
                            bookingId={row.id}
                            isCancelled={row.is_cancelled}
                            entityName={'booking'}
                            messageBoxName='eventLayout'
                            successCallback={() => {
                                refetchQueryByName('GetEventBookingsForTable');
                            }}
                            mutationChangeCancellationStatus={ToggleIsCancelledBooking}
                        />
                    </div>
                </Authorization>
            );
        }
        default: {
            return null;
        }
    }
};
