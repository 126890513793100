import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {GetProductsForTable} from '@graphql/product/query';
import navigation from '@constants/navigation';
import HeaderRenderer from '@appComponents/HeaderRenderer';

import ProductTable from '../components/ProductTable';
import {exportExcel} from '../utils/export/productIndexExcel';
import mapModulesToProps from '../../../utils/mapModulesToProps';

export function ProductIndex ({
    DataProduct: {
        products,
        loading,
    },
    Menu: {
        storeMenu,
    },
}) {
    React.useEffect(() => {
        storeMenu(navigation.eventAdministration.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(products)}
                loading={loading}
                messagesBoxNames={'productMessage'}
                pageTitle='Products'
            />
            <Segment basic className='--table'>
                <ProductTable
                    products={products}
                    loading={loading}
                />
            </Segment>
        </div>
    );
}

ProductIndex.propTypes = {
    DataProduct: PropTypes.object,
    Menu: PropTypes.shape({
        storeMenu: PropTypes.func.isRequired,
    }),
};

export default graphql(GetProductsForTable, {
    name: 'DataProduct',
})(connect(null, mapModulesToProps(['Menu']))(ProductIndex));
