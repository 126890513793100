import _orderBy from 'lodash/orderBy';

import {getSortingTerritories} from '@utils/countryHelpers';
import {createRowData} from '@modules/client/utils/clientContent/createRowData';

export const composeTableColumns = (clientProduct) => {
    const hasEventBookingSupport  = clientProduct?.product?.has_event_booking_support;
    const hasHqSupport = clientProduct?.product?.has_hq_support;
    const hasLlSupport = clientProduct?.product?.has_ll_support;

    return {
        content: {
            label: 'Content',
        },
        ...(hasEventBookingSupport
            ? {event_booking_type: {label: 'Event booking type', align: 'center'}}
            : {}
        ),
        booking: {
            label: 'Booking type',
        },
        event_content_variants: {
            label: 'Content variants',
        },
        ...(hasHqSupport ?
            {
                has_hq: {
                    label: 'HQ',
                    align: 'center',
                },
            } : {}
        ),
        ...(hasLlSupport ?
            {
                has_ll: {
                    label: 'LL',
                    align: 'center',
                },
            } : {}
        ),
        num_bookable_events: {
            label: '#Events',
        },
        event_price: {
            label: 'Event price',
            align: 'right',
        },
        ...(hasEventBookingSupport ?
            {
                event_content_type_configs: {
                    label: 'Clips',
                    align: 'left',
                },
            } : {}
        ),
        territories: {
            label: 'Territories',
            align: 'left',
            sorting: (row) => getSortingTerritories(row),
        },
        active: {
            label: 'Active?',
        },
        actions: {
            label: 'Actions',
        },
    };
};

export const getContentPackageTreeLevelObject = (level, data, parentId) => {
    const treeObject = {
        keys: [],
        treeId: null,
    };

    switch (level) {
        case 1:
            treeObject.keys = ['sport', 'content_category'];
            break;
        case 2:
            treeObject.keys = ['content_tier'];
            break;
        case 3:
            treeObject.keys = ['tournament_category', 'content_category'];
            break;
        case 4:
            treeObject.keys = ['property_licence'];
            break;
        case 5:
            treeObject.keys = ['tournament', 'content_category'];
            break;
    }

    if (data) {
        treeObject.keys.forEach(
            (value) => {
                if (data[value]) {
                    const contentCategory = data[value],
                        id = `${removeDashes(contentCategory.name)}-${removeDashes(contentCategory.id)}`
                            .replace(/[\s.,]/g, '&&');

                    treeObject.treeId = (parentId ? `${parentId}.${id}` : id);
                }
            }
        );
    }

    return treeObject;
};

export const removeDashes = (value) => {
    return value.replace(/-|\[|\]/g, '');
};

export const parseDataForTable = (clientPackage, data, level, parent, parentNames) => {
    let parentNamesArray = [];

    if (parentNames) {
        parentNamesArray = [...parentNames];
    }

    if (parent) {
        parentNamesArray.push(parent.name);
    }

    const rowsData = data.map((object) => {
        const rowData =
            createRowData(clientPackage, object, parent, getContentPackageTreeLevelObject(level).keys, level);

        if (object.children && 0 < object.children.length) {
            rowData.children = parseDataForTable(clientPackage, object.children, level + 1, rowData, parentNamesArray);
        }

        rowData.parentNames = parentNamesArray;

        return rowData;
    });

    return _orderBy(rowsData, (row) => (row.name.toLowerCase()), ['asc']);
};

export const getTreeDataForSave = (selected, data, level, parentId) => {
    if (!level) {
        level = 1;
    }

    return data.map((object) => {
        const rowToSave = {
                children: null,
                client_package_content: null,
            },
            clientPackageContent = object.client_package_content,
            treeObject = getContentPackageTreeLevelObject(level, object.client_package_content, parentId);

        const isActive = (-1 < selected.indexOf(treeObject.treeId)),
            isActiveParent = (-1 < selected.indexOf(parentId));

        if (0 < object.children.length) {
            rowToSave.children = getTreeDataForSave(selected, object.children, level + 1, treeObject.treeId);
        }

        if (clientPackageContent.id || isActive !== isActiveParent) {
            rowToSave.client_package_content = {
                id: clientPackageContent.id,
                sport: clientPackageContent.sport && parseInt(clientPackageContent.sport.id, 10),
                content_tier: clientPackageContent.content_tier && parseInt(clientPackageContent.content_tier.id, 10),
                tournament_category: clientPackageContent.tournament_category
                    && parseInt(clientPackageContent.tournament_category.id, 10),
                property_licence: clientPackageContent.property_licence
                    && parseInt(clientPackageContent.property_licence.id, 10),
                tournament: clientPackageContent.tournament && parseInt(clientPackageContent.tournament.id, 10),
                content_category: clientPackageContent.content_category
                    && parseInt(clientPackageContent.content_category.id, 10),
                is_active: isActive,
                territory_selection_type: clientPackageContent.territory_selection_type && parseInt(clientPackageContent.territory_selection_type.id, 10),
                countries: clientPackageContent.countries && clientPackageContent.countries.map(({id})=>id),
                country_subdivisions: clientPackageContent.country_subdivisions && clientPackageContent.country_subdivisions.map(({id})=>id),
            };
        }

        return rowToSave;
    });
};

export const formatEventContentTypes = (eventContentTypes) => {
    if (!eventContentTypes || !eventContentTypes.length) {
        return 'All';
    }

    return _orderBy(eventContentTypes, row => row.event_content_type.name, 'asc')
        .map(ect => {
            let match_event_types = '';

            if (ect.match_event_types.length) {
                match_event_types =
                    ` (${_orderBy(ect.match_event_types, row => row.name, 'asc')
                        .map(met => met.name).join(', ')})`;
            }

            return ect.event_content_type.name + match_event_types;
        })
        .join(', ');
};

export const formatEventContentVariants = (variantsArray) => {
    return (variantsArray ?? []).map(ecv => ecv.name).join(', ');
};
