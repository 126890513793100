import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {GetFiltersForAdSpliceTable, GetAdSplicesForTable} from '@graphql/adSplice/query';
import {exportExcel} from '@modules/adSplice/utils/adSpliceExcel';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';

import AdSpliceFilters from '../components/AdSpliceFilters';
import AdSpliceIndexButtons from '../components/AdSpliceIndexButtons';
import AdSpliceTable from '../components/AdSpliceTable';

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    adSplices: [],
};

export function AdSpliceIndex(props) {
    const filters = props.filters,
        queryVariables = {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            adSplice: filters.adSplices ? filters.adSplices : DEFAULT_FILTERS_VALUES.adSplices,
        };

    const {
        loading: areDataLoading = true,
        data = {},
    } = useQueryCustom({
        query: GetAdSplicesForTable,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
        fetchPolicy: 'no-cache',
    });

    const {Menu} = props;

    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQueryCustom({
        query: GetFiltersForAdSpliceTable,
        fetchPolicy: 'no-cache',
    });

    return (
        <div>
            <HeaderRenderer
                messagesBoxNames='adSpliceMessage'
                pageTitle='Ad splice configs'
                buttons={AdSpliceIndexButtons}
                exportExcelParams={exportExcel(data.adSpliceConfigs)}
                filters={AdSpliceFilters}
                filtersProps={{
                    loading: areFiltersLoading,
                    data: {
                        adSplices: dataForFilters.adSplices || [],
                    },
                }}
                filtersButtonName='adSplice'
                loading={areDataLoading}
            />
            <div className='table-container'>
                <AdSpliceTable
                    adSplices={data.adSpliceConfigs}
                    loading={areDataLoading}
                />
            </div>

        </div>
    );
}

AdSpliceIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    Menu: PropTypes.object,
};

const AdSpliceFilterWrapped = FilterQueryWrapper(AdSpliceIndex, {
    queryForRefresh: 'GetAdSplicesForTable',
    filterUrls: ['adSplice.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'adSplice', {...DEFAULT_FILTERS_VALUES}),
});

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(FilterUrlParamsWrapper(AdSpliceFilterWrapped,  {
    search: null,
    adSplices: [],
}));
