import React from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    forEach as _forEach,
    size as _size,
} from 'lodash';
import {Label, Popup, TableCell} from 'semantic-ui-react';

import Table from '@appComponents/Table';
import Link from '@appComponents/Link';
import PopupTable from '@appComponents/PopupTable';
import {
    PRODUCT_LCPP,
    PRODUCT_SPOTT,
    STREAM_PROTOCOL_CMAF,
    STREAM_PROTOCOL_DASH, STREAM_PROTOCOL_FILE,
    STREAM_PROTOCOL_HLS, STREAM_PROTOCOL_LL_HLS,
    STREAM_PROTOCOL_RTMP, STREAM_PROTOCOL_WEBSOCKET,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

const playBackColumns = {
    product: {
        label: 'Product',
        singleLine: false,
    },
    distribution_types: {
        label: 'Distribution type',
        singleLine: false,
    },
    stream_delay_types: {
        label: 'Stream delay type',
        singleLine: false,
    },
    encoding_target: {
        label: 'Encoding target',
        singleLine: false,
    },
    format: {
        label: 'Format',
        content: {
            singleLine: false,
        },
    },
    protocol: {
        label: 'Protocol',
        content: {
            singleLine: false,
        },
    },
    bitrates: {
        label: 'Manifest / bitrates',
        content: {
            singleLine: false,
        },
    },
};

const StreamsPlaybackTable = (props) => {
    const showBitrates = (streamProtocol, product) => {
        const protocolId = convertToInt(streamProtocol);

        if (
            PRODUCT_SPOTT === product || PRODUCT_LCPP === product
        ) {
            return false;
        } else if (
            STREAM_PROTOCOL_RTMP === protocolId
            || STREAM_PROTOCOL_HLS === protocolId
            || STREAM_PROTOCOL_FILE === protocolId
            || STREAM_PROTOCOL_WEBSOCKET === protocolId
        ) {
            return true;
        }

        return false;
    };

    const showManifest = (product, streamProtocol) => {
        const protocolId = convertToInt(streamProtocol);

        return PRODUCT_SPOTT === product
            || PRODUCT_LCPP === product
            || STREAM_PROTOCOL_DASH === protocolId
            || STREAM_PROTOCOL_CMAF === protocolId
            || STREAM_PROTOCOL_HLS === protocolId
            || STREAM_PROTOCOL_LL_HLS === protocolId;
    };

    const getBitrateAsNumber = (bitrate) => (convertToInt(bitrate.replace(' kbps', '')));

    const countFormatsTargetsRegionsSize = (initialRegionSize, formats) => {
        let regionsSize = initialRegionSize;

        _forEach(formats, format => {
            _forEach(format.protocols, protocol => {
                regionsSize += _size(protocol.distr_regions);
            });
        });

        return regionsSize;
    };


    const countEncodingTargetsRegionsSize = (initialRegionSize, encodingTargets) => {
        let regionsSize = initialRegionSize;

        _forEach(encodingTargets, (encodingTarget) => {
            regionsSize += countFormatsTargetsRegionsSize(initialRegionSize, encodingTarget.formats);
        });

        return regionsSize;
    };
    const rowRenderer = (column, row) => {
        switch (column) {
            case 'product' :
                return row.product;
            case 'distribution_types': {
                const distributionTypeCells = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    let regionsSize = 0;

                    _forEach(distributionType.stream_delay_types, type => {
                        regionsSize += countEncodingTargetsRegionsSize(0, type.encoding_targets);
                    });

                    distributionTypeCells.push(
                        <TableCell
                            as='div'
                            style={{padding: `${regionsSize - 0.5}em 0.5em`}}
                            className='cell'
                            key={'distribution_type_' + distributionTypeId}
                            content={distributionType.distribution_type}
                        />
                    );
                });

                return distributionTypeCells;
            }
            case 'stream_delay_types': {
                const streamDelayTypeCells = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    _forEach(distributionType.stream_delay_types, (type, typeId) => {
                        const regionsSize = countEncodingTargetsRegionsSize(0, type.encoding_targets);

                        streamDelayTypeCells.push(
                            <TableCell
                                as='div'
                                style={{padding: `${regionsSize - 0.5}em 0.5em`}}
                                className='cell'
                                key={`distribution_type_${distributionTypeId}_type_${typeId}`}
                                content={type.stream_delay_type}
                            />
                        );
                    });
                });

                return streamDelayTypeCells;
            }
            case 'encoding_target': {
                const encodingTargetsRows = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    _forEach(distributionType.stream_delay_types, (streamDelayType, typeId) => {
                        _forEach(streamDelayType.encoding_targets, (encodingTarget) => {
                            const regionsSize = countFormatsTargetsRegionsSize(0, encodingTarget.formats);

                            encodingTargetsRows.push(
                                <Popup
                                    className='--noPadding'
                                    flowing
                                    key={`popup_lct_${row.id}_encodingTarget_${encodingTarget.id}_distribution_${distributionTypeId}`}
                                    hoverable
                                    trigger={<TableCell
                                        as='div'
                                        style={{padding: `${regionsSize - 0.5}em 0.5em`}}
                                        className='cell'
                                        key={`distribution_type_${distributionTypeId}_type_${typeId}_encoding_target_${encodingTarget.id}`}
                                        content={
                                            <div>
                                                {encodingTarget.id}
                                                {encodingTarget.isComputerVision && <Label size='mini' className='td__label'>CV</Label>}
                                            </div>
                                        }
                                    />
                                    }
                                >
                                    <PopupTable rows={[{
                                        header: 'Default:',
                                        body: encodingTarget.isDefault ? 'Yes' : 'No',
                                    },
                                    {
                                        header: 'CDN:',
                                        body: `${encodingTarget.cdn.name}`,
                                    },
                                    encodingTarget.ingest_method.name
                                        ? ({
                                            header: 'Ingest method:',
                                            body: `${encodingTarget.ingest_method.name}`,
                                        })
                                        : {},
                                    {
                                        header: 'Encoders:',
                                        body: encodingTarget.encoder_type.name,
                                    },
                                    {
                                        header: 'Encoding job profile:',
                                        body: _get(encodingTarget, 'encoding_target_sr.name', '-'),
                                    },
                                    ]}/>
                                </Popup>
                            );
                        });
                    });
                });

                return encodingTargetsRows;
            }
            case 'format': {
                const formatRows = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    _forEach(distributionType.stream_delay_types, (type, typeId) => {
                        _forEach(type.encoding_targets, (encodingTarget) => {
                            _forEach(encodingTarget.formats, (format, formatId) => {
                                formatRows.push(
                                    <TableCell
                                        as='div'
                                        className='cell'
                                        style={{padding: `${_size(format.protocols) - 0.5}em 0.5em`}}
                                        key={`distribution_type_${distributionTypeId}_type_${typeId}_format_${formatId}`}
                                        content={format.format}
                                    />
                                );
                            });
                        });
                    });
                });

                return formatRows;
            }
            case 'protocol': {
                const protocolRows = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    _forEach(distributionType.stream_delay_types, (type, typeId) => {
                        _forEach(type.encoding_targets, (encodingTarget) => {
                            _forEach(encodingTarget.formats, (format, formatId) => {
                                _forEach(format.protocols, (protocol, protocolId) => {
                                    protocolRows.push(
                                        <TableCell
                                            as='div'
                                            className='cell'
                                            style={{padding: `${_size(protocol.distr_regions) - 0.5}em 0.5em`}}
                                            key={`distribution_type_${distributionTypeId}_type_${typeId}_format_${formatId}_protocol_${protocolId}`}
                                            content={protocol.protocol}
                                        />
                                    );
                                });
                            });
                        });
                    });
                });

                return protocolRows;
            }
            case 'bitrates': {
                const bitratesRows = [];

                _forEach(row.distribution_types, (distributionType, distributionTypeId) => {
                    _forEach(distributionType.stream_delay_types, (type, typeId) => {
                        _forEach(type.encoding_targets, (encodingTarget) => {
                            _forEach(encodingTarget.formats, (format, formatId) => {
                                _forEach(format.protocols, (protocol, protocolId) => {
                                    _forEach(protocol.distr_regions, (region, regionId) => {
                                        const product = convertToInt(row.id),
                                            dataRow = [],
                                            cdnClients = _get(encodingTarget, 'cdn.clients', []),
                                            ejpClients = _get(encodingTarget, 'job_profile.clients', []);

                                        if (showManifest(product, protocolId)) {
                                            const manifest = region.manifest;
                                            let routeName = (props.debugMode)
                                                ? 'events.streams.play.debug.manifest_akamai'
                                                : 'events.streams.play.manifest_akamai';

                                            if (
                                                !manifest.akamaiRtmpDistrRegionId
                                                || PRODUCT_SPOTT === convertToInt(row.id)
                                            ) {
                                                routeName = (props.debugMode)
                                                    ? 'events.streams.play.debug.manifest'
                                                    : 'events.streams.play.manifest';

                                                delete manifest.akamaiRtmpDistrRegionId;
                                            }

                                            if (cdnClients && 0 < cdnClients.length) {
                                                cdnClients.forEach((client) => {
                                                    dataRow.push(
                                                        <TableCell
                                                            as='div'
                                                            className='inline-cell'
                                                            key={`manifest_${regionId}_${client.id}`}
                                                            content={
                                                                <Link
                                                                    newWindow={props.newWindow}
                                                                    name={routeName}
                                                                    params={{
                                                                        id: props.eventId,
                                                                        eventContentId: props.eventContentId,
                                                                        ...manifest,
                                                                    }}
                                                                    query={{clientId: client.id}}
                                                                >
                                                                    Manifest ({client.name})
                                                                </Link>
                                                            }
                                                        />
                                                    );
                                                });
                                            } else {
                                                dataRow.push(
                                                    <TableCell
                                                        as='div'
                                                        className='inline-cell'
                                                        key={`manifest_${regionId}`}
                                                        content={
                                                            <Link
                                                                newWindow={props.newWindow}
                                                                name={routeName}
                                                                params={{
                                                                    id: props.eventId,
                                                                    eventContentId: props.eventContentId,
                                                                    ...manifest,
                                                                }}
                                                            >
                                                                Manifest
                                                            </Link>
                                                        }
                                                    />
                                                );
                                            }
                                        }

                                        if (showBitrates(protocolId, product)) {
                                            const sortedBitrates = region.bitrates.sort((a, b) => (
                                                getBitrateAsNumber(a.rate) > getBitrateAsNumber(b.rate)
                                                    ? -1
                                                    : 1
                                            ));

                                            _forEach(sortedBitrates, bitrate => {
                                                if (ejpClients && 0 < ejpClients.length) {
                                                    ejpClients.forEach((client) => {
                                                        dataRow.push(
                                                            <TableCell
                                                                as='div'
                                                                className='inline-cell'
                                                                key={`stream_${bitrate.streamId}_${client.id}`}
                                                                style={{whiteSpace: 'nowrap'}}
                                                                content={
                                                                    <Link
                                                                        newWindow={props.newWindow}
                                                                        name={(props.debugMode)
                                                                            ? 'events.streams.play.debug.event_stream'
                                                                            : 'events.streams.play.event_stream'
                                                                        }
                                                                        params={{
                                                                            id: props.eventId,
                                                                            eventContentId:
                                                                            props.eventContentId,
                                                                            streamId: bitrate.streamId,
                                                                        }}
                                                                        query={{clientId: client.id}}
                                                                    >
                                                                        {bitrate.rate} ({client.name})
                                                                    </Link>
                                                                }
                                                            />
                                                        );
                                                    });
                                                } else {
                                                    dataRow.push(
                                                        <TableCell
                                                            as='div'
                                                            className='inline-cell'
                                                            key={`stream_${bitrate.streamId}`}
                                                            style={{whiteSpace: 'nowrap'}}
                                                            content={
                                                                <Link
                                                                    newWindow={props.newWindow}
                                                                    name={(props.debugMode)
                                                                        ? 'events.streams.play.debug.event_stream'
                                                                        : 'events.streams.play.event_stream'
                                                                    }
                                                                    params={{
                                                                        id: props.eventId,
                                                                        eventContentId:
                                                                        props.eventContentId,
                                                                        streamId: bitrate.streamId,
                                                                    }}
                                                                >
                                                                    {bitrate.rate}
                                                                </Link>
                                                            }
                                                        />
                                                    );
                                                }
                                            });
                                        }

                                        bitratesRows.push(<TableCell
                                            as='div'
                                            className='cell'
                                            key={`distribution_type_${distributionTypeId}_type_${typeId}_format_${formatId}_protocol_${protocolId}_${regionId}_bitrates`}
                                        >{dataRow}</TableCell>);
                                    });
                                });
                            });
                        });
                    });
                });

                return bitratesRows;
            }

            default:
                return null;
        }
    };

    return (
        <div className='streams__table'>
            <Table
                name='streamsList'
                columns={playBackColumns}
                data={props.streams}
                loading={props.loading}
                noDataText='No streams found'
                rowRenderer={rowRenderer}
                pagination={false}
            />
        </div>
    );
};

StreamsPlaybackTable.propTypes = {
    eventId: PropTypes.number.isRequired,
    eventContentId: PropTypes.number.isRequired,
    debugMode: PropTypes.bool.isRequired,
    newWindow: PropTypes.bool.isRequired,
    streams: PropTypes.array,
    loading: PropTypes.bool,
};
StreamsPlaybackTable.defaultProps = {
    loading: false,
    streams: [],
};

export default StreamsPlaybackTable;
