import moment from 'moment';
import React from 'react';
import {Label} from 'semantic-ui-react';

import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconCheckmark} from '@appComponents/IconCollection';
import {renderInvoiceStatusColumn} from '@modules/client/utils/clientBookingTable';
import {CLIENT_PACKAGE, EVENT} from '@constants/resources';
import {convertToInt, formattedPriceOrEmptyValue} from '@utils/helpers';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';
import {EMPTY_VALUE_DASH, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

export const columns = {
    'id': {
        label: 'ID',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
        sorting: true,
    },
    'start_datetime': {
        label: 'Date/time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'content_category_level_1_name': {
        label: 'Sport /',
        subLabel: '1st level category',
        sorting: (row) => row.content_category_level_1_name,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'content_category_level_2_name': {
        label: 'Category /',
        subLabel: '2nd level category',
        sorting: (row) => row.content_category_level_2_name,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'content_category_level_3_name': {
        label: 'Tournament /',
        subLabel: '3rd level category',
        sorting: (row) => row.content_category_level_3_name,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'content_tier_name': {
        label: 'Tier',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'competitors': {
        label: 'Competitors / Description',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'event_content_type_name': {
        label: 'Content',
        sorting: (row) => (`${row.event_content_type_name}${row.event_content_name ? ' - ' + row.event_content_name : ''}`),
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'product_short_name': {
        label: 'Product',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'distribution_type_name': {
        label: 'Distribution type',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'client_package_name': {
        label: 'Package',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'is_ll': {
        label: 'LL',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'booking_type_name': {
        label: 'Type',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'price': {
        label: 'Price',
        sorting: (row) => row.price ? row.price : 0,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'auto_update_price': {
        label: 'Auto-update price',
        sorting: (row) => row.auto_update_price,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'event_invoice_status_name': {
        label: 'Invoice status',
        sorting: (row) => row.invoice_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'auto_update_geo_restrictions': {
        label: 'Auto-update geo restrictions',
        sorting: (row) => row.auto_update_geo_restrictions,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

export const rowRenderer = (column, row, props) => {
    const eventContent = {
        name: row.event_content_name,
        event_content_type: {
            name: row.event_content_type_name,
        },
        event_content_variant: {
            id: row.event_content_variant_id,
            name: row.event_content_variant_name,
            short_name: row.event_content_variant_short_name,
        },
    };


    switch (column) {
        case 'start_datetime':
            return moment(row[column]).format('YYYY-MM-DD HH:mm');
        case 'competitors':
            return (
                <div>
                    <LinkWithAuthorization
                        resources={EVENT}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'events.configuration.index',
                            params: {id: convertToInt(row.event_id)},
                        }}
                    >
                        {row.competitors ? row.competitors : null}
                    </LinkWithAuthorization>
                    {(null === row.event_description || undefined === row.event_description)
                        ? null
                        : <div>
                            <Label style={{backgroundColor: `#${row.event_color}`}} size='mini'>
                                {row.event_description}
                            </Label>
                        </div>
                    }
                </div>
            );
        case 'event_content_type_name':
            return <EventContentVariantPopupWithName eventContent={eventContent}/>;
        case 'client_package_name':
            return (
                <LinkWithAuthorization
                    authorization={{
                        [CLIENT_PACKAGE]: SECURITY_PRIVILEGES_UPDATE,
                    }}
                    link={{
                        name: 'clients.packages.edit',
                        params: {
                            id: props.clientId,
                            packagesId: convertToInt(row.client_package_id),
                        },
                    }}
                >
                    {row.client_package_name}
                </LinkWithAuthorization>
            );
        case 'is_hq':
            return row.is_hq ? <IconCheckmark /> : null;
        case 'is_ll':
            return row.is_ll ? <IconCheckmark /> : null;
        case 'price':
            return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
        case 'auto_update_price':
            return (row.auto_update_price ? <IconCheckmark/> : '');
        case 'event_invoice_status_name':
            return renderInvoiceStatusColumn(row);
        case 'auto_update_geo_restrictions':
            return (row.auto_update_geo_restrictions ? <IconCheckmark/> : '');
        default:
            return null;
    }
};
