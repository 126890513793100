import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';
import moment from 'moment/moment';
import {gql} from '@apollo/client';

import {showMessageBox, removeMessageBox} from '@utils/messageBox';
import {
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    ORIGIN_STREAM_TYPE_SRT,
    BROADCAST_STATUS_ON_AIR_BROADCAST,
    BROADCAST_STATUS_ON_AIR_READY,
    BROADCAST_STATUS_DELIVERED,
    DISTRIBUTION_TYPE_LIVE,
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
    LIVE_CONTROL_ORANGE_WARNING_MINUTES,
    PRODUCT_SPOTT,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import FailoverContentsMessageBoxList from '@modules/liveControl/views/LiveControlIndex/FailoverContentsMessageBoxList';
import ModalHeaderEventContentLiveControl from '@modules/liveControl/views/ModalLiveControlHeader';
import {showModalConfirmation, hideModal} from '@utils/modal';
import {setUdpOriginEntryPointFailover} from '@graphql/liveControl/actions';
import {refetchQueryByName} from '@utils/apollo';
import responseStatus from '@constants/responseStatuses';

import apolloClient from '../../../apolloClient';

/**
 * Get event content type with content name (if exist)
 * @param {Object} event
 * @returns {string}
 */
export const getEventContentTypeName = (event) => (
    (!_isEmpty(event.event_content_name))
        ? ` -  ${event.event_content_type_name} - ${event.event_content_name}`
        : ` - ${event.event_content_type_name}`
);

getEventContentTypeName.propTypes = {
    event: PropTypes.shape({
        event_content_type_name: PropTypes.string.isRequired,
        event_content_name: PropTypes.string,
    }).isRequired,
};

export const failoverContentsMessageBox = (autoFailoverContents) => {
    if (_isEmpty(autoFailoverContents)) {
        removeMessageBox(autoFailoverContents);
    }

    const originStreamTypes = [
        {
            id: ORIGIN_STREAM_TYPE_UDP,
            title: 'A UDP origin entry point failover happened on some event contents being encoded:',
            messageBoxName: 'liveControlAutoFailoverForUdp',
        },
        {
            id: ORIGIN_STREAM_TYPE_SRT,
            title: 'A SRT origin entry point failover happened on some event contents being encoded:',
            messageBoxName: 'liveControlAutoFailoverForSrt',
        },
    ];

    originStreamTypes.forEach((originStreamType) => {
        const failoverContentByOriginStreamType = autoFailoverContents.filter((autoFailoverContent)=>{
            return autoFailoverContent.origin_stream_type_id === originStreamType.id;
        });

        if (!_isEmpty(failoverContentByOriginStreamType)) {
            showMessageBox(
                originStreamType.messageBoxName,
                originStreamType.title,
                <FailoverContentsMessageBoxList failoverContents={failoverContentByOriginStreamType}/>,
                'info',
                true
            );
        }
    });
};

export const isWarningVisible = (row) => {
    return (
        ORIGIN_STREAM_TYPE_RTMP === row.origin_stream_type_id && !row.rtmp_origin_stream_name
        || ORIGIN_STREAM_TYPE_UDP === row.origin_stream_type_id && !row.udp_entry_point_name
        || ORIGIN_STREAM_TYPE_SDI === row.origin_stream_type_id && !row.sdi_entry_point_name
        || ORIGIN_STREAM_TYPE_HTTP === row.origin_stream_type_id && !row.http_origin_entry_point_url
        || ORIGIN_STREAM_TYPE_MEDIACONNECT === row.origin_stream_type_id && !row.mediaconnect_entry_point_name
        || ORIGIN_STREAM_TYPE_SRT === row.origin_stream_type_id && !row.srt_origin_entry_point_url
    ) && row.event_content_input_id;
};

isWarningVisible.propTypes = {
    row: PropTypes.object.isRequired,
};


const getBroadcastStatuses = (data) => {
    let broadcastStatuses = [];

    data.distributions_by_product.forEach((distribution) => {
        distribution.distribution_types.forEach((distributionType) => {
            if (DISTRIBUTION_TYPE_LIVE === distributionType.distribution_type_id
                && distributionType.broadcast_status_id
                && (-1 === broadcastStatuses.indexOf(distributionType.broadcast_status_id))
            ) {
                broadcastStatuses.push(distributionType.broadcast_status_id);
            }
        });
    });

    return broadcastStatuses;
};

const hasSportradarEncoderExistence = (data) => {
    return data.distributions_by_product.some((distribution) => distribution.sr_encoding_targets_count);
};

export const shouldRowBeRed = (data) => {
    return ENCODING_STATUS_ERROR === data.encoding_status_id
        || ENCODING_STATUS_PREPARING === data.encoding_status_id;
};

export const shouldRowBeOrange = (data, timezone) => {
    const currentTimeZoneMoment = moment.tz(timezone);
    const broadcastStatuses = getBroadcastStatuses(data),
        hasOneLiveDistWithoutOnAirBroadcast = 0 < broadcastStatuses
            .filter((broadcastStatus) => (BROADCAST_STATUS_ON_AIR_BROADCAST !== broadcastStatus))
            .length,
        isEventContentBeingEncoded = ENCODING_STATUS_ENCODING === data.encoding_status_id,
        isEventContentRunning = (
            (
                data.match_id
                && (data.match_about_to_start_datetime || data.match_started_datetime)
                && (data.match_about_to_start_datetime
                    ? moment
                        .tz(data.match_about_to_start_datetime, timezone)
                        .isBefore(currentTimeZoneMoment)
                    : moment
                        .tz(data.match_started_datetime, timezone)
                        .isBefore(currentTimeZoneMoment))
                && (
                    (
                        data.match_ended_datetime
                        && moment
                            .tz(data.match_ended_datetime, timezone)
                            .add(LIVE_CONTROL_ORANGE_WARNING_MINUTES, 'minutes')
                            .isAfter(currentTimeZoneMoment)
                    )
                    || false === data.match_has_ended)
            )
            || (
                null === data.match_id
                && (
                    data.event_content_start_datetime
                    && moment
                        .tz(data.event_content_start_datetime, timezone)
                        .subtract(LIVE_CONTROL_ORANGE_WARNING_MINUTES, 'minutes')
                        .isBefore(currentTimeZoneMoment)
                )
                && (data.event_content_end_datetime
                    && moment
                        .tz(data.event_content_end_datetime, timezone)
                        .add(LIVE_CONTROL_ORANGE_WARNING_MINUTES, 'minutes')
                        .isAfter(currentTimeZoneMoment)
                )
            )
        ),
        targetWithSportradarEncoderExistence = hasSportradarEncoderExistence(data),
        isBroadcastStatusOnAirReadyOrOnAirBroadcast = (
            broadcastStatuses.includes(BROADCAST_STATUS_ON_AIR_READY)
            || broadcastStatuses.includes(BROADCAST_STATUS_ON_AIR_BROADCAST)
        ),
        hasEventContentInput = data.event_content_has_input,
        encodingStatusIsNotEncoding = ENCODING_STATUS_ENCODING !== data.encoding_status_id,
        liveDistributionsAmount = getLiveDistributions(data),
        hasOneLiveDistWithOnAirBroadcast = 0 < broadcastStatuses
            .filter((broadcastStatus) => (BROADCAST_STATUS_ON_AIR_BROADCAST === broadcastStatus))
            .length,
        firstDocumentationCondition = (
            hasOneLiveDistWithoutOnAirBroadcast
            && isEventContentBeingEncoded
            && isEventContentRunning
        ),
        secondDocumentationCondition = (
            isBroadcastStatusOnAirReadyOrOnAirBroadcast
            && hasEventContentInput
            && targetWithSportradarEncoderExistence
            && encodingStatusIsNotEncoding
        ),
        hasMultipleLiveDistWithDifferentBroadcastStatuses = (
            1 < liveDistributionsAmount
            && hasOneLiveDistWithOnAirBroadcast
            && isEventContentBeingEncoded
            && hasOneLiveDistWithoutOnAirBroadcast
            && isEventContentBeingEncoded
        );

    return firstDocumentationCondition || secondDocumentationCondition || hasMultipleLiveDistWithDifferentBroadcastStatuses;
};

const getLiveDistributions = (data) => {
    const liveDistributionsArray = [];
    data.distributions_by_product.forEach((distribution) => {
        distribution.distribution_types.forEach((distributionType) => {
            if (DISTRIBUTION_TYPE_LIVE === distributionType.distribution_type_id) {
                liveDistributionsArray.push(distributionType.distribution_type_id);
            }
        });
    });

    return liveDistributionsArray.length;
};

export const shouldRowBeYellow = (data) => {
    const distrOnAirReadyorDelivered = data.distributions_by_product.filter((element) => {
        return undefined !== element.distribution_types.find((distrType) => {
            return DISTRIBUTION_TYPE_LIVE === distrType.distribution_type_id
                && (BROADCAST_STATUS_ON_AIR_READY === distrType.broadcast_status_id
                    || BROADCAST_STATUS_DELIVERED === distrType.broadcast_status_id);
        });
    });

    return ENCODING_STATUS_ENCODING === data.encoding_status_id
        && 0 < distrOnAirReadyorDelivered.length;
};

export const shouldRowBeGreen = (data) => {
    const distrOnAirBroadcast = data.distributions_by_product.filter((element) => {
        return undefined !== element.distribution_types.find((distrType) => {
            return DISTRIBUTION_TYPE_LIVE === distrType.distribution_type_id
                && BROADCAST_STATUS_ON_AIR_BROADCAST === distrType.broadcast_status_id;
        });
    });

    return 0 < distrOnAirBroadcast.length;
};

export const shouldRowBePurple = (data) => {
    if (!data.event_report_id) {
        const distrDelivered = data.distributions_by_product.filter((distr) => {
            return (PRODUCT_SPOTT !== distr.product_id)
                && (undefined !== distr.distribution_types.find((distrType) => {
                    return DISTRIBUTION_TYPE_LIVE === distrType.distribution_type_id
                        && BROADCAST_STATUS_DELIVERED === distrType.broadcast_status_id;
                }));
        });

        return 0 < distrDelivered.length;
    }
};

export const hasProperOriginStreamType = (originStreamTypeId) => {
    const properOriginStreamTypes = [ORIGIN_STREAM_TYPE_RTMP, ORIGIN_STREAM_TYPE_HTTP, ORIGIN_STREAM_TYPE_SRT];

    return properOriginStreamTypes.includes(convertToInt(originStreamTypeId));
};

export const getOriginStreamTypeName = (originStreamType) => {
    let originStreamTypeName = '';

    switch(originStreamType) {
        case ORIGIN_STREAM_TYPE_HTTP:
            originStreamTypeName = 'HTTP';
            break;
        case ORIGIN_STREAM_TYPE_RTMP:
            originStreamTypeName = 'RTMP';
            break;
        case ORIGIN_STREAM_TYPE_SRT:
            originStreamTypeName = 'SRT';
            break;
    }

    return originStreamTypeName;
};

export const isValidMatchStatusId = (matchStatusId) => {
    const statusId = convertToInt(matchStatusId);
    const matchStatusNames = {
        INTERRUPTED: 80,
        SUSPENDED: 81,
        ABANDONED: 90,
        RETIRED: 92,
    };

    return statusId || 0 === statusId ? Object.values(matchStatusNames).some(id => id === statusId) : false;
};

export const handleUdpOriginEntryPointFailover = (row) => {
    showModalConfirmation({
        header: <ModalHeaderEventContentLiveControl event={row} title='UDP origin entry point failover'/>,
        text: `Are you sure you want to failover to the UDP origin entry point "${row.failover_udp_entry_point_name}" now?`,
        onYes: () => {
            let eventContentUdpOriginEntryPointFailover = [{
                event_content: row.event_content_id,
                udp_origin_entry_point_failover: row.failover_udp_entry_point_id,
                udp_origin_entry_point_failover_encoding_datacenter: convertToInt(row.failover_udp_entry_point_encoding_datacenter_id),
            }];

            return udpateUdpOriginEntryPoint(eventContentUdpOriginEntryPointFailover);
        },
    });
};

export const udpateUdpOriginEntryPoint = (eventContentUdpOriginEntryPointFailover, multiple = false) => {
    apolloClient.mutate({
        mutation: gql(setUdpOriginEntryPointFailover),
        variables: {
            eventContentUdpOriginEntryPointFailover: eventContentUdpOriginEntryPointFailover,
        },
    }).then((data) => {
        hideModal();

        let result = data.data.setUdpOriginEntryPointFailover,
            failedIds = result.filter((item) => (
                responseStatus.BAD_REQUEST === item.status
            )).map((item) => (item.id));

        if (0 < failedIds.length) {
            showMessageBox(
                'liveControl',
                (!multiple ? result[0].message : `
                        The UDP origin entry point failover failed for the following 
                        event contents: ${failedIds.join(', ')}.
                    `),
                null,
                'error'
            );
        } else if (multiple) {
            showMessageBox(
                'liveControl',
                'The UDP origin entry point failover was applied successfully.',
                null,
                'success'
            );
        }

        refetchQueryByName('LiveControl');
    }).catch((error) => {
        hideModal();
        showMessageBox(
            'liveControl',
            'There was an error while changing to UDP origin entry point failover.',
            `${error}`,
            'error'
        );
    });
};
