import {withRouter} from 'react-router';
import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {prepareContentCategoriesForDropdown} from '@utils/contentCategories';
import {setDefaultSuccessCallbacks} from '@utils/forms';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {prepareTournamentsForDropdown} from '@utils/tournament';
import {
    checkAndMapIds,
    handleAdSpliceConfigField,
    prepareDataForSubmitForm,
} from '@modules/srtOriginEntryPoint/utils/helpers';
import {adSpliceConfigOptionParser} from '@utils/dropdownOptionParser';

export class SrtOriginEntryPointForm extends DefaultForm {
    static propTypes = {
        client: PropTypes.object,
        history: PropTypes.object,
        formValues: PropTypes.object,
    };

    componentDidMount = () => {
        setDefaultSuccessCallbacks(this, () => {
            this.props.history.push(this.createLinkToIndexPage());
        });

        this.setOptionParsers({
            ad_splice_config: adSpliceConfigOptionParser,
        });
    };

    componentDidUpdate = (prevProps) => {
        super.componentDidUpdate();
        handleAdSpliceConfigField(this, prevProps);

        if (this.props.GraphQLOptionsData !== prevProps.GraphQLOptionsData) {
            const {
                GraphQLOptionsData: {
                    contentCategories,
                    tournaments,
                },
            } = this.props;

            if (!this.props.contentCategories !== prevProps.contentCategories) {
                this.setField('content_categories', {
                    options: !_isEmpty(contentCategories)
                        ? sortDropdownOptionsAlphanumerically(prepareContentCategoriesForDropdown(contentCategories))
                        : null,
                });
            }

            if (!this.props.tournaments !== prevProps.tournaments) {
                this.setField('tournaments', {
                    options: !_isEmpty(tournaments)
                        ? sortDropdownOptionsAlphanumerically(prepareTournamentsForDropdown(tournaments))
                        : null,
                });
            }
        }

        if (!_isEmpty(this.props.formValues.id)) {
            if (!_isEmpty(this.props.formValues.content_categories)) {
                if (this.props.formValues.content_categories !== prevProps.formValues.content_categories) {
                    this.setField('content_categories', {
                        defaultValue: checkAndMapIds(this.props.formValues.content_categories),
                    });
                }
            }

            if (!_isEmpty(this.props.formValues.tournaments)) {
                if (this.props.formValues.tournaments !== prevProps.formValues.tournaments) {
                    this.setField('tournaments', {
                        defaultValue: checkAndMapIds(this.props.formValues.tournaments),
                    });
                }
            }
        }
    };

    prepareDataForSubmit = (data) => {
        return prepareDataForSubmitForm(data, this.props.Model);
    };

    renderCancelButton = () => null;

    createLinkToIndexPage = () => (
        getLink('srtOriginEntryPoints.index')
    );

    renderErrors = () => {
        super.renderErrors(
            'The requested SRT origin entry point could not be found.',
            this.props.Model.entityLabel,
            this.createLinkToIndexPage()
        );
    };
}

export default withRouter(SrtOriginEntryPointForm);
