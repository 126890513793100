import * as VARIABLES from '@constants/variables';
import {FREE_TEXT_DROPDOWN} from '@constants/staticDropdowns';

export const filterOriginEntryPointsByTournamentsAndCategories = (originEntryPoints, tournaments, contentCategories) => {
    return originEntryPoints.filter((originEntryPoint) => (
        (0 === originEntryPoint.tournaments.length && 0 === originEntryPoint.content_categories.length)
        || tournaments && originEntryPoint.tournaments.find((tournament) => (tournament.id === tournaments))
        || contentCategories && originEntryPoint.content_categories.find((contentCategory) => (
            contentCategory.id === contentCategories
        ))
    ));
};

const mapOriginEntryPoints = (originEntryPoints) => {
    return originEntryPoints.map((item) => ({
        key: item.key,
        text: item.text + ' - ' + item.url,
        value: item.value,
    }));
};

export const parseOriginEntryPointsOptions = (originEntryPoints, tournaments, contentCategories) => {
    const filteredEntryPoints = filterOriginEntryPointsByTournamentsAndCategories(originEntryPoints, tournaments, contentCategories);

    return mapOriginEntryPoints(filteredEntryPoints);
};

export const showSrtFieldsBasedOnFormValues = (formValues, setField) => {
    if (!formValues.srt_origin_entry_point) {
        setField('srt_origin_entry_point', {defaultValue: VARIABLES.FREE_TEXT});
    }

    if (formValues.srt_origin_entry_point === VARIABLES.FREE_TEXT || !formValues.srt_origin_entry_point) {
        setField('srt_origin_entry_point_url', {hidden: false});
        setField('failover_srt_origin_entry_point_url', {hidden: false});
        setField('srt_has_scte35', {hidden: false});

        if (formValues.srt_has_scte35) {
            setField('srt_ad_splice_config', {hidden: false, required: true});
        }

        if (formValues.failover_srt_origin_entry_point_url) {
            setField('is_srt_origin_entry_point_in_failover', {hidden: false});
        } else {
            setField('is_srt_origin_entry_point_in_failover', {hidden: true});
        }

        setField('failover_srt_origin_entry_point', {hidden: true});
        setField('override_ad_setup', {hidden: true});
    } else {
        setField('failover_srt_origin_entry_point', {hidden: false});
        setField('override_ad_setup', {hidden: false});
        setField('srt_origin_entry_point_url', {hidden: true});
        setField('failover_srt_origin_entry_point_url', {hidden: true});

        if (formValues.srt_has_scte35) {
            setField('srt_has_scte35', {hidden: false});
            setField('srt_ad_splice_config', {hidden: false, required: true});
            setField('override_ad_setup', {timestamp: Date.now(), defaultValue: true});
        } else {
            setField('srt_ad_splice_config', {hidden: true});
        }

        if (formValues.override_ad_setup) {
            setField('srt_has_scte35', {hidden: false});
        } else {
            setField('srt_has_scte35', {hidden: true});
            setField('srt_ad_splice_config', {hidden: true});
        }

        if (formValues.failover_srt_origin_entry_point) {
            setField('is_srt_origin_entry_point_in_failover', {hidden: false});
        } else {
            setField('is_srt_origin_entry_point_in_failover', {hidden: true});
        }
    }
};

export const prepareSrtOriginEntryPointsOptions = (querySrtOriginEntryPointsOptions, setField, getTournamentOfEvent, getContentCategoryOfEvent) => {
    querySrtOriginEntryPointsOptions()
        .then((data) => {
            const optionsFromGQL = parseOriginEntryPointsOptions(
                data.data.srtOriginEntryPoints,
                getTournamentOfEvent(),
                getContentCategoryOfEvent()
            );
            setField('srt_origin_entry_point', {
                options: [FREE_TEXT_DROPDOWN].concat(optionsFromGQL),
            });
            setField('failover_srt_origin_entry_point', {
                options: optionsFromGQL,
            });
        });
};

