import PropTypes from 'prop-types';
import React from 'react';

import {graphql} from 'react-apollo';
import Table from '@appComponents/Table';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {columns} from '@modules/events/components/EventsOfferings/eventsOfferingsTableColumns';
import {rowRenderer} from '@modules/events/components/EventsOfferings/eventsOfferingsTableRowRenderer';

export const EventsOfferingsTable = (props) => {
    const {data, eventOfferings, loading} = props;

    const preparedRowRenderer = (column, row) => {
        return rowRenderer(column, row, data);
    };

    return (
        <div className='eventOfferings__table'>
            <Table
                className='mini'
                name='eventOfferingsList'
                columns={columns}
                data={eventOfferings}
                loading={loading}
                pagination={false}
                noDataText='No offerings found'
                rowRenderer={preparedRowRenderer}
            />
        </div>
    );
};

EventsOfferingsTable.propTypes = {
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    eventOfferings: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};
EventsOfferingsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    eventOfferings: [],
};
EventsOfferingsTable.displayName = 'EventsOfferingsTable';

export default graphql(GetMappedIsoCodes)(EventsOfferingsTable);
