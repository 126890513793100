import {isEqual as _isEqual} from 'lodash';

import {convertToInt, formatPrice, parseIntOrNull} from '@utils/helpers';
export const parseEventContentTypes = (formDataTypes) => {
    if (!formDataTypes) {
        return [];
    }

    return formDataTypes.map(eventContentType => {
        return {
            event_content_type: eventContentType.value,
            match_event_types: (eventContentType.children || []).map(matchEventType => {
                return matchEventType.value;
            }),
        };
    });
};

export const areFormDataEqual = (dataToSave, formData) => {
    const {
        id,
        booking_type,
        event_price,
        is_hq,
        is_ll,
        event_content_type_configs,
        event_content_variants,
        is_active,
        num_bookable_events,
    } = formData;

    const initialValues = {
        id: id,
        booking_type: booking_type || null,
        event_price: formatPrice(event_price),
        is_hq: is_hq || null,
        is_ll: is_ll || null,
        event_content_type_configs: event_content_type_configs,
        event_content_variants: event_content_variants ? parseEventContentVariants(event_content_variants) : [],
        is_active: is_active,
        num_bookable_events: parseIntOrNull(num_bookable_events),
    };

    const newValues = {
        id: dataToSave.id,
        booking_type: dataToSave.booking_type,
        event_price: formatPrice(dataToSave.event_price),
        is_hq: dataToSave.is_hq,
        is_ll: dataToSave.is_ll,
        event_content_type_configs: dataToSave.event_content_type_configs,
        event_content_variants: dataToSave.event_content_variants,
        is_active: dataToSave.is_active,
        num_bookable_events: parseIntOrNull(dataToSave.num_bookable_events),
    };

    return _isEqual(newValues, initialValues);
};

export const parseEventContentVariants = (variants) => variants.map((variant) => convertToInt(variant.id));

