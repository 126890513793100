import {
    get as _get,
    isNumber as _isNumber,
} from 'lodash';

import {formatEventContentTypes, formatEventContentVariants} from '@modules/client/utils/clientContent/contentIndexHelpers';
export const createRowData = (clientPackage, data, parentData, keys, level) => {
    let row = {};

    const clientPackageContentData = data.client_package_content;

    for (let value of keys) {
        if (clientPackageContentData[value]) {
            const source = clientPackageContentData[value],
                parents = {
                    event_booking_type: null,
                    booking: null,
                    sport: null,
                    content_category: null,
                    content_tier: null,
                    tournament_category: null,
                    property_licence: null,
                    tournament: null,
                    event_content_type_configs: null,
                    event_content_variants: null,
                    is_hq: null,
                    is_ll: null,
                    territory_selection_type: null,
                    countries: null,
                    country_subdivisions: null,
                },
                values = {
                    event_booking_type: clientPackageContentData.event_booking_type,
                    booking: clientPackageContentData.booking_type,
                    events: '',
                    eventPrice: '',
                    event_content_type_configs: clientPackageContentData.event_content_type_configs,
                    event_content_variants: clientPackageContentData.event_content_variants,
                    is_hq: clientPackageContentData.is_hq,
                    is_ll: clientPackageContentData.is_ll,
                    territory_selection_type: null,
                    countries: [],
                    country_subdivisions: [],
                };

            if (!clientPackageContentData.booking_type) {
                if (1 < level) {
                    values.booking = parentData.parents.booking;
                }

                if (!values.booking) {
                    values.booking = clientPackage.booking_type;
                }
            }

            parents.booking = values.booking;

            if (!clientPackageContentData.event_booking_type) {
                if (1 < level) {
                    values.event_booking_type = parentData.parents.event_booking_type;
                }

                if (!values.event_booking_type) {
                    values.event_booking_type = clientPackage.event_booking_type;
                }
            }

            parents.event_booking_type = values.event_booking_type;

            if (!clientPackageContentData.is_hq) {
                if (1 < level) {
                    values.is_hq = parentData.parents.is_hq;
                }

                if (!values.is_hq) {
                    values.is_hq = clientPackage.is_hq;
                }
            }

            parents.is_hq = values.is_hq;

            if (!clientPackageContentData.is_ll) {
                if (1 < level) {
                    values.is_ll = parentData.parents.is_ll;
                }

                if (!values.is_ll) {
                    values.is_ll = clientPackage.is_ll;
                }
            }

            parents.is_ll = values.is_ll;

            if (!values.event_content_type_configs || !values.event_content_type_configs.length) {
                if (1 < level) {
                    values.event_content_type_configs = parentData.parents.event_content_type_configs;
                }
            }

            parents.event_content_type_configs = values.event_content_type_configs;

            if (!values.event_content_variants || !values.event_content_variants.length) {
                if (1 < level) {
                    values.event_content_variants = parentData.parents.event_content_variants;

                    if (!values.event_content_variants || !values.event_content_variants.length) {
                        values.event_content_variants = clientPackage.event_content_variants;
                    }
                }
            }

            parents.event_content_variants = values.event_content_variants;

            switch (level) {
                case 2:
                    parents.sport = clientPackageContentData.sport && clientPackageContentData.sport.id;
                    parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                    parents.content_tier = clientPackageContentData.content_tier && clientPackageContentData.content_tier.id;

                    break;
                case 3:
                    parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                    parents.content_tier = parentData.parents.content_tier;
                    parents.tournament_category = clientPackageContentData.tournament_category && clientPackageContentData.tournament_category.id;

                    break;
                case 4:
                    parents.content_category = parentData.parents.content_category;
                    parents.content_tier = parentData.parents.content_tier;
                    parents.tournament_category = parentData.parents.tournament_category;
                    parents.property_licence = clientPackageContentData.property_licence && clientPackageContentData.property_licence.id;

                    break;
                case 5:
                    parents.content_category = clientPackageContentData.content_category && clientPackageContentData.content_category.id;
                    parents.content_tier = parentData.parents.content_tier;
                    parents.tournament = clientPackageContentData.tournament && clientPackageContentData.tournament.id;
                    parents.property_licence = parentData.parents.property_licence;

                    break;
            }

            if (clientPackageContentData.sport?.id) {
                parents.sportId = clientPackageContentData.sport.id;
            } else {
                parents.sportId = parentData?.parents?.sportId ?? parentData?.sportId ?? null;
            }

            if (1 !== level) {
                values.events = (null !== clientPackageContentData.num_bookable_events
                    ? clientPackageContentData.num_bookable_events : parentData.num_bookable_events);

                values.eventPrice = (_isNumber(clientPackageContentData.event_price))
                    ? clientPackageContentData.event_price
                    : parentData.event_price;

                values.is_hq = (null !== clientPackageContentData.is_hq
                    ? clientPackageContentData.is_hq : parentData.is_hq);

                values.is_ll = (null !== clientPackageContentData.is_ll
                    ? clientPackageContentData.is_ll : parentData.is_ll);

                if (!clientPackageContentData.territory_selection_type) {
                    values.territory_selection_type = parentData.territory_selection_type;
                    values.countries = parentData.countries;
                    values.country_subdivisions = parentData.country_subdivisions;
                } else {
                    values.territory_selection_type = clientPackageContentData.territory_selection_type;
                    values.countries = clientPackageContentData.countries;
                    values.country_subdivisions = clientPackageContentData.country_subdivisions;
                }

                parents.territory_selection_type = values.territory_selection_type;
                parents.countries = values.countries;
                parents.country_subdivisions = values.country_subdivisions;
            }

            row = {
                id: clientPackageContentData.id,
                is_active: clientPackageContentData.is_active || false,
                key: clientPackageContentData.id,
                value: source.id,
                name: source.name,
                content: source.name,
                selected: clientPackageContentData.is_active,
                event_booking_type: values.event_booking_type,
                booking: values.booking,
                booking_type: (values.booking ? values.booking.id : null),
                event_content_type_configs: formatEventContentTypes(values.event_content_type_configs),
                event_content_variants: formatEventContentVariants(values.event_content_variants),
                num_bookable_events: values.events,
                event_price: values.eventPrice,
                parents: parents,
                clientPackageContentData: clientPackageContentData,
                clips: values.clips,
                is_hq: values.is_hq,
                is_ll: values.is_ll,
                territory_selection_type: values.territory_selection_type,
                countries: values.countries,
                country_subdivisions: values.country_subdivisions,
            };

            row.treeActiveStatus = {
                is_active: row.is_active,
                parent: _get(parentData, 'treeActiveStatus', null),
            };
        }
    }

    return row;
};
