import {get as _get} from 'lodash';

import {getParsedId} from '@utils/helpers';
import {getEventContentNameWithTypeName} from '@utils/eventContentVariant/eventContentVariant';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

export const getBasicEventBookingsColumnsForBookingsTable = (type) => {
    return {
        [`${type}Id`]: {
            label: 'ID',
            sorting: row => getParsedId(row),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'client': {label: 'Client', sorting: row => row.client.name},
        'content': {label: 'Content', sorting: row => getEventContentNameWithTypeName(row.distribution.event_content)},
        'product': {
            label: 'Product',
            sorting: row => _get(row, 'distribution.product.short_name', null),
        },
        'distributionType': {
            label: 'Distribution type',
            sorting: row => _get(row, 'distribution.distribution_type.name', null),
        },
        'deviceCategories': {label: 'Device categories', sorting: false, className: 'noPadding'},
        'countries': {label: 'Countries', sorting: false, className: 'noPadding'},
        'numberOfCountries': {
            label: '#Countries',
            sorting: false,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
            className: 'noPadding',
        },
        'blackoutZones': {label: 'Blackout Zones', sorting: false, className: 'noPadding'},
        'client_package': {
            label: 'Package',
            content: {
                align: 'left',
            },
            sorting: true,
        },
        'is_hq': {
            label: 'HQ',
            sorting: true,
            ...columnObjectAlignCenter,
        },
        'is_ll': {
            label: 'LL',
            sorting: true,
            ...columnObjectAlignCenter,
        },
    };
};
