import {
    isUndefined as _isUndefined,
} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {routes} from '@constants/routes';
import {
    PRODUCT_LCI,
    PRODUCT_LCO,
    PRODUCT_LCPP,
    PRODUCT_LCR,
    PRODUCT_LCT,
    PRODUCT_SPOTT,
    PRODUCT_THIRD_PARTY,
    PRODUCT_QB,
    EXPORT_EXCEL_URL_FRAGMENT,
} from '@constants/variables';

export const checkRouteIsAllowed = (activeRoute, client) => {
    if (_isUndefined(client)) {
        return true;
    }

    switch (activeRoute.active) {
        case routes.clients.products.lci.add.path:
            return (!activeRoute.tabs[routes.clients.products.lci.edit.path]);
        case routes.clients.products.lci.edit.path:
            return (activeRoute.tabs[routes.clients.products.lci.edit.path]);
        case routes.clients.products.lcr.add.path:
            return (!activeRoute.tabs[routes.clients.products.lcr.edit.path]);
        case routes.clients.products.lcr.edit.path:
            return (activeRoute.tabs[routes.clients.products.lcr.edit.path]);
        case routes.clients.products.lco.add.path:
            return (!activeRoute.tabs[routes.clients.products.lco.edit.path]);
        case routes.clients.products.lco.edit.path:
            return (activeRoute.tabs[routes.clients.products.lco.edit.path]);
        case routes.clients.products.lcpp.add.path:
            return (!activeRoute.tabs[routes.clients.products.lcpp.edit.path]);
        case routes.clients.products.lcpp.edit.path:
            return (activeRoute.tabs[routes.clients.products.lcpp.edit.path]);
        case routes.clients.products.lct.add.path:
            return (!activeRoute.tabs[routes.clients.products.lct.edit.path]);
        case routes.clients.products.lct.edit.path:
            return (activeRoute.tabs[routes.clients.products.lct.edit.path]);
        case routes.clients.products.spott.add.path:
            return (!activeRoute.tabs[routes.clients.products.spott.edit.path]);
        case routes.clients.products.spott.edit.path:
            return (activeRoute.tabs[routes.clients.products.spott.edit.path]);
        case routes.clients.products.thirdParty.add.path:
            return (!activeRoute.tabs[routes.clients.products.thirdParty.edit.path]);
        case routes.clients.products.thirdParty.edit.path:
            return (activeRoute.tabs[routes.clients.products.thirdParty.edit.path]);
        case routes.clients.products.qb.add.path:
            return (!activeRoute.tabs[routes.clients.products.qb.edit.path]);
        case routes.clients.products.qb.edit.path:
            return (activeRoute.tabs[routes.clients.products.qb.edit.path]);
        default:
            return true;
    }
};

const findProduct = (clientProducts, productId) => clientProducts
    .find((clientProduct) => productId === convertToInt(clientProduct.product.id));
const isProductDefined = (product) => !_isUndefined(product);

const getProductStatus = (product, productName) => ({
    [`isDisabledProduct${productName}`]: product?.is_disabled || false,
    [`isTrialProduct${productName}`]: product?.is_trial || false,
});

export const getClientProductDetails = (clientData) => {
    if (_isUndefined(clientData)) {
        return {};
    }

    const clientProducts = clientData.clientProducts;
    const clientProductLci = findProduct(clientProducts, PRODUCT_LCI);
    const clientProductLcr = findProduct(clientProducts, PRODUCT_LCR);
    const clientProductLco = findProduct(clientProducts, PRODUCT_LCO);
    const clientProductLcpp = findProduct(clientProducts, PRODUCT_LCPP);
    const clientProductLct = findProduct(clientProducts, PRODUCT_LCT);
    const clientProductSpott = findProduct(clientProducts, PRODUCT_SPOTT);
    const clientProductThirdParty = findProduct(clientProducts, PRODUCT_THIRD_PARTY);
    const clientProductQb = findProduct(clientProducts, PRODUCT_QB);

    return {
        clientName: clientData.name,
        clientProductLci,
        clientProductLcr,
        clientProductLco,
        clientProductLcpp,
        clientProductLct,
        clientProductSpott,
        clientProductThirdParty,
        clientProductQb,
        hasLciProduct: isProductDefined(clientProductLci),
        hasLcrProduct: isProductDefined(clientProductLcr),
        hasLcoProduct: isProductDefined(clientProductLco),
        hasLcppProduct: isProductDefined(clientProductLcpp),
        hasLctProduct: isProductDefined(clientProductLct),
        hasSpottProduct: isProductDefined(clientProductSpott),
        hasThirdPartyProduct: isProductDefined(clientProductThirdParty),
        hasQuickBetsProduct: isProductDefined(clientProductQb),
        ...getProductStatus(clientProductLci, 'Lci'),
        ...getProductStatus(clientProductLco, 'Lco'),
        ...getProductStatus(clientProductLcpp, 'Lcpp'),
        ...getProductStatus(clientProductLcr, 'Lcr'),
        ...getProductStatus(clientProductLct, 'Lct'),
        ...getProductStatus(clientProductSpott, 'Spott'),
        ...getProductStatus(clientProductThirdParty, 'ThirdParty'),
        ...getProductStatus(clientProductQb, 'Qb'),
    };
};

export const hasRequestedProduct = (activePath, clientData) => {
    const clientProducts = clientData.clientProducts;
    let requestedProduct = null;

    if (activePath === routes.clients.products.lci.edit.path) {
        requestedProduct = PRODUCT_LCI;
    } else if (activePath === routes.clients.products.lcr.edit.path) {
        requestedProduct = PRODUCT_LCR;
    } else if (activePath === routes.clients.products.lco.edit.path) {
        requestedProduct = PRODUCT_LCO;
    } else if (activePath === routes.clients.products.lcpp.edit.path) {
        requestedProduct = PRODUCT_LCPP;
    } else if (activePath === routes.clients.products.lct.edit.path) {
        requestedProduct = PRODUCT_LCT;
    } else if (activePath === routes.clients.products.spott.edit.path) {
        requestedProduct = PRODUCT_SPOTT;
    } else if (activePath === routes.clients.products.thirdParty.edit.path
        || activePath === routes.clients.products.thirdParty.cdns.index.path
        || activePath === `${routes.clients.products.thirdParty.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || activePath === routes.clients.products.thirdParty.cdns.add.path
        || activePath === routes.clients.products.thirdParty.cdns.edit.path
        || activePath === routes.clients.products.thirdParty.pullOrigins.index.path
        || activePath === `${routes.clients.products.thirdParty.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || activePath === routes.clients.products.thirdParty.pullOrigins.add.path
        || activePath === routes.clients.products.thirdParty.pullOrigins.edit.path
        || activePath === routes.clients.products.thirdParty.streamNamesPush.index.path
        || activePath === `${routes.clients.products.thirdParty.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || activePath === routes.clients.products.thirdParty.streamNamesPush.add.path
        || activePath === routes.clients.products.thirdParty.streamNamesPush.edit.path
        || activePath === routes.clients.products.thirdParty.streamNamesPull.index.path
        || activePath === `${routes.clients.products.thirdParty.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || activePath === routes.clients.products.thirdParty.streamNamesPull.add.path
        || activePath === routes.clients.products.thirdParty.streamNamesPull.edit.path
        || activePath === routes.clients.products.thirdParty.vodStorages.index.path
        || activePath === `${routes.clients.products.thirdParty.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || activePath === routes.clients.products.thirdParty.vodStorages.add.path
        || activePath === routes.clients.products.thirdParty.vodStorages.edit.path
    ) {
        requestedProduct = PRODUCT_THIRD_PARTY;
    } else if (activePath === routes.clients.products.qb.edit.path) {
        requestedProduct = PRODUCT_QB;
    } else {
        return true;
    }

    return !_isUndefined(clientProducts.find((clientProduct) =>
        convertToInt(clientProduct.product.id) === requestedProduct
    ));
};
