import PropTypes from 'prop-types';
import React from 'react';
import {Divider, Dropdown, Header, Segment} from 'semantic-ui-react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {has as _has, get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import {gql, graphql, withApollo} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Link, {getLink} from '@appComponents/Link';
import MessageBox from '@appComponents/MessageBox';
import Tabs from '@appComponents/Tabs';
import {ButtonEdit, ButtonLinkDropdown} from '@appComponents/ButtonCollection';
import mapModulesToProps from '@utils/mapModulesToProps';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {
    hasEventContentWithLcppDistribution,
    renderEventLcppClientNotificationLogModalError,
} from '@modules/events/utils/eventsLcppClientNotificationLog';
import * as MESSAGES from '@constants/messages';
import * as RESOURCE from '@constants/resources';
import navigation from '@constants/navigation';
import {
    EXPORT_EXCEL_URL_FRAGMENT,
    EVENT_STATUS_REVOKED,
    EVENT_TYPE_SPORTRADAR,
    EVENT_TYPE_NON_SPORTRADAR,
    EVENT_NOT_PICKED,
    EVENT_STATUS_PLANNED,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import {routes} from '@constants/routes';
import * as eventsGraphQl from '@graphql/events/events';
import {GetEventPresetsByEvent} from '@graphql/eventPreset/query';
import {GetPropertyLicenceTechSetupTvStreamingFlag} from '@graphql/propertyLicenceTechSetup/query';
import {GetRightsScopeForCurrentEvent} from '@graphql/rightsScope/query';
import {GetEventContentsDistributionsProducts} from '@graphql/eventContent/query';
import {showMessageBox} from '@utils/messageBox';
import {EVENT} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import NotFound from '@modules/app/views/NotFound';
import EventsEventBookings from '@modules/events/views/EventsEventBookings';
import DropdownMoreButton from '@modules/events/views/EventsTabsLayout/DropdownMoreButton/DropdownMoreButton';
import EventDetails from '@modules/events//views/EventsTabsLayout/Details/EventDetails';
import EventStreamsIndex from '@modules/events/views/EventStreamsIndex';
import EventUsageTypesModel from '@modules/events/forms/UsageTypes/EventUsageTypesModel';
import EventUsageTypesForm from '@modules/events/forms/UsageTypes/EventUsageTypesForm';
import EventsBookings from '@modules/events/views/EventsBookings';
import EventsConfiguration from '@modules/events/views/EventsConfiguration';
import EventsGeoRestrictionsIndex from '@modules/events/views/EventsGeoRestrictionsIndex';
import EventsOfferings from '@modules/events/views/EventsOfferings';
import EventsReport from '@modules/events/views/EventsReport';
import EventsStreamConstraints from '@modules/events/views/EventsStreamConstraints';
import EventsTabsLayoutButtons from '@modules/events/components/EventsTabsLayoutButtons';
import EventsTabsLayoutHeader from '@modules/events/views/EventsTabsLayout/EventsTabsLayoutHeader';
import LinkEventToMatchIDForm from '@modules/events/forms/LinkEventToMatchIDForm';
import LinkEventToMatchIDModel from '@modules/events/forms/LinkEventToMatchIDModel';
import MatchDetails from '@modules/events/views/EventsTabsLayout/Details/MatchDetails';
import NonSportradarEventEditModel from '@modules/events/forms/NonSportradarEventEditModel';
import NonSportradarEventForm from '@modules/events/forms/NonSportradarEventForm';
import SportradarExistingEventModel from '@modules/events/forms/SportradarExistingEventModel';
import SportradarExistingForm from '@modules/events/forms/SportradarExistingForm';
import SportradarNonExistingEventEditModel from '@modules/events/forms/SportradarNonExistingEventEditModel';
import SportradarNonExistingEventForm from '@modules/events/forms/SportradarNonExistingEventForm';
import {RenderEventPresetsInfoLink} from '@modules/events/utils/EventsTabsLayout/RenderEventPresetsInfoLink';
import {eventFieldsList} from '@modules/events/utils/EventsTabsLayout/Details/EventDetails/eventfieldsList';
import {matchFieldsList} from '@modules/events/utils/EventsTabsLayout/Details/MatchDetails/matchFieldsList';
import EventLcppClientNotificationIndex from '@modules/events/views/EventLcppClientNotificationLogIndex';
import {isBeingEncodedQueryHandler, renderModalErrorObject} from '@modules/events/utils/eventContentHelper';
import {CANT_DELETE_EVENT} from '@modules/events/constants/messages';
import {showBroadcastStatusChangelogModal} from '@modules/events/utils/eventContentDistributionBroadcastStatusChangelog';

const FETCH_POLICY_NETWORK_ONLY = 'network-only';

const authorizationUpdateEventObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

class EventsTabsLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        Entity: PropTypes.object,
        EventContentsDistributionsProducts: PropTypes.object,
        EventData: PropTypes.object,
        EventPresetsData: PropTypes.shape({
            eventPresets: PropTypes.array,
        }),
        EventStatusesData: PropTypes.object,
        TvStreamingFlagData: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object.isRequired,
        Menu: PropTypes.object,
        modal: PropTypes.object,
        Modal: PropTypes.object,
        RightsScopesForCurrentEvent: PropTypes.shape({
            loading: PropTypes.bool,
            rightsScopeForCurrentEvent: PropTypes.array,
        }),
        DataRightsScopeForCurrentEvent: PropTypes.object,
        userPrivileges: PropTypes.object,
        onClick: PropTypes.func,
        timezone: PropTypes.string,
    };

    static defaultProps = {
        EventPresetsData: {
            eventPresets: [],
        },
        RightsScopesForCurrentEvent: {
            loading: false,
            rightsScopeForCurrentEvent: [],
        },
        timezone: null,
        match: {
            params: {
                id: null,
            },
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            active: this.getActiveTab(props),
            event: null,
            matchTableLoading: true,
            tabs: {},
            match: this.props.match,
            modal: this.props.modal,
            modalWasClosed: false,
            shouldOpenModal: false,
            isRightScopesForCurrentEventLoaded: false,
        };
    }

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.events_scheduling.key);
    }

    componentDidUpdate(prevProps) {
        const {
            RightsScopesForCurrentEvent: {loading: prevRightsScopesForCurrentEventLoading},
        } = prevProps;

        const {
            match: {
                params: {
                    id: eventId,
                },
            },
            RightsScopesForCurrentEvent: {
                loading: rightsScopesForCurrentEventLoading,
                rightsScopeForCurrentEvent: rightsScopeForCurrentEvent,
            },
        } = this.props;

        const isLcppClientNotificationsHistoryTabExist = _has(
                this.state,
                `tabs.${routes.events.lcppClientNotifications.path}`
            ),
            isEventContentsDistributionsDataLoading = this.props.EventContentsDistributionsProducts.loading;

        if (prevRightsScopesForCurrentEventLoading && !rightsScopesForCurrentEventLoading) {
            this.setState(() => ({isRightScopesForCurrentEventLoaded: true}));
        }

        this.displayLcppErrorMsg(isEventContentsDistributionsDataLoading, prevProps, isLcppClientNotificationsHistoryTabExist, eventId);

        if (
            !rightsScopesForCurrentEventLoading
            && prevRightsScopesForCurrentEventLoading !== rightsScopesForCurrentEventLoading
            && 0 === rightsScopeForCurrentEvent.length
        ) {
            showMessageBox(
                'mediaRightsWarning',
                'This event does not have valid media-rights. Please check the rights scopes of the property licence.',
                null,
                'warning',
                true
            );
        }

        if (JSON.stringify(this.state.tabs) !== JSON.stringify(this.getTabs(this.props))) {
            this.setState(() => ({
                tabs: {...this.getTabs(this.props)},
                active: this.getActiveTab(this.props),
            }));
        }

        if (this.state.active !== this.getActiveTab(this.props)) {
            this.setState(() => ({
                active: this.getActiveTab(this.props),
            }));
        }

        if (this.state.loading !== (this.props.EventData.loading || this.props.RightsScopesForCurrentEvent.loading)) {
            this.setState(() => ({
                loading: this.props.EventData.loading || this.props.RightsScopesForCurrentEvent.loading,
            }));
        }

        if (this.props.EventData.error && this.state.modalIsNotVisible
            || (this.props.match.params.id !== prevProps.match.params.id
            && !isUrlParamValid(eventId))) {
            this.displayEventErrorModal();
        }

        if (this.props.EventData.event !== prevProps.EventData.event) {
            this.prepareEventBasicInfo(prevProps);
        }

        if (this.props.EventData.event) {
            this.showPresetMsg(this.props);
        }

        if (
            this.props.match.path === getLink('events.linkToSportradarMatch')
            && this.state.modalWasClosed
        ) {
            this.props.history.push(
                getLink('events.configuration.index', {
                    id: this.props.match.params.id,
                }));
        }

        if (
            this.props.match.path === getLink('events.linkToSportradarMatch')
            && this.props.EventData.event
            && (
                null === this.props.EventData.event.sr_event
                || parseInt(this.props.EventData.event.event_type.id, 10) === EVENT_TYPE_NON_SPORTRADAR
            )
            && true === this.state.shouldOpenModal
        ) {
            this.openLinkToSrMatchModalError();
        }

        if (this.isEventPath(this.props.match.path)) {
            if (this.state.active !== this.getActiveTab(this.props)) {
                this.setState(() => ({active: this.getActiveTab(this.props)}));
            }
        }

        this.state.shouldOpenModal && this.loadModal(this.props.match.path, this.props.EventData);
    }

    isEventPath = (path) => {
        const eventRoutes = [routes.events.configuration.index.path,
            routes.events.eventBookings.index.path,
            routes.events.bookings.index.path,
            routes.events.edit.path,
            routes.events.configuration.content.target.edit.path,
            routes.events.configuration.content.target.audio.path,
            routes.events.configuration.content.target.subtitles.path,
            routes.events.streams.index.path,
            routes.events.configuration.content.changeEncodingStartTime.path,
            routes.events.configuration.content.changeEncodingEndTime.path,
            routes.events.configuration.content.changeEncodingStatus.path,
            routes.events.configuration.content.releaseAkamaiStreamId.path,
            routes.events.lcppClientNotifications.path];

        eventRoutes.forEach((route) => {
            return route === path;
        });
    };

    displayEventErrorModal = () => {
        this.props.Modal.setModal({
            header: false,
            isVisible : true,
            content : renderModalError('Event', getLink('events.index')),
        });

        this.setState({
            shouldOpenModal: false,
        });
    };

    displayLcppErrorMsg = (isEventContentsDistributionsDataLoading, prevProps, isLcppClientNotificationsHistoryTabExist, eventId) => {
        if (
            !isEventContentsDistributionsDataLoading
            && (routes.events.lcppClientNotifications.path === prevProps.match.path)
            && !isLcppClientNotificationsHistoryTabExist
        ) {
            if ('undefined' === typeof this.state.shouldOpenErrorModal) {
                this.setState(() => ({
                    shouldOpenErrorModal: false,
                }));

                this.props.Modal.setModal({
                    header: false,
                    isVisible: true,
                    content: renderEventLcppClientNotificationLogModalError(
                        eventId,
                        'This event doesn\'t have event content with a LCP-P distribution.'
                    ),
                });
            }
        }
    };

    openLinkToSrMatchModalError = () => {
        this.setState({shouldOpenModal: false});
        this.props.history.push(getLink('events.configuration.index', {id: this.props.match.params.id}));
        this.props.Modal.setModal({
            header: false,
            isVisible : true,
            content : renderModalError(
                null,
                getLink(
                    'events.configuration.index',
                    {id: this.props.match.params.id}
                ),
                'This event cannot be linked to a Sportradar match.', 'Go to event page'
            ),
        });
    };

    prepareEventBasicInfo = (prevProps) => {
        let event = this.props.EventData.event;

        this.setState(() => ({
            event: eventFieldsList(event),
        }));

        this.props.Entity.setEntity({
            name: 'event',
            data: event,
        });

        if (this.state.modalIsNotVisible && !this.state.modalWasClosed) {
            this.setState({
                shouldOpenModal: true,
            });
        }

        if (this.props.TvStreamingFlagData !== prevProps.TvStreamingFlagData) {
            const  isTvStreaming = _get(this.props.TvStreamingFlagData, 'technicalSetups.0.is_tv_streaming', false);

            this.setState(() => ({
                match: matchFieldsList(event, isTvStreaming),
                matchTableLoading: false,
                matchTable: matchFieldsList(event, isTvStreaming),
            }));
        }
    };

    showPresetMsg = (props) => {
        let {
            EventPresetsData: {eventPresets},
        } = props;
        let event = this.props.EventData.event;

        if (eventPresets && 0 < eventPresets.length) {
            let eventPresetsSorted = [].concat(eventPresets).sort((a, b) => (
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            ));
            showMessageBox(
                'eventPresetInfo',
                <RenderEventPresetsInfoLink
                    eventPresets={eventPresetsSorted}
                    propertyLicenceId={convertToInt(event.property_licence.id)}
                />,
                null,
                'info',
                true
            );
        }
    };

    getTabs = (nextProps) => {
        const event = _get(nextProps, 'EventData.event', {}),
            EVENT_ID = convertToInt(nextProps.match.params.id),
            eventContentsDistributionsProducts = _get(
                nextProps,
                'EventContentsDistributionsProducts.eventContents',
                []
            ),
            eventsEventBookingsIndexRoute = routes.events.eventBookings.index.path;

        const tabs = {
            [routes.events.configuration.index.path]: {
                key: routes.events.configuration.index.path,
                url: 'events.configuration.index',
                urlParams: {id: EVENT_ID},
                text: 'Configuration',
                render: <EventsConfiguration
                    matchTvStreamingEncoder = {_get(nextProps.EventData, 'event.sr_event.match.tv_streaming_encoder', null)}
                    isTvStreaming={_get(nextProps.TvStreamingFlagData, 'technicalSetups.0.is_tv_streaming', false)}
                />,
            },
            [routes.events.geoRestrictions.index.path]: {
                key: routes.events.geoRestrictions.index.path,
                url: 'events.geoRestrictions.index',
                urlParams: {id: EVENT_ID},
                text: 'Geo restrictions',
                render: <EventsGeoRestrictionsIndex
                    event={event}
                    eventArray={[EVENT_ID]}
                    rightsScopes={nextProps.RightsScopesForCurrentEvent.rightsScopeForCurrentEvent}
                />,
                resource: RESOURCE.EVENT_GEO_RESTRICTION,
            },
            [routes.events.streamConstraints.path]: {
                key: routes.events.streamConstraints.path,
                url: 'events.streamConstraints',
                urlParams: {id: EVENT_ID},
                text: 'Stream constraints',
                render: <EventsStreamConstraints eventId={EVENT_ID}/>,
                resource: RESOURCE.EVENT_STREAM_CONSTRAINT,
            },
            [routes.events.eventBookings.index.path]: {
                key:  routes.events.eventBookings.index.path,
                url: 'events.eventBookings.index',
                urlParams: {id: EVENT_ID},
                text: 'Event bookings',
                render: <EventsEventBookings/>,
                resource: RESOURCE.EVENT_BOOKING,
            },
            [routes.events.bookings.index.path]: {
                key: routes.events.bookings.index.path,
                url: 'events.bookings.index',
                urlParams: {id: EVENT_ID},
                text: 'Bookings',
                render: <EventsBookings/>,
                resource: RESOURCE.BOOKING,
            },
            [routes.events.offerings.index.path]: {
                key: routes.events.offerings.index.path,
                url: 'events.offerings.index',
                urlParams: {id: EVENT_ID},
                text: 'Offerings',
                render: <EventsOfferings/>,
                resource: RESOURCE.OFFERING,
            },
            [routes.events.streams.index.path]: {
                key: routes.events.streams.index.path,
                url: 'events.streams.index',
                urlParams: {id: EVENT_ID},
                text: 'Streams',
                render: <EventStreamsIndex/>,
                resource: RESOURCE.STREAM,
            },
            [routes.events.lcppClientNotifications.path]: {
                key: routes.events.lcppClientNotifications.path,
                url: 'events.lcppClientNotifications',
                urlParams: {id: EVENT_ID},
                text: 'LCP-P client notifications',
                render: <EventLcppClientNotificationIndex />,
                resource: RESOURCE.CLIENT_LCP_P_NOTIFICATION,
            },
            [routes.events.report.path]: {
                key: routes.events.report.path,
                url: 'events.report',
                urlParams: {id: EVENT_ID},
                text: 'Report',
                render: <EventsReport eventContents={nextProps.EventContentsDistributionsProducts.eventContents}/>,
            },
        };

        if (_isEmpty(_get(event, 'products', []))) {
            delete tabs[eventsEventBookingsIndexRoute];
        }

        if (!hasEventContentWithLcppDistribution(eventContentsDistributionsProducts)) {
            delete tabs[routes.events.lcppClientNotifications.path];
        }

        return tabs;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.events.index.path,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        let shouldOpenModal = urlChanged && urlIsNotIndex;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
            modalIsNotVisible: modalIsNotVisible,
        };
    }

    loadModal(path, data) {
        const sportradarEvent = _has(data, 'event.sr_event.id'),
            isExistingEvent = _has(data, 'event.sr_event.match.id');

        if (path === routes.events.edit.path) {
            if (isExistingEvent && sportradarEvent) {
                return this.loadModalForm(createForm(
                    SportradarExistingEventModel,
                    SportradarExistingForm,
                    {
                        id: data.event.id,
                    }
                ));
            } else if (!sportradarEvent) {
                return this.loadModalForm(createForm(
                    NonSportradarEventEditModel,
                    NonSportradarEventForm,
                    {
                        id: data.event.id,
                    }
                ));
            } else if (sportradarEvent && !isExistingEvent) {
                return this.loadModalForm(createForm(
                    SportradarNonExistingEventEditModel,
                    SportradarNonExistingEventForm,
                    {
                        id: data.event.id,
                    }
                ));
            }
        }

        if (path === routes.events.linkToSportradarMatch.path) {
            return this.loadModalForm(createForm(
                LinkEventToMatchIDModel,
                LinkEventToMatchIDForm,
                {
                    dataRequest: false,
                    eventStartDatetime: data.event.start_datetime,
                    id: data.event.sr_event.id,
                    /**
                     * To refetch with correct event ID,
                     * after link / edit match ID on EVENT
                     */
                    eventId: convertToInt(data.event.id),
                }
            ), data, 'small');
        }

        if (path === routes.events.usageTypes.path) {
            return this.loadModalForm(createForm(
                EventUsageTypesModel,
                EventUsageTypesForm,
                {
                    id: this.props.match.params.id,
                    dataRequest: false,
                }
            ), data, 'small');
        }

        if (path === routes.events.configuration.content.broadcastStatusChangelog.path) {
            if (this.props.match.params.id
                && this.props.match.params.contentId
                && this.props.match.params.distributionId
            ) {
                return showBroadcastStatusChangelogModal(null, {
                    eventId: this.props.match.params.id,
                    contentId: this.props.match.params.contentId,
                    distributionId: this.props.match.params.distributionId,
                });
            }

            this.props.history.replace(
                getLink('events.configuration.index', {id: this.props.match.params.id})
            );
        }
    }

    loadModalForm = (Form, data, size = 'small') => {
        this.props.Modal.setModal({
            size: size,
            header: false,
            isVisible: true,
            content: <Form formData={data}/>,
        });
    };

    getActiveTab = (params) => {
        const
            eventGeoRestrictionRoutes = routes.events.geoRestrictions,
            bookingRoutes = routes.events.bookings,
            eventsEventBookingsRoutes = routes.events.eventBookings;

        let active = params.match.path;

        switch (active) {
            case routes.events.edit.path:
            case routes.events.configuration.content.add.path:
            case routes.events.configuration.content.edit.path:
            case routes.events.configuration.content.input.path:
            case routes.events.configuration.content.distribution.path:
            case routes.events.configuration.content.thirdPartyClients.path:
            case routes.events.linkToSportradarMatch.path:
            case routes.events.usageTypes.path:
            case routes.events.configuration.content.target.add.path:
            case routes.events.configuration.content.target.edit.path:
            case routes.events.configuration.content.target.audio.path:
            case routes.events.configuration.content.target.subtitles.path:
            case routes.events.configuration.content.cdnConfiguration.add.path:
            case routes.events.configuration.content.thirdPartyClientVodStorage.add.path:
            case routes.events.configuration.content.changeEncodingStartTime.path:
            case routes.events.configuration.content.changeEncodingEndTime.path:
            case routes.events.configuration.content.changeEncodingStatus.path:
            case routes.events.configuration.content.releaseAkamaiStreamId.path:
            case routes.events.configuration.content.broadcastStatusChangelog.path:
                active = routes.events.configuration.index.path;
                break;
        }

        if (active === eventGeoRestrictionRoutes.add.path
            || active === eventGeoRestrictionRoutes.editGeneralInformation.path
            || active === eventGeoRestrictionRoutes.editGeoRestrictions.path
            || active === eventGeoRestrictionRoutes.blackoutZones.path
        ) {
            active = eventGeoRestrictionRoutes.index.path;
        }

        if (active === `${routes.events.streams.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === routes.events.streams.add.path
            || active === routes.events.streams.edit.path
        ) {
            active = routes.events.streams.index.path;
        }

        if (active === `${eventsEventBookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === eventsEventBookingsRoutes.add.path
            || active === eventsEventBookingsRoutes.edit.path
            || active === eventsEventBookingsRoutes.clips.path
            || active === eventsEventBookingsRoutes.editGeoRestrictions.path
            || active === eventsEventBookingsRoutes.blackoutZones.path
        ) {
            active = eventsEventBookingsRoutes.index.path;
        }

        if (active === `${bookingRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === bookingRoutes.add.path
            || active === bookingRoutes.edit.path
            || active === bookingRoutes.editGeoRestrictions.path
            || active === bookingRoutes.blackoutZones.path) {
            active = bookingRoutes.index.path;
        }

        if (active === routes.events.incidents.add.path
            || active === routes.events.incidents.edit.path) {
            active = routes.events.report.path;
        }

        if (active === `${routes.events.offerings.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`) {
            active = routes.events.offerings.index.path;
        }

        if (active === routes.events.lcppClientNotifications.path) {
            active = routes.events.lcppClientNotifications.path;
        }

        return active;
    };

    handleOpenLinkToMatchIDModal = () => {
        this.props.history.push(
            getLink(
                'events.linkToSportradarMatch',
                {id: this.props.match.params.id}
            )
        );
    };

    /**
     * Open popup for "usage types" configuration for event
     */
    handleOpenPopupEventUsageTypes = () => {
        this.props.history.push(
            getLink(
                'events.usageTypes',
                {id: this.props.match.params.id}
            )
        );
    };

    changeEventStatus = (status) => {
        let mutation = null;
        let dataToSave;

        if (EVENT_TYPE_SPORTRADAR === convertToInt(this.props.EventData.event.event_type.id)) {
            mutation = eventsGraphQl.updateSportradarEventStatus;
            dataToSave = {
                event: {
                    event_status: convertToInt(status.id),
                },
                id: convertToInt(this.props.EventData.event.sr_event.id),
            };
        } else if (parseInt(this.props.EventData.event.event_type.id, 10) === EVENT_TYPE_NON_SPORTRADAR) {

            mutation = eventsGraphQl.updateNonSportradarEventStatus;

            dataToSave = {
                event: {
                    event_status: convertToInt(status.id),
                },
                id: convertToInt(this.props.EventData.event.av_event.id),
            };
        }

        this.setState(() => ({
            loading: true,
        }));

        this.props.client.mutate({
            mutation: gql`${mutation}`,
            variables: {...dataToSave},
        }).then(() => {
            this.setState(() => ({
                loading: false,
            }));

            let message = `The status of the event has been changed to "${status.name}" successfully.`;
            let statusId = convertToInt(status.id);

            if (EVENT_STATUS_REVOKED === statusId || EVENT_NOT_PICKED === statusId) {
                message = (
                    <div>
                        {message}
                        <br/>
                        {'The product status has been changed to "Disabled" for all of the distributions of the event.'}
                    </div>
                );
            }

            if (EVENT_STATUS_PLANNED === statusId) {
                message = (
                    <div>
                        {message}
                        <br/>
                        {'The product status has been changed to "Announced" for all of the distributions of the event.'}
                    </div>
                );
            }

            refetchQueryByName('EventQuery');
            showMessageBox(
                'eventLayout',
                message,
                null,
                'success'
            );
        }).catch((error) => {
            this.setState(() => ({
                loading: false,
            }));

            showMessageBox(
                'eventLayout',
                MESSAGES.NOT_SAVED('event status'),
                `${error}`,
                'error'
            );
        });
    };

    showErrorMessage = (error) => {
        showMessageBox(
            'eventLayout',
            MESSAGES.NOT_DELETED('Event'),
            `${error}`,
            'error'
        );
    };

    showDeleteConfirmationModal = () => {
        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content='Delete event' />,
            text: MESSAGES.DELETE_CONFIRMATION('Event'),
            onYes: () => {
                this.props.Modal.setModalConfirmation({
                    text: MESSAGES.DELETING(`event ${convertToInt(this.props.EventData.event.id)}`),
                });

                this.props.client.mutate({
                    mutation: gql`${eventsGraphQl.srEventDeleteMutation}`,
                    variables: {
                        id: convertToInt(this.props.EventData.event.id),
                    },
                }).then(() => {
                    this.props.history.push(getLink('events.index'));
                    showMessageBox(
                        'eventsIndex',
                        MESSAGES.DELETED('Event'),
                        null,
                        'success'
                    );
                }).catch((error) => {
                    this.showErrorMessage(error);
                });
            },
        });
    };

    deleteEvent = () => {
        const eventId = _get(
                this.props,
                'EventData.event.id',
                null
            ),
            variables = {
                id: eventId,
                is_event: true,
            };

        isBeingEncodedQueryHandler(variables)
            .then((result) => {
                if (result.data.isBeingEncoded) {
                    this.props.Modal.setModalError(renderModalErrorObject(CANT_DELETE_EVENT));
                } else {
                    this.showDeleteConfirmationModal();
                }
            })
            .catch((error) => {
                this.showErrorMessage(error);
            });
    };

    renderStatusOptions = () => {
        const currentEventStatusId = this.props.EventData.event.event_status.id;

        return _get(this.props, 'EventStatusesData.eventStatuses', []).map(el => {
            const handleChangeEventStatus = () => this.changeEventStatus(el);

            if (el.id !== currentEventStatusId) {
                return(
                    <Dropdown.Item
                        text={el.name}
                        key={el.id}
                        onClick={handleChangeEventStatus}
                    />
                );
            }
        }
        );
    };

    handleDeleteEvent = () => this.deleteEvent();

    render() {
        const {event} = this.props.EventData;

        if (event
            && this.props.match.path.includes('/events/:id/event-bookings')
            && _isEmpty(_get(event, 'products', []))
        ) {
            return <NotFound />;
        }

        return (
            <div>
                {this.props.EventData.event && this.state.event && this.props.EventStatusesData.eventStatuses && !this.state.loading ? (
                    <div>
                        <MessageBox name='eventLayout'/>
                        <HeaderRenderer
                            messagesBoxNames={['event', 'eventsConfiguration', 'automaticTargetGeneration']}
                            pageTitle={(<EventsTabsLayoutHeader event={this.props.EventData.event} />)}
                            buttons={EventsTabsLayoutButtons}
                        />
                        <Authorization authorization={authorizationUpdateEventObject}>
                            <Link name='events.edit' params={{id: this.props.EventData.event.id}}>
                                <ButtonEdit>Edit</ButtonEdit>
                            </Link>
                            <ButtonLinkDropdown text='Status'>
                                {this.renderStatusOptions()}
                            </ButtonLinkDropdown>
                        </Authorization>
                        <DropdownMoreButton
                            event={this.props.EventData.event}
                            handleDeleteEvent={this.handleDeleteEvent}
                            handleOpenLinkToMatchIDModal={this.handleOpenLinkToMatchIDModal}
                            handleOpenPopupEventUsageTypes={this.handleOpenPopupEventUsageTypes}
                        />
                        <div className='--marginTop-10'>
                            <MessageBox name='eventPresetInfo'/>
                            <MessageBox name='mediaRightsWarning'/>
                        </div>
                        <EventDetails event={this.state.event} />
                        <MatchDetails match={this.state.matchTable} loading={this.state.matchTableLoading} />

                        <Divider/>

                        <Tabs items={this.state.tabs} active={this.state.active}/>
                    </div>
                ) : (
                    <Segment basic loading className='--full-height'/>
                )}
            </div>
        );
    }
}

const EventQuery = gql(eventsGraphQl.event);
const EventStatusesQuery = gql(eventsGraphQl.eventStatuses);

const EventsTabsLayoutWithGraphQL = compose(
    graphql(EventQuery, {
        options: (props) => ({
            fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
            notifyOnNetworkStatusChange: true,
            variables: {
                id: convertToInt(props.match.params.id),
            },
        }),
        name: 'EventData',
    }),
    graphql(EventStatusesQuery, {name: 'EventStatusesData'}),
    graphql(GetEventPresetsByEvent, {
        options: (props) => ({
            fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
            notifyOnNetworkStatusChange: true,
            variables: {event: convertToInt(props.match.params.id)},
        }),
        name: 'EventPresetsData',
    }),
    graphql(GetRightsScopeForCurrentEvent, {
        name: 'RightsScopesForCurrentEvent',
        skip: props => (props.EventData.loading || !props.EventData.event),
        options: (props) => ({
            fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
            notifyOnNetworkStatusChange: true,
            variables: {event: convertToInt(props.match.params.id)},
        }),
    }),
    graphql(GetRightsScopeForCurrentEvent, {
        name: 'DataRightsScopeForCurrentEvent',
        skip: (props) =>
            props.EventData.loading
            || !props.EventData.event,
        options: (props) => ({
            fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
            notifyOnNetworkStatusChange: true,
            variables: {
                event: convertToInt(props.match.params.id),
            },
        }),
    }),
    graphql(GetPropertyLicenceTechSetupTvStreamingFlag, {
        name: 'TvStreamingFlagData',
        skip: props => (props.EventData.loading || !props.EventData.event),
        options: (props) => ({
            fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
            notifyOnNetworkStatusChange: true,
            variables: {
                id: (props.EventData.loading || !props.EventData.event)
                    ? null
                    : convertToInt(props.EventData.event.property_licence.id),
            },
        }),
    }),
    graphql(GetEventContentsDistributionsProducts, {
        options: (props) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
            variables: {
                eventId: convertToInt(props.match.params.id),
            },
        }),
        name: 'EventContentsDistributionsProducts',
    })
)(EventsTabsLayout);

const mapStateToProps = (state) => ({
    modal: state.modal,
    timezone: _get(state, 'timezone.timezone', null),
});

export default withApollo(withRouter(connect(
    mapStateToProps,
    mapModulesToProps(['Menu', 'Modal', 'Entity']))(EventsTabsLayoutWithGraphQL))
);
