import moment from 'moment';

import validators from '@utils/validators';
import {COMMENT_LIMIT} from '@constants/variables';

const validate = (values) => {
    const errors = {};

    if (!values.lasted_entire_duration) {
        Object.assign(errors, validators.dateTime(values.start_datetime, 'start_datetime'));
        Object.assign(errors, validators.dateTime(values.end_datetime, 'end_datetime'));

        if (values.end_datetime && moment(values.start_datetime).isAfter(values.end_datetime)) {
            Object.assign(errors, {end_datetime: 'End date must be later than the Start date'});
        }
    }

    Object.assign(errors, validators.required(values.location, 'location'));
    Object.assign(errors, validators.required(values.causer, 'causer'));
    Object.assign(errors, validators.required(values.event_incident_reason, 'event_incident_reason'));
    Object.assign(errors, validators.required(values.event_contents, 'event_contents'));
    Object.assign(errors, validators.maxLength({value: values.comment, maxLength: COMMENT_LIMIT}, 'comment'));

    if (!values.affects_all_output_streams) {
        Object.assign(errors, validators.required(values.event_incident_scopes, 'event_incident_scopes'));
    }

    return errors;
};

export default validate;
