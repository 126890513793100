import {isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {prepareStringForCidrSorting} from '@utils/CidrHelpers';
import {loadModalForm, openDisableModal as openDisableModalHelper} from '@utils/disableCidrHelpers';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {GetBlacklistedIpsForTable} from '@graphql/blacklistedIp/query';
import {isUrlParamValid} from '@utils/helpers';

import {IpBlacklistingIndexButtons} from '../components/IpBlacklistingIndexButtons';
import {IpBlacklistingIndexInfoMessage} from '../components/IpBlacklistingIndexInfoMessage';
import IpBlacklistingTable from '../components/IpBlacklistingTable';
import IpBlacklistingEdit from './IpBlacklistingEdit';
import IpBlacklistingForm from '../forms/IpBlacklistingForm';
import IpBlacklistingModelEdit from '../forms/IpBlacklistingModelEdit';
import {exportExcel} from '../utils/export/ipBlacklistingIndexExcel';

export function IpBlacklistingIndex({history, match, Menu, isVisible, timezone}) {
    const {index, add, edit} = routes.ipBlacklisting,
        [modalVisibility, setModalVisibility] = useState(isVisible),
        [mutationSuccessCallback, setMutationSuccessCallback] = useState(null),
        {loading = true, error = {}, data = {}} = useQueryCustom({
            query: GetBlacklistedIpsForTable,
            options: {
                variables: {
                    notifyOnNetworkStatusChange: true,
                    client: match.params.id,
                },
                refetch: mutationSuccessCallback,
            },
        });

    if (!_isEmpty(error) || !isUrlParamValid(match.params.id)) {
        showModal({
            isVisible : true,
            content : renderModalError('Blacklisted IP', routes.ipBlacklisting.index.path),
        });
    }

    const {blacklistedIps} = data;

    useEffect(() => {
        Menu.storeMenu(navigation.tools.key);
    }, []);

    useEffect(() => {
        if (add.path === match.path) {
            showModal({
                isVisible: true,
                size: 'small',
                content: <IpBlacklistingEdit setMutationSuccessCallback={setMutationSuccessCallback} />,
            });
            setModalVisibility(true);
        } else if (edit.path === match.path) {
            loadModalForm(
                createForm(
                    IpBlacklistingModelEdit,
                    IpBlacklistingForm,
                    {
                        id: match.params.id,
                        setMutationSuccessCallback,
                    }
                ),
                match.params,
                setModalVisibility
            );
        }
    }, [match.path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            history.push(index.path);
        }
    }, [isVisible]);

    const openDisableModal = (value) => {
        const cidrData = {
            id: value.id,
            cidr: value.cidr,
        };

        openDisableModalHelper(
            cidrData,
            index.path,
            history,
            setMutationSuccessCallback,
            timezone
        );
    };

    return (
        <div>
            <HeaderRenderer
                buttons={IpBlacklistingIndexButtons}
                exportExcelParams={exportExcel(_sortBy(blacklistedIps, [prepareStringForCidrSorting]))}
                loading={loading}
                messagesBoxNames={['ipBlacklistingIndex', 'formInnerErrorMessage']}
                pageTitle='IP blacklisting'
            />
            <IpBlacklistingIndexInfoMessage/>
            <IpBlacklistingTable
                blacklistedIps={blacklistedIps}
                disableClick={openDisableModal}
                loading={loading}
            />
        </div>
    );
}

IpBlacklistingIndex.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    Menu: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    timezone: PropTypes.string.isRequired,
};

IpBlacklistingIndex.defaultProps = {
    match: {
        params: {
            id: null,
        },
    },
};

const mapStateToProps = (state) => ({
    isVisible: state.modal.isVisible,
    timezone: state.timezone.timezone,
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Menu']))(IpBlacklistingIndex));
