export const ENV_ERROR_REPORT = ['development'];

export const SECURITY_PRIVILEGES_CREATE = 0b0001;
export const SECURITY_PRIVILEGES_READ = 0b0010;
export const SECURITY_PRIVILEGES_UPDATE = 0b0100;
export const SECURITY_PRIVILEGES_DELETE = 0b1000;
export const SECURITY_PRIVILEGES_CREATE_READ = [SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_READ];
export const SECURITY_PRIVILEGES_READ_UPDATE = [SECURITY_PRIVILEGES_UPDATE, SECURITY_PRIVILEGES_READ];
export const SECURITY_PRIVILEGES_READ_DELETE = [SECURITY_PRIVILEGES_DELETE, SECURITY_PRIVILEGES_READ];
export const SECURITY_PRIVILEGES_CREATE_READ_UPDATE = [SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE];
export const SECURITY_PRIVILEGES_CREATE_READ_UPDATE_DELETE = [SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_READ, SECURITY_PRIVILEGES_UPDATE, SECURITY_PRIVILEGES_DELETE];
export const SECURITY_PRIVILEGES_CREATE_UPDATE_DELETE = [SECURITY_PRIVILEGES_CREATE, SECURITY_PRIVILEGES_UPDATE, SECURITY_PRIVILEGES_DELETE];

export const TABLE_DATA_LIMIT = 500;

export const COMMENT_LIMIT = 1000;

export const NOTES_LIMIT = 200;

export const DESCRIPTION_LIMIT = 200;

export const BOOKING_TYPE_AVCMP_MANUAL = 3;

export const CONTACT_PERSON_TYPE_CLIENT_KEY_ACCOUNT_MANAGER = 7;
export const CONTACT_PERSON_TYPE_CLIENT_SALES_REPRESENTATIVE = 6;
export const CONTACT_PERSON_TYPE_CLIENT_CONTACT = 5;
export const CONTACT_PERSON_TYPE_ACCREDITATION = 4;
export const CONTACT_PERSON_TYPE_LICENSEE = 3;
export const CONTACT_PERSON_TYPE_LICENSOR = 1;
export const CONTACT_PERSON_TYPE_SPORTRADAR = 2;
export const CONTACT_PERSON_TYPE_PRODUCTION_COMPANY_CONTACT = 8;
export const CONTACT_PERSON_TYPE_PRODUCTION_COMPANY_CONTACT_NAME = 'Production company contact';
export const CONTACT_PERSON_TYPE_VENUE_CONTACT = 9;
export const CONTACT_PERSON_TYPE_VENUE_CONTACT_NAME = 'Venue contact';

export const PROPERTY_LICENCE_TYPE_SPORTRADAR = 1;
export const PROPERTY_LICENCE_TYPE_RACE = 2;
export const PROPERTY_LICENCE_TYPE_OTHER = 3;

export const LCR_CONTENT_SELECTION_TYPE_ALL = 1;
export const LCR_CONTENT_SELECTION_TYPE_WHITELIST = 2;
export const LCR_CONTENT_SELECTION_TYPE_BLACKLIST = 3;

export const TERRITORY_SELECTION_TYPE_WORLDWIDE = 1;
export const TERRITORY_SELECTION_TYPE_WHITELIST = 2;
export const TERRITORY_SELECTION_TYPE_BLACKLIST = 3;
export const MAX_COUNTRIES_LENGTH = 10;

export const EXCLUSIVITY_SELECTION_EXCLUSIVE = 1;
export const EXCLUSIVITY_SELECTION_NON_EXCLUSIVE = 2;
export const EXCLUSIVITY_SELECTION_OTHERS = 3;

export const EVENT_SPORTRADAR_TYPE = 1;
export const EVENT_NON_SPORTRADAR_TYPE = 2;

export const SUB_LICENSING_SELECTION_FREE = 1;
export const SUB_LICENSING_SELECTION_OTHERS = 2;

export const CHANGELOG_ACTION_TYPE_CREATION = 1;

export const CHANGELOG_ACTION_TYPE_MODIFICATION = 2;

export const CHANGELOG_ACTION_TYPE_DELETION = 3;

export const ARCHIVED_OR_NOT = -1;
export const ARCHIVED = 1;
export const NOT_ARCHIVED = 0;
export const ORIGIN_HOSTING_PROVIDER_AMAZON = 2;

export const SPORT_TENNIS = 5;
export const SPORT_BADMINTON = 31;
export const SPORT_VOLLEYBALL = 23;
export const TOURNAMENT_ITF_MEN = 785;
export const TOURNAMENT_ITF_WOMEN = 213;
export const TOURNAMENT_CATEGORY_ITF_MEN = 785;
export const TOURNAMENT_CATEGORY_ITF_WOMEN = 213;

export const SR_EVENT = 1;
export const AV_EVENT = 2;

export const TRIGGER_MANUAL_OPTION = '1';
export const TRIGGER_MARGIN_OPTION = '2';
export const TRIGGER_DATE_OPTION = '3';

export const ENCODER_TYPE_SPORTRADAR = 1;
export const ENCODER_TYPE_MANUAL = 2;
export const STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION = '4';

export const ORIGIN_STREAM_TYPE_RTMP = 1;
export const ORIGIN_STREAM_TYPE_UDP = 2;
export const ORIGIN_STREAM_TYPE_SDI = 3;
export const ORIGIN_STREAM_TYPE_HTTP = 7;
export const ORIGIN_STREAM_TYPE_MEDIACONNECT = 8;
export const ORIGIN_STREAM_TYPE_SRT = 9;

export const STREAM_PROTOCOL_DASH = 3;
export const STREAM_PROTOCOL_CMAF = 6;

export const DISTRIBUTION_TYPE_LIVE = 1;
export const DISTRIBUTION_TYPE_RECORDING = 2;
export const DISTRIBUTION_TYPE_VOD = 3;

export const INGEST_METHOD_DISTRIBUTION_TYPES = [DISTRIBUTION_TYPE_LIVE, DISTRIBUTION_TYPE_RECORDING];

export const CLIENT_SELECTION_TYPE_ALL = 1;
export const CLIENT_SELECTION_TYPE_WHITELIST = 2;
export const CLIENT_SELECTION_TYPE_BLACKLIST = 3;

export const COMPETITOR_NOT_DEFINED_BOTH = 'No competitors defined';
export const COMPETITOR_NOT_DEFINED = 'Undefined competitor';

export const PRODUCT_LCR = 1;
export const PRODUCT_LCO = 2;
export const PRODUCT_LCT = 4;
export const PRODUCT_SPOTT = 5;
export const PRODUCT_THIRD_PARTY = 6;
export const PRODUCT_LCPP = 8;
export const PRODUCT_LCI = 9;
export const PRODUCT_QB = 10;

export const EVENT_TYPE_SPORTRADAR = 1;
export const EVENT_TYPE_NON_SPORTRADAR = 2;

export const BROADCAST_STATUS_ON_AIR_READY= 1;
export const BROADCAST_STATUS_ON_AIR_BROADCAST = 2;
export const BROADCAST_STATUS_ERROR = 3;
export const BROADCAST_STATUS_DELIVERED= 4;
export const BROADCAST_STATUS_APPROVED = 5;
export const BROADCAST_STATUS_CREATED = 6;

export const ENCODING_STATUS_PREPARING = 1;
export const ENCODING_STATUS_ENCODING = 2;
export const ENCODING_STATUS_STOPPING = 3;
export const ENCODING_STATUS_ERROR = 4;
export const ENCODING_STATUS_ENDED = 5;
export const ENCODING_STATUS_SCHEDULED = 6;

export const ENCODING_END_MARGIN = 4;
export const ENCODING_START_MARGIN = 10;

export const EVENT_STATUS_PLANNED = 3;
export const EVENT_STATUS_REVOKED = 5;
export const EVENT_NOT_PICKED = 4;

export const RTS_COVERAGE_CONFIRMED = 2;

export const THIRD_PARTY_CLIENT_TYPE_PUSH = 2;
export const THIRD_PARTY_CLIENT_TYPE_PULL = 3;

export const DEVICE_CATEGORY_DESKTOP = 1;
export const DEVICE_CATEGORY_MOBILE = 2;
export const DEVICE_CATEGORY_SMART_TV = 4;

export const DEVICE_CATEGORY_DESKTOP_LABEL = 'Desktop';
export const DEVICE_CATEGORY_MOBILE_LABEL = 'Mobile';
export const DEVICE_CATEGORY_SMART_TV_LABEL = 'Smart TV';

export const AKAMAI_RTMP_DISTRIBUTION_REGION_ASIA = 2;
export const AKAMAI_RTMP_DISTRIBUTION_REGION_EUROPE = 1;

export const CDN_AKAMAI = 1;
export const CDN_AKAMAI_2 = 4;
export const CDN_LIMELIGHT = 2;
export const CDN_THIRD_PARTY = 3;

export const FIELD_TYPE_MULTIPLE = 'multiple';

export const IP_VERSION_IP_V4 = 1;
export const IP_VERSION_IP_V6 = 2;

export const DEBUG_PLATFORM_LINK = 'http://streaming-monitor.sportradar.ag';
export const VIDEO_MANAGEMENT_PLATFORM_LINK = 'https://vmp.sportradar.online';

/**
 * Links in Dropdown on Event main page after click on "More" button.
 */
export const SCOUT_MANAGEMENT_PLATFORM_MATCH_DETAILS_LINK = 'https://www.sportsdata.ag/default/match-details/index/id/';
export const LIVE_DATA_CLIENT_MATCH_NO_ENDED_LINK = 'https://livedataclient.betradar.com/match/';
export const LIVE_DATA_CLIENT_MATCH_ENDED_LINK = 'https://livedataclient.betradar.com/smp/bc/match/';
export const COMPETITOR_FLAG_LINK = 'https://img.sportradar.com/ls/crest/small/';

export const REQUIRE_THREE_LETTERS_PLACEHOLDER = 'Start typing at least 3 characters...';

export const COLOR_DEFAULT = '3a87ad';

export const STREAM_FORMAT_FLV = 1;
export const STREAM_FORMAT_MP4 = 2;
export const STREAM_FORMAT_DASH = 5;

export const STREAM_PROTOCOL_RTMP = 1;
export const STREAM_PROTOCOL_HLS = 2;
export const STREAM_PROTOCOL_FILE = 4;
export const STREAM_PROTOCOL_WEBSOCKET =  5;
export const STREAM_PROTOCOL_LL_HLS = 8;

export const BITRATE_VIDEO_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT = 450000;
export const BITRATE_VIDEO_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT = 120000;

export const BITRATE_AUDIO_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT = 64000;
export const BITRATE_AUDIO_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT = 4800;

export const AKAMAI_CP_CODE_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT = 508682;

export const PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT = {
    device_category: DEVICE_CATEGORY_DESKTOP.toString(),
    bitrate_video: BITRATE_VIDEO_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT,
    bitrate_audio: BITRATE_AUDIO_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT,
    stream_format: STREAM_FORMAT_FLV.toString(),
    stream_protocol: STREAM_PROTOCOL_RTMP.toString(),
    akamai_cp_code: AKAMAI_CP_CODE_FOR_ENCODING_TARGET_MANUAL_LIVE_LCT,
    akamai_rtmp_distr_region: AKAMAI_RTMP_DISTRIBUTION_REGION_EUROPE.toString(),
};

export const PRE_FILL_OBJECT_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT = {
    device_category: DEVICE_CATEGORY_DESKTOP.toString(),
    bitrate_video: BITRATE_VIDEO_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT,
    bitrate_audio: BITRATE_AUDIO_FOR_ENCODING_TARGET_MANUAL_LIVE_SPOTT,
    stream_format: STREAM_FORMAT_MP4.toString(),
    stream_protocol: STREAM_PROTOCOL_HLS.toString(),
    akamai_cp_code: null,
    akamai_rtmp_distr_region: null,
};

export const AKAMAI_CDN_INGEST_METHOD_AMD_PULL = 1;
export const AKAMAI_CDN_INGEST_METHOD_STREAM_PACKAGING = 2;
export const AKAMAI_CDN_INGEST_METHOD_MSL_PUSH = 3;
export const LIVE_INGEST_METHOD_AKAMAI_STREAM_PACKAGING = 2;
export const LIVE_INGEST_METHOD_AKAMAI_MSL_PUSH = 3;

export const INGEST_METHOD_MSL_PUSH = 3;

export const WAY_OF_TRANSMISSION_LIVE = 1;
export const WAY_OF_TRANSMISSION_DELAYED = 2;
export const WAY_OF_TRANSMISSION_ON_DEMAND = 3;

export const EXPORT_EXCEL_URL_FRAGMENT = '/export/excel';

export const REGEX_SVG_SELECTOR = /<svg(.|\r\n|\r|\n)*svg>/;

export const STREAM_STATUS_UPLOADED = 2;

export const LIVE_CONTROL_ORANGE_WARNING_MINUTES = 3;

export const SPORTS_COURT_LIST = [SPORT_TENNIS, SPORT_BADMINTON];

export const UPLOAD_TARGET_TYPE_FTP = 2;
export const UPLOAD_TARGET_TYPE_AMAZON_S3 = 4;
export const UPLOAD_TARGET_TYPE_SFTP = 7;

export const EVENT_CONTENT_BOOKED_BY_CLIENT = 1;
export const EVENT_CONTENT_OFFERED_BY_CLIENT = 2;
export const EVENT_CONTENT_TYPE_FULLMATCH = 6;

export const DRM_PROVIDER_BUY_DRM = 1;

export const PULL = 'pull';
export const PUSH = 'push';

export const ALERT_TYPE_INERRUPTION = 3;
export const ALERT_TEMPLATE_INTERRUPTION_RESOLUTION = 30;

export const BOOKMAKER_TRONDHEIMTEST_ID = 24;

export const SPORTRADAR_DOMAIN = 'sportradar.com';

export const STREAM_DELAY_TYPE_LIVE_NAME = 'Live';

export const CLIENT_PACKAGE_TYPE_MSM = 1;

export const TV_STREAMING_PRODUCTION_COMPANY = 386;
export const TV_STREAMING_CONTACT_PERSON = 2728;
export const TV_STREAMING_RTMP_ORIGIN_ENTRY_POINT_APPLICATION_WARNING_ADD_MODE = (streamUrl) => (
    `Could not find a RTMP origin entry point application matching the TV Streaming encoder (${streamUrl}).`
);
export const TV_STREAMING_RTMP_ORIGIN_ENTRY_POINT_APPLICATION_WARNING_EDIT_MODE = (streamUrl) => (
    `This RTMP origin entry point application does not match the TV Streaming encoder (${streamUrl}).`
);
export const TV_STREAMING_RTMP_ORIGIN_STREAM_NAME_WARNING_ADD_MODE = (streamUrl) => (
    `Could not find a RTMP origin stream name matching the TV Streaming encoder (${streamUrl}).`
);
export const TV_STREAMING_RTMP_ORIGIN_STREAM_NAME_WARNING_EDIT_MODE = (streamUrl) => (
    `This RTMP origin stream name does not match the TV Streaming encoder (${streamUrl}).`
);

export const MINIMUM_SPEND_COMMITMENT_MAX = 99999999.99;
export const MINIMUM_SPEND_COMMITMENT_MIN = 0;
export const MINIMUM_SPEND_COMMITMENT_MAX_LENGTH = 11;
export const MINIMUM_SPEND_COMMITMENT_STEP = .01;

export const CONTENT_ACTIVATION_TICKET_NUMBER_MAX_LENGTH = 100;

export const TYPE_AUTOMATIC_TARGET_GENERATION = 'automaticTargetGeneration';

export const CLIENT_AV_INTERNAL = 222;

export const MATCH_EVENT_SELECTION_TOOLTIP = 'Defines if the event content type requires a selection of match event types. ' +
    'This information is useful only for products supporting booking based on event content type' +
    ' / match event type.';

export const EVENT_CONTENT_VARIANT_STANDARD_FEED = 1;

// STRING, as dropdown ID is string
export const EVENT_CONTENT_DEFAULT_DROPDOWN_CONTENT_VARIANT = '1';

export const EMPTY_VALUE_DASH = '-';

export const OVERLAY_TYPE_SYNERGY_EVO_GFX = '2';

export const ENCODING_REDUNDANCY_TYPE_MULTI_AZ_ENCODING = 2;

export const ADD_CONTACT_PERSON_TYPE_LICENSOR = ' - Add new licensor contact person - AV-CMP';

export const ADD_CONTACT_PERSON_TYPE_SPORTRADAR = ' - Add new Sportradar contact person - AV-CMP';

export const FORMAT_M3U = 'm3u';

export const SPORTRADAR_EXISTING_EVENT= 'Sportradar existing event';

export const FREE_TEXT = 'Free text';
