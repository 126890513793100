export const BasicData = `
    id
    name
`;

export const DropdownData = `
    id
    name
    key: id
    value: id
    text: name
`;

export const FragmentEncoderTypeData = `
    fragment EncoderTypeData on EncoderType {
        id
        name
    }
`;

export const FragmentEncoderTypeDropdown = `
    fragment EncoderTypeDropdown on EncoderType {
      id
      name
      key: id
      value: id
      text: name
    }
`;

export const FragmentStreamDelayTypeData = `
    fragment StreamDelayTypeData on StreamDelayType {
        id
        name
    }
`;

export const FragmentDeviceCategoryBasicData = `
    fragment DeviceCategoryBasicData on DeviceCategory {
        id
        name
    }
`;

export const FragmentLanguagesDropdown = `
    fragment LanguagesDropdown on Language {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentCDNsDropdown = `
    fragment CDNsDropdown on Cdn {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentAkamaiCdnIngestMethodsDropdown = `
    fragment AkamaiCdnIngestMethodsDropdown on AkamaiCdnIngestMethod {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentScoreboardOverlayPositionsDropdown = `
    fragment ScoreboardOverlayPositionsDropdown on ScoreboardOverlayPosition {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentEncodingJobProfilesDropdown = `
    fragment EncodingJobProfilesDropdown on EncodingJobProfile {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentAdTypesDropdown = `
    fragment AdTypesDropdown on AdType {
        id
        name
        key: id
        value: id
        text: name
    }
`;

export const FragmentClientDrmSetupsDropdown = `
    fragment ClientDrmSetupsDropdown on ClientDrmSetup {
        id
        client {
            id
            name
        }
    }
`;
