import { Icon, Popup } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

import { Authorization } from '@appComponents/Authorization';
import {
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
    ENCODING_STATUS_SCHEDULED,
    ORIGIN_STREAM_TYPE_UDP,
} from '@constants/variables';
import { authorizationLiveControlUpdateObject } from '@modules/liveControl/components/LiveControlTable/LiveControlTable';
import {handleUdpOriginEntryPointFailover} from '@modules/liveControl/utils/helpers';

export const UdpFailoverEntryPointPopup = ({row = {}}) => {
    if (row.event_content_input_id
        && ORIGIN_STREAM_TYPE_UDP === row.origin_stream_type_id
        && row.failover_udp_entry_point_id
    ) {
        let udpFailoverOriginEntryPointIcon = {
            content: `Failover to "${row.failover_udp_entry_point_name}"`,
            disabled: false,
            onClick: () => handleUdpOriginEntryPointFailover(row),
        };

        const encodingStatusList = [
            ENCODING_STATUS_SCHEDULED,
            ENCODING_STATUS_PREPARING,
            ENCODING_STATUS_ENCODING,
            ENCODING_STATUS_ERROR,
        ];

        if (encodingStatusList.includes(row.encoding_status_id)
            && row.udp_entry_point_encoding_datacenter_id !== row.failover_udp_entry_point_encoding_datacenter_id
        ) {
            udpFailoverOriginEntryPointIcon.content = `
                    Failover to "${row.failover_udp_entry_point_name}" is not possible while the event content is 
                    being encoded because its datacenter is not the same as the current UDP origin entry point. 
                    The encoding must be stopped first.
                `;
            udpFailoverOriginEntryPointIcon.disabled = true;
            delete udpFailoverOriginEntryPointIcon.onClick;
        }

        return (
            <Authorization authorization={authorizationLiveControlUpdateObject}>
                <Popup
                    key={`failover-udp-icon-${row.id}`}
                    trigger={
                        <Icon
                            className='--height-100-percent --marginLeft-0-5-em'
                            disabled={udpFailoverOriginEntryPointIcon.disabled}
                            name='retweet'
                            onClick={udpFailoverOriginEntryPointIcon.onClick}
                            rotated='clockwise'
                        />
                    }
                    flowing
                    content={udpFailoverOriginEntryPointIcon.content}
                    hoverable
                />
            </Authorization>
        );
    }

    return null;
};

UdpFailoverEntryPointPopup.propTypes = {
    row: PropTypes.object.isRequired,
};
