import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {withRouter} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";

import {EventRedirect} from "./EventRedirect";

import {convertToInt} from "../../../utils/helpers";
import useQueryCustom from "@utils/hooks/useQueryCustom";

import {GetEventRedirectByEventContent} from "../../../graphql/event/query.graphql";

export function EventRedirectByEventContent({
    history,
    match,
}) {
    const {
        data: {
            eventRedirectByEventContent
        } = {},
        error,
        loading: isDataLoading = true,
    } = useQueryCustom({
        query: GetEventRedirectByEventContent,
        options: {
            variables: {
                id: convertToInt(match.params.id),
            },
        },
    });

    return (isDataLoading && !error)
        ? (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        )
        : (
            <EventRedirect
                errorMessage="content"
                eventContentId={_get(eventRedirectByEventContent, 'event_content_id', null)}
                eventId={_get(eventRedirectByEventContent, 'event_id', null)}
                history={history}
            />
        );
}

EventRedirectByEventContent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

export default withRouter(EventRedirectByEventContent);
