import moment from 'moment';
import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {renderLcppClientNotificationLogModalError} from '@modules/client/utils/lcppClientNotificationLog';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {showModal} from '@utils/modal';
import {getSearchFilters} from '@utils/filters';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import {convertToInt} from '@utils/helpers';
import {GetLcppClientNotificationLog} from '@graphql/lcppClientNotificationLog/query';

import LcppClientNotificationLogTable from '../components/LcppClientNotificationLogTable';
import LcppClientNotificationLogFilters from '../components/LcppClientNotificationLogFilters';
import {exportExcel} from '../utils/export/lcppClientNotificationLogExcel';

const DEFAULT_FILTERS_VALUES = {
    search: '',
    from: moment().format('YYYY-MM-DD 00:00'),
    to: moment().add(1, 'd').format('YYYY-MM-DD 00:00'),
    propertyLicence: [],
    sport: [],
    tournament: [],
    tournamentCategory: [],
};

export function LcppClientNotificationLogIndex({
    filters,
    match: {
        params: {
            id,
        },
    },
}) {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        from: filters.from ? filters.from + ':00' : DEFAULT_FILTERS_VALUES.from,
        to: filters.to ? filters.to + ':00' : DEFAULT_FILTERS_VALUES.to,
        propertyLicence: filters.propertyLicence
            ? filters.propertyLicence.map(Number) :
            DEFAULT_FILTERS_VALUES.propertyLicence,
        sport: filters.sport ? filters.sport.map(Number) : DEFAULT_FILTERS_VALUES.sport,
        tournament: filters.tournament ? filters.tournament.map(Number) : DEFAULT_FILTERS_VALUES.tournament,
        tournamentCategory: filters.tournamentCategory
            ? filters.tournamentCategory.map(Number)
            : DEFAULT_FILTERS_VALUES.tournamentCategory,
    };

    const {
        data: {
            lcppClientNotificationsLog,
        } = {},
        error,
        loading: isDataLoading = true,
    } = useQueryCustom({
        query: GetLcppClientNotificationLog,
        options: {
            variables: {
                client: [convertToInt(id)],
                ...queryVariables,
            },
            refetch: filters.lastClickSubmit,
        },
    });

    if (!_isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderLcppClientNotificationLogModalError(id, error),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(lcppClientNotificationsLog, id)}
                loading={isDataLoading}
                filters={LcppClientNotificationLogFilters}
                filtersButtonName='LcppClientNotificationLog'
                messagesBoxNames='LcppClientNotificationLog'
            />
            <LcppClientNotificationLogTable
                lcppClientNotificationsLog={lcppClientNotificationsLog}
                loading={isDataLoading}
            />
        </div>
    );
}

LcppClientNotificationLogIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
};

const NotificationHistoryIndexWrapped = FilterQueryWrapper(LcppClientNotificationLogIndex, {
    queryForRefresh: 'GetLcppClientNotificationLog',
    filterUrls: ['clients.products.lcpp.notificationHistory'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'LcppClientNotificationLog', DEFAULT_FILTERS_VALUES),
});

export default withRouter(connect(mapStateToProps, null)(
    FilterUrlParamsWrapper(NotificationHistoryIndexWrapped, DEFAULT_FILTERS_VALUES)
));
