import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';
import {Icon, Label} from 'semantic-ui-react';

import {
    convertToInt,
    parseTvStreamingEncoderStreamUrl,
    replaceMatchIdPlaceholderInsideString,
} from '@utils/helpers';
import Authorization from '@appComponents/Authorization';
import Link from '@appComponents/Link';

import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';

const link = (data, path, resources) => {
    if (!data || !data.id || !data.name || !resources || !path) {
        return null;
    }

    return <Authorization
        notAuthorized={<span>{data.name}</span>}
        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
        resources={resources}
    >
        <Link name={path} params={{id: data.id}}>
            {data.name}
        </Link>
    </Authorization>;
};

const linkToContactPersonOfProductionCompany = (contactPerson, productionCompany) => {
    if (!contactPerson || !contactPerson.id || !contactPerson.name) {
        return null;
    }

    if (!productionCompany || !productionCompany.id) {
        return contactPerson.name;
    }

    return <Authorization
        combinedResources={true}
        notAuthorized={<span>{contactPerson.name}</span>}
        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
        resources={[RESOURCES.PRODUCTION_COMPANY, RESOURCES.CONTACT_PERSON]}
    >
        <Link
            name='productionCompanies.contactPersons.edit'
            params={{
                contactPersonId: contactPerson.id,
                id: productionCompany.id,
            }}
        >
            {contactPerson.name}
        </Link>
    </Authorization>;
};

const renderName = (value, matchId = '') => {
    if (!value || !value.id || !value.name) {
        return <Warning/>;
    } else {
        return replaceMatchIdPlaceholderInsideString(value.name, matchId);
    }
};

const SrtOriginEntryPoint = ({data}) => {
    if (!data.srt_origin_entry_point && !data.srt_origin_entry_point_url) {
        return <Warning/>;
    }
    let originEntryPoint;

    if (data.srt_origin_entry_point) {
        originEntryPoint = data.srt_origin_entry_point.name;
    } else {
        originEntryPoint =  data.srt_origin_entry_point_url;
    }

    if (null !== data.is_srt_origin_entry_point_in_failover && !data.is_srt_origin_entry_point_in_failover) {
        return <SrtLinkWithLabel url={originEntryPoint}/>;
    }

    return originEntryPoint;
};

const SrtFailoverOriginEntryPoint = ({data}) => {
    if (!data.failover_srt_origin_entry_point_url && !data.failover_srt_origin_entry_point) {
        return <Warning/>;
    }
    let failoverSrtOriginEntryPoint;

    if (data.failover_srt_origin_entry_point) {
        failoverSrtOriginEntryPoint = data.failover_srt_origin_entry_point.name;
    } else {
        failoverSrtOriginEntryPoint =  data.failover_srt_origin_entry_point_url;
    }

    if (data.is_srt_origin_entry_point_in_failover) {
        return <SrtLinkWithLabel url={failoverSrtOriginEntryPoint}/>;
    }

    return failoverSrtOriginEntryPoint;
};

const Warning = ({text}) => {
    return (
        <div className='warning'>
            <Icon name='warning sign' color='red'/>
            { text || 'Not defined!' }
        </div>
    );
};

const SrtLinkWithLabel = ({url}) => {
    return (
        <div>
            {url}
            <Label className='--marginLeft-0-5-em'>
                In use
            </Label>
        </div>
    );
};


export function parseDetails(data) {
    if (!data) {
        return null;
    }

    const details = [
        [
            {
                name: 'Contribution type',
                value: data.contribution_type.name,
            },
            {
                name: 'Encoding datacenter',
                value: data.encoding_datacenter.name,
            },
        ],
        [
            {
                name: 'Production company',
                value: link(data.production_company, 'productionCompanies.edit', RESOURCES.PRODUCTION_COMPANY),
            },
            {
                name: 'Contact person',
                value: linkToContactPersonOfProductionCompany(data.contact_person, data.production_company),
            },
        ],
    ];
    let value;

    switch (parseInt(data.origin_stream_type.id, 10)) {
        case CONST.ORIGIN_STREAM_TYPE_RTMP: {
            const isTvStreamingDataSet = data.isTvStreaming && data.matchTvStreamingEncoder && data.isMainContent,
                tvStreamingWarningText = 'Does not match the TV Streaming encoder',
                parsedUrl = parseTvStreamingEncoderStreamUrl(_get(data, 'matchTvStreamingEncoder.stream_url', ''));

            let originStreamNameValue = null,
                originEntryPointApplicationValue = null;

            if (!data.rtmp_origin_stream_name) {
                originStreamNameValue = <Warning/>;
            } else {
                originStreamNameValue = <div>
                    {renderName(data.rtmp_origin_stream_name, data.matchId)}
                    {
                        (
                            isTvStreamingDataSet
                            && (
                                convertToInt(
                                    _get(data, 'rtmp_origin_stream_name.production_company.id', 0)
                                ) !== CONST.TV_STREAMING_PRODUCTION_COMPANY
                                || _get(data, 'rtmp_origin_stream_name.name', false) !== parsedUrl.streamName
                            )
                        )
                        && <Warning text={tvStreamingWarningText}/>
                    }
                </div>;
            }

            if (!data.rtmp_origin_entry_point_application) {
                originEntryPointApplicationValue = <Warning/>;
            } else {
                originEntryPointApplicationValue = <div>
                    {renderName(data.rtmp_origin_entry_point_application)}
                    {
                        (
                            isTvStreamingDataSet
                            && (
                                _get(data, 'rtmp_origin_entry_point_application.rtmp_origin_entry_point_application_type.path', false)
                                !== parsedUrl.path
                                || _get(data, 'rtmp_origin_entry_point_application.rtmp_origin_entry_point.host', false)
                                !== parsedUrl.host
                            )
                        ) && <Warning text={tvStreamingWarningText}/>
                    }
                </div>;
            }

            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point application',
                    value: originEntryPointApplicationValue,
                },
                {
                    name: 'Origin stream name',
                    value: originStreamNameValue,
                },
            ]);
            break;
        }
        case CONST.ORIGIN_STREAM_TYPE_SDI:
            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point',
                    value: renderName(data.sdi_origin_entry_point),
                },
            ]);
            break;
        case CONST.ORIGIN_STREAM_TYPE_UDP:
            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point',
                    value: renderName(data.udp_origin_entry_point),
                },
            ]);

            if (data.udp_origin_entry_point) {
                details[2].push({
                    name: 'Failover origin entry point',
                    value: renderName(
                        data.udp_origin_entry_point
                            ? data.udp_origin_entry_point.failover_udp_origin_entry_point
                            : null
                    ),
                });
            }
            break;
        case CONST.ORIGIN_STREAM_TYPE_HTTP:
            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point URL',
                    value: data.http_origin_entry_point_url || <Warning/>,
                },
            ]);
            break;
        case CONST.ORIGIN_STREAM_TYPE_SRT:
            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point URL',
                    value: <SrtOriginEntryPoint data={data}/>,
                },
                {
                    name: 'Failover origin entry point URL',
                    value: <SrtFailoverOriginEntryPoint data={data}/>,
                },
            ]);
            break;
        case CONST.ORIGIN_STREAM_TYPE_MEDIACONNECT:
            details.push([
                {
                    name: 'Origin stream type',
                    value: data.origin_stream_type.name,
                },
                {
                    name: 'Origin entry point',
                    value: renderName(data.mediaconnect_origin_entry_point),
                },
            ]);

            if (2 === data.mediaconnect_origin_entry_point?.mediaconnect_origin_feeds?.length && data.mediaconnect_origin_entry_point?.id) {
                switch (data.mediaconnect_origin_feeds) {
                    case '1,2':
                        value = 'Use both, start with primary';
                        break;
                    case '2,1':
                        value = 'Use both, start with secondary';
                        break;
                    case '1':
                        value = 'Use only primary';
                        break;
                    case '2':
                        value = 'Use only secondary';
                        break;
                }
                details[2].push({
                    name: 'Feeds',
                    value: value,
                });
            }
            break;
    }

    return details;
}
parseDetails.propTypes = {
    data: PropTypes.shape({
        contact_person: PropTypes.object.isRequired,
        contribution_type: PropTypes.object.isRequired,
        encoding_datacenter: PropTypes.object.isRequired,
        origin_stream_type: PropTypes.object.isRequired,
        production_company: PropTypes.object.isRequired,
        rtmp_origin_entry_point_application: PropTypes.object.isRequired,
        rtmp_origin_stream_name: PropTypes.object.isRequired,
        sdi_origin_entry_point: PropTypes.object.isRequired,
        udp_origin_entry_point: PropTypes.object.isRequired,
        mediaconnect_origin_entry_point: PropTypes.object.isRequired,
    }),
};

SrtLinkWithLabel.propTypes = {
    url: PropTypes.string,
};

Warning.propTypes = {
    text: PropTypes.string,
};

SrtOriginEntryPoint.propTypes = {
    data: PropTypes.shape({
        srt_origin_entry_point: PropTypes.object,
        srt_origin_entry_point_url: PropTypes.string,
        is_srt_origin_entry_point_in_failover: PropTypes.bool,
        failover_srt_origin_entry_point_url: PropTypes.string,
    }),
};

SrtFailoverOriginEntryPoint.propTypes = {
    data: PropTypes.shape({
        is_srt_origin_entry_point_in_failover: PropTypes.bool,
        failover_srt_origin_entry_point: PropTypes.object,
        failover_srt_origin_entry_point_url: PropTypes.string,
    }),
};
