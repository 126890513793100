/**
 * statuses of the response
 */
const responseStatus = {
    'HTTP_OK' : 200,
    'BAD_REQUEST' : 400,
    'HTTP_UNAUTHORIZED' : 401,
    'HTTP_NOT_FOUND' : 404,
    'HTTP_FORBIDDEN' : 403,
    'HTTP_URI_TOO_LONG' : 414,
};

export default responseStatus;
