import React from 'react';
import PropTypes from 'prop-types';

import {graphql} from 'react-apollo';
import Table from '@appComponents/Table';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {columns} from '@modules/client/components/ClientOfferings/clientOfferingsTableColumns';
import {
    clientBookingRowRenderer,
} from '@modules/client/utils/clientBookingTable';

export const ClientOfferingsTable = (props) => {
    const {data, clientOfferings, loading} = props;

    return (
        <div className='clientOfferings__table'>
            <Table
                adjustHeight={props.adjustHeight}
                className='mini'
                columns={columns}
                data={clientOfferings}
                defaultSort={'start_datetime'}
                showLimitWarning={true}
                dataLimitWarning={10000}
                loading={loading}
                name='clientOfferingsList'
                noDataText='No offerings found'
                padded={false}
                rowRenderer={clientBookingRowRenderer}
                rowRendererProps={{mappedIsoCodes: data.mappedIsoCodes, type: 'offering', clientId: props.clientId}}
            />
        </div>
    );
};

ClientOfferingsTable.propTypes = {
    adjustHeight: PropTypes.func.isRequired,
    clientOfferings: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    loading: PropTypes.bool.isRequired,
    refProperty: PropTypes.func.isRequired,
};
ClientOfferingsTable.defaultProps = {
    clientOfferings: [],
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
};
ClientOfferingsTable.displayName = 'ClientOfferingsTable';

export default graphql(GetMappedIsoCodes)(ClientOfferingsTable);
