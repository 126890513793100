import React from 'react';
import moment from 'moment';
import {Label} from 'semantic-ui-react';
import {isEmpty as _isEmpty} from 'lodash';

import {renderEventStatusName} from '@utils/status';

import {renderTimePopup} from './renderTimePopup';

/**
 *
 * @param row
 * @returns {XML}
 */
export const renderTime = (row) => {
    let renderTimeComponents = [],
        startDatetimeFormat = 'HH:mm',
        endDatetimeFormat = 'HH:mm',
        hasEventMatch = (row.match_id);

    if (!moment(row.event_content_start_datetime).isSame(new Date(), 'day')) {
        startDatetimeFormat = 'YYYY-MM-DD HH:mm';
    }

    renderTimeComponents.push(
        <span key={'ecsd_st' + row.id.toString()}>
            {moment(row.event_content_start_datetime).format(startDatetimeFormat)}
        </span>
    );

    if (!_isEmpty(row.event_content_end_datetime)) {
        if (!moment(row.event_content_end_datetime).isSame(new Date(), 'day')) {
            endDatetimeFormat = 'YYYY-MM-DD HH:mm';
        }

        renderTimeComponents.push(<span key={'ecsd_sep' + row.id.toString()}><br />-<br /></span>);
        renderTimeComponents.push(
            <span key={'ecsd_et' + row.id.toString()}>
                {moment(row.event_content_end_datetime).format(endDatetimeFormat)}
            </span>
        );
    }

    if (row.event_content_is_247_stream) {
        renderTimeComponents.push(
            <div key={`ec247_${row.id}`}>
                <Label color='grey' size='mini'>24/7</Label>
            </div>
        );
    }

    if (hasEventMatch) {
        return renderTimePopup(row, renderTimeComponents);
    } else {
        return (
            <React.Fragment key={`rtc_${row.id}`}>
                {renderTimeComponents}
                {renderEventStatusName(row.event_status_id, row.event_status, row.match_status_name, false)}
            </React.Fragment>
        );
    }
};
