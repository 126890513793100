import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {HAS_HQ, HAS_LL} from '@constants/staticDropdowns';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {getBookingFiltersIsCancelledStatus} from '@utils/booking';

export const EventsBookingsComponent = (props) => {
    const {
            products,
            distributionTypes,
            deviceCategories,
            bookingTypes,
            clients,
            countries,
            eventContents,
            invoiceStatuses,
            eventContentTypes,
            eventContentVariants,
        } = props.data,
        loading = props.loading;

    return (
        <div className='eventBookings__filters filter__controls__container'>
            <Field
                component={Form.ReduxSelect}
                name='clients'
                placeholder='Client'
                options={clients}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'clients')}
            />
            <Field
                component={Form.ReduxSelect}
                defaultValue={0}
                name='eventContents'
                placeholder='Content'
                options={eventContents}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'eventContents')}
            />
            <Field
                className='--customWidth --contentType'
                component={Form.ReduxSelect}
                name='eventContentTypes'
                placeholder='Content type'
                options={eventContentTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'eventContentTypes')}
            />
            <Field
                className='--customWidth --contentVariant'
                component={Form.ReduxSelect}
                name='eventContentVariants'
                placeholder='Content variant'
                options={eventContentVariants}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'eventContentVariants')}
            />
            <Field
                className='--customWidth --products'
                component={Form.ReduxSelect}
                name='products'
                placeholder='Product'
                options={products}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'products')}
            />
            <Field
                component={Form.ReduxSelect}
                name='distributionTypes'
                placeholder='Distribution type'
                options={distributionTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'distributionTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                name='deviceCategories'
                placeholder='Device category'
                options={deviceCategories}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'deviceCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                name='countries'
                placeholder='Country'
                options={countries}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'countries')}
            />
            <Field
                name='isHq'
                className='--customWidth --isHq'
                placeholder='HQ?'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                options={HAS_HQ}
                selection
                setValue={props.restoreFilterValues(props.filters.EventBookings, 'isHq')}
            />
            <Field
                className='--customWidth --isLl'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                selection
                name='isLl'
                placeholder='LL?'
                options={HAS_LL}
                setValue={props.restoreFilterValues(props.filters.EventBookings, 'isLl')}
            />
            <Field
                component={Form.ReduxSelect}
                name='bookingTypes'
                placeholder='Type'
                options={bookingTypes}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'bookingTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                name='invoiceStatuses'
                placeholder='Invoice status'
                options={invoiceStatuses}
                search
                loading={loading}
                multiple
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'invoiceStatuses')}
            />
            <Field
                component={Form.ReduxSelect}
                name='isCancelled'
                placeholder='Cancellation status'
                options={getBookingFiltersIsCancelledStatus()}
                search
                loading={loading}
                disabled={loading}
                setValue={props.restoreFilterValues(props.filters.EventsBookings, 'isCancelled', 0)}
            />
        </div>
    );
};

EventsBookingsComponent.propTypes = {
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(EventsBookingsComponent, {
    name: 'EventsBookings',
    initialValues: {
        isCancelled: 0,
    },
});
