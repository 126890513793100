import classNames from 'classnames';
import {
    intersection as _intersection,
    isEqual as _isEqual,
    isFunction as _isFunction,
    union as _union,
    without as _without,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Icon, Segment, Table as SemanticTable} from 'semantic-ui-react';

import {TreeBehaviourComponent} from '@appComponents/ReduxFormControlsComponents/TreeBehaviourComponent';
import TableRow from '@appComponents/TableComponents/Row';
import {ButtonExpandCollapse} from '@appComponents/ButtonCollection';

class SemanticTreeTable extends TreeBehaviourComponent {

    static propTypes = {
        columns: PropTypes.object,
        mappedIsoCodesData: PropTypes.object,
        dataForModal: PropTypes.object,
        data: PropTypes.array,
        rowRender: PropTypes.func,
        setSelected: PropTypes.func,
    };

    constructor(props) {
        super(props);

        /**
         * @ignore
         */
        this.state = {
            options: props.data,
            valuesTree: [],
            selected: [],
            indeterminate: [],
            expanded: [],
            loading: props.loading,
            expandedAll: false,
        };

        this.treeActions = {
            checkChildren: (data) => {
                let selected = this.state.selected,
                    path = data.treeId,
                    selectedIndex = selected.indexOf(path);

                if (-1 < selected.indexOf(path)) {
                    selected.splice(selectedIndex, 1);

                    if (this.state.valuesTree[path]) {
                        selected = _without(selected, ...this.state.valuesTree[path]);
                    }

                } else {
                    if (this.state.valuesTree[path]) {
                        selected = _union(selected, this.state.valuesTree[path]);
                    }

                    selected = _union(selected, [path]);
                }

                let intersectData = this.checkIntersection(path, selected, this.state.valuesTree, true);

                selected = _union(selected, intersectData.selected);

                this.setState(() => ({
                    indeterminate: intersectData.indeterminate,
                    selected: selected,
                }));
                this.props.setSelected(selected);
            },
        };
    }

    componentDidMount() {
        if (0 === this.state.options.length) {
            return;
        }

        let mapOptions = this.mapOptions(this.state.options, true),
            data = this.getTreeData(mapOptions, true);

        /**
         * @ignore
         */
        this.setState(() => ({
            valuesTree: mapOptions.values,
            selected: mapOptions.selected,
            indeterminate: data.indeterminate,
        }));
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => ({
            loading: nextProps.loading,
        }));

        if (!_isEqual(this.state.options, nextProps.data)) {
            this.setState({indeterminate: []}, () => {
                let mapOptions = this.mapOptions(nextProps.data, true),
                    data = this.getTreeData(mapOptions, true);

                this.setState(() => ({
                    options: nextProps.data,
                    valuesTree: mapOptions.values,
                    selected: mapOptions.selected,
                    indeterminate: data.indeterminate,
                }));
            });
        }
    }

    renderHeaderRow() {
        let header = [],
            firstCell = true;

        for (let columnKey in this.props.columns) {
            let isExpandedAll = this.state.expanded.length === Object.keys(this.state.valuesTree).length;
            const button = firstCell ?
                <ButtonExpandCollapse onClick={() => this.expandOrCollapseAll()} shouldExpand={!isExpandedAll}
                    position='top left'></ButtonExpandCollapse> : null;

            const column = this.props.columns[columnKey],
                align = column.align || 'left';

            header.push(
                <SemanticTable.HeaderCell textAlign={align} key={columnKey}>
                    {button}{column.label}
                </SemanticTable.HeaderCell>
            );
            firstCell = false;
        }

        return header;
    }

    rowClick = (props) => {
        let expanded = this.state.expanded.slice(0),
            key = props.rowId;

        if (-1 < this.state.expanded.indexOf(key)) {
            expanded.splice(this.state.expanded.indexOf(key), 1);
        } else {
            expanded.push(key);
        }

        this.setState(() => ({
            expanded: expanded,
        }));
    };

    expandAllChildren = (key, valuesTree, expandedKeys = []) => {
        if (!valuesTree[key]) return expandedKeys;
        const newExpandedKeys = [...expandedKeys, key];

        return valuesTree[key].reduce((acc, childKey) => {
            return this.expandAllChildren(childKey, valuesTree, acc);
        }, newExpandedKeys);
    };

    expandOrCollapseParent = (key, isExpandedAllChildren) => {
        let allKeysToExpandOrCollapse = this.expandAllChildren(key, this.state.valuesTree);

        this.setState(prevState => {
            let updatedExpanded;

            if (isExpandedAllChildren) {
                updatedExpanded = prevState.expanded.filter(expKey => !allKeysToExpandOrCollapse.includes(expKey));
            } else {
                updatedExpanded = Array.from(new Set([...prevState.expanded, ...allKeysToExpandOrCollapse]));
            }

            return {expanded: updatedExpanded};
        });
    };

    expandOrCollapseAll = () => {
        if (this.state.expanded.length === Object.keys(this.state.valuesTree).length) {
            this.setState(() => ({
                expanded: [],
            }));
        } else {
            this.setState(() => ({
                expanded: Object.keys(this.state.valuesTree),
            }));
        }
    }

    renderCellsForDataRow = (data, level, key, parentData) => {
        let cells = [],
            firstCell = true;

        for (let columnKey in this.props.columns) {
            let icon = null,
                cellContent = null,
                button = null;

            if (_isFunction(this.props.rowRenderer)) {
                cellContent = this.props.rowRenderer(
                    columnKey, {...data, ...{treeId: key}, ...this.props.dataForModal,  ...{mappedIsoCodesData: this.props.mappedIsoCodesData}}, this.state, this.treeActions, level, parentData
                );
            }

            if (null === cellContent && data[columnKey]) {
                cellContent = data[columnKey];
            }

            let iconClass = classNames({
                'chevron': true,
                'small': true,
                '--no-children': !data.children,
            }, -1 < this.state.expanded.indexOf(key) ? 'down' : 'right');

            if (firstCell) {
                icon = <Icon
                    className={iconClass}
                    data-id={data.id}
                />;

                if (data.children && 0 < data.children.length) {
                    if (2 >= level) {
                        let allChildren = Object.keys(this.state.valuesTree).filter((value) => value.startsWith(key)),
                            expandedChildren = this.state.expanded.filter((value) => value.startsWith(key)),
                            isExpandedAllChildren = allChildren.length === expandedChildren.length;
                        button = <ButtonExpandCollapse onClick={(e) => {
                            e.stopPropagation();
                            this.expandOrCollapseParent(key, isExpandedAllChildren);
                        }} shouldExpand={!isExpandedAllChildren} position='top left'></ButtonExpandCollapse>;
                    }
                }

                firstCell = false;
            }

            const align = this.props.columns[columnKey].align || 'center';

            cells.push(
                <SemanticTable.Cell className={`treeTable__cell --level${level} --column-${columnKey}`}
                    textAlign={align} key={`${columnKey}_${key}`}>
                    {button}{icon}{cellContent}
                </SemanticTable.Cell>
            );
        }

        let rowClass = classNames({
            'treeTable__row': true,
            [`--level${level}`]: true,
            '--indeterminate': -1 < this.state.indeterminate.indexOf(key),
            '--selected': -1 < this.state.selected.indexOf(key),
            '--no-children': data.children && -1 < this.state.selected.indexOf(key)
                && 0 === _intersection(this.state.valuesTree[key], this.state.selected).length,
            '--full-children': data.children && this.state.valuesTree[key]
                && _intersection(this.state.valuesTree[key], this.state.selected).length
                === this.state.valuesTree[key].length,
        });

        let propsForRow = {
            className: rowClass,
            data: data,
            rowId: key,
        };

        if (data.children) {
            propsForRow['onClick'] = this.rowClick;
        }

        return (
            <TableRow {...propsForRow} key={'row-' + key}>
                {cells}
            </TableRow>
        );
    };

    renderDataRows = (data, parent, level, parentData) => {
        let dataRows = data.map((value) => {
            let key = `${this.removeDashes(value.name)}-${this.removeDashes(value.value)}`.replace(/[\s,.]/g, '&&');

            if (parent) {
                key = parent + '.' + key;
            }

            let rows = [
                this.renderCellsForDataRow(value, level, key, parentData),
            ];

            if (-1 < this.state.expanded.indexOf(key)) {
                rows.push(this.renderDataRows(value.children, key, level + 1, value));
            }

            return rows;
        });

        return dataRows;
    };

    render() {
        return (
            <Segment basic className='--tabs --clear'>
                <SemanticTable collapsing className='treeTable'>
                    <SemanticTable.Header>
                        <SemanticTable.Row>
                            {this.renderHeaderRow()}
                        </SemanticTable.Row>
                    </SemanticTable.Header>
                    <SemanticTable.Body>
                        {this.renderDataRows(this.props.data, false, 0, null)}
                    </SemanticTable.Body>
                </SemanticTable>
            </Segment>
        );
    }
}

export default SemanticTreeTable;
