import validators from '@utils/validators';
import {FIELD_REQUIRED} from '@constants/messages';
import {COMMENT_LIMIT} from '@constants/variables';

export const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.origin_stream_status, 'origin_stream_status'));
    Object.assign(errors, validators.maxLength({value: values.comment, maxLength: COMMENT_LIMIT}, 'comment'));

    return errors;
};

export const validateMultipleInput = (value) => {
    if (!value) {
        return FIELD_REQUIRED;
    }
};
