import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { get as _get } from 'lodash';
import {Dimmer, Loader} from 'semantic-ui-react';

import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {LIVE_CONTROL, EVENT_CONTENT} from '@constants/resources';
import Table from '@appComponents/Table';

import {liveControlTableRowRenderer} from './liveControlTableRowRenderer';
import {entryPointSortValue, getRowClassname} from './utils';

export const authorizationLiveControlUpdateObject = {
    [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
    [EVENT_CONTENT]: SECURITY_PRIVILEGES_UPDATE,
};

export const columns = {
    'event_content_start_datetime': {
        label: 'Time',
        sorting: true,
        className: '--noWrap-time',
    },
    'content': {label: 'Content', sorting: false},
    'entryPoint': {
        label: 'Entry point',
        sorting: row => entryPointSortValue(row),
    },
    'datacenter': {
        label: 'Datacenter',
        sorting: row => _get(row, 'encoding_datacenter_name', null),
    },
    'distributions': {label: 'Distributions', sorting: false},
    'cdn': {label: 'CDN', sorting: false},
    'bookings': {
        label: 'Bookings',
        sorting: false,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'encodingStartTime': {
        label: 'Enc. start time',
        sorting: false,
        content: {singleLine: true},
    },
    'encodingEndTime': {
        label: 'Enc. end time',
        sorting: false,
        content: {singleLine: true},
    },
    'encodingStatus': {
        label: 'Enc. status',
        sorting: (row) => row.encoding_status,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

/**
 *
 * @param props
 * @returns {XML}
 * @constructor
 */
const LiveControlTable = (props) => {
    return (
        <div className='liveControl__table'>
            <Dimmer inverted active={props.loading}>
                <Loader/>
            </Dimmer>
            {(0 < props.liveControlList.length || !props.loading) && <Table
                name='liveControlList'
                columns={columns}
                data={props.liveControlList}
                noDataText='No content found'
                rowRenderer={(column, row) => liveControlTableRowRenderer(column, row, props.timezone)}
                rowClassname={(rowData) => getRowClassname(rowData, props.timezone)}
            />
            }
        </div>
    );
};

LiveControlTable.propTypes = {
    liveControlList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    timezone: PropTypes.string,
};

export default connect(state => ({timezone: state.timezone.timezone}))(LiveControlTable);
