import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {graphql} from 'react-apollo';
import {showConstraintsModal, skipSearch} from '@modules/client/utils/constraintsTables';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {convertToInt} from '@utils/helpers';
import {getBookingStatusForOfferingsDefaultValues} from '@utils/booking';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {
    GetClientOfferingsTableData,
    GetCountries,
    GetDropdownData,
    GetPropertyLicences,
} from '@graphql/clientOffering/query';
import {adjustHeight} from '@modules/client/utils/adjustHeightHelper';

import ClientOfferingsTable from '../components/ClientOfferings/ClientOfferingsTable';
import ClientOfferingsFilters from '../components/ClientOfferingsFilters';
import {exportExcel as clientOfferingsExportExcel} from '../utils/export/clientOfferingsExcel';
import {
    updateClientOfferings,
    updateEventTypes,
    updatePropertyLicences,
} from '../utils/clientOfferingsHelper';

export class ClientOfferings extends Component {
    static propTypes = {
        DataClientOfferings: PropTypes.shape({
            clientOfferings: PropTypes.array,
            loading: PropTypes.bool,
        }),
        dataCountries: PropTypes.shape({
            countries: PropTypes.array,
        }),
        dataDropDown: PropTypes.shape({
            clientPackages: PropTypes.array,
            contentTiers: PropTypes.array,
            deviceCategories: PropTypes.array,
            distributionTypes: PropTypes.array,
            eventTypes: PropTypes.array,
            loading: PropTypes.bool.isRequired,
            productsDropdown: PropTypes.array,
            eventContentTypes: PropTypes.array,
            eventContentVariants: PropTypes.array,
        }),
        dataPropertyLicences: PropTypes.shape({
            propertyLicences: PropTypes.array,
        }),
        filters: PropTypes.shape({
            bookingStatus: PropTypes.arrayOf(PropTypes.string),
            clientPackages: PropTypes.arrayOf(PropTypes.number),
            isHq: PropTypes.number,
            isLl: PropTypes.number,
            contentCategoryLevel1: PropTypes.arrayOf(PropTypes.string),
            contentCategoryLevel2: PropTypes.arrayOf(PropTypes.string),
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.string),
            contentTiers: PropTypes.arrayOf(PropTypes.string),
            countries: PropTypes.arrayOf(PropTypes.string),
            deviceCategories: PropTypes.arrayOf(PropTypes.string),
            distributionTypes: PropTypes.arrayOf(PropTypes.string),
            eventTypes: PropTypes.number,
            from: PropTypes.string,
            products: PropTypes.arrayOf(PropTypes.string),
            propertyLicences: PropTypes.arrayOf(PropTypes.number),
            sports: PropTypes.arrayOf(PropTypes.string),
            to: PropTypes.string,
            tournamentCategories: PropTypes.arrayOf(PropTypes.string),
            tournaments: PropTypes.arrayOf(PropTypes.string),
            eventContentTypes: PropTypes.arrayOf(PropTypes.string),
            eventContentVariants: PropTypes.arrayOf(PropTypes.string),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        DataClientOfferings: {
            clientOfferings: [],
        },
        dataCountries: {
            countries: [],
        },
        dataDropDown: {
            clientPackages: [],
            contentTiers: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: [],
            loading: false,
            products: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
        dataPropertyLicences: {
            propertyLicences: [],
        },
        filters: {
            bookingStatus: getBookingStatusForOfferingsDefaultValues(),
            clientPackages: [],
            isHq: null,
            isLl: null,
            contentCategoryLevel1: [],
            contentCategoryLevel2: [],
            contentCategoryLevel3: [],
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: null,
            from: moment().format('YYYY-MM-DD 00:00'),
            to: moment().add(1, 'day').format('YYYY-MM-DD 00:00'),
            products: [],
            propertyLicences: [],
            sports: [],
            tournamentCategories: [],
            tournaments: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
    };

    cellRefs = [];

    state = {
        clientOfferings: [],
        clientOfferingsOriginal: [],
        contentCategoryLevel1: [],
        contentCategoryLevel1Original: [],
        heightAdjusted: false,
        propertyLicences: [],
        propertyLicencesOriginal: [],
        sports: [],
        sportsOriginal: [],
        eventTypes: [],
        eventTypesOriginal: [],
    };

    componentDidMount() {
        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataClientOfferings: { clientOfferings },
            dataDropDown: { eventTypes },
            dataPropertyLicences: { propertyLicences },
        } = nextProps;

        return {
            ...updatePropertyLicences(propertyLicences, prevState),
            ...updateEventTypes(eventTypes, prevState),
            ...updateClientOfferings(clientOfferings, prevState),
        };
    }

    componentDidUpdate() {
        if (!this.state.heightAdjusted) {
            let res = adjustHeight(this.cellRefs);

            if (res) {
                this.setState(() => ({heightAdjusted: true}));
            }
        }
    }

    setCellRef = (el) => {
        if (null !== el) {
            this.cellRefs.push(el.firstChild);
        }
    };

    render() {
        const clientId = convertToInt(this.props.match.params.id),
            defaultProps = ClientOfferings.defaultProps.dataDropDown,
            dropdownProps = this.props.dataDropDown,
            dataCountries = this.props.dataCountries,
            loading = (
                this.props.DataClientOfferings.loading
                || this.props.dataDropDown.loading
                || this.props.loadingRefetch
            ),
            loadingTable = (this.props.DataClientOfferings.loading || this.props.loadingRefetch),
            filtersProps = {
                additionalFilterId: this.props.match.params.id,
                data: {
                    clientPackages: dropdownProps.clientPackages || defaultProps.clientPackages,
                    contentTiers: dropdownProps.contentTiers || defaultProps.contentTiers,
                    countries: dataCountries.countries || defaultProps.countries,
                    deviceCategories: dropdownProps.deviceCategories || defaultProps.deviceCategories,
                    distributionTypes: dropdownProps.distributionTypes || defaultProps.distributionTypes,
                    eventTypes: this.state.eventTypes,
                    products: dropdownProps.productsDropdown || defaultProps.products,
                    propertyLicences: this.state.propertyLicences,
                    eventContentTypes: dropdownProps.eventContentTypes || defaultProps.eventContentTypes,
                    eventContentVariants: dropdownProps.eventContentVariants || defaultProps.eventContentVariants,
                },
                loading: loading,
            };

        return (
            <div>
                <HeaderRenderer
                    buttonsProps={{clientID: clientId}}
                    exportExcelParams={clientOfferingsExportExcel(this.state.clientOfferings, clientId)}
                    filters={ClientOfferingsFilters}
                    filtersProps={filtersProps}
                    loading={loading}
                />
                <Segment basic className='--table'>
                    <ClientOfferingsTable
                        adjustHeight={adjustHeight}
                        clientOfferings={this.state.clientOfferings}
                        clientId={clientId}
                        loading={loadingTable}
                        refProperty={this.setCellRef}
                    />
                </Segment>
            </div>
        );
    }
}

const ClientOfferingsWithQuery = compose(
    graphql(GetClientOfferingsTableData, {
        skip: (props) => {
            return skipSearch(props);
        },
        options: (props) => {
            let defaultFilters = ClientOfferings.defaultProps.filters;

            let clientPackages = props.filters.clientPackages || defaultFilters.clientPackages,
                contentCategoryLevel1 = props.filters.contentCategoryLevel1 || defaultFilters.contentCategoryLevel1,
                contentCategoryLevel2 = props.filters.contentCategoryLevel2 || defaultFilters.contentCategoryLevel2,
                contentCategoryLevel3 = props.filters.contentCategoryLevel3 || defaultFilters.contentCategoryLevel3,
                contentTiers = props.filters.contentTiers || defaultFilters.contentTiers,
                countries = props.filters.countries || defaultFilters.countries,
                deviceCategories = props.filters.deviceCategories || defaultFilters.deviceCategories,
                distributionTypes = props.filters.distributionTypes || defaultFilters.distributionTypes,
                eventTypes = props.filters.eventTypes || defaultFilters.eventTypes,
                products = props.filters.products || defaultFilters.products,
                sports = props.filters.sports || defaultFilters.sports,
                tournamentCategories = props.filters.tournamentCategories || defaultFilters.tournamentCategories,
                tournaments = props.filters.tournaments || defaultFilters.tournaments,
                eventContentTypes = props.filters.eventContentTypes || defaultFilters.eventContentTypes,
                eventContentVariants = props.filters.eventContentVariants || defaultFilters.eventContentVariants;

            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    bookingStatus: props.filters.bookingStatus,
                    client: convertToInt(props.match.params.id),
                    clientPackages: (props.filters.clientPackages)
                        ? props.filters.clientPackages.map(id => convertToInt(id))
                        : clientPackages,
                    isHq: convertBooleanValueForFilters(props.filters.isHq, null),
                    isLl: convertBooleanValueForFilters(props.filters.isLl, null),
                    contentCategoryLevel1: (props.filters.contentCategoryLevel1)
                        ? props.filters.contentCategoryLevel1.map(id => convertToInt(id))
                        : contentCategoryLevel1,
                    contentCategoryLevel2: (props.filters.contentCategoryLevel2)
                        ? props.filters.contentCategoryLevel2.map(id => convertToInt(id))
                        : contentCategoryLevel2,
                    contentCategoryLevel3: (props.filters.contentCategoryLevel3)
                        ? props.filters.contentCategoryLevel3.map(id => convertToInt(id))
                        : contentCategoryLevel3,
                    contentTiers: (props.filters.contentTiers)
                        ? props.filters.contentTiers.map(id => convertToInt(id))
                        : contentTiers,
                    countries: props.filters.countries ? props.filters.countries : countries,
                    deviceCategories: (props.filters.deviceCategories)
                        ? props.filters.deviceCategories.map(id => convertToInt(id))
                        : deviceCategories,
                    distributionTypes: (props.filters.distributionTypes)
                        ? props.filters.distributionTypes.map(id => convertToInt(id))
                        : distributionTypes,
                    endDate: props.filters.to ? `${props.filters.to}:00` : defaultFilters.to,
                    eventTypes: (props.filters.eventTypes)
                        ? convertToInt(props.filters.eventTypes)
                        : eventTypes,
                    products: (props.filters.products)
                        ? props.filters.products.map(id => convertToInt(id))
                        : products,
                    propertyLicences: props.filters.propertyLicences || defaultFilters.propertyLicences,
                    sports: (props.filters.sports)
                        ? props.filters.sports.map(id => convertToInt(id))
                        : sports,
                    startDate: props.filters.from ? `${props.filters.from}:00` : `${defaultFilters.from}:00`,
                    tournamentCategories: (props.filters.tournamentCategories)
                        ? props.filters.tournamentCategories.map(id => convertToInt(id))
                        : tournamentCategories,
                    tournaments: (props.filters.tournaments)
                        ? props.filters.tournaments.map(id => convertToInt(id))
                        : tournaments,
                    eventContentTypes: (props.filters.eventContentTypes)
                        ? props.filters.eventContentTypes.map(id => convertToInt(id))
                        : eventContentTypes,
                    eventContentVariants: (props.filters.eventContentVariants)
                        ? props.filters.eventContentVariants.map(id => convertToInt(id))
                        : eventContentVariants,
                },
            };
        },
        name: 'DataClientOfferings',
    }),
    graphql(GetCountries, {
        options: () => ({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        name: 'dataCountries',
    }),
    graphql(GetDropdownData, {
        options: (props) => ({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            variables: {client: [convertToInt(props.match.params.id)]},
        }),
        name: 'dataDropDown',
    }),
    graphql(GetPropertyLicences, {
        options: () => ({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        name: 'dataPropertyLicences',
    })
)(FilterQueryWrapper(ClientOfferings, {
    queryForRefresh: 'DataClientOfferings',
    filterUrls: ['clients.offerings.index'],
}));

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, `ClientOfferings${ownProps.match.params.id}`, ClientOfferings.defaultProps.filters),
});

export default withRouter((connect(mapStateToProps)(
    FilterUrlParamsWrapper(ClientOfferingsWithQuery, ClientOfferings.defaultProps.filters)
)));
