import moment from 'moment-timezone';
import {isEmpty as _isEmpty, map as _map} from 'lodash';
import React from 'react';

import {isValidJiraTicketNumber} from '@utils/helpers';

export const getClassNameForDatesCells = (startDate, endDate) => {
    const todayDate = moment().format('YYYY-MM-DD');

    if (todayDate > endDate) {
        return '--is-disabled';
    } else if (todayDate < startDate) {
        return '--is-upcoming';
    }

    return null;
};

export const renderClientPackagesGeoRestriction = (clientGeoRestrictions) => {
    return (
        <div>
            {!(_isEmpty(clientGeoRestrictions))
                ? 'Specific'
                : 'Same as product settings'}
        </div>
    );
};

export const renderEventContentVariants = (column, row) => {
    return _map(row[column], (eventContentVariant) => eventContentVariant.name).join(', ');
};

export const renderContentActivationTicketNumber = (column, row) => {
    const ticketNumber = row[column],
        url = `https://jira.sportradar.ag/browse/${ticketNumber}`;

    return isValidJiraTicketNumber(ticketNumber)
        ? <a href={url} target='_blank' rel='noopener noreferrer'>{ticketNumber.toUpperCase()}</a>
        : ticketNumber;
};
