import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {isEqual as _isEqual} from 'lodash';
import {Button, Header, Segment} from 'semantic-ui-react';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';

import {getLink} from '@appComponents/Link';
import {graphql} from 'react-apollo';
import SemanticTreeTable from '@appComponents/ReduxFormControlsComponents/SemanticTreeTable';
import MessageBox from '@appComponents/MessageBox';
import Authorization from '@appComponents/Authorization';
import {
    saveClientPackageContentTree as saveClientPackageContentTreeMutation,
} from '@graphql/clients/mutation';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {clientPackageContentTree as contentPackageTreeQuery} from '@graphql/clients/query';
import {
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import {CLIENT_PACKAGE_CONTENT} from '@constants/resources';
import {convertToInt} from '@utils/helpers';
import {showMessageBox} from '@utils/messageBox';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {routes} from '@constants/routes';
import {
    composeTableColumns,
    getTreeDataForSave,
    parseDataForTable,
} from '@modules/client/utils/clientContent/contentIndexHelpers';
import {rowRender} from '@modules/client/utils/clientContent/clientContentIndexRowRender';
import {openEditGlobeModal, openEditModal} from '@modules/client/utils/clientContent/contentIndexModalHelpers';

import client from '../../../apolloClient';


class ContentIndex extends React.Component {
    static propTypes = {
        ContentTreeData: PropTypes.object,
        booking: PropTypes.object,
        saveClientPackageContentTree: PropTypes.func,
        submitting: PropTypes.bool,
        clientName: PropTypes.string,
        productVariants: PropTypes.array,
        clientId: PropTypes.number,
        clientProduct: PropTypes.object,
        clientPackageName: PropTypes.string,
        packageId: PropTypes.number,
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
            params: PropTypes.object,
        }),
        clientPackageId: PropTypes.number,
        client_package: PropTypes.number,
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }),
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: props.ContentTreeData.loading,
            data: [],
            selected: false,
            dataForTable: [],
            clientName: props.clientName,
            clientPackageName: props.clientPackageName,
        };
    }

    reloadTable = () => {
        hideModal();
        this.setState(() => ({
            loading: true,
        }));
        this.setSelected(false);
        this.props.ContentTreeData.refetch().then(() => {
            this.setState(() => ({
                loading: false,
            }));
        });
        this.backToIndexUrl();
    };

    backToIndexUrl = () => {
        this.props.history.push(getLink(
            'clients.packages.content.index',
            {
                id: this.props.clientId,
                packagesId: this.props.clientPackageId,
            }
        ));
    };

    componentDidMount() {
        this.setState = this.setState.bind(this);
        this.getMappedIsoCodes();
    }

    componentDidUpdate(prevProps, prevState) {
        let data = prevState.dataForTable;

        if (this.props.ContentTreeData.clientPackageContentTree
            && !_isEqual(this.props.ContentTreeData.clientPackageContentTree, this.state.data)
            && (!this.props.ContentTreeData.loading
                && this.props.ContentTreeData !== prevProps.ContentTreeData
                && this.props.ContentTreeData.clientPackageContentTree !== prevProps.ContentTreeData.clientPackageContentTree)
        ) {
            this.setState(() => ({
                loading: false,
            }));

            this.booking = this.props.ContentTreeData.clientPackage.booking_type;
            this.eventBooking = this.props.ContentTreeData.clientPackage.event_booking_type;
            this.clientPackage = this.props.ContentTreeData.clientPackage;

            data = parseDataForTable(this.props.ContentTreeData.clientPackage, this.props.ContentTreeData.clientPackageContentTree, 1);

            this.setState(() => ({
                data: this.props.ContentTreeData.clientPackageContentTree,
                dataForTable: data,
                clientName: this.props.clientName,
                clientPackage: this.props.ContentTreeData.clientPackage,
                clientPackageId: this.props.clientPackageId,
            }), () => {
                const queryStringToObject = url =>
                    [...new URLSearchParams(url.split('?')[1])].reduce(
                        (a, [k, v]) => ((a[k] = convertToInt(v)), a),
                        {}
                    );
                const params = this.props.location.search && queryStringToObject(this.props.location.search);
                let dataForForm;

                if (params && this.state.dataForTable && this.state.dataForTable.length
                    && this.state.dataForTable !== prevState.dataForTable) {
                    dataForForm = this.state.dataForTable.find((item)=>item.value === params.sport_id.toString());

                    if (params.content_tier_id && dataForForm && dataForForm.children) {
                        dataForForm = dataForForm.children.find((item) => item.parents.content_tier === params.content_tier_id.toString());

                        if (params.tournament_category_id) {
                            dataForForm = dataForForm.children.find((item) => item.parents.tournament_category === params.tournament_category_id.toString());

                            if (params.property_licence_id) {
                                dataForForm = dataForForm.children.find((item) => item.parents.property_licence === params.property_licence_id.toString());

                                if (params.tournament_id) {
                                    dataForForm = dataForForm.children.find((item) => item.parents.tournament === params.tournament_id.toString());
                                }
                            }
                        } else if (params.property_licence_id && dataForForm && dataForForm.children) {
                            dataForForm = dataForForm.children.map((item) => {
                                dataForForm = item.children.find((itemLevelBelow)=> itemLevelBelow.parents.property_licence === params.property_licence_id.toString());

                                if (params.tournament_id) {
                                    dataForForm = dataForForm?.children.find((item) => item.parents.tournament === params.tournament_id.toString());
                                }

                                return dataForForm;
                            });
                            dataForForm = dataForForm[0];
                        }
                    }

                    dataForForm = {...dataForForm,
                        ...{
                            clientName: this.state.clientName,
                            clientPackageName: this.state.clientPackageName,
                            ContentTreeData: this.props.ContentTreeData,
                            clientProduct: this.props.clientProduct,
                            clientId: this.props.clientId,
                            clientPackageId: this.props.clientPackageId,
                            setState: this.setState,
                            history: this.props.history,
                            dataForTable: this.state.dataForTable,
                            reloadTable: this.reloadTable,
                            backToIndexUrl: this.backToIndexUrl,
                        },
                    };

                    if (routes.clients.packages.content.index.path === this.props.match.path) {
                        openEditModal(dataForForm);
                    } else if (routes.clients.packages.content.geoRestrictions.path === this.props.match.path) {
                        openEditGlobeModal(dataForForm);
                    }
                }
            });
        }
    }

    openSaveTreeModal = () => {
        showModalConfirmation({
            header: <Header content={'Apply changes on activation status'}/>,
            text: 'Are you sure you want to apply the changes on the activation status?',
            onYes: this.saveContentPackageTree,
        });
    };

    getMappedIsoCodes = () => {
        client.query({
            fetchPolicy: 'cache-first',
            query: GetMappedIsoCodes,
        }).then((response) => {
            this.setState(() => ({
                mappedIsoCodesData: response.data.mappedIsoCodes,
            }));
        });
    };

    saveContentPackageTree = () => {
        const dataToSave = getTreeDataForSave(this.state.selected, this.state.data);

        hideModal();
        this.setState(() => ({
            loading: true,
        }));

        if (this.props.clientPackageId) {
            const saveTree = this.props.saveClientPackageContentTree({
                variables: {
                    contentId: dataToSave,
                    clientPackageId: this.props.clientPackageId,
                    client_package_id: this.props.clientPackageId,
                    client_package_content_tree: dataToSave,
                },
            }).then(() => {
                showMessageBox('contentPackageMessage',
                    'The activation statuses have been saved successfully.', '', 'success');

            }).catch((response) => {
                showMessageBox('contentPackageMessage',
                    'The activation statuses have not been saved successfully.', response.message, 'error');
            });
            saveTree.then(() => {
                this.reloadTable();
            });
        }
    };

    setSelected = (selected) => {
        this.setState(() => ({
            selected: selected,
        }));
    };

    renderContent = () => {
        if (this.state.data && this.state.data.length || this.state.loading) {
            return (
                <>
                    <MessageBox name='_warningBox'/>
                    <div className='contentPackageContainer'>
                        <MessageBox name='contentPackageMessage'/>
                        <Segment basic compact loading={this.props.submitting || this.state.loading} className='--table --clear contentPackage'>
                            <SemanticTreeTable
                                columns={composeTableColumns(this.props.clientProduct)}
                                setSelected={this.setSelected}
                                data={this.state.dataForTable}
                                rowRenderer={rowRender}
                                loading={this.state.loading}
                                mappedIsoCodesData={this.state.mappedIsoCodesData}
                                dataForModal={{
                                    clientName: this.state.clientName,
                                    clientPackageName: this.state.clientPackageName,
                                    ContentTreeData: this.props.ContentTreeData,
                                    clientProduct: this.props.clientProduct,
                                    clientId: this.props.clientId,
                                    clientPackageId: this.props.clientPackageId,
                                    setState: this.setState,
                                    history: this.props.history,
                                    dataForTable: this.state.dataForTable,
                                    reloadTable: this.reloadTable,
                                    backToIndexUrl: this.backToIndexUrl,
                                }}
                                className='widthOfRow'
                            />
                            <Authorization resources={CLIENT_PACKAGE_CONTENT} privileges={SECURITY_PRIVILEGES_UPDATE}>
                                <Button
                                    onClick={this.openSaveTreeModal}
                                    color='blue'
                                    type='submit'
                                    loading={this.props.submitting}
                                    disabled={this.props.submitting || false === this.state.selected || this.state.loading}
                                    content='Apply changes on activation status'
                                />
                            </Authorization>
                        </Segment>
                    </div>
                </>
            );
        } else {
            return <div>There are no package contents for this client</div>;
        }
    };

    render() {
        return this.renderContent();
    }
}

const mapStateToProps = (state, props) => {
    const clientName = state.app.entities.client?.name;
    const clientPackageName = state.app.entities.clientPackage?.name ?? null;
    const clientPackageId = isNaN(props.clientPackageId)
        ? convertToInt(props.match.params.packageId)
        : props.clientPackageId;

    return {
        booking: props.clientPackage,
        clientName: clientName,
        clientPackageName: clientPackageName,
        clientPackageId: clientPackageId,
    };
};

const contentIndexWithData = compose(
    graphql(saveClientPackageContentTreeMutation, {
        name: 'saveClientPackageContentTree',
    }),
    graphql(contentPackageTreeQuery, {
        options: (props) => ({
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'no-cache',
            variables: {
                clientPackageId: props.clientPackageId,
            },
        }),
        name: 'ContentTreeData',
    }
    ))(reduxForm({form: 'ContentPackageTree'})(ContentIndex));

export default withRouter(connect(mapStateToProps)(contentIndexWithData));
