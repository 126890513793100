import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';
import {get as _get} from 'lodash';

import {graphql} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import StepsContent from '@appComponents/StepsContent';
import {routes} from '@constants/routes';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {getSearchFilters, convertBooleanValueForFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {
    EventBookingsFiltersDropdownsOptions,
    GetEventBookingsForTable,
} from '@graphql/eventBooking/query';
import {getBookingFormParams} from '@utils/booking';
import {showModal} from '@utils/modal';
import {getEventContentText} from '@utils/eventContentVariant/eventContentVariant';

import EventsBookingsFilters from '../components/EventsBookingsFilters';
import EventsBookingsTable from '../components/EventsBookings/EventsBookingsTable';
import EventsBookingsButtons from '../components/EventsBookingsButtons';
import EventBookingGeoRestrictionsStep
    from './EventBookingGeoRestrictionsStep';
import EventBookingGeoRestrictionsGeneralInformationForm
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsGeneralInformationForm';
import EventBookingGeoRestrictionsGeneralInformationModel
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsGeneralInformationModel';
import EventBookingGeoRestrictionsGeneralInformationEditModel
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsGeneralInformationEditModel';
import {exportExcel as eventsBookingsExportExcel} from '../utils/export/eventsBookingsExcel';
import EventBookingGeoRestrictionsBlackoutZonesEditModel
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsBlackoutZonesEditModel';
import EventBookingGeoRestrictionsBlackoutZonesForm
    from '../forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsBlackoutZonesForm';

export class EventsBookings extends React.PureComponent {
    static propTypes = {
        DataEventBookings: PropTypes.object.isRequired,
        DataDropDown: PropTypes.object.isRequired,
        filters: PropTypes.shape({
            bookingTypes: PropTypes.arrayOf(PropTypes.number),
            clients: PropTypes.arrayOf(PropTypes.number),
            countries: PropTypes.arrayOf(PropTypes.string),
            isHq: PropTypes.number,
            isLl: PropTypes.number,
            deviceCategories: PropTypes.arrayOf(PropTypes.number),
            distributionTypes: PropTypes.arrayOf(PropTypes.number),
            eventContents: PropTypes.arrayOf(PropTypes.number),
            invoiceStatuses: PropTypes.arrayOf(PropTypes.number),
            products: PropTypes.arrayOf(PropTypes.number),
            eventContentTypes: PropTypes.arrayOf(PropTypes.number),
            eventContentVariants: PropTypes.arrayOf(PropTypes.number),
            isCancelled: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        }),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                bookingId: PropTypes.string,
                id: PropTypes.string.isRequired,
            }),
            path: PropTypes.string.isRequired,
        }),
        Menu: PropTypes.object.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        filters: {
            bookingTypes: [],
            clients: [],
            countries: [],
            isHq: null,
            isLl: null,
            deviceCategories: [],
            distributionTypes: [],
            eventContents: [],
            invoiceStatuses: [],
            products: [],
            isCancelled: 0,
            eventContentTypes: [],
            eventContentVariants: [],
        },
        match: {
            params: {
                bookingId: null,
            },
        },
    };

    componentDidMount() {
        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        this.loadModal(this.props.match.path);
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.events.bookings.index.path,
            modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible;

        if (urlChanged && urlIsNotIndex) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (modalChanged && modalIsNotVisible) {
            this.props.history.push(
                getLink('events.bookings.index', {
                    id: this.props.match.params.id,
                })
            );
        }
    }

    loadModal(path, params = {}) {
        const eventBookingsGeoRestrictionsPath = routes.events.bookings,
            form = EventBookingGeoRestrictionsGeneralInformationForm;
        let formParams = {};

        if (path === eventBookingsGeoRestrictionsPath.add.path) {
            formParams = Object.assign({}, {
                optionsVariables: {
                    event: convertToInt(this.props.match.params.id),
                },
            });

            return this.loadAddModalForm(
                createForm(EventBookingGeoRestrictionsGeneralInformationModel, form, formParams)
            );
        } else if (path === eventBookingsGeoRestrictionsPath.edit.path
            || path === eventBookingsGeoRestrictionsPath.editGeoRestrictions.path
            || path === eventBookingsGeoRestrictionsPath.blackoutZones.path
        ) {
            if (!isUrlParamValid(this.props.match.params.bookingId)) {
                return showModal({
                    isVisible: true,
                    content: renderModalError('Event booking',
                        getLink('events.bookings.index',
                            {id: this.props.match.params.id})),
                });
            }

            const [formParamsObject, linkParams] = getBookingFormParams(
                this.props.match.params.id,
                this.props.match.params.bookingId,
                params,
                true
            );
            formParams = formParamsObject;

            return this.loadEditModalForm(
                {
                    tabs: [
                        {
                            key: 'step1',
                            menuItem: {content: 'General information', disabled: false },
                            pane: {
                                content: createForm(
                                    EventBookingGeoRestrictionsGeneralInformationEditModel,
                                    form,
                                    formParams
                                ),
                            },
                            url: getLink('events.bookings.edit', linkParams),
                        },
                        {
                            key: 'step2',
                            menuItem: {content: 'Geo restrictions', disabled: false },
                            pane: {
                                content: <EventBookingGeoRestrictionsStep
                                    bookingId={convertToInt(this.props.match.params.bookingId)}
                                    eventId={convertToInt(this.props.match.params.id)}
                                />,
                            },
                            url: getLink('events.bookings.editGeoRestrictions', linkParams),
                        },
                        {
                            key: 'step3',
                            menuItem: {content: 'Blackout Zones', disabled: false },
                            pane: {
                                content: createForm(
                                    EventBookingGeoRestrictionsBlackoutZonesEditModel,
                                    EventBookingGeoRestrictionsBlackoutZonesForm,
                                    formParams
                                ),
                            },
                            url: getLink('events.bookings.blackoutZones', linkParams),
                        },
                    ],
                },
                formParams
            );
        }
    }

    loadAddModalForm = (Form) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={'Add new booking'}
                isAddMode={true}
                tabs={[
                    {key: 'step1', menuItem: {content: 'General information', disabled: false}, pane: {content: Form}},
                    {key: 'step2', menuItem: {content: 'Geo restrictions', disabled: true}, pane: {content: null}},
                    {key: 'step3', menuItem: {content: 'Blackout Zones', disabled: true}, pane: {content: null}},
                ]}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    loadEditModalForm = (Forms) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={'Edit booking'}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    prepareEventContentOptions = (eventContents) => {
        return eventContents.map((eventContent) => ({
            key: eventContent.id,
            text: getEventContentText(eventContent),
            value: convertToInt(eventContent.id),
        }));
    };

    render() {
        const filtersProps = {
                data: {
                    products: _get(this.props, 'DataDropDown.products', [])
                        .mapDataForDropdownWithIntVal(),
                    distributionTypes: _get(this.props, 'DataDropDown.distributionTypes', [])
                        .mapDataForDropdownWithIntVal(),
                    deviceCategories: _get(this.props, 'DataDropDown.deviceCategories', [])
                        .mapDataForDropdownWithIntVal(),
                    countries: _get(this.props, 'DataDropDown.countries', [])
                        .mapDataForDropdown('key'),
                    bookingTypes: _get(this.props, 'DataDropDown.bookingTypes', [])
                        .mapDataForDropdownWithIntVal(),
                    invoiceStatuses: _get(this.props, 'DataDropDown.invoiceStatuses', [])
                        .mapDataForDropdownWithIntVal(),
                    clients: _get(this.props, 'DataDropDown.clients', [])
                        .mapDataForDropdownWithIntVal(),
                    eventContentTypes: _get(this.props, 'DataDropDown.eventContentTypes', [])
                        .mapDataForDropdownWithIntVal(),
                    eventContentVariants: _get(this.props, 'DataDropDown.eventContentVariants', [])
                        .mapDataForDropdownWithIntVal(),
                    eventContents: this.prepareEventContentOptions(
                        _get(this.props, 'DataDropDown.eventContents', [])
                    ),
                },
                loading: this.props.DataDropDown.loading,
                url: routes.events.bookings.index.path,
            },
            isLoadingTable = (this.props.DataEventBookings.loading || this.props.loadingRefetch);

        return (
            <div>
                <HeaderRenderer
                    buttons={EventsBookingsButtons}
                    buttonsProps={{eventId: convertToInt(this.props.match.params.id)}}
                    exportExcelParams={eventsBookingsExportExcel(
                        this.props.DataEventBookings.eventBookings,
                        convertToInt(this.props.match.params.id)
                    )}
                    filters={EventsBookingsFilters}
                    filtersProps={filtersProps}
                    loading={isLoadingTable}
                    messagesBoxNames='eventBookingsMessage'
                />
                <Segment basic className='--table'>
                    <EventsBookingsTable
                        className={'eventBookings__table'}
                        eventBookings={this.props.DataEventBookings.eventBookings}
                        eventId={convertToInt(this.props.match.params.id)}
                        loading={isLoadingTable}
                    />
                </Segment>
            </div>
        );
    }
}

const EventsBookingsWithQuery = compose(
    graphql(GetEventBookingsForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                variables: {
                    event: convertToInt(props.match.params.id),
                    eventContentTypes: props.filters.eventContentTypes || EventsBookings.defaultProps.filters.eventContentTypes,
                    eventContentVariants: props.filters.eventContentVariants || EventsBookings.defaultProps.filters.eventContentVariants,
                    clients: props.filters.clients || EventsBookings.defaultProps.filters.clients,
                    contents: props.filters.eventContents || EventsBookings.defaultProps.filters.eventContents,
                    countries: props.filters.countries || EventsBookings.defaultProps.filters.countries,
                    isHq: convertBooleanValueForFilters(props.filters.isHq, null),
                    isLl: convertBooleanValueForFilters(props.filters.isLl, null),
                    products: props.filters.products || EventsBookings.defaultProps.filters.products,
                    distributions: props.filters.distributionTypes || EventsBookings.defaultProps.filters.distributionTypes,
                    devices: props.filters.deviceCategories || EventsBookings.defaultProps.filters.deviceCategories,
                    types: props.filters.bookingTypes || EventsBookings.defaultProps.filters.bookingTypes,
                    invoiceStatuses: props.filters.invoiceStatuses || EventsBookings.defaultProps.filters.invoiceStatuses,
                    isCancelled: convertBooleanValueForFilters(props.filters.isCancelled, null),
                },
            };
        },
        name: 'DataEventBookings',
    }),
    graphql(EventBookingsFiltersDropdownsOptions, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                variables: {
                    event: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataDropDown',
    })
)(FilterQueryWrapper(EventsBookings, {
    queryForRefresh: 'DataEventBookings',
    filterUrls: ['events.bookings.index'],
}));

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'Menu']);

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'EventsBookings', EventsBookings.defaultProps.filters),
    modal: state.modal,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(EventsBookingsWithQuery, EventsBookings.defaultProps.filters, 'events.bookings.index')
));
