import PropTypes from 'prop-types';
import {
    cloneDeep as _cloneDeep,
    debounce as _debounce,
    find as _find,
    findIndex as _findIndex,
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';

import * as MESSAGES from '@constants/messages';
import {isBeingEncoded} from '@graphql/events/query';
import {showMessageBox} from '@utils/messageBox';
import {convertToInt} from '@utils/helpers';
import {GetSportsWithTvChannel} from '@graphql/sport/query';

import apolloClient from '../../../apolloClient';
import {avaliableProductsBasedOfRightScopes} from '@utils/rightsScopeProducts';
import {EVENT_CONTENT_DEFAULT_DROPDOWN_CONTENT_VARIANT} from '@constants/variables';

export const getEventContentVariantDropdownOptions = (event, rightsScopes) => {
    const dropdownOptions = [];

    rightsScopes.forEach((rightsScope) => {
        // We need at least one product with right scopes
        if (!_isEmpty(avaliableProductsBasedOfRightScopes(event, [rightsScope]))) {
            rightsScope.rights_scope_event_content_variants.forEach((eventContentVariant) => {
                const eventContentVariantKey = eventContentVariant.event_content_variant.key;

                if (0 > _findIndex(dropdownOptions, {key: eventContentVariantKey})) {
                    dropdownOptions.push(eventContentVariant.event_content_variant);
                }
            });
        }
    });

    return dropdownOptions;
};

/**
 * Initial event content variant for ADD and EDIT mode
 */
export const getInitialEventContentVariant = (formParams, contentVariantOptions) => {
    let selectedContentVariant = null;

    const eventContentVariantId = _get(
        formParams,
        'eventContent.event_content_variant.id',
        null
    );

    // Add mode - default should be "Standard Feed"
    if (!eventContentVariantId) {
        selectedContentVariant = EVENT_CONTENT_DEFAULT_DROPDOWN_CONTENT_VARIANT;
    } else {
        selectedContentVariant = eventContentVariantId;
    }

    const isContentVariantInDropdownOptions = _find(
        contentVariantOptions,
        (contentVariant) => (contentVariant.id === selectedContentVariant)
    );

    return isContentVariantInDropdownOptions
        ? selectedContentVariant
        : null;
};

export const getRightScopesByEventContentVariant = (rightScope, variant) => {
    const clonedRightScopes = _cloneDeep(rightScope);
    const filteredScopeBySelectedEventContentVariant = rightScope.rights_scope_event_content_variants
        .filter(({event_content_variant}) => event_content_variant.id === variant);
    const productsIdFromEventContentVariant = filteredScopeBySelectedEventContentVariant.map(rs => rs.product.id);

    clonedRightScopes.usages = rightScope.usages.filter((usage) => {
        if (productsIdFromEventContentVariant.includes(usage.product.id)) {

            return usage;
        }

        return null;
    });

    return clonedRightScopes;
};

export const renderModalErrorObject = (text = '', onClose = null) => ({
    isVisible: true,
    size: 'tiny',
    header: 'Error',
    text: text,
    errorButtonText: 'Back',
    errorButtonIcon: 'arrow circle left',
    onClose: onClose,
});
renderModalErrorObject.propTypes = {
    text: PropTypes.string,
    onClose: PropTypes.func,
};

export const isBeingEncodedQueryHandler = (variables) => {
    return apolloClient.query({
        query: isBeingEncoded,
        variables: variables,
        fetchPolicy: 'network-only',
    });
};

export const setCanDeleteState = (id, setState, isEvent) => {
    const variables = {
        id: id,
        is_event: isEvent,
    };

    isBeingEncodedQueryHandler(variables)
        .then((result) => {
            const canDeleteObject = {canDelete: true};

            if (result.data.isBeingEncoded) {
                canDeleteObject.canDelete = false;
            }

            setState(canDeleteObject);
        })
        .catch((error) => {
            const label = isEvent ? 'Event' : 'Content';

            showMessageBox(
                'formInnerErrorMessage',
                MESSAGES.NOT_DELETED(label),
                `${error}`,
                'error'
            );
        });
};

export const setTvChannelState = (propsForm, selectedSports, setState, messageBoxName) => {
    apolloClient.query({
        query: GetSportsWithTvChannel,
    }).then((result) => {
        const sportsWithTvChannel = result.data.sports.map(sport => convertToInt(sport.id));
        const hasTvChannel = selectedSports.some((sport) => {
            return sportsWithTvChannel.includes(sport);
        });

        if (hasTvChannel) {
            setState({
                hasSportsWithTvChannel: true,
                tvChannelsLoading: true,
            });

            const debouncedStateUpdate = _debounce(() => {
                setState({
                    hasSportsWithTvChannel: true,
                    tvChannelsLoading: false,
                });
            }, 1000);

            debouncedStateUpdate();
        } else {
            setState({hasSportsWithTvChannel: false});

            propsForm.changeValue('tvChannels', null);
        }
    }).catch((error) => {
        showMessageBox(
            messageBoxName,
            'Failed to fetch sport has tv channel filter dropdown data',
            `${error}`,
            'error'
        );
    });
};

export const filterActiveRtmp = (allRtmpOriginEntryPointApplications, rtmpFromEditForm) => (
    allRtmpOriginEntryPointApplications.filter((rtmp) => (
        rtmp.rtmp_origin_entry_point.is_active || rtmp.value === rtmpFromEditForm
    ))
);

setCanDeleteState.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    setState: PropTypes.func.isRequired,
    isEvent: PropTypes.bool.isRequired,
};
