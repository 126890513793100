import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DOMPurify from 'dompurify';

import {ReduxComponent} from '@appComponents/ReduxFormControlsComponents/ReduxComponent';
import {REGEX_SVG_SELECTOR} from '@constants/variables';

/**
 * Render file preview for Redux
 */
class FilePreview extends ReduxComponent {
    static propTypes = {
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        defaultValue: PropTypes.string,
        input: PropTypes.object,
        meta: PropTypes.object,
        type: PropTypes.string,
    };

    /**
     * @ignore
     */
    componentDidMount() {
        this.props.input.onChange(this.props.defaultValue);
    }

    /**
     * @ignore
     */
    componentDidUpdate(prevProps) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.props.input.onChange(this.props.defaultValue);
        }
    }

    /**
     * @ignore
     */
    prepareSvgType() {
        let svg = this.props.defaultValue.match(REGEX_SVG_SELECTOR);

        return svg ? svg[0] : null;
    }

    /**
     * @ignore
     */
    renderContent() {
        let content = <div>{this.props.defaultValue}</div>;

        if (this.props.defaultValue && 'svg' === this.props.type) {
            const sanitizedHTML = DOMPurify.sanitize(this.prepareSvgType(), { USE_PROFILES: { svg: true } });

            // eslint-disable-next-line
            content = <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
        }

        return content;
    }

    /**
     * @ignore
     */
    render() {
        const errorInfo = this.isError(this.props) ? this.props.meta.error : '';

        return (
            <div className={classnames(
                {'error': errorInfo},
                this.props.className
            )}>
                {this.renderLabel(this.props)}
                <div className={classnames(
                    'input-container',
                    this.props.containerClassName
                )}>
                    <div className='form__field-wrapper'>
                        {this.renderContent()}
                    </div>
                    <div className='error info'>{errorInfo}</div>
                </div>
            </div>
        );
    }
}

export default FilePreview;
