import {isEmpty as _isEmpty} from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {FilterQueryWrapper, FilterUrlParamsWrapper} from "../../app/components/HOCFiltersQueryWrapper";
import LicenseeFilters from "../components/LicenseeFilters";
import LicenseeIndexButtons from "../components/LicenseeIndexButtons";
import LicenseeTable from "../components/LicenseeTable";

import {getSearchFilters} from "../../../utils/filters";
import {exportExcel} from "../utils/export/licenseeIndexExcel";
import {renderLicenseeModalError} from "../utils/licenseeHelpers";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import useQueryCustom from "@utils/hooks/useQueryCustom";

import navigation from "../../app/constants/navigation";

import {GetLicenseesForView} from "../../../graphql/licensee/query.graphql";
import {GetCountries} from "../../../graphql/country/query.graphql";

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    countries: [],
};

const LicenseeIndex = ({areFiltersVisible = true, filters = {}, Menu, Modal}) => {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        country_id: filters.countries ? filters.countries.map(Number) : DEFAULT_FILTERS_VALUES.countries,
    };

    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQueryCustom({
        query: GetLicenseesForView,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQueryCustom({
        query: GetCountries,
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: renderLicenseeModalError()
        })
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={LicenseeIndexButtons}
                exportExcelParams={exportExcel(data.licensees)}
                filters={LicenseeFilters}
                filtersButtonName="Licensees"
                filtersProps={{
                    data: {
                        countries: dataForFilters.countries || DEFAULT_FILTERS_VALUES.countries,
                        isVisible: areFiltersVisible,
                    },
                    loading: areFiltersLoading,
                }}
                loading={areDataLoading}
                messagesBoxNames="licenseeMessage"
                pageTitle="Licensees"
            />
            <LicenseeTable
                licensees={data.licensees}
                loading={areDataLoading}
            />
        </div>
    );
};

LicenseeIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    filters: PropTypes.shape({
        search: PropTypes.string,
        countries: PropTypes.array,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};

const LicenseeIndexWrapped = FilterQueryWrapper(LicenseeIndex, {
    queryForRefresh: "GetLicenseesForView",
    filterUrls: ['licensees.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Licensees', DEFAULT_FILTERS_VALUES)
});

export default connect(mapStateToProps, mapModulesToProps(['Menu', 'Modal']))(
    FilterUrlParamsWrapper(LicenseeIndexWrapped, DEFAULT_FILTERS_VALUES)
);
