import PropTypes from 'prop-types';
import React from 'react';

import {graphql} from 'react-apollo';
import Table from '@appComponents/Table';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {routes} from '@constants/routes';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';
import {rowRenderer} from '@modules/events/components/EventsBookings/eventsBookingsTableRowRenderer';

import {columns} from './eventsBookingsTableColumns';

export const EventsBookingsTable = (props) => {
    const {data, eventBookings, eventId, loading} = props;

    const preparedRowRenderer = (column, row) => {
        return rowRenderer(column, row, data, eventId);
    };

    return (
        <div className='eventBookings__table'>
            <Table
                className='mini'
                name='eventBookingsList'
                columns={columns}
                data={eventBookings}
                loading={loading}
                pagination={false}
                noDataText='No bookings found'
                rowRenderer={preparedRowRenderer}
                rowClassname={getTableRowIsDisabledClassName}
                url={routes.events.bookings.index.path}
            />
        </div>
    );
};

EventsBookingsTable.propTypes = {
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    eventBookings: PropTypes.array,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};
EventsBookingsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    eventBookings: [],
    loading: false,
};
EventsBookingsTable.displayName = 'EventsBookingsTable';

export default graphql(GetMappedIsoCodes)(EventsBookingsTable);
