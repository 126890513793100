import React from 'react';
import {filter as _filter, get as _get, isNil as _isNil, has as _has, isUndefined as _isUndefined, map as _map} from 'lodash';
import moment from 'moment/moment';
import {Icon, Label, List, Popup} from 'semantic-ui-react';

import {PRODUCT_LCO, RTS_COVERAGE_CONFIRMED} from '@constants/variables';
import {getSportNameFromEvent} from '@utils/sport';
import {getTournamentCategoryNameFromEvent, getTournamentNameFromEvent} from '@utils/tournament';
import {isValidMatchStatusId} from '@modules/liveControl/utils/helpers';
import {renderEventStatusName} from '@utils/status';
import {convertToInt} from '@utils/helpers';
import {IconAdd, IconEdit, IconLinkify} from '@appComponents/IconCollection';
import {CompetitorsBlacklistedStatus} from '@modules/events/views/common/EventScheduleTableColumns';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup';
import Link, {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import {eventAddAuthorizationObject, eventReadAuthorizationObject} from '@modules/events/components/Table/EventSchedule/authorizationObject';

const getPropertyNameHandler = (event, fn, propertyName) => {
    let result = fn(event);

    // @TODO: properly refactor getTournamentCategoryNameFromEvent, getTournamentNameFromEvent, getSportNameFromEvent
    // methods to get rid of annoying minus
    if ('-' === result) {
        result = _get(event, propertyName, '-');
    }

    return result;
};

export const checkIfIsEvent = (event) => (_has(event, 'sr_event.id') || _has(event, 'av_event.id'));

export const checkIfIsTvStreaming = (event) => {
    const path = checkIfIsEvent(event) ? 'sr_event.match.is_tv_streaming' : 'is_tv_streaming';

    return Boolean(_get(event, path, false));
};

export const getSportName = (event) => (
    getPropertyNameHandler(event, getSportNameFromEvent, 'av_event.content_category_level1.name')
);

export const getTournamentCategoryName = (event) => (
    getPropertyNameHandler(event, getTournamentCategoryNameFromEvent, 'av_event.content_category_level2.name')
);

export const getTournamentName = (event) => (
    getPropertyNameHandler(event, getTournamentNameFromEvent, 'av_event.content_category_level3.name')
);

export const getTvStreamingEncoderId = (event) => {
    const path = checkIfIsEvent(event) ? 'sr_event.match.tv_streaming_encoder.id' : 'tv_streaming_encoder.id';

    return _get(event, path, null);
};

export const getCompetitorWarnings = (event) => {
    const {match_status_id, match_status_name} = getMatchStatusData(event);
    let competitorWarnings = [],
        match = event;

    if (checkIfIsEvent(event) && _has(event, 'sr_event.match.id')) {
        match = event.sr_event.match;
    }

    if (!_has(match, 'home_competitor.id')) {
        competitorWarnings.push('No unique home competitor');
    }

    if (!_has(match, 'away_competitor.id')) {
        competitorWarnings.push('No unique away competitor');
    }

    if (_has(match, 'rts_coverage.id') && parseInt(match.rts_coverage.id, 10) === RTS_COVERAGE_CONFIRMED) {
        competitorWarnings.push('RTS');
    }

    if (!match.has_live_scout) {
        competitorWarnings.push('No scout');
    }

    if (!match.has_live_odds) {
        competitorWarnings.push('No Live Odds');
    }

    if (match.has_underage_competitors) {
        competitorWarnings.push('Underage competitors');
    }

    if (match.is_cancelled) {
        competitorWarnings.push('Cancelled');
    }

    if (match.is_postponed) {
        competitorWarnings.push('Postponed');
    }

    if (match.is_walkover) {
        competitorWarnings.push('Walkover');
    }

    if (isValidMatchStatusId(match_status_id)) {
        competitorWarnings.push(match_status_name);
    }

    return competitorWarnings;
};

export const getMatchStatusData = (data) => {
    if (_has(data, 'sr_event.match')) {
        return {
            match_status_id: data.sr_event.match?.match_status_id,
            match_status_name: data.sr_event.match?.match_status_name,
        };
    }

    return {
        match_status_id: data.match_status_id || null,
        match_status_name: data.match_status_name || null,
    };
};

export const isHomeCompetitorBlacklisted = (event) => {
    return Boolean(_get(event, 'sr_event.match.home_competitor.is_blacklisted',
        _get(event, 'sr_event.home_competitor.is_blacklisted',
            _get(event, 'home_competitor.is_blacklisted', false)
        )
    ));
};

export const isAwayCompetitorBlackListed = (event) => {
    return Boolean(_get(event, 'sr_event.match.away_competitor.is_blacklisted',
        _get(event, 'sr_event.away_competitor.is_blacklisted',
            _get(event, 'away_competitor.is_blacklisted', false)
        )
    ));
};

export const filteredSelectedTournamentCategory = (selectedSports, prevState, setState) => {
    if (selectedSports && prevState.selectedSports && selectedSports !== prevState.selectedSports) {
        setState(prevState => ({
            selectedTournamentCategories: prevState.selectedTournamentCategories
                ?.filter(selectedTournamentCategory => prevState.tournamentCategories
                    .some(tournamentCategory => tournamentCategory.value === selectedTournamentCategory)),
        }));
    }
};

export const filteredSelectedTournament = (prevState, setState, tournamentsState, selectedTournaments) => {
    if (tournamentsState !== prevState.tournaments) {
        setState(() => ({
            selectedTournaments: selectedTournaments
                ?.filter(selectedTournament => tournamentsState
                    .some(item => item.value === selectedTournament)),
        }));
    }
};

export const allEventsHasLcoDistribution = (selectedEventsData) => {
    return selectedEventsData.every(event =>
        event.event_contents.some(content =>
            content.products.some(product => PRODUCT_LCO === product.product_id)
        )
    );
};

export const getSortingValue = (value) => {
    if ('object' === typeof value) {
        if (null === value) {
            return '';
        } else if (value.length) {
            return value.join();
        }

        return value.toString();
    }

    return value;
};

export const rowContainsColumn = (column, row) => {
    return !_isUndefined(row[column]) && null !== row[column];
};

export const renderStatusColumn = (column, row) => {
    const renderedStatus = rowContainsColumn(column, row)
        ? renderEventStatusName(row.status_id, row.status)
        : '';

    return <div>{renderedStatus}</div>;
};

export const renderEventIdColumn = (column, row) => {
    return (
        <div>
            {rowContainsColumn(column, row) ? convertToInt(row[column]) : ''}
        </div>
    );
};

export const renderMatchIdColumn = (column, row) => {
    const matchIdColumnContent = (row.is_sr_event)
        ? <IconLinkify color='red'/>
        : '';

    return (
        <div>
            {rowContainsColumn(column, row) ? convertToInt(row[column]) : matchIdColumnContent}
        </div>
    );
};

export const renderStartDatetimeColumn = (column, row) => {
    return (
        <div>
            <div>
                {rowContainsColumn(column, row) ? moment(row[column]).format('YYYY-MM-DD HH:mm') : ''}
            </div>
            <div>
                {rowContainsColumn('end_datetime', row) ? moment(row.end_datetime).format('YYYY-MM-DD HH:mm') : ''}
            </div>
        </div>
    );
};

export const renderCompetitorsColumn = (column, row) => {
    const competitorsWarningsExist = row.competitors_warnings && 0 !== row.competitors_warnings.length,
        tvStreamingInfoExist = row.is_tv_streaming && null !== row.tv_streaming_encoder_id,
        marginClass = (row.description && (competitorsWarningsExist || tvStreamingInfoExist))
            ? 'marginBetweenDescriptionAndWarnings'
            : '';

    return (
        <div>
            <div>
                {rowContainsColumn(column, row) ? row[column] : ''}
            </div>
            <div>
                {(rowContainsColumn('description', row))
                    ? <Label style={{backgroundColor: `#${row.color}`}} size='mini'>{row.description}</Label>
                    : ''
                }
            </div>
            <div className={marginClass}>
                {tvStreamingInfoExist && <Label color='grey' size='mini'>TV Streaming</Label>}
                {competitorsWarningsExist && _map(row.competitors_warnings, (warning, index) => (
                    <Label key={index} color='red' size='mini'>{warning}</Label>
                ))}
                <CompetitorsBlacklistedStatus
                    ac_blacklisted={row.is_ac_blacklisted}
                    hc_blacklisted={row.is_hc_blacklisted}/>
            </div>
        </div>
    );
};

export const renderEventContentWarning = (content) => (
    <Popup
        trigger={<Icon className='warningSign' name='warning sign' color='red'/>}
        content={content.eventContentWarning.content}
        size='small'
        hoverable
        key={`content.popup.${content.event_content_id}`}
    />
);

export const renderContentColumns = (column, row) => {
    const renderedIds = new Set();

    if (!row[column] || 0 === row[column].length) {
        return null;
    }

    return (
        <List>
            {_map(row[column], (item, index) => {
                if (!renderedIds.has(item.event_content_id)) {
                    renderedIds.add(item.event_content_id);
                    const itemCount = _filter(row[column], {event_content_id: item.event_content_id}).length;

                    return (
                        <List.Item key={`${column}.list.item.${item.event_content_id}_${index}`}
                            className='eventContentDetails' style={{'--item-count': itemCount}}>
                            <List.Content>
                                <p>
                                    {item.name || '\u00A0'}
                                    {item.eventContentWarning && renderEventContentWarning(item)}
                                    {item.eventContentVariant &&
                                        <EventContentVariantPopup eventContentVariant={item.eventContentVariant}/>}
                                </p>
                            </List.Content>
                        </List.Item>
                    );
                } else {
                    return null;
                }
            })}
        </List>
    );
};

export const renderProductsAndDistribution = (column, row, fieldName = 'name') => {
    const lastElementIndices = new Map();
    row[column].forEach((item, index) => {
        lastElementIndices.set(item.event_content_id, index);
    });

    return (
        (!row[column] || 0 === row[column].length) ? null :
            <List>
                {_map(row[column], (item, index) => {
                    const product = row.products ? row.products[index] : null,
                        isLastInGroup = lastElementIndices.get(item.event_content_id) === index;

                    return (
                        <List.Item key={`${column}.list.item.${item.event_content_id}_${index}`}
                            className={isLastInGroup ? 'lastInGroup' : ''}>
                            <List.Content key={`${column}.list.content.${item.event_content_id}`}>
                                <p key={`${column}.p.${item.product_id}`}>
                                    {item[fieldName] || (product ? product.short_name : '\u00A0')}
                                </p>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

export const renderColumnWithList = (column, row, defaultValue = 0, fieldName = false) => {
    let field = column;

    if (fieldName) {
        field = fieldName;
    }

    const lastElementIndices = new Map();
    row[column].forEach((item, index) => {
        lastElementIndices.set(item.event_content_id, index);
    });

    return (
        (!row[column] || 0 === row[column].length) ? null:
            <List>
                {_map(row[column], (item, index) => {
                    const isLastInGroup = lastElementIndices.get(item.event_content_id) === index;
                    const columnValues = _filter(row[column], {
                        event_content_id: row.products[index].event_content_id,
                        product_id: row.products[index].product_id,
                    });

                    return (
                        <List.Item
                            key={`${field}.list.item.${item.event_content_id}_${index}`}
                            className={isLastInGroup ? 'lastInGroup' : ''}
                        >
                            <List.Content key={`${field}.list.content.${item.event_content_id}`}>
                                {0 === columnValues.length ? <p key={`product.p.${item.product_id}`}>0</p> :
                                    <p key={`product.p.${item.product_id}`}>
                                        {columnValues[0][field] || defaultValue}
                                    </p>
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

export const renderCategoryOrTournament = (column, row) => {
    return Array.isArray(row[column]) && 0 < row[column].length
        ? <List>
            {_map(row[column], (data, index) => {
                return (
                    <List.Item key={`tournament.list.item.${index}`}>
                        <List.Content key={`tournament.list.content.${index}`}>
                            <p key={`tournament.p.${index}`}>
                                {data}
                            </p>
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
        : row[column] || null;
};

export const renderVenueOrCourtAndTvChannelColumn = (column, row) => {
    return (
        <div>
            <div>
                {_isNil(row[column])
                    ? ''
                    : row[column]
                }
            </div>
            <div>
                {_isNil(row.tv_channel)
                    ? ''
                    : row.tv_channel
                }
            </div>
        </div>
    );
};

export const renderCoverageValue = (column, row) => {
    return (_isUndefined(row[column]) || null === row[column]) ? '' : row[column];
};

export const renderAction = (row) => {
    if (null !== row.event_id) {
        return (
            <Authorization authorization={eventReadAuthorizationObject}>
                <IconEdit
                    size='large'
                    link={getLink('events.configuration.index', {id: row.event_id})}
                    resource={RESOURCES.EVENT}
                    editIconName='setting'
                />
            </Authorization>
        );
    }

    return (
        <Authorization authorization={eventAddAuthorizationObject}>
            <Link name='events.add.sportradarExisting' params={{matchId: row.match_id}}>
                <IconAdd size='large'/>
            </Link>
        </Authorization>
    );
};
