import {get as _get} from 'lodash';

import {getBasicEventBookingsColumnsForBookingsTable} from '@modules/events/utils/bookingTableHelper';

export const columns = {
    ...getBasicEventBookingsColumnsForBookingsTable('booking'),
    'booking_type': {
        label: 'Type',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        }},
    'price': {
        label: 'Price',
        sorting: true,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        }},
    'booking_invoice_status': {
        label: 'Invoice status',
        sorting: row => _get(row, 'booking_invoice_status.name', _get(row, 'event_invoice_status.name', null)),
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'time': {
        label: 'Time',
        sorting: row => row.creation_datetime,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        }},
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};
