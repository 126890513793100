import React from 'react';
import PropTypes from 'prop-types';

import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';
import Authorization from '@appComponents/Authorization';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationObject = {
    [RESOURCES.PRODUCT]: CONST.SECURITY_PRIVILEGES_READ,
};

export const renderText = (text, label) => {
    return (
        <div>
            <label>{label}</label>
            <div>{text}</div>
        </div>
    );
};

renderText.propTypes = {
    text: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {label: 'Name', sorting: true},
    'short_name': {label: 'Short name', sorting: true},
    'cdn': {
        label: 'Default CDN',
        sorting: true,
    },
    'live_ingest_method': {
        label: 'Default Live ingest method',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'recording_ingest_method': {
        label: 'Default Recording ingest method',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'akamai_cdn_ingest_method': {
        label: 'Akamai CDN ingest method',
        sorting: true,
    },
    'has_akamai_rtmp_distr_region_support': {
        label: 'Akamai RTMP distr. regions',
        sorting: (row) => (row.has_akamai_rtmp_distr_region_support),
        ...columnObjectAlignCenter,
    },
    'has_scoreboard_overlay_support': {
        label: 'Scoreboard overlays',
        sorting: (row) => (row.has_scoreboard_overlay_support),
        ...columnObjectAlignCenter,
    },
    'has_slate_support': {
        label: 'Slates',
        sorting: (row) => (row.has_slate_support),
        ...columnObjectAlignCenter,
    },
    'has_overlay_support': {
        label: 'Overlays',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'has_ad_support': {
        label: 'Ads',
        sorting: (row) => (row.has_ad_support),
        ...columnObjectAlignCenter,
    },
    'has_drm_support': {
        label: 'DRM',
        sorting: (row) => (row.has_drm_support),
        ...columnObjectAlignCenter,
    },
    'has_cc_support': {
        label: 'CC',
        sorting: (row) => (row.has_cc_support),
        ...columnObjectAlignCenter,
    },
    'has_hq_support': {
        label: 'HQ',
        sorting: (row) => (row.has_hq_support),
        ...columnObjectAlignCenter,
    },
    'has_ll_support': {
        label: 'LL',
        sorting: (row) => (row.has_ll_support),
        ...columnObjectAlignCenter,
    },
    'has_usage_type_support': {
        label: 'Usage types',
        sorting: (row) => (row.has_usage_type_support),
        ...columnObjectAlignCenter,
    },
    'has_geolocation_support': {
        label: 'Geolocation',
        sorting: (row) => (row.has_geolocation_support),
        ...columnObjectAlignCenter,
    },
    'has_event_booking_support': {
        label: 'Event booking',
        sorting: (row) => (row.has_event_booking_support),
        ...columnObjectAlignCenter,
    },
    'is_invoiceable': {
        label: 'Invoiceable',
        sorting: (row) => (row.is_invoiceable),
        ...columnObjectAlignCenter,
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'products.edit',
                        params: {id: row.id},
                    }}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );
        case 'actions' :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={
                            getLink('products.edit', {id: row.id})
                        } resource={RESOURCES.PRODUCT}/>
                    </div>
                </Authorization>
            );
        case 'short_name':
            return row.short_name;
        case 'cdn':
            return row[column] ? row[column].name : null;
        case 'live_ingest_method':
            return row[column] ? row[column].name : null;
        case 'recording_ingest_method':
            return row[column] ? row[column].name : null;
        case 'akamai_cdn_ingest_method':
            return null !== row[column] ? row[column].name : null;
        case 'has_akamai_rtmp_distr_region_support':
        case 'has_scoreboard_overlay_support':
        case 'has_slate_support':
        case 'has_overlay_support':
        case 'has_ad_support':
        case 'has_cc_support':
        case 'has_hq_support':
        case 'has_ll_support':
        case 'has_drm_support':
        case 'has_usage_type_support':
        case 'has_geolocation_support':
        case 'has_event_booking_support':
        case 'is_invoiceable':
            return !row[column] ? '' : <IconCheckmark/>;
        default:
            return null;
    }
};
