import {EVENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE_READ,SECURITY_PRIVILEGES_READ} from '@constants/variables';

export const eventAddAuthorizationObject = {
    [EVENT]: SECURITY_PRIVILEGES_CREATE_READ,
};

export const eventReadAuthorizationObject = {
    [EVENT]: SECURITY_PRIVILEGES_READ,
};
