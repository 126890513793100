import PropTypes from 'prop-types';
import React from 'react';
import {Divider, Popup} from 'semantic-ui-react';

import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {MEDIACONNECT_ORIGIN_FEEDS} from '@constants/staticDropdowns';
import * as eventContentGraphQl from '@graphql/events/eventContent';
import {
    GetEventContentForEditModal,
    GetEventContentOptionsForEditModal,
} from '@graphql/eventContent/query';
import {Icon} from '@appComponents/IconCollection';
import {EVENT_CONTENT} from '@constants/resources';

import {renderProducts} from '../utils/eventsContentModel';
import validate from './validatorEventsContent';

export default function EventsContentModel(props) {
    const renderDividerTarget = () => (<Divider className="distributions-tab-product-divider"/>),
        startBroadcastInformationTooltip = <Popup
            trigger={<Icon name="info circle"/>}
            content='If this option is selected, the broadcast status of the Live distributions of the content will be
             changed automatically to "On Air Broadcast" shortly before the match starts. If the content is not being
             encoded at that time, its encoding will also automatically be started priorly.'
            hoverable
            wide="very"
            position="top left"
        />,
        setAsDeliveredAutomatically = <Popup
            trigger={<Icon name="info circle"/>}
            content='If this option is selected, the broadcast status of the Live distributions of the content will be
             changed automatically to "Delivered" if the match has ended when the encoding is stopped.'
            hoverable
            wide="very"
            position="top left"
        />,
        renderGenerateTargetsWarning = () => (
            <div className="--block">
                <p className="--float-right">{`
                    Automatic encoding target generation is not possible because there is no tech setup defined
                    for the property licence of the event.
                `}</p>
            </div>
        );

    return {
        formName: 'event content',
        entityLabel: 'content',
        validate: validate,
        entityDataMapKey: 'event_content',
        resources: EVENT_CONTENT,
        showChangeLog: true,
        mutation: {
            updateEntity: eventContentGraphQl.eventContentUpdateMutation,
            createEntity: eventContentGraphQl.eventContentCreateMutation,
            deleteEntity: eventContentGraphQl.eventContentDeleteMutation,
        },
        mutationOptions: {
            update: {
                refetchQueries: ['GetEventContentEncodingConfigurationChanged', 'GetEncodingTargetsForDistributions'],
            },
        },
        query: {
            getEntity: GetEventContentForEditModal,
            getOptions: GetEventContentOptionsForEditModal,
        },
        title: (data, type) => (
            (data && data.event_content && type === 'edit') ? 'Edit Content' : 'Add Content'
        ),
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'event',
            },
            text: MESSAGES,
        },
        wizard: {
            generalInformation: {
                title: 'General Information',
                icon: 'setting',
                path: 'events.configuration.content.edit',
            },
            input: {
                title: 'Input',
                icon: 'signal',
                path: 'events.configuration.content.input',
            },
            distribution: {
                title: 'Distribution',
                icon: 'tasks',
                path: 'events.configuration.content.distribution',
            },
        },
        dataMap: {
            event_content: {
                id: '',
                event: {id: ''},
                event_content_type: {id: ''},
                event_content_variant: {id: ''},
                name: '',
                synchronise_times: true,
                start_datetime: null,
                end_datetime: null,
                video_length: '',
                video_duration: '',
                is_247_stream: false,
                is_main_content: false,
                hide_in_live_control: false,
                has_input: false,
                start_broadcast_automatically: false,
                set_as_delivered_automatically: false,
                event_content_input: {
                    id: '',
                    contribution_type: {id: ''},
                    encoding_datacenter: {id: ''},
                    encoding_redundancy_type: {id: ''},
                    production_company: {id: ''},
                    contact_person: {id: ''},
                    origin_stream_type: {id: ''},
                    rtmp_origin_entry_point_application: {id: ''},
                    srt_ad_splice_config: {id: ''},
                },
                distributions: [],
            },
            event_content_types: [],
            event_content_variants: [],
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'event_content.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            event_content_input_id: {
                dataMapKey: 'event_content.event_content_input.id',
                component: Form.IdField,
                props: {
                    label: '',
                    name: 'eventContentInputId',
                    hidden: true,
                },
            },
            event_content_type_id: {
                dataMapKey: 'event_content.event_content_type.id',
                optionsKey: 'eventContentTypes',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Type',
                    required: true,
                    name: 'event_content_type',
                },
            },
            event_content_variant_id: {
                dataMapKey: 'event_content.event_content_variant.id',
                optionsKey: 'eventContentVariants',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Variant',
                    required: true,
                    name: 'event_content_variant',
                    hidden: false,
                },
            },
            description: {
                dataMapKey: 'event_content.name',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    withRef: true,
                },
            },
            synchronise_times: {
                dataMapKey: 'event_content.synchronise_times',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Synchronise the times with the event times?',
                    name: 'synchronise_times',
                    type: 'checkbox',
                    hidden: false,
                },
            },
            start_datetime: {
                dataMapKey: 'event_content.start_datetime',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                        updateOnExternalChange: true,
                    },
                    label: 'Start time',
                    name: 'start_datetime',
                    required: true,
                    restorePreviousValue: true,
                    type: 'date',
                    hidden: false,
                },
            },
            end_datetime: {
                dataMapKey: 'event_content.end_datetime',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                        isClearable: false,
                        updateOnExternalChange: true,
                    },
                    label: 'End time',
                    name: 'end_datetime',
                    restorePreviousValue: true,
                    type: 'date',
                    hidden: false,
                },
            },
            video_length: {
                dataMapKey: 'event_content.video_length',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Video length',
                    name: 'video_length',
                    description: 'minutes',
                    hidden: true,
                },
            },
            video_duration: {
                dataMapKey: 'event_content.video_duration',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Video duration',
                    name: 'video_duration',
                    description: 'milliseconds',
                    hidden: true,
                },
            },
            is_247_stream: {
                dataMapKey: 'event_content.is_247_stream',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    disabled: true,
                    hidden: true,
                    label: '24/7 stream?',
                    name: 'is_247_stream',
                    type: 'checkbox',
                },
            },
            is_main_content: {
                dataMapKey: 'event_content.is_main_content',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Main content of the event?',
                    name: 'is_main_content',
                    type: 'checkbox',
                },
            },
            start_broadcast_automatically: {
                dataMapKey: 'event_content.start_broadcast_automatically',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Start broadcast automatically?',
                    name: 'start_broadcast_automatically',
                    type: 'checkbox',
                    description: startBroadcastInformationTooltip,
                    hidden: true,
                    disabled: true
                },
            },
            set_as_delivered_automatically: {
                dataMapKey: 'event_content.set_as_delivered_automatically',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    label: 'Set as delivered automatically?',
                    name: 'set_as_delivered_automatically',
                    type: 'checkbox',
                    description: setAsDeliveredAutomatically,
                    hidden: true,
                    disabled: true,
                },
            },
            hide_in_live_control: {
                dataMapKey: 'event_content.hide_in_live_control',
                wizardKey: 'generalInformation',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Hidden in Live Control?',
                    name: 'hide_in_live_control',
                    type: 'checkbox',
                },
            },
            has_input: {
                dataMapKey: 'event_content.has_input',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    label: 'Define input',
                    name: 'has_input',
                    type: 'toggle',
                },
            },
            contribution_type_id: {
                dataMapKey: 'event_content.event_content_input.contribution_type.id',
                optionsKey: 'contributionTypes',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Contribution type',
                    required: true,
                    name: 'contribution_type',
                    hidden: true,
                },
            },
            encoding_datacenter_id: {
                dataMapKey: 'event_content.event_content_input.encoding_datacenter.id',
                optionsKey: 'encodingDatacenters',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoding datacenter',
                    required: true,
                    name: 'encoding_datacenter',
                    hidden: true,
                },
            },
            encoding_redundancy_type_id: {
                dataMapKey: 'event_content.event_content_input.encoding_redundancy_type.id',
                optionsKey: 'encodingRedundancyTypes',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Encoding redundancy type',
                    required: true,
                    name: 'encoding_redundancy_type',
                    hidden: true,
                },
            },
            production_company_id: {
                dataMapKey: 'event_content.event_content_input.production_company.id',
                optionsKey: 'productionCompanies',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Production company',
                    required: true,
                    name: 'production_company',
                    hidden: true,
                },
            },
            contact_person_id: {
                dataMapKey: 'event_content.event_content_input.contact_person.id',
                optionsKey: 'contactPerson',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Contact person',
                    required: true,
                    name: 'contact_person',
                    hidden: true,
                },
            },
            origin_stream_type_id: {
                dataMapKey: 'event_content.event_content_input.origin_stream_type.id',
                optionsKey: 'originStreamTypes',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Origin stream type',
                    required: true,
                    name: 'origin_stream_type',
                    hidden: true,
                },
            },
            rtmp_origin_entry_point_application_id: {
                dataMapKey: 'event_content.event_content_input.rtmp_origin_entry_point_application.id',
                optionsKey: 'rtmpOriginEntryPointApplications',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'RTMP origin entry point application',
                    required: true,
                    name: 'rtmp_origin_entry_point_application',
                    hidden: true,
                },
            },
            rtmp_origin_stream_name_id: {
                dataMapKey: 'event_content.event_content_input.rtmp_origin_stream_name.id',
                optionsKey: 'rtmpOriginStreamName',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'RTMP origin stream name',
                    name: 'rtmp_origin_stream_name',
                    hidden: true,
                    allowClear: true,
                },
            },
            udp_origin_entry_point_id: {
                dataMapKey: 'event_content.event_content_input.udp_origin_entry_point.id',
                optionsKey: 'udpOriginEntryPoint',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'UDP origin entry point',
                    name: 'udp_origin_entry_point',
                    hidden: true,
                    allowClear: true,
                },
            },
            sdi_origin_entry_point_id: {
                dataMapKey: 'event_content.event_content_input.sdi_origin_entry_point.id',
                optionsKey: 'sdiOriginEntryPoint',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'SDI origin entry point',
                    name: 'sdi_origin_entry_point',
                    hidden: true,
                    allowClear: true,
                },
            },
            http_origin_entry_point_url: {
                dataMapKey: 'event_content.event_content_input.http_origin_entry_point_url',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'HTTP origin entry point URL',
                    name: 'http_origin_entry_point_url',
                },
            },
            srt_origin_entry_point_id: {
                dataMapKey: 'event_content.event_content_input.srt_origin_entry_point.id',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'SRT origin entry point',
                    name: 'srt_origin_entry_point',
                },
            },
            failover_srt_origin_entry_point_id: {
                dataMapKey: 'event_content.event_content_input.failover_srt_origin_entry_point.id',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'Failover SRT origin entry point',
                    name: 'failover_srt_origin_entry_point',
                    allowClear: true,
                },
            },
            srt_origin_entry_point_url: {
                dataMapKey: 'event_content.event_content_input.srt_origin_entry_point_url',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'SRT origin entry point URL',
                    name: 'srt_origin_entry_point_url',
                },
            },
            failover_srt_origin_entry_point_url: {
                dataMapKey: 'event_content.event_content_input.failover_srt_origin_entry_point_url',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    label: 'Failover SRT origin entry point URL',
                    name: 'failover_srt_origin_entry_point_url',
                },
            },
            is_srt_origin_entry_point_in_failover: {
                dataMapKey: 'event_content.event_content_input.is_srt_origin_entry_point_in_failover',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    label: 'Use failover',
                    name: 'is_srt_origin_entry_point_in_failover',
                    type: 'toggle',
                    hidden: true,
                },
            },
            override_ad_setup: {
                dataMapKey: 'event_content.event_content_input.override_ad_setup',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    label: 'Override ad setup',
                    name: 'override_ad_setup',
                    type: 'toggle',
                    hidden: true,
                },
            },
            srt_has_scte35: {
                dataMapKey: 'event_content.event_content_input.srt_has_scte35',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticInput,
                    label: 'Ad support (SCTE-35)',
                    name: 'srt_has_scte35',
                    type: 'toggle',
                    hidden: true,
                },
            },
            srt_ad_splice_config: {
                dataMapKey: 'event_content.event_content_input.srt_ad_splice_config.id',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Ad splice config',
                    name: 'srt_ad_splice_config',
                    hidden: true,
                },
            },
            mediaconnect_origin_entry_point: {
                dataMapKey: 'event_content.event_content_input.mediaconnect_origin_entry_point.id',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    label: 'MediaConnect origin entry point',
                    name: 'mediaconnect_origin_entry_point',
                    allowClear: true,
                },
            },
            mediaconnect_origin_feeds: {
                dataMapKey: 'event_content.event_content_input.mediaconnect_origin_feeds',
                wizardKey: 'input',
                props: {
                    component: Form.SemanticSelect,
                    hidden: true,
                    options: MEDIACONNECT_ORIGIN_FEEDS,
                    label: 'MediaConnect origin feeds',
                    name: 'mediaconnect_origin_feeds',
                },
            },
            ...renderProducts(props.products, renderDividerTarget),
            generateTargets: {
                wizardKey: 'distribution',
                props: {
                    component: Form.SemanticInput,
                    description: 'Automatically generate the targets',
                    name: 'generateTargets',
                    type: 'checkbox',
                    defaultValue: true,
                    className: '--float-right',
                    divClassName: '--block',
                    hidden: true,
                },
            },
            generateTargetsWarning: {
                wizardKey: 'distribution',
                props: {
                    component: renderGenerateTargetsWarning,
                    name: 'generateTargetsWarning',
                    hidden: true,
                },
            },
        },
    };
}

EventsContentModel.propTypes = {
    products: PropTypes.array.isRequired,
};
