import React from 'react';
import PropTypes from 'prop-types';
import {get as _get, isEmpty as _isEmpty, map as _map} from 'lodash';

import Table from '@modules/app/components/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconCheckmark} from '@appComponents/IconCollection';
import {convertToInt} from '@utils/helpers';
import {getDisabledClassNameForCell, columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';
import * as CONST from '@constants/variables' ;
import * as RESOURCES from '@constants/resources';
import {
    getClassNameForDatesCells,
    renderClientPackagesGeoRestriction,
    renderContentActivationTicketNumber,
    renderEventContentVariants,
} from '@modules/client/utils/clientPackagesHelpers';

const authorizationObject = {
    [RESOURCES.CLIENT_PACKAGE]: CONST.SECURITY_PRIVILEGES_READ,
};

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        ...columnObjectAlignCenter,
    },
    'client': {
        label: 'Client',
        sorting: (row) => row.client_product.client.name.toLowerCase(),
        ...columnObjectAlignLeft,
        className: (row) => getDisabledClassNameForCell(row.client_product.client),
    },
    'product': {
        label: 'Product',
        sorting: (row) => row.client_product.product.short_name.toLowerCase(),
        ...columnObjectAlignLeft,
        className: (row) => getDisabledClassNameForCell(row.client_product),
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.toLowerCase(),
        ...columnObjectAlignLeft,
        className: (row) => getDisabledClassNameForCell(row),
    },
    'unique_sportradar_contract_number': {
        label: 'Unique Sportradar contract number',
        sorting: (row) => _get(row, 'unique_sportradar_contract_number',''),
        ...columnObjectAlignLeft,
    },
    'content_activation_ticket_number': {
        label: 'Content activation ticket number',
        sorting: (row) => _get(row, 'content_activation_ticket_number',''),
        ...columnObjectAlignLeft,
    },
    'sf_contract_number': {
        label: 'SF contract number',
        sorting: (row) => _get(row, 'sf_contract_number',''),
        ...columnObjectAlignLeft,
    },
    'type': {
        label: 'Type',
        sorting: (row) => row.client_package_type.name.toLowerCase(),
        ...columnObjectAlignLeft,
    },
    'booking_type': {
        label: 'Booking type',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'content_package_selection_type': {
        label: 'Content selection',
        sorting: (row) => row.content_package_selection_type.name.toLowerCase(),
        ...columnObjectAlignLeft,
    },
    'event_content_variants': {
        label: 'Content variants',
        sorting: (row) => _map(
            row['event_content_variants'], (eventContentVariant) => eventContentVariant.name
        ).join(', '),
    },
    'start_date': {
        label: 'Start date',
        sorting: true,
        ...columnObjectAlignCenter,
        className: (row) => getClassNameForDatesCells(row.start_date, row.end_date),
    },
    'end_date': {
        label: 'End date',
        sorting: true,
        ...columnObjectAlignCenter,
        className: (row) => getClassNameForDatesCells(row.start_date, row.end_date),
    },
    'geo_restrictions': {
        label: 'Geo restrictions',
        sorting: (row) => row.client_package_geo_restrictions,
        ...columnObjectAlignLeft,
    },
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'is_ll': {
        label: 'LL',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'is_test': {
        label: 'Test',
        sorting: true,
        ...columnObjectAlignCenter,
    },
};

const renderClientPackagesIdColumn = (column, row) => {
    return (
        <LinkWithAuthorization
            authorization={authorizationObject}
            link={{
                name: 'clientPackages.edit',
                params: {
                    id: row.client_product.client.id,
                    packagesId: row.id,
                },
            }}
        >
            {row[column]}
        </LinkWithAuthorization>
    );
};

const renderClientPackagesNameColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isEmpty(row[column]))
                    ? ''
                    :
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'clientPackages.edit',
                            params: {
                                id: row.client_product.client.id,
                                packagesId: row.id,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                }
            </div>
            <div>
                {(!_isEmpty(row.description)) && <p className='muted'>{row.description}</p>}
            </div>
        </div>
    );
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return renderClientPackagesIdColumn(column, row);
        case 'name':
            return renderClientPackagesNameColumn(column, row);
        case 'client':
            return row.client_product.client.name;
        case 'product':
            return row.client_product.product.short_name;
        case 'unique_sportradar_contract_number':
            return row.unique_sportradar_contract_number;
        case 'content_activation_ticket_number':
            return renderContentActivationTicketNumber(column, row);
        case 'sf_contract_number':
        case 'start_date':
        case 'end_date':
            return row[column];
        case 'type':
            return row.client_package_type.name;
        case 'booking_type':
            return row.booking_type.name;
        case 'content_package_selection_type':
            return row.content_package_selection_type.name;
        case 'event_content_variants': {
            return renderEventContentVariants(column, row);
        }
        case 'geo_restrictions':
            return renderClientPackagesGeoRestriction(row.client_package_geo_restrictions);
        case 'is_hq':
            return row.is_hq ? <IconCheckmark/> : '';
        case 'is_ll':
            return row.is_ll ? <IconCheckmark/> : '';
        case 'is_test':
            return row.is_test ? <IconCheckmark/> : '';
        default:
            return null;
    }
};

const ClientPackagesTable = ({data, loading}) => {
    return (
        <Table
            name='clientPackagesTable'
            columns={columns}
            data={data}
            loading={loading}
            limit={50}
            showLimitWarning={true}
            dataLimitWarning={1000}
            limitMessage='The number of results displayed is limited to 1000. Please use filters to narrow your search.'
            rowRenderer={rowRenderer}
            noDataText='No client packages found'
            defaultSort={cp => [cp.client_product.client.name, cp.client_product.product.short_name, cp.name]}
        />
    );
};

ClientPackagesTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

export default ClientPackagesTable;
