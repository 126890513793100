import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {includes as _includes} from 'lodash';

import {Renderer} from '@appComponents/TabsComponents/Renderer';
import navigation from '@constants/navigation';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import {showModal} from '@utils/modal';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import {GetLicensorForTabs} from '@graphql/licensor/query';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {isUrlParamValid} from '@utils/helpers';
import {ADD_CONTACT_PERSON_TYPE_LICENSOR, ADD_CONTACT_PERSON_TYPE_SPORTRADAR} from '@constants/variables';

import {createTabs, RenderBackToListButton, renderLicensorModalError} from '../utils/licensorTabsLayoutHelper';

export const LicensorTabsLayout = (props) => {
    const {
        match: {
            path,
            params: {
                id = null,
            },
        },
        Menu,
    } = props;

    const activePath = React.useMemo(() => (
        (_includes([
            routes.licensors.contactPerson.index.path,
            routes.licensors.contactPerson.add.path,
            routes.licensors.contactPerson.edit.path,
        ], path))
            ? routes.licensors.contactPerson.index.path
            : path
    ), [path]);

    const {data} = useQueryCustom({
        query: GetLicensorForTabs,
        options: {
            variables: {id: id},
            skip: (routes.licensors.add.path === activePath),
        },
    });

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderLicensorModalError(),
        });
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    React.useEffect(() => {
        if (data?.licensor?.name) {
            const licencorsName = data.licensor.name;

            if (!path.includes('add')) {
                document.title = !path.includes('contact-persons')
                    ? ('Licensor "' + licencorsName + '" - AV-CMP')
                    : ('Licensor "' + licencorsName + '" - Contact persons - AV-CMP');
            }

            if (path.includes('add') && path.includes('contact-persons')) {
                document.title = window.location.href.includes('contact_person_type_id=1')
                    ? ('Licensor "' + licencorsName + '" '+ ADD_CONTACT_PERSON_TYPE_LICENSOR)
                    : ('Licensor "' + licencorsName + '" '+ ADD_CONTACT_PERSON_TYPE_SPORTRADAR);
            }
        }

        return () => {
            document.title = 'AVCMP';
        };
    }, [data?.licensor?.name, path]);

    return (
        <Renderer
            active={activePath}
            buttons={RenderBackToListButton}
            items={createTabs(id, path, data?.licensor?.name)}
            messagesBoxNames={['contactPerson', 'licensor', 'licensorMessageError']}
            pageTitle={
                <TitleFromQuery
                    query={GetLicensorForTabs}
                    variables={{id}}
                    errorBackLink={routes.licensors.index.path}
                    path={'licensor.name'}
                    entity={'licensor'}
                />
            }
        />
    );
};

LicensorTabsLayout.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Menu: PropTypes.object.isRequired,
};
LicensorTabsLayout.displayName = 'LicensorTabsLayout';

export default connect(null, mapModulesToProps(['Menu']))(LicensorTabsLayout);
