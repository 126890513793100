import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';

import {
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_SRT, ORIGIN_STREAM_TYPE_UDP,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import PopupTable from '@appComponents/PopupTable';
import {replaceMatchIdPlaceholderInsideString} from '@utils/helpers';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {isWarningVisible} from '@modules/liveControl/utils/helpers';
import {UdpOriginEntryPointFailoverWarning} from '@modules/liveControl/views/LiveControlTable/UdpOriginEntryPointFailoverWarning';
import {UdpFailoverEntryPointPopup} from '@modules/liveControl/views/LiveControlTable/UdpFailoverEntryPointPopup';
import {SrtOriginEntryPointFailoverDetails} from '@modules/liveControl/views/LiveControlTable/SrtOriginEntryPointDetails';

import {getEntryPointNameAndWarningText} from './getEntryPointNameAndWarningText';
import {getWarningsForEntryPoint} from './getWarningsForEntryPoint';

export const renderEntryPointName = (row) => {
    let entryPointName = (!isWarningVisible(row) || ORIGIN_STREAM_TYPE_RTMP === row.origin_stream_type_id)
            && getEntryPointNameAndWarningText(row).name;

    const rows = [
        {
            header: 'Contribution type',
            body: row.contribution_type_name,
        },
        {
            header: 'Production company',
            body: (
                <LinkWithAuthorization
                    link={{
                        name: 'productionCompanies.edit',
                        params: {id: row.production_company_id},
                    }}
                    newWindow={true}
                    authorization={{
                        [RESOURCES.PRODUCTION_COMPANY]: SECURITY_PRIVILEGES_UPDATE,
                    }}
                >
                    {row.production_company_name}
                </LinkWithAuthorization>
            ),
        },
        {
            header: 'Contact person',
            body: (
                <LinkWithAuthorization
                    link={{
                        name: 'productionCompanies.contactPersons.edit',
                        params: {
                            id: row.production_company_id,
                            contactPersonId: row.contact_person_id,
                        },
                    }}
                    newWindow={true}
                    authorization={{
                        [RESOURCES.CONTACT_PERSON]: SECURITY_PRIVILEGES_UPDATE,
                    }}
                >
                    {row.contact_person_name}
                </LinkWithAuthorization>
            ),
        },
        {
            header: 'Origin stream name',
            body: row.rtmp_origin_stream_name ?
                replaceMatchIdPlaceholderInsideString(row.rtmp_origin_stream_name, row.match_id) : '-',
            hidden: ORIGIN_STREAM_TYPE_RTMP !== row.origin_stream_type_id,
        },
        {
            header: 'Failover origin entry point',
            body: row.failover_udp_entry_point_name || '-',
            hidden: ORIGIN_STREAM_TYPE_UDP !== row.origin_stream_type_id,
        },
        {
            header: 'HTTP origin entry point URL',
            body: row.http_origin_entry_point_url || '-',
            hidden: ORIGIN_STREAM_TYPE_HTTP !== row.origin_stream_type_id,
        },
        {
            header: 'SRT origin entry point URL',
            body: row.srt_origin_entry_point_url || '-',
            hidden: ORIGIN_STREAM_TYPE_SRT !== row.origin_stream_type_id,
        },
        {
            header: 'Failover SRT origin entry point URL',
            body: row.failover_srt_origin_entry_point_url || '-',
            hidden: ORIGIN_STREAM_TYPE_SRT !== row.origin_stream_type_id,
        },
    ];

    return (
        <>
            <Popup
                className='--noPadding'
                flowing
                hoverable
                trigger={
                    <span>
                        <span>{entryPointName}</span>
                        {getWarningsForEntryPoint(row)}
                        {
                            isWarningVisible(row)
                            && (
                                <div className='--warning'>
                                    <Icon name='warning sign' color='red'/>
                                    {getEntryPointNameAndWarningText(row).warningText}
                                </div>
                            )
                        }
                    </span>
                }
            >
                <PopupTable rows={rows}/>
            </Popup>
            <UdpOriginEntryPointFailoverWarning row={row} />
            <UdpFailoverEntryPointPopup row={row} />
            <SrtOriginEntryPointFailoverDetails row={row} />
        </>
    );
};
