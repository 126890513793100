import {find as _find, isEmpty as _isEmpty} from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Segment} from "semantic-ui-react";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import {getLink} from "../../app/components/Link";
import ThirdPartyCdnsIndexButtons from "../components/ThirdPartyCdnsIndexButtons";
import ThirdPartyCdnsTable from "../components/ThirdPartyCdnsTable";

import {createForm} from "../../../utils/forms";
import {convertToInt} from "../../../utils/helpers";
import {showModal} from "../../../utils/modal";
import useQueryCustom from "@utils/hooks/useQueryCustom";
import {loadModalForm, renderThirdPartyCdnsModalError} from "../utils/thirdPartyCdn";
import {exportExcel as thirdPartyCdnsIndexExportExcel} from "../utils/export/thirdPartyCdnsIndexExcel";

import ThirdPartyCdnsForm from "../forms/ThirdPartyCdnsForm";
import ThirdPartyCdnsModel from "../forms/ThirdPartyCdnsModel";

import {routes} from "../../app/constants/routes";
import {PRODUCT_THIRD_PARTY} from "../../app/constants/variables";

import {GetThirdPartyClientCdns} from "../../../graphql/thirdPartyClientCdn/query.graphql";

export function ThirdPartyCdnsIndex ({
    history,
    isVisible,
    match:  {
        path,
        params: {
            id,
            thirdPartyClientCdnId = null,
        },
    },
    prepareThirdPartyClientCdnsForStore,
    thirdPartyId,
}) {
    const clientId = convertToInt(id),
        {add, edit} = routes.clients.products.thirdParty.cdns,
        [modalVisibility, setModalVisibility] = useState(isVisible),
        [mutationSuccessCallback, setMutationSuccessCallback] = useState(null),
        thirdPartyClientCdnsIndexLink = getLink('clients.products.thirdParty.cdns.index', {id: clientId}),
        {loading = true, error = {}, data = {}} = useQueryCustom({
            query: GetThirdPartyClientCdns,
            options: {
                variables: {
                    notifyOnNetworkStatusChange: true,
                    clientProductThirdParty: thirdPartyId,
                },
                refetch: mutationSuccessCallback,
            },
        });

    if (!_isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderThirdPartyCdnsModalError(thirdPartyClientCdnsIndexLink),
        });
    }

    const {thirdPartyClientCdns} = data,
        loadModal = () => {
            const params = {
                clientId: clientId,
                thirdPartyId: thirdPartyId,
                optionsVariables: {
                    products: [PRODUCT_THIRD_PARTY]
                },
                setMutationSuccessCallback,
            };

            if (thirdPartyClientCdnId) {
                params.id = convertToInt(thirdPartyClientCdnId);
                params.clientId = clientId;
            }

            loadModalForm(
                createForm(
                    ThirdPartyCdnsModel,
                    ThirdPartyCdnsForm,
                    params
                ),
                setModalVisibility
            );
        };

    useEffect(() => {
        if ((add.path === path) || (edit.path === path)) {
            loadModal();
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            history.push(thirdPartyClientCdnsIndexLink);
        }
    }, [isVisible]);

    useEffect(() => {
        prepareThirdPartyClientCdnsForStore(thirdPartyClientCdns);
    }, [thirdPartyClientCdns]);

    return (
        <Segment basic loading={false} className="content__segment--margin-negative-15 content__segment--padding-top-0">
            <HeaderRenderer
                buttons={ThirdPartyCdnsIndexButtons}
                buttonsProps={{client: clientId}}
                exportExcelParams={thirdPartyCdnsIndexExportExcel(
                    thirdPartyClientCdns,
                    clientId
                )}
                loading={loading}
                messagesBoxNames={["formInnerErrorMessage"]}
            />
            <ThirdPartyCdnsTable
                clientId={clientId}
                loading={loading}
                thirdPartyClientCdns={thirdPartyClientCdns}
            />
        </Segment>
    );
}

ThirdPartyCdnsIndex.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            thirdPartyClientCdnId: PropTypes.string,
        }),
    }),
    isVisible: PropTypes.bool.isRequired,
    prepareThirdPartyClientCdnsForStore: PropTypes.func.isRequired,
    thirdPartyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    let thirdPartyClientProduct = null;

    if (state.app.entities.client.clientProducts) {
        thirdPartyClientProduct = _find(state.app.entities.client.clientProducts, clientProduct => (
            PRODUCT_THIRD_PARTY === convertToInt(clientProduct.product.id)
        ));
    }

    return {
        isVisible: state.modal.isVisible,
        thirdPartyId: thirdPartyClientProduct
            ? convertToInt(thirdPartyClientProduct.third_party_client_product.id)
            : null,
    }
};

export default withRouter(connect(mapStateToProps)(ThirdPartyCdnsIndex));
