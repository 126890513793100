import PropTypes from 'prop-types';
import React from 'react';

import Table from '@appComponents/Table';
import {routes} from '@constants/routes';
import {RowRenderer} from '@modules/events/components/Table/EventSchedule/RowRenderer';

import {Columns} from './Table/EventSchedule/Columns';

const EventScheduleTable = (props) => {
    const {eventSchedule, loading} = props;

    return (
        <div className='eventSchedule__table'>
            <Table
                url={routes.events.index.path}
                className='mini'
                columns={Columns}
                data={eventSchedule}
                getSelectedElements={props.getSelectedElements}
                loading={loading}
                multiple={true}
                name='eventScheduleList'
                noDataText='No event schedule found'
                padded={false}
                rowRenderer={RowRenderer}
                selectable={true}
                defaultSort={'start_datetime'}
                showLimitWarning={true}
                dataLimitWarning={10000}
                limitMessage='Not all of the results are displayed because the number of results was too high. Please use filters to narrow your search.'
            />
        </div>
    );
};

EventScheduleTable.propTypes = {
    eventSchedule: PropTypes.array,
    getSelectedElements: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

EventScheduleTable.defaultProps = {
    eventSchedule: [],
};

export default EventScheduleTable;
