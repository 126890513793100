import React from 'react';
import moment from 'moment-timezone';
import {isNil as _isNil} from 'lodash';

import {refetchQueryByName} from '@utils/apollo';
import {showModal} from '@utils/modal';
import ModifyEncodingStartDatetimeForm from '@modules/liveControl/forms/ModifyEncodingStartDatetimeForm';

import {EncodingModifyButton} from './utils';
import LiveControlEncodingTimePopup from '../LiveControlEncodingTimePopup';

/**
 *
 * @param row
 * @param timezone
 *
 * @returns {XML}
 */
export const renderEncodingStartTime = (row, timezone) => {
    let encodingStartDatetime = 'Manual';
    let displayEditButton = false;

    if (false === _isNil(row.encoding_start_datetime)) {
        const encStartTime = moment.tz(row.encoding_start_datetime, timezone);
        encodingStartDatetime = encStartTime.format('HH:mm');

        if (false === _isNil(row.encoding_start_margin)) {
            encodingStartDatetime += ' (-' + row.encoding_start_margin.toString() + ' min)';
        }

        if (moment.tz(timezone).isBefore(encStartTime)) {
            displayEditButton = true;
        }

    } else if (false === _isNil(row.event_content_start_datetime)) {
        const encStartTime = moment.tz(row.event_content_start_datetime, timezone);

        if (false === _isNil(row.encoding_start_margin)) {
            encodingStartDatetime = encStartTime.subtract(row.encoding_start_margin, 'minutes').format('HH:mm');
            encodingStartDatetime += ' (-' + row.encoding_start_margin.toString() + ' min)';
        }

        displayEditButton = true;
    } else {
        displayEditButton = true;
    }


    const showStartEncodingModal = data => showModal({
        isVisible: true,
        content: <ModifyEncodingStartDatetimeForm
            formData={row}
            id={data}
            timezone={timezone}
            successCallback={() => refetchQueryByName('LiveControl')}
        />,
    });

    const content = (
        <div>
            <span>{encodingStartDatetime}</span>
            {displayEditButton
                ? <EncodingModifyButton
                    dataId={row.id}
                    onClick={showStartEncodingModal}
                />
                : ''
            }
        </div>
    );

    return (row.encoding_first_start_datetime || row.encoding_start_datetime || row.encoding_start_margin)
        ? (
            <LiveControlEncodingTimePopup
                content={content}
                row={row}
                type='start'
            />
        )
        : content;
};
