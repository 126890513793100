import React from 'react';
import {
    get as _get,
    isNumber as _isNumber,
    isNil as _isNil,
} from 'lodash';

import {Checkbox} from '@appComponents/TreeTableComponents/Checkbox';
import {formattedPriceOrEmptyValue} from '@utils/helpers';
import {EMPTY_VALUE_DASH, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {getTerritoriesWithTooltip} from '@utils/countryHelpers';
import Authorization from '@appComponents/Authorization';
import {CLIENT_PACKAGE_CONTENT} from '@constants/resources';
import {IconSettings, IconSettingsAdd, IconGlobe, IconGlobeAdd} from '@appComponents/IconCollection';
import {openEditGlobeModal, openEditModal} from '@modules/client/utils/clientContent/contentIndexModalHelpers';

export const rowRender = (column, data, treeState, treeActions, level) => {

    let columnToRender = null;

    const emptyValue = 0 === level ? '' : EMPTY_VALUE_DASH;
    const eventPrice = data.clientPackageContentData.event_price || data.event_price;

    data.is_active = -1 < treeState.selected.indexOf(data.treeId);

    switch (column) {
        case 'event_price': {
            columnToRender = formattedPriceOrEmptyValue(eventPrice, emptyValue);

            if (_isNumber(data.clientPackageContentData.event_price)) {
                columnToRender = <strong>{columnToRender}</strong>;
            }

            break;
        }
        case 'num_bookable_events':
            columnToRender = data.clientPackageContentData.num_bookable_events ?? emptyValue;

            if (_isNumber(data.clientPackageContentData.num_bookable_events) && data.id) {
                columnToRender = <strong>{columnToRender}</strong>;
            }
            break;
        case 'booking':
            columnToRender = emptyValue;

            if (0 !== level && data.booking) {
                columnToRender = data.booking.name;
            }

            if (data.clientPackageContentData.booking_type && data.id) {
                columnToRender = <strong>{columnToRender}</strong>;
            }

            break;
        case 'territories':
            if(0 !== level) {
                const hasGeoRestrictions = null !== data.clientPackageContentData.territory_selection_type;

                let territoriesConfiguration = {
                    id: data.id,
                    countries: _get(data, 'countries', []),
                    country_subdivisions: _get(data, 'country_subdivisions', []),
                    territory_selection_type: _get(data, 'territory_selection_type', null),
                };

                columnToRender = getTerritoriesWithTooltip(territoriesConfiguration, data.mappedIsoCodesData);

                return hasGeoRestrictions ? <strong>{columnToRender}</strong> : columnToRender;
            }
            break;
        case 'event_booking_type':
            columnToRender = emptyValue;

            if (0 !== level && data.event_booking_type) {
                columnToRender = data.event_booking_type.name;
            }

            if (data.clientPackageContentData.event_booking_type && data.id) {
                columnToRender = <strong>{columnToRender}</strong>;
            }

            break;
        case 'event_content_type_configs':
            columnToRender = emptyValue;

            if (0 !== level && data.event_content_type_configs) {
                columnToRender = data.event_content_type_configs;
            }

            if (data.clientPackageContentData.event_content_type_configs?.length && data.id) {
                columnToRender = <strong>{columnToRender}</strong>;
            }

            break;
        case 'event_content_variants':
            columnToRender = emptyValue;

            if (0 !== level && data.event_content_variants) {
                columnToRender = data.event_content_variants;
            }

            if (data.clientPackageContentData.event_content_variants?.length && data.id) {
                columnToRender = <strong>{columnToRender}</strong>;
            }

            break;
        case 'has_hq':
            columnToRender = emptyValue;

            if (0 !== level && !_isNil(data.is_hq)) {
                if (data.is_hq) {
                    columnToRender = 'Yes';
                } else {
                    columnToRender = 'No';
                }
            }

            if (!_isNil(data.clientPackageContentData.is_hq) && data.id) {
                if (data.clientPackageContentData.is_hq) {
                    columnToRender = <strong>Yes</strong>;
                } else {
                    columnToRender = <strong>No</strong>;
                }
            }

            break;
        case 'has_ll':
            columnToRender = emptyValue;

            if (0 !== level && !_isNil(data.is_ll)) {
                if (data.is_ll) {
                    columnToRender = 'Yes';
                } else {
                    columnToRender = 'No';
                }
            }

            if (!_isNil(data.clientPackageContentData.is_ll) && data.id) {
                if (data.clientPackageContentData.is_ll) {
                    columnToRender = <strong>Yes</strong>;
                } else {
                    columnToRender = <strong>No</strong>;
                }
            }

            break;
        case 'active':
            columnToRender = (0 !== level)
                && <Checkbox
                    data={data} onClick={treeActions.checkChildren}
                    selected={-1 < treeState.selected.indexOf(data.treeId)}
                    indeterminate={-1 < treeState.indeterminate.indexOf(data.treeId)}/>;
            break;
        case 'actions': {

            const iconProps = {
                onClick: openEditModal,
                onClickElement: data,
                className: 'large',
            };

            const iconGlobProps = {
                onClick: openEditGlobeModal,
                onClickElement: data,
                className: 'large',
            };

            if ((1 <= level)) {
                columnToRender =
                    <Authorization resources={CLIENT_PACKAGE_CONTENT} privileges={SECURITY_PRIVILEGES_UPDATE}>
                        {
                            (_get(data, 'clientPackageContentData.booking_type.id', null)
                                || _get(data, 'clientPackageContentData.event_booking_type.id', null)
                                || data.clientPackageContentData.num_bookable_events
                                || data.clientPackageContentData.event_content_type_configs?.length
                                || data.clientPackageContentData.event_content_variants?.length
                                || _isNumber(data.clientPackageContentData.event_price)
                                || !_isNil(data.clientPackageContentData.is_hq)
                                || !_isNil(data.clientPackageContentData.is_ll)
                            )
                                ? <IconSettings {...iconProps}/>
                                : <IconSettingsAdd {...iconProps}/>
                        }
                        {
                            (data.clientPackageContentData.territory_selection_type?.id && data.clientPackageContentData?.countries)
                                ? <IconGlobe {...iconGlobProps} size='large' />
                                : <IconGlobeAdd {...iconGlobProps} size='large' />
                        }
                    </Authorization>;
            }
        }
    }

    if (null === columnToRender && data[column]) {
        return data[column];
    }

    return columnToRender;
};
