import {isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import useQueryCustom from '@utils/hooks/useQueryCustom';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {
    convertBooleanValueForFilters,
    getSearchFilters,
    reverseBooleanValueForFilters,
} from '@utils/filters';
import {GetLcrChannelsForTable} from '@graphql/lcr/query';
import Table from '@appComponents/Table';
import {showModal} from '@utils/modal';
import navigation from '@constants/navigation';
import {
    lcrChannelsColumns,
    renderLcrChannelsModalError,
    rowRenderer,
    prepareStringForExcelSorting,
} from '@modules/lcr/utils/lcrChannels';
import mapModulesToProps from '@utils/mapModulesToProps';
import LcrChannelFilters from '@modules/lcr/components/LcrChannelFilters';
import LcrChannelIndexButtons from '@modules/lcr/components/LcrChannelIndexButtons';
import {exportExcel} from '@modules/lcr/utils/export/lcrChannelIndexExcel';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {routes} from '@constants/routes';

const DEFAULT_FILTERS_VALUES = {
    search: null,
    has_lmt_support: null,
    countries: [],
    sports: [],
    status: 1,
};

export const getLcrChannelTableColumnsClassNames = (tableRowData) => (
    tableRowData.is_disabled ? '--is-disabled' : null
);

export function LcrChannelIndex(props) {
    const filters = props.filters,
        queryVariables = {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            has_lmt_support: convertBooleanValueForFilters(filters.has_lmt_support, DEFAULT_FILTERS_VALUES.has_lmt_support),
            countries: filters.countries ? filters.countries : DEFAULT_FILTERS_VALUES.countries,
            sports: filters.sports ? filters.sports.map(Number) : DEFAULT_FILTERS_VALUES.sports,
            is_disabled: reverseBooleanValueForFilters(filters.status),
        };
    props.Menu.storeMenu(navigation.lcrChannels.key);

    const {
        data: {
            lcrChannels,
        } = {},
        error: lcrChannelsFetchingErrorOccurred,
        loading: isLcrChannelsDataLoading = true,
    } = useQueryCustom({
        query: GetLcrChannelsForTable,
        options: {
            variables: {
                ...queryVariables,
            },
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        data: {
            mappedIsoCodes,
        } = {},
        error: mappedIsoCodesFetchingErrorOccurred,
        loading: isMappedIsoCodesDataLoading = true,
    } = useQueryCustom({
        query: GetMappedIsoCodes,
    });

    const isLoading = isLcrChannelsDataLoading || isMappedIsoCodesDataLoading;

    if (!isLoading && (!_isEmpty(lcrChannelsFetchingErrorOccurred) || !_isEmpty(mappedIsoCodesFetchingErrorOccurred))) {
        showModal({
            isVisible: true,
            content: renderLcrChannelsModalError(
                lcrChannelsFetchingErrorOccurred?.message
                || mappedIsoCodesFetchingErrorOccurred?.message),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(_sortBy(lcrChannels, [prepareStringForExcelSorting]))}
                filters={LcrChannelFilters}
                filtersProps={{loading: isLoading}}
                buttons={LcrChannelIndexButtons}
                filtersButtonName='LcrChannel'
                messagesBoxNames='LcrChannelIndex'
                pageTitle='LCR channels'
            />
            <div className='table-container'>
                <Table
                    columns={lcrChannelsColumns}
                    data={lcrChannels}
                    defaultSort={'name'}
                    loading={isLoading}
                    name='LcrChannelList'
                    noDataText='No LCR channel found'
                    rowRenderer={rowRenderer}
                    rowRendererProps={{mappedIsoCodes}}
                    rowClassname={getLcrChannelTableColumnsClassNames}
                    url={routes.lcrChannel.index.path}
                />
            </div>
        </div>
    );
}

LcrChannelIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    match: PropTypes.object,
    Menu: PropTypes.object,
};

const LcrChannelIndexWrapped = FilterQueryWrapper(LcrChannelIndex, {
    queryForRefresh: 'GetLcrChannelsForTable',
    filterUrls: ['lcrChannel.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'LcrChannel', DEFAULT_FILTERS_VALUES),
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(LcrChannelIndexWrapped, DEFAULT_FILTERS_VALUES, 'lcrChannel.index')
));
