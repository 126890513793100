import React from 'react';
import moment from 'moment/moment';
import {Popup} from 'semantic-ui-react';

import {getWarnings} from '@modules/liveControl/utils/getWarnings';
import {ShowWarnings} from '@utils/ShowWarnings';
import PopupTable from '@appComponents/PopupTable';

import {getMatchStartedOrEnded} from './utils';

export const renderTimePopup = (row, renderTimeComponents) => {
    const format = 'YYYY-MM-DD HH:mm:ss';

    let rows = [
        {
            header: 'Match status',
            body: row.match_status_name || '-',
        },
        {
            header: 'Original match start time',
            body: moment(row.match_start_datetime).format(format),
        },
        {
            header: 'About to start at',
            body: row.match_about_to_start_datetime ? moment(row.match_about_to_start_datetime).format(format) : '-',
        },
        {
            header: 'Started',
            body: getMatchStartedOrEnded(row.match_has_started),
        },
        {
            header: 'Started at',
            body: row.match_started_datetime ? moment(row.match_started_datetime).format(format) : '-',
            hidden: !row.match_has_started,
        },
        {
            header: 'Ended',
            body: getMatchStartedOrEnded(row.match_has_ended),
        },
        {
            header: 'Ended at',
            body: row.match_ended_datetime ? moment(row.match_ended_datetime).format(format) : '-',
            hidden: !row.match_has_ended,
        },
    ];

    return (
        <Popup
            className='--noPadding'
            flowing
            key={`popup_lct_${row.id}`}
            hoverable
            trigger={
                <div key={`popup_trigger_${row.id}`}>
                    {renderTimeComponents}
                    <div>
                        <ShowWarnings warnings={getWarnings(row)} />
                    </div>
                </div>
            }
        >
            <PopupTable rows={rows}/>
        </Popup>
    );
};
