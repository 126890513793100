import React from 'react';

import {EVENT_INCIDENT} from '@constants/resources';
import * as eventsIncidentGraphQlGraphQl from '@graphql/events/eventsIncident';
import Form from '@appComponents/ReduxFormControls';
import * as messages from '@constants/messages';

import validatorEventsIncident from './validatorEventsIncident';

export default function EventIncidentModel() {
    function renderScopeTree(data) {
        const CheckboxTree = Form.SemanticCheckboxTree;

        if (0 === data.options.length && !data.loading) {
            return <div className={data.meta.error && 'error'}>
                <label>Scope*</label>
                <div className='input-container text'>There is no scopes for selected event contents.</div>
            </div>;
        }

        return <CheckboxTree {...data}/>;
    }

    return {
        title: ()=> ('Add new incident'),
        formName: 'EventIncident',
        entityLabel: 'incident',
        entityDataMapKey: 'eventIncident',
        resources: EVENT_INCIDENT, // optional, by default form is open for all actions
        showChangeLog: true, // optional, true by default
        validate: validatorEventsIncident,
        label: 'Incident',
        dataMap: {
            event_incident_reason: null,
            affects_all_output_streams: true,
            lasted_entire_duration: false,
            start_datetime: null,
            end_datetime: null,
            count_as_downtime: false,
            comment: null,
            event_contents: null,
            event_incident_scopes: null,
        },
        messages: {
            boxName: {
                delete: 'event',
                success: 'event',
                error: 'event',
            },
            text: {
                ...messages,
                DELETE_HEADER_ARGUMENT_FIRST: () => messages.DELETE_HEADER('incident'),
            },

        },
        mutation: {
            updateEntity: eventsIncidentGraphQlGraphQl.updateEventIncidentMutation,
            createEntity: eventsIncidentGraphQlGraphQl.createEventIncidentMutation,
            deleteEntity: eventsIncidentGraphQlGraphQl.deleteEventIncidentMutation,
        },
        mutationOptions: {
            create: {
                refetchQueries: ['eventIncidents'],
            },
            update: {
                refetchQueries: ['eventIncidents'],
            },
            delete: {
                refetchQueries: ['eventIncidents'],
            },
        },
        query: {
            getEntity: eventsIncidentGraphQlGraphQl.getEventIncident,
            getOptions: eventsIncidentGraphQlGraphQl.optionsForEventIncident,
        },
        fields: {
            id: {
                dataMapKey: 'eventIncident.id',
                props: {
                    component: Form.IdField,
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            location: {
                dataMapKey: 'eventIncident.event_incident_reason.event_incident_causer.event_incident_location.id',
                optionsKey: 'eventIncidentLocations',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Location',
                    name: 'location',
                    required: true,
                    size: 'small',
                },
            },
            causer: {
                dataMapKey: 'eventIncident.event_incident_reason.event_incident_causer.id',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Causer',
                    name: 'causer',
                    required: true,
                    disabled: true,
                    size: 'small',
                },
            },
            reason: {
                dataMapKey: 'eventIncident.event_incident_reason.id',
                props: {
                    component: Form.SemanticSelect,
                    name: 'event_incident_reason',
                    label: 'Reason',
                    required: true,
                    disabled: true,
                    size: 'small',
                },
            },
            contents: {
                dataMapKey: null,
                optionsKey: 'eventContents',
                props: {
                    component: Form.SemanticSelect,
                    multiple: true,
                    label: 'Contents',
                    name: 'event_contents',
                    required: true,
                },
            },
            all_output_streams: {
                dataMapKey: 'eventIncident.affects_all_output_streams',
                props: {
                    component: Form.SemanticInput,
                    label: 'All output streams affected',
                    name: 'affects_all_output_streams',
                    type: 'checkbox',
                    defaultValue: true,
                },
            },
            scope: {
                dataMapKey: null,
                props: {
                    component: renderScopeTree,
                    label: 'Scope',
                    name: 'event_incident_scopes',
                    disabled: true,
                    hidden: true,
                    required: true,
                },
            },
            entire_duration: {
                dataMapKey: 'eventIncident.lasted_entire_duration',
                props: {
                    component: Form.SemanticInput,
                    label: 'Entire duration',
                    name: 'lasted_entire_duration',
                    type: 'checkbox',
                },
            },
            start_datetime: {
                dataMapKey: 'eventIncident.start_datetime',
                props: {
                    component: Form.SemanticInput,
                    name: 'start_datetime',
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm:ss',
                        timeIntervals: 5,
                    },
                    label: 'Start time',
                    required: true,
                    type: 'date',
                },
            },
            end_datetime: {
                dataMapKey: 'eventIncident.end_datetime',
                props: {
                    component: Form.SemanticInput,
                    name: 'end_datetime',
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm:ss',
                        timeIntervals: 5,
                    },
                    label: 'End time',
                    required: true,
                    type: 'date',
                },
            },
            count_as_downtime: {
                dataMapKey: 'eventIncident.count_as_downtime',
                props: {
                    component: Form.SemanticInput,
                    label: 'Count as downtime',
                    name: 'count_as_downtime',
                    type: 'checkbox',
                },
            },
            comment: {
                dataMapKey: 'eventIncident.comment',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Comment',
                    name: 'comment',
                    rows: 3,
                },
            },
        },
    };
}
