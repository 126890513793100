import {columnObjectAlignCenter} from '@utils/tableHelpers';
import {getBasicEventBookingsColumnsForBookingsTable} from '@modules/events/utils/bookingTableHelper';

export const columns = {
    ...getBasicEventBookingsColumnsForBookingsTable('offering'),
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'is_ll': {
        label: 'LL',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'price': {
        label: 'Price',
        sorting: true,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'booking_status': {
        label: 'Booking status',
        sorting: row => row.booking_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'time': {
        label: 'Time',
        sorting: row => row.creation_datetime,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};
