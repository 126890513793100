import {
    filter as _filter,
    get as _get,
    includes as _includes,
    isEmpty as _isEmpty,
    sortBy as _sortBy,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {convertToInt, defaultValuePrice} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategories} from '@utils/countryHelpers';
import {createForm, getFormMessageForWizard} from '@utils/forms';
import {refetchQueryByName} from '@utils/apollo';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import {PreFillGeneralInformation} from '@graphql/eventBooking/query';
import {ClientPackageList} from '@graphql/clientPackage/query';
import {GetEventBookingsForClient} from '@graphql/booking/query';
import {getEventContentVariantSteppedText} from '@utils/eventContentVariant/eventContentVariant';
import {showErrorModal} from '@utils/modal';

import EventBookingGeoRestrictionsModel from './EventBookingGeoRestrictionsModel';
import {EventBookingGeoRestrictionsForm} from './EventBookingGeoRestrictionsForm';
import {getEventBookingDataFromAllSteps} from '../../utils/eventsBookingGeoRestriction';

export class EventBookingGeoRestrictionsGeneralInformationForm extends DefaultForm {
    static  propTypes = {
        preFillGeneralInformation: PropTypes.array,
    };

    prepareDataForSubmit = () => {
        return getEventBookingDataFromAllSteps(this.props.stepsMethods, this.props.formParams.id);
    };

    onFormSubmit = (data) => {
        if (!this.props.Model.editForm) {
            this.setState(() => ({
                defaultForm_errorShowed: false,
            }));

            const Form = createForm(
                EventBookingGeoRestrictionsModel,
                EventBookingGeoRestrictionsForm,
                {
                    id: data.id,
                    optionsVariables: {
                        event: this.props.formParams.optionsVariables.event,
                        distribution: convertToInt(this.props.formValues.distribution),
                    },
                });

            this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
            this.props.stepsMethods.setStep({
                id: 'step2',
                menuItem: {disabled: false},
                pane: {content: <Form previousStepData={data}/>},
            });
            this.props.stepsMethods.showStep('step2');
        } else {
            if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
                this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                    _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                        .toLocaleLowerCase()
                ));
            } else {
                return super.onFormSubmit(Object.assign({}, data, {
                    booking_geo_restrictions: getGeoRestrictionsForDeviceCategories(
                        this.props.stepsMethods.getForms()['step2'].formValues
                    ),
                }));
            }
        }
    };

    setFormMessage = (message) => {
        super.setFormMessage(getFormMessageForWizard(message, this.props.Model));
    };

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);

        if(prevProps !== this.props) {

            const {
                GraphQLOptionsData: {
                    eventContents,
                },
            } = this.props;

            if (eventContents && !this.state.distributions) {
                this.setDistributions(eventContents);
            }

            if (null !== this.props.formParams.id && this.props.GraphQLEntityData) {
                const bookingId = _get(this.props, 'GraphQLEntityData.booking.id', null),
                    clientId = _get(this.props, 'GraphQLEntityData.booking.client.id', null),
                    distribution = _get(this.props, 'GraphQLEntityData.booking.distribution', null);

                if (clientId && distribution && !this.state.otherSettingsGenerated) {
                    this.getClientPackages(clientId, distribution.product.id);
                    this.setState(() => ({otherSettingsGenerated: true}));
                }

                if (bookingId && !this.state.dataFilled) {
                    if (_get(distribution, 'product.is_invoiceable', false)) {
                        this.setField('invoice_status', {
                            hidden: false,
                            disabled: false,
                        });
                    }

                    if (_get(distribution, 'product.has_hq_support', false)) {
                        this.setField('is_hq', {
                            hidden: false,
                        });
                    } else {
                        this.setField('is_hq', {
                            defaultValue: false,
                        });
                    }

                    if (_get(distribution, 'product.has_ll_support', false)) {
                        this.setField('is_ll', {
                            hidden: false,
                        });
                    } else {
                        this.setField('is_ll', {
                            defaultValue: false,
                        });
                    }

                    const autoUpdatePrice = _get(this.props, 'GraphQLEntityData.booking.auto_update_price', true);

                    this.setField('auto_update_price', {
                        defaultValue: autoUpdatePrice,
                    });

                    this.setField('price', {
                        defaultValue: defaultValuePrice(this.props.GraphQLEntityData.booking.price),
                    });

                    this.setState(() => ({dataFilled: true}));
                }
            } else {
                if(prevProps.formValues.client_package !== this.props.formValues.client_package) {
                    this.setField('price', {
                        defaultValue: '',
                    });

                    this.setField(['is_hq', 'is_ll'], {
                        defaultValue: false,
                    });
                }
                this.setField('auto_update_geo_restrictions', {
                    defaultValue: true,
                });

                this.setField('client', {
                    disabled: false,
                });
            }

            this.props.stepsMethods.setForm('step1', this.props);
        }
    }

    componentDidMount() {
        if(!this.props.GraphQLEntityData) {
            this.setField('client_package', {
                defaultValue: null,
            });
        }
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetEventBookingsForTable');
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetEventBookingsForTable');
        });

        this.setOnChangeCallback({
            client: (data) => {
                this.setField(['client_package', 'distribution'], {
                    defaultValue: null,
                    disabled: true,
                });

                this.getClientBookings(data.value);
            },
            distribution: (data) => {
                if (data.value) {
                    this.setField('client_package', {
                        defaultValue: null,
                        disabled: true,
                    });

                    if (this.props.formValues.client && this.state.distributionProduct) {
                        const selectedProduct = this.state.distributionProduct[data.value];

                        this.getClientPackages(this.props.formValues.client, selectedProduct.id);
                        this.setField('client_package', {
                            disabled: true,
                            allowClear: true,
                        });

                        if (selectedProduct.has_hq_support) {
                            this.setField('is_hq', {
                                hidden: false,
                            });
                        } else {
                            this.setField('is_hq', {
                                hidden: true,
                                defaultValue: null,
                            });
                        }

                        if (selectedProduct.has_ll_support) {
                            this.setField('is_ll', {
                                hidden: false,
                            });
                        } else {
                            this.setField('is_ll', {
                                hidden: true,
                                defaultValue: null,
                            });
                        }

                        let isInvoiceStatusHidden = true;
                        const invoiceStatusValue = {};

                        if (selectedProduct.is_invoiceable) {
                            isInvoiceStatusHidden = false;
                        } else {
                            invoiceStatusValue.defaultValue = null;
                        }

                        this.setField('invoice_status', {
                            hidden: isInvoiceStatusHidden,
                            disabled: isInvoiceStatusHidden,
                            ...invoiceStatusValue,
                        });
                    }
                }
            },
            client_package: (data) => {
                if (data.value) {
                    this.resetFields(['price']);
                    this.setPreFilledGeneralInformation(data.value, this.props.formValues.distribution);
                }
            },
        });
    }

    resetFields = (fields) => {
        fields.forEach((field) => {
            this.setField(field, {defaultValue: null});
        });
    };

    getClientBookings = (clientId) => {
        this.setField('distribution', {
            loading: true,
        });

        return this.props.client.query({
            fetchPolicy: 'network-only',
            query: GetEventBookingsForClient,
            variables: {
                event: this.props.formParams.optionsVariables.event,
                clients: [convertToInt(clientId)],
                contents: [],
                products: [],
                distributions: [],
                devices: [],
                type: null,
            },
        }).then((response) => {
            let distributionOptions = this.state.distributions;

            if (response.data.bookings.length) {
                const booked = response.data.bookings.map(el => el.distribution.id);

                distributionOptions = _filter(distributionOptions, (option) => {
                    return false === _includes(booked, option.id);
                });
            }

            this.setField('distribution', {
                options: distributionOptions,
                disabled: false,
                loading: false,
            });
        }).catch((response) => {
            this.props.MessageBox.addMessage(
                'formInnerErrorMessage',
                "Can't get client bookings to exclude booked distributions.",
                response.message,
                'error'
            );
        });
    };

    getClientPackages = (clientId, productId) => {
        this.setField('client_package', {
            disabled: true,
            loading: true,
        });

        return this.props.client.query({
            fetchPolicy: 'network-only',
            query: ClientPackageList,
            variables: {
                client: [convertToInt(clientId)],
                product: [convertToInt(productId)],
            },
        }).then((response) => {
            this.setField('client_package', {
                options: response.data.clientPackages,
                loading: false,
                disabled: false,
            });
        }).catch((response) => {
            this.props.MessageBox
                .addMessage('formInnerErrorMessage',
                    "Can't get client packages.",
                    response.message, 'error');
        });
    };

    setPreFilledGeneralInformation = (clientPackageId, distributionId) => {
        return this.props.client.query({
            fetchPolicy: 'network-only',
            query: PreFillGeneralInformation,
            variables: {
                clientPackage: convertToInt(clientPackageId),
                distribution: convertToInt(distributionId),
            },
        }).then((response) => {
            if (!response.data) {
                return;
            }

            const preFillGeneralInformation = response.data?.activePackageContent;

            this.setField('is_hq', {
                defaultValue: preFillGeneralInformation.is_hq ? preFillGeneralInformation.is_hq : null,
            });

            this.setField('is_ll', {
                defaultValue: preFillGeneralInformation.is_ll ? preFillGeneralInformation.is_ll : null,
            });

            this.setField('price', {
                defaultValue: preFillGeneralInformation ? defaultValuePrice(preFillGeneralInformation.event_price) : null,
            });
        }).catch(() => {
            showErrorModal({
                header: 'Error',
                text: 'Unable to fetch prefilled data for general information tab.',
                errorButtonText: 'Close',
            });
        });
    };

    setDistributions = (eventContents) => {
        let distributions = [];
        const distributionProduct = {},
            distributionDeviceCategories = {};

        for (const eventContent of eventContents) {

            for (const distribution of eventContent.distributions) {

                distributions.push({
                    id: distribution.id,
                    text: getEventContentVariantSteppedText(eventContent, distribution),
                    sort_event_content_type_name: eventContent.event_content_type.name,
                    sort_event_content_name: eventContent.name,
                    sort_product_id: distribution.product.id,
                    sort_distribution_type_id: distribution.distribution_type.id,
                    value: distribution.id,
                });

                distributionProduct[distribution.id] = {
                    id: distribution.product.id,
                    has_hq_support: distribution.product.has_hq_support,
                    has_ll_support: distribution.product.has_ll_support,
                    is_invoiceable: distribution.product.is_invoiceable,
                };
                distributionDeviceCategories[distribution.id] = distribution.product.device_categories;
            }
        }

        distributions = _sortBy(distributions, [
            'sort_event_content_type_name',
            'sort_event_content_name',
            'sort_product_id',
            'sort_distribution_type_id',
        ]);

        this.setState(() => ({
            distributions,
            distributionProduct,
            distributionDeviceCategories,
        }));

        this.setField('distribution', {
            options: distributions,
        });
    };

    renderErrors(errorData, childLabel = null, childLink = null, childModalProps = {}) {
        const label = childLabel || 'Booking',
            link = childLink || getLink('events.bookings.index', {
                id: this.props.formParams.optionsVariables.event || null,
            }),
            modalProps = childModalProps || {size: 'tiny'};

        super.renderErrors(errorData, label, link, modalProps);
    }

    renderSaveButton = (props) => {
        if (!this.props.Model.editForm) {
            return super.renderSaveButton({
                ...props,
                content: 'Next',
                icon: 'angle double right',
                labelPosition: 'right',
            });
        }

        return super.renderSaveButton();
    };
}

export default EventBookingGeoRestrictionsGeneralInformationForm;
