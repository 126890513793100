import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Form as SemanticForm} from 'semantic-ui-react';

import { ReduxComponent } from './ReduxComponent';

/**
 * Render textarea for Redux
 */
export class SemanticTextArea extends ReduxComponent {
    /**
     *  @property {Number} [rows=3] Number of rows of textarea
     */
    static propTypes = {
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        input: PropTypes.object,
        meta: PropTypes.object,
        rows: PropTypes.number,
    };

    /**
     * @ignore
     */
    constructor(props) {
        super(props);
        this.options = {'rows': props.rows ? props.rows : 3};
    }

    /**
     * @ignore
     */
    componentDidMount() {
        this.props.input.onChange(this.props.defaultValue);
    }

    componentDidUpdate(prevProps) {
        const { defaultValue, input, meta } = this.props;

        if (defaultValue !== prevProps.defaultValue) {
            input.onChange(defaultValue);
        }

        if ((0 === input.value.length || input.value !== defaultValue) && !meta.visited) {
            input.onChange(defaultValue);
        }
    }

    /**
     * @ignore
     */
    render() {
        return (
            <div className={classnames({
                'hidden': this.props.hidden,
                'error': this.isError(this.props),
            })}>
                {this.renderLabel(this.props)}
                <div className='input-container'>
                    <SemanticForm.TextArea
                        {...this.props.input}
                        disabled={this.props.meta.submitting || this.props.disabled}
                        rows={this.options.rows}
                        error={this.isError(this.props)}
                        className={'--' + this.props.input.name}
                    />
                    <div className='error info'>{this.isError(this.props) ? this.props.meta.error : ''}</div>
                </div>
            </div>
        );
    }
}

export default SemanticTextArea;
