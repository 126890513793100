import React from 'react';
import {Popup} from 'semantic-ui-react';
import {isNil as _isNil} from 'lodash';

import {IconCheckmark} from '@appComponents/IconCollection';
import {
    renderAction,
    renderCategoryOrTournament,
    renderColumnWithList,
    renderCompetitorsColumn,
    rowContainsColumn,
    renderContentColumns,
    renderCoverageValue,
    renderEventIdColumn,
    renderMatchIdColumn,
    renderProductsAndDistribution,
    renderStartDatetimeColumn,
    renderStatusColumn,
    renderVenueOrCourtAndTvChannelColumn,
} from '@modules/events/utils/eventSchedule';

export const RowRenderer = (column, row) => {
    switch (column) {
        case 'event_id':
            return renderEventIdColumn(column, row);
        case 'match_id':
            return renderMatchIdColumn(column, row);
        case 'start_datetime':
            return renderStartDatetimeColumn(column, row);
        case 'category_or_content_category_level_2':
        case 'tournament_or_content_category_level_3':
            return renderCategoryOrTournament(column, row);
        case 'sport_or_content_category_level_1':
            return (
                <div>
                    {_isNil(row[column])
                        ? ''
                        : row[column]
                    }
                </div>
            );
        case 'venue_or_court':
            return renderVenueOrCourtAndTvChannelColumn(column, row);
        case 'notes':
            return (
                <div>
                    {rowContainsColumn(column, row) ? row[column] : ''}
                </div>
            );
        case 'competitors':
            return renderCompetitorsColumn(column, row);
        case 'status':
            return renderStatusColumn(column, row);
        case 'coverage':
            return (
                row.clip_provider
                    ? (<Popup
                        trigger={<div>{renderCoverageValue(column, row)}</div>}
                        flowing
                        content={'Clip provider: ' + row.clip_provider}
                        hoverable
                    />)
                    : renderCoverageValue(column, row)
            );
        case 'contents':
            return renderContentColumns(column, row);
        case 'contribution_types':
            return renderContentColumns(column, row);
        case 'encoding_datacenters':
            return renderContentColumns(column, row);
        case 'products':
            return renderProductsAndDistribution(column, row);
        case 'distribution_types':
            return renderProductsAndDistribution(column, row);
        case 'product_statuses':
            return renderColumnWithList(column, row, '', 'name');
        case 'targets':
            return renderColumnWithList(column, row);
        case 'bookings':
            return renderColumnWithList(column, row);
        case 'has_event_report':
            return row[column] ? <IconCheckmark/> : null;
        case 'actions' :
            return (
                <div className='actions-list-icon'>{renderAction(row)}</div>
            );
        default:
            return null;
    }
};
