import {
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_MEDIACONNECT, ORIGIN_STREAM_TYPE_RTMP, ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_SRT, ORIGIN_STREAM_TYPE_UDP,
} from '@constants/variables';

import {getSrtOriginEntryPointName} from './utils';

export const getEntryPointNameAndWarningText = (row) => {
    let warningText = '';
    let name = '';

    switch (row.origin_stream_type_id) {
        case ORIGIN_STREAM_TYPE_RTMP: {
            name = `${row.origin_stream_type_name} - ${row.entry_point_application_name}`;
            warningText = 'RTMP origin stream name not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_UDP: {
            name = `${row.origin_stream_type_name} - ${row.udp_entry_point_name}`;
            warningText = 'UDP origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_SDI: {
            name = `${row.origin_stream_type_name} - ${row.sdi_entry_point_name}`;
            warningText = 'SDI origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_HTTP: {
            name = row.origin_stream_type_name;
            warningText = 'HTTP origin entry point URL not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_MEDIACONNECT: {
            name = `${row.origin_stream_type_name} - ${row.mediaconnect_entry_point_name}`;
            warningText = 'MediaConnect origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_SRT: {
            name = getSrtOriginEntryPointName(row);
            warningText = 'SRT origin entry point not defined!';
        }
    }

    return {
        warningText,
        name,
    };
};
