import {
    camelCase as _camelCase,
    has as _has,
    isUndefined as _isUndefined,
    omit as _omit,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {gql, withApollo} from 'react-apollo';
import {isAuthorized} from '@appComponents/Authorization';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import MessageBox from '@appComponents/MessageBox';
import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';
import Tabs from '@appComponents/Tabs';
import mapModulesToProps from '@utils/mapModulesToProps';
import {NOT_AUTHORIZED} from '@constants/messages';
import {CLIENT_PRODUCT} from '@constants/resources';
import {routes} from '@constants/routes';
import {
    EXPORT_EXCEL_URL_FRAGMENT,
    PRODUCT_LCI,
    PRODUCT_LCO,
    PRODUCT_LCPP,
    PRODUCT_LCR,
    PRODUCT_LCT,
    PRODUCT_SPOTT,
    PRODUCT_THIRD_PARTY,
    PRODUCT_QB,
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import responseStatus from '@constants/responseStatuses';
import * as clientGraphQl from '@graphql/clients/index';
import ClientEventBookings from '@modules/client/views/ClientEventBookings';
import {showModal} from '@utils/modal';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import WhitelistedIpTabsLayout from '@modules/client/layouts/WhitelistedIpTabsLayout';
import ClientVideoPlayerTab from '@modules/client/views/ClientVideoPlayerTab';

import ClientTabsButtons from '../components/ClientTabsButtons';
import ClientFormAdd from '../forms/ClientFormAdd';
import ClientFormEdit from '../forms/ClientFormEdit';
import ClientProductLciForm from '../forms/ClientProductLciForm';
import ClientProductLciModel from '../forms/ClientProductLciModel';
import ClientProductLcoForm from '../forms/ClientProductLcoForm';
import ClientProductLcoModel from '../forms/ClientProductLcoModel';
import ClientProductLcppForm from '../forms/ClientProductLcppForm';
import ClientProductLcppModel from '../forms/ClientProductLcppModel';
import ClientProductLcrModel from '../forms/ClientProductLcrModel';
import ClientProductLctForm from '../forms/ClientProductLctForm';
import ClientProductLctModel from '../forms/ClientProductLctModel';
import ClientProductLcrForm from '../forms/ClientProductLcrForm';
import ClientProductSpottForm from '../forms/ClientProductSpottForm';
import ClientProductSpottModel from '../forms/ClientProductSpottModel';
import ClientProductQbForm from '../forms/ClientProductQbForm';
import ClientProductQbModel from '../forms/ClientProductQbModel';
import ClientAnalyticsSetupTabLayout from '../layouts/ClientAnalyticsSetupTabsLayout';
import ClientProductThirdPartyLayout from '../layouts/ClientProductThirdPartyLayout';
import {
    checkRouteIsAllowed,
    getClientProductDetails,
    hasRequestedProduct,
} from '../utils/clientTabsLayoutHelper';
import ApiKeyIndex from '../views/ApiKeyIndex';
import CdnConfigurationsTab from '../views/CdnConfigurationsTab';
import ClientBookings from '../views/ClientBookings';
import ClientDrmSetupIndex from '../views/ClientDrmSetupIndex';
import ClientOfferings from '../views/ClientOfferings';
import ContactPersonsIndex from '../views/ContactPersonsIndex';
import PackagesTab from '../views/PackagesTab';
import ProductAdd from '../views/ProductAdd';
import ProductTab from '../views/ProductTab';

class ClientTabsLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientData: PropTypes.object,
        clientProductLci: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLcr: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLco: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLcpp: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLct: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductSpott: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductQb: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductThirdParty: PropTypes.object,
        DataClientForStore: PropTypes.object,
        Entity: PropTypes.object,
        hasLciProduct: PropTypes.bool,
        hasLcrProduct: PropTypes.bool,
        hasLcoProduct: PropTypes.bool,
        hasLcppProduct: PropTypes.bool,
        hasLctProduct: PropTypes.bool,
        hasSpottProduct: PropTypes.bool,
        hasThirdPartyProduct: PropTypes.bool,
        hasQuickBetsProduct: PropTypes.bool,
        history: PropTypes.object,
        lciProductIsDisabled: PropTypes.bool,
        lciProductIsTrial: PropTypes.bool,
        lcrProductIsDisabled: PropTypes.bool,
        lcrProductIsTrial: PropTypes.bool,
        lcoProductIsDisabled: PropTypes.bool,
        lcoProductIsTrial: PropTypes.bool,
        lcppProductIsDisabled: PropTypes.bool,
        lcppProductIsTrial: PropTypes.bool,
        lctProductIsDisabled: PropTypes.bool,
        lctProductIsTrial: PropTypes.bool,
        spottProductIsDisabled: PropTypes.bool,
        spottProductIsTrial: PropTypes.bool,
        thirdPartyProductIsDisabled: PropTypes.bool,
        thirdPartyProductIsTrial: PropTypes.bool,
        qbProductIsDisabled: PropTypes.bool,
        qbProductIsTrial: PropTypes.bool,
        match: PropTypes.object.isRequired,
        modal: PropTypes.object,
        Modal: PropTypes.object,
        params: PropTypes.object,
        userPrivileges: PropTypes.object,
        has_event_booking_support: PropTypes.bool,
    };

    static defaultProps = {
        clientProductLci: {
            id: null,
        },
        clientProductLcr: {
            id: null,
        },
        clientProductLco: {
            id: null,
        },
        clientProductLcpp: {
            id: null,
        },
        clientProductLct: {
            id: null,
        },
        clientProductSpott: {
            id: null,
        },
        clientProductQb: {
            id: null,
        },
        hasLciProduct: false,
        hasLcrProduct: false,
        hasLcoProduct: false,
        hasLcppProduct: false,
        hasLctProduct: false,
        hasSpottProduct: false,
        hasThirdPartyProduct: false,
        hasQuickBetsProduct: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.checkProductAvailability(),
            loading: false,
            renderTabs: false,
        };
    }

    checkModalVisibility(prevProps) {
        if (this.props.modal.isVisible !== prevProps.modal.isVisible && this.props.modal.isVisible) {
            return;
        }
    }

    checkClientDataChange(prevProps) {
        if (this.props.clientData !== prevProps.clientData) {
            this.setState(() => ({
                ...this.checkProductAvailability(this.props),
            }));
        }
    }

    componentDidMount() {
        this.setState(() => this.getClientTabsState());

        this.getClientFromGraphQL();
    }

    componentDidUpdate(prevProps, prevState) {
        this.checkModalVisibility(prevProps);
        this.checkClientDataChange(prevProps);

        if (this.state.clientName !== prevState.clientName
            || (this.props.match.path !== prevProps.match.path && '' !== this.state.clientName)) {
            const tabStates = this.getClientTabsState();

            if (!checkRouteIsAllowed(tabStates) && null !== checkRouteIsAllowed(tabStates)) {
                const tabStatesContent = (
                    tabStates.active === routes.clients.products.thirdParty.edit.path ||
                    tabStates.active === routes.clients.products.lci.edit.path ||
                    tabStates.active === routes.clients.products.lco.edit.path ||
                    tabStates.active === routes.clients.products.lcpp.edit.path ||
                    tabStates.active === routes.clients.products.lcr.edit.path ||
                    tabStates.active === routes.clients.products.lct.edit.path ||
                    tabStates.active === routes.clients.products.spott.edit.path ||
                    tabStates.active === routes.clients.products.qb.edit.path
                )
                    ? 'Product not found!'
                    : "Can't add another product!";

                const returnLink = getLink('clients.edit', {id: this.props.clientData.id});
                this.props.Modal.setModal({
                    content: renderModalError('Product', returnLink, tabStatesContent),
                    isVisible: true,
                });
            }

            this.setState(() => ({
                ...tabStates,
            }));

            if (!this.state.clientDataRequestSent && _isUndefined(this.props.clientData)) {
                this.getClientFromGraphQL();
            }
        }
    }

    componentWillUnmount() {
        this.props.Entity.removeEntity('client');
    }

    checkProductAvailability = (props) => {
        if (_isUndefined(props)) {
            props = this.props;
        }

        return getClientProductDetails(props.clientData);
    };

    getClientFromGraphQL = () => {
        if (_isUndefined(this.props.match.params.id)) {
            return;
        }

        this.setState(() => ({
            loading: true,
            clientDataRequestSent: true,
        }));

        if (!isUrlParamValid(this.props.match.params.id)) {
            this.setState(() => ({loading: false}));
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }

        this.props.client.query({
            fetchPolicy: 'network-only',
            query: ClientForStore,
            variables: {
                id: this.props.match.params.id,
            },
        }).then((data) => {
            if (data) {
                this.props.Entity.setEntity(prepareClientForStore(data.data.client));

                if (!hasRequestedProduct(this.props.match.path, this.props.clientData)) {
                    const returnLink = getLink('clients.edit', {id: this.props.clientData.id });

                    this.props.Modal.setModal({
                        isVisible: true,
                        content: renderModalError('Product', returnLink, 'Client product was not found'),
                    });
                }
            }

            this.setState(() => ({
                loading: false,
                renderTabs: true,
            }));
        }).catch((error) => {
            this.setState(() => ({loading: false}));

            if (error?.networkError?.statusCode !== responseStatus.HTTP_UNAUTHORIZED) {
                showModal({
                    isVisible: true,
                    content: renderModalError('Client', routes.clients.index.path),
                });
            }
        });
    };

    getClientTabsState = () => {
        const clientId = convertToInt(this.props.match.params.id),
            clientData = this.props.clientData,
            contactPersonsRoutes = routes.clients.contactPersons,
            productsRoutes = routes.clients.products,
            productsThirdPartyRoutes = routes.clients.products.thirdParty,
            packagesRoutes = routes.clients.packages,
            whitelistedIpsRoutes = routes.clients.whitelistedIps,
            clientAnalyticsSetupRoutes = routes.clients.clientAnalyticsSetup,
            clientAnalyticsSetupLabelsRoutes = routes.clients.clientAnalyticsSetup.labels,
            eventBookingsRoutes = routes.clients.eventBookings,
            eventBookingsExcelExportRoute = `${eventBookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            bookingsRoutes = routes.clients.bookings,
            bookingsExcelExportRoute = `${bookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            offeringsRoutes = routes.clients.offerings,
            offeringsExcelExportRoute = `${offeringsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            clientCdnConfigurationRoutes = routes.clients.clientCdnConfiguration,
            clientApiKeyRoutes = routes.clients.apiKey,
            clientVideoPlayer = routes.clients.clientVideoPlayer,
            {userPrivileges} = this.props;

        let active = this.props.match.path,
            shouldOpenModal = false,
            tabs;

        if (
            active === clientAnalyticsSetupLabelsRoutes.index.path
            || active === `${clientAnalyticsSetupLabelsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === clientAnalyticsSetupLabelsRoutes.add.path
            || active === clientAnalyticsSetupLabelsRoutes.edit.path
        ) {
            active = clientAnalyticsSetupRoutes.index.path;
        }

        if (active === whitelistedIpsRoutes.add.path
            || active === whitelistedIpsRoutes.edit.path
            || active === whitelistedIpsRoutes.avApiAccess.index.path
            || active === whitelistedIpsRoutes.avApiAccess.add.path
            || active === whitelistedIpsRoutes.avApiAccess.edit.path
            || active === `${whitelistedIpsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = whitelistedIpsRoutes.index.path;
        }

        if (active === contactPersonsRoutes.add.path || active === contactPersonsRoutes.edit.path) {
            active = contactPersonsRoutes.index.path;
        }

        if (active === eventBookingsRoutes.blackoutZones.path
            || active === eventBookingsRoutes.editGeoRestrictions.path
            || active === eventBookingsRoutes.clips.path
            || active === eventBookingsRoutes.edit.path
            || active === eventBookingsExcelExportRoute) {
            active = eventBookingsRoutes.index.path;
        }

        if (active === bookingsRoutes.edit.path
            || active === bookingsRoutes.editGeoRestrictions.path
            || active === bookingsRoutes.bulkChange.path
            || active === bookingsRoutes.bulkCancel.path
            || active === bookingsRoutes.blackoutZones.path
            || active === bookingsExcelExportRoute) {
            active = bookingsRoutes.index.path;
        }

        if (active === offeringsExcelExportRoute) {
            active = offeringsRoutes.index.path;
        }

        if (active === `${packagesRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === packagesRoutes.add.path
            || active === packagesRoutes.edit.path
            || active === packagesRoutes.duplicate.path
            || active === packagesRoutes.geoRestrictions.index.path
            || active === packagesRoutes.geoRestrictions.add.path
            || active === packagesRoutes.geoRestrictions.edit.path
            || active === packagesRoutes.geoRestrictions.editGeoRestrictions.path
            || active === packagesRoutes.content.index.path
            || active === packagesRoutes.content.geoRestrictions.path
        ) {
            active = packagesRoutes.index.path;
        }

        if (active === packagesRoutes.duplicate.path) {
            shouldOpenModal = true;
        }

        if (active === productsThirdPartyRoutes.cdns.index.path
            || active === `${productsThirdPartyRoutes.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.cdns.add.path
            || active === productsThirdPartyRoutes.cdns.edit.path
            || active === productsThirdPartyRoutes.pullOrigins.index.path
            || active === `${productsThirdPartyRoutes.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.pullOrigins.add.path
            || active === productsThirdPartyRoutes.pullOrigins.edit.path
            || active === productsThirdPartyRoutes.streamNamesPush.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPush.add.path
            || active === productsThirdPartyRoutes.streamNamesPush.edit.path
            || active === productsThirdPartyRoutes.streamNamesPull.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPull.add.path
            || active === productsThirdPartyRoutes.streamNamesPull.edit.path
            || active === productsThirdPartyRoutes.vodStorages.index.path
            || active === `${productsThirdPartyRoutes.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.vodStorages.add.path
            || active === productsThirdPartyRoutes.vodStorages.edit.path
            || active === productsThirdPartyRoutes.geolocation.index.path
            || active === productsThirdPartyRoutes.geolocation.add.path
            || active === productsThirdPartyRoutes.geolocation.edit.path
        ) {
            if (active === productsThirdPartyRoutes.vodStorages.add.path
                || active === productsThirdPartyRoutes.vodStorages.edit.path
                || active === productsThirdPartyRoutes.geolocation.add.path
                || active === productsThirdPartyRoutes.geolocation.edit.path
            ) {
                shouldOpenModal = true;
            }

            active = routes.clients.products.thirdParty.edit.path;
        }

        if (active === clientCdnConfigurationRoutes.add.path
            || active === clientCdnConfigurationRoutes.edit.path
            || active === `${clientCdnConfigurationRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.index.path
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.add.path
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.edit.path
            || active === `${clientCdnConfigurationRoutes.akamaiLiveStreamName.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = clientCdnConfigurationRoutes.index.path;
        }

        if (active === clientApiKeyRoutes.add.path || active === clientApiKeyRoutes.edit.path) {
            active = clientApiKeyRoutes.index.path;
        }

        if (active === clientVideoPlayer.add.path
            || active === clientVideoPlayer.edit.path
            || active === clientVideoPlayer.index.path) {
            active = clientVideoPlayer.index.path;
        }

        const activeKeys = active.split('/');

        if ('products' === activeKeys[3]
            && activeKeys[4]
            && (
                'geo-restrictions' === activeKeys[5]
                || 'geolocation' === activeKeys[5]
                || 'notification-history' === activeKeys[5]
            )
        ) {
            const productKey = ('lcp-p' === activeKeys[4])
                ? _camelCase(activeKeys[4].replace('-', ''))
                : _camelCase(activeKeys[4]);

            if (_has(productsRoutes, productKey)) {
                active = productsRoutes[productKey].edit.path;
            }
        }

        if (this.props.match.path === routes.clients.add.path) {
            tabs = {
                [routes.clients.add.path]: {
                    key: routes.clients.add.path,
                    render: <ClientFormAdd/>,
                    text: 'General information',
                    url: 'clients.add',
                },
            };
        } else {
            tabs = {
                [routes.clients.edit.path]: {
                    key: routes.clients.edit.path,
                    render: <ClientFormEdit/>,
                    text: 'General information',
                    url: 'clients.edit',
                    urlParams: {id: this.props.match.params.id},
                },
                [contactPersonsRoutes.index.path]: {
                    key: contactPersonsRoutes.index.path,
                    render: <ContactPersonsIndex clientId={clientId}/>,
                    text: 'Contact persons',
                    url: 'clients.contactPersons.index',
                    urlParams: {id: this.props.match.params.id},
                },
            };

            if (this.state.hasLcrProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.edit.path]: {
                            isDisabled: this.state.lcrProductIsDisabled,
                            key: productsRoutes.lcr.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.state.clientProductLcr.id)}/>,
                            text: `LCR ${this.state.lcrProductIsTrial ? '(trial)' : ''}`,
                            url: 'clients.products.lcr.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.edit.path]: {
                            isDisabled: this.state.lcrProductIsDisabled,
                            key: productsRoutes.lcr.edit.path,
                            text: 'LCR',
                            render: NOT_AUTHORIZED,
                            url: 'clients.products.lcr.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lcr.add.path && false === this.state.hasLcrProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcr.add.path,
                            render: <ProductAdd
                                form={ClientProductLcrForm}
                                model={ClientProductLcrModel}
                                productId={PRODUCT_LCR}
                            />,
                            text: 'LCR',
                            url: 'clients.products.lcr.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcr.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCR',
                            url: 'clients.products.lcr.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasLcoProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.edit.path]: {
                            isDisabled: this.state.lcoProductIsDisabled,
                            key: productsRoutes.lco.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.state.clientProductLco.id)}/>,
                            url: 'clients.products.lco.edit',
                            urlParams: {id: this.props.match.params.id},
                            text: `LCO ${this.state.lcoProductIsTrial ? '(trial)' : ''}`,
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.edit.path]: {
                            isDisabled: this.state.lcoProductIsDisabled,
                            key: productsRoutes.lco.edit.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCO',
                            url: 'clients.products.lco.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lco.add.path && false === this.state.hasLcoProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lco.add.path,
                            render: <ProductAdd
                                form={ClientProductLcoForm}
                                model={ClientProductLcoModel}
                                productId={PRODUCT_LCO}
                            />,
                            text: 'LCO',
                            url: 'clients.products.lco.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lco.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCO',
                            url: 'clients.products.lco.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasLcppProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.edit.path]: {
                            isDisabled: this.state.lcppProductIsDisabled,
                            key: productsRoutes.lcpp.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.state.clientProductLcpp.id)}/>,
                            url: 'clients.products.lcpp.edit',
                            urlParams: {id: this.props.match.params.id},
                            text: `LCP-P ${this.state.lcppProductIsTrial ? '(trial)' : ''}`,
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.edit.path]: {
                            isDisabled: this.state.lcppProductIsDisabled,
                            key: productsRoutes.lcpp.edit.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCP-P',
                            url: 'clients.products.lcpp.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (routes.clients.products.lcpp.add.path === active && false === this.state.hasLcppProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcpp.add.path,
                            render: <ProductAdd
                                form={ClientProductLcppForm}
                                model={ClientProductLcppModel}
                                productId={PRODUCT_LCPP}
                            />,
                            text: 'LCP-P',
                            url: 'clients.products.lcpp.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcpp.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCP-P',
                            url: 'clients.products.lcpp.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasLctProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.edit.path]: {
                            isDisabled: this.state.lctProductIsDisabled,
                            key: productsRoutes.lct.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.state.clientProductLct.id)}/>,
                            text: `LCT ${this.state.lctProductIsTrial ? '(trial)' : ''}`,
                            url: 'clients.products.lct.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.edit.path]: {
                            isDisabled: this.state.lctProductIsDisabled,
                            key: productsRoutes.lct.edit.path,
                            text: 'LCT',
                            render: NOT_AUTHORIZED,
                            url: 'clients.products.lct.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lct.add.path && false === this.state.hasLctProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lct.add.path,
                            render: <ProductAdd
                                form={ClientProductLctForm}
                                model={ClientProductLctModel}
                                productId={PRODUCT_LCT}
                            />,
                            text: 'LCT',
                            url: 'clients.products.lct.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lct.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'LCT',
                            url: 'clients.products.lct.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasSpottProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.edit.path]: {
                            isDisabled: this.state.spottProductIsDisabled,
                            key: productsRoutes.spott.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.state.clientProductSpott.id)}/>,
                            text: `SpOTT ${this.state.spottProductIsTrial ? '(trial)' : ''}`,
                            url: 'clients.products.spott.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.edit.path]: {
                            isDisabled: this.state.spottProductIsDisabled,
                            key: productsRoutes.spott.edit.path,
                            render: NOT_AUTHORIZED,
                            text: 'SpOTT',
                            url: 'clients.products.spott.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.spott.add.path && false === this.state.hasSpottProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.spott.add.path,
                            render: <ProductAdd
                                form={ClientProductSpottForm}
                                model={ClientProductSpottModel}
                                productId={PRODUCT_SPOTT}
                            />,
                            text: 'SpOTT',
                            url: 'clients.products.spott.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.spott.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'SpOTT',
                            url: 'clients.products.spott.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasThirdPartyProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.edit.path]: {
                            isDisabled: this.state.thirdPartyProductIsDisabled,
                            key: productsRoutes.thirdParty.edit.path,
                            render: <ClientProductThirdPartyLayout
                                clientProductThirdParty={this.state.clientProductThirdParty}
                                shouldOpenModal={shouldOpenModal}
                            />,
                            text: `Third party ${this.state.thirdPartyProductIsTrial ? '(trial)' : ''}`,
                            url: 'clients.products.thirdParty.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.edit.path]: {
                            isDisabled: this.state.thirdPartyProductIsDisabled,
                            key: productsRoutes.thirdParty.edit.path,
                            render: NOT_AUTHORIZED,
                            text: 'Third party',
                            url: 'clients.products.thirdParty.edit',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.thirdParty.add.path && false === this.state.hasThirdPartyProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.thirdParty.add.path,
                            render: <ClientProductThirdPartyLayout
                                clientProductThirdParty={{}}
                            />,
                            text: 'Third party',
                            url: 'clients.products.thirdParty.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.thirdParty.add.path,
                            render: NOT_AUTHORIZED,
                            text: 'Third party',
                            url: 'clients.products.thirdParty.add',
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.state.hasLciProduct) {
                let lciEditTab = {
                    isDisabled: this.state.lciProductIsDisabled,
                    key: productsRoutes.lci.edit.path,
                    url: 'clients.products.lci.edit',
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    lciEditTab = Object.assign(lciEditTab, {
                        render: <ProductTab clientProductId={convertToInt(this.state.clientProductLci.id)}/>,
                        text: `LCI ${this.state.lciProductIsTrial ? '(trial)' : ''}`,
                    });
                } else {
                    lciEditTab = Object.assign(lciEditTab, {
                        text: 'LCI',
                        render: NOT_AUTHORIZED,
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.lci.edit.path]: lciEditTab,
                });
            }

            if (active === routes.clients.products.lci.add.path && false === this.state.hasLciProduct) {
                let lciAddTab = {
                    isDisabled: false,
                    key: productsRoutes.lci.add.path,
                    url: 'clients.products.lci.add',
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    lciAddTab = Object.assign(lciAddTab, {
                        render: <ProductAdd
                            form={ClientProductLciForm}
                            model={ClientProductLciModel}
                            productId={PRODUCT_LCI}
                        />,
                        text: 'LCI',
                    });
                } else {
                    lciAddTab = Object.assign(lciAddTab, {
                        render: NOT_AUTHORIZED,
                        text: 'LCI',
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.lci.add.path]: lciAddTab,
                });
            }

            if (this.state.hasQuickBetsProduct) {
                let qbEditTab = {
                    isDisabled: this.state.qbProductIsDisabled,
                    key: productsRoutes.qb.edit.path,
                    url: 'clients.products.qb.edit',
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    qbEditTab = Object.assign(qbEditTab, {
                        render: <ProductTab clientProductId={convertToInt(this.state.clientProductQb.id)}/>,
                        text: `QB ${this.state.qbProductIsTrial ? '(trial)' : ''}`,
                    });
                } else {
                    qbEditTab = Object.assign(qbEditTab, {
                        text: 'QB',
                        render: NOT_AUTHORIZED,
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.qb.edit.path]: qbEditTab,
                });
            }

            if (active === routes.clients.products.qb.add.path && false === this.state.hasQuickBetsProduct) {
                let qbAddTab = {
                    isDisabled: false,
                    key: productsRoutes.qb.add.path,
                    url: 'clients.products.qb.add',
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    qbAddTab = Object.assign(qbAddTab, {
                        render: <ProductAdd
                            form={ClientProductQbForm}
                            model={ClientProductQbModel}
                            productId={PRODUCT_QB}
                        />,
                        text: 'QB',
                    });
                } else {
                    qbAddTab = Object.assign(qbAddTab, {
                        render: NOT_AUTHORIZED,
                        text: 'QB',
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.qb.add.path]: qbAddTab,
                });
            }

            tabs = Object.assign(tabs, {
                [packagesRoutes.index.path]: {
                    key: packagesRoutes.index.path,
                    render: <PackagesTab clientId={clientId} />,
                    text: 'Packages',
                    url: 'clients.packages.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [eventBookingsRoutes.index.path]: {
                    key: eventBookingsRoutes.index.path,
                    render: <ClientEventBookings/>,
                    text: 'Event bookings',
                    url: 'clients.eventBookings.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [bookingsRoutes.index.path]: {
                    key: bookingsRoutes.index.path,
                    render: <ClientBookings/>,
                    text: 'Bookings',
                    url: 'clients.bookings.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [offeringsRoutes.index.path]: {
                    key: offeringsRoutes.index.path,
                    render: <ClientOfferings/>,
                    text: 'Offerings',
                    url: 'clients.offerings.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [clientCdnConfigurationRoutes.index.path]: {
                    key: clientCdnConfigurationRoutes.index.path,
                    render: <CdnConfigurationsTab clientId={clientId}/>,
                    text: 'CDN configurations',
                    url: 'clients.clientCdnConfiguration.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [routes.clients.drmSetup.index.path]: {
                    key: routes.clients.drmSetup.index.path,
                    render: <ClientDrmSetupIndex
                        clientDrmSetup={this.props.clientData ? this.props.clientData.clientDrmSetup : null}
                        clientId={convertToInt(this.props.match.params.id)}
                    />,
                    text: 'DRM setup',
                    url: 'clients.drmSetup.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [clientVideoPlayer.index.path]: {
                    key: clientVideoPlayer.index.path,
                    render: <ClientVideoPlayerTab />,
                    text: 'Video player',
                    url: 'clients.clientVideoPlayer.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [whitelistedIpsRoutes.index.path]: {
                    key: whitelistedIpsRoutes.index.path,
                    render: <WhitelistedIpTabsLayout />,
                    text: 'IP whitelisting',
                    url: 'clients.whitelistedIps.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [clientAnalyticsSetupRoutes.index.path]: {
                    key: clientAnalyticsSetupRoutes.index.path,
                    render: <ClientAnalyticsSetupTabLayout />,
                    text: 'Analytics setup',
                    url: 'clients.clientAnalyticsSetup.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [clientApiKeyRoutes.index.path]: {
                    key: clientApiKeyRoutes.index.path,
                    render: <ApiKeyIndex />,
                    text: 'API keys',
                    url: 'clients.apiKey.index',
                    urlParams: {id: this.props.match.params.id},
                },
            });
        }

        if (clientData) {
            const productHasEventBookingSupport = clientData.clientProducts.some(product => product.product.has_event_booking_support);

            if (!productHasEventBookingSupport) {
                delete tabs[eventBookingsRoutes.index.path];

                if (active === eventBookingsRoutes.index.path) {
                    const returnLink = getLink('clients.edit', {id: clientData.id });

                    showModal({
                        isVisible: true,
                        content: renderModalError('Event bookings', returnLink, 'Client event bookings was not found'),
                    });
                }
            }
        }

        return {
            active: active,
            tabs: tabs,
        };
    };

    renderHeaderTitle = () => {
        if (!_isUndefined(this.props.match.params.id)) {
            return (
                !_isUndefined(this.props.clientData)
                && !_isUndefined(this.props.clientData.name)
                && '' !== this.props.clientData.name
            )
                ? 'Client "' + this.props.clientData.name + '"'
                : 'Client';
        } else {
            return 'Add new client';
        }
    };

    render() {
        return (
            <div>
                <HeaderRenderer
                    buttons={ClientTabsButtons}
                    buttonsProps={{clientId: convertToInt(this.props.match.params.id), loading: this.state.loading}}
                    pageTitle={this.renderHeaderTitle()}
                />
                <div className='clearfix'/>
                <MessageBox name='clientMessage'/>
                {(this.state.renderTabs || this.props.match.path === routes.clients.add.path)
                && <Tabs active={this.state.active} items={this.state.tabs}/>}
            </div>
        );
    }
}

export const ClientForStore = gql(clientGraphQl.clientForStore);

export const prepareClientForStore = (client) => {
    const clientProducts = [];

    for (let i = 0; i < client.client_products.length; i++) {
        if (PRODUCT_THIRD_PARTY !== convertToInt(client.client_products[i].product.id)) {
            clientProducts.push(_omit(client.client_products[i], ['third_party_client_product']));
        } else {
            clientProducts.push(client.client_products[i]);
        }
    }

    return {
        name: 'client',
        data: {
            api_key: client.api_key,
            clientProducts: clientProducts,
            id: client.id,
            name: client.name,
            bookmaker: client.bookmaker,
            clientDrmSetup: client.client_drm_setup,
        },
    };
};

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        clientData: state.app.entities.client,
        userPrivileges: state.app.security.user.privileges,
    };
};

const mapDispatchToProps = mapModulesToProps(['Entity', 'MessageBox', 'Modal']);

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ClientTabsLayout)));
