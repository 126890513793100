import {convertToInt} from '@utils/helpers';
import validators from '@utils/validators';
import {
    CLIENT_SELECTION_TYPE_ALL,
    DISTRIBUTION_TYPE_RECORDING,
    FREE_TEXT,
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_SRT,
} from '@constants/variables';

const checkDistributionUsageErrors = (id, values, errors) => {
    Object.assign(errors, validators.required(values[`${id}_product_status`], `${id}_product_status`));
    Object.assign(errors, validators.required(values[`${id}_start_datetime`], `${id}_start_datetime`));
    Object.assign(errors, validators.dateTime(values[`${id}_start_datetime`], `${id}_start_datetime`));

    if (values[`${id}_end_datetime`]) {
        Object.assign(errors, validators.dateTime(values[`${id}_end_datetime`], `${id}_end_datetime`));

        if (new Date(values[`${id}_start_datetime`]) > new Date(values[`${id}_end_datetime`])) {
            Object.assign(errors, {[`${id}_start_datetime`]: 'Start date must be before the End date'});
            Object.assign(errors, {[`${id}_end_datetime`]: 'End date must be later than the Start date'});
        }
    }

    Object.assign(errors, validators.required(values[`${id}_client_selection_type`], `${id}_client_selection_type`));

    if (CLIENT_SELECTION_TYPE_ALL !== convertToInt(values[`${id}_client_selection_type`])) {
        Object.assign(errors, validators.required(values[`${id}_clients`], `${id}_clients`));
    }

    if (DISTRIBUTION_TYPE_RECORDING !== convertToInt(id.slice(-1))) {
        Object.assign(errors, validators.required(values[`${id}_broadcast_status`], `${id}_broadcast_status`));
    }

    return errors;
};

const validate = (values, props) => {
    let errors = {};

    Object.assign(errors, validators.required(values.event_content_type, 'event_content_type'));
    Object.assign(errors, validators.required(values.event_content_variant, 'event_content_variant'));
    Object.assign(errors, validators.numeric(values.video_length, 'video_length'));
    Object.assign(errors, validators.numeric(values.video_duration, 'video_duration'));

    if (false === values.synchronise_times) {
        Object.assign(errors, validators.required(values.start_datetime, 'start_datetime'));
        Object.assign(errors, validators.dateTime(values.start_datetime, 'start_datetime'));

        if (values.end_datetime) {
            Object.assign(errors, validators.dateTime(values.end_datetime, 'end_datetime'));

            if (new Date(values.start_datetime) > new Date(values.end_datetime)) {
                Object.assign(errors, {start_datetime: 'Start date must be before the End date'});
                Object.assign(errors, {end_datetime: 'End date must be later than the Start date'});
            }
        }
    }

    if (values.has_input) {
        Object.assign(errors, validators.required(values.contribution_type, 'contribution_type'));
        Object.assign(errors, validators.required(values.encoding_datacenter, 'encoding_datacenter'));
        Object.assign(errors, validators.required(values.encoding_redundancy_type, 'encoding_redundancy_type'));
        Object.assign(errors, validators.required(values.production_company, 'production_company'));
        Object.assign(errors, validators.required(values.contact_person, 'contact_person'));
        Object.assign(errors, validators.required(values.origin_stream_type, 'origin_stream_type'));

        if (ORIGIN_STREAM_TYPE_RTMP === parseInt(values.origin_stream_type, 10)) {
            Object.assign(errors, validators.required(values.rtmp_origin_entry_point_application, 'rtmp_origin_entry_point_application'));
        } else if (ORIGIN_STREAM_TYPE_HTTP === convertToInt(values.origin_stream_type)) {
            Object.assign(
                errors,
                validators.urlOptional(values.http_origin_entry_point_url, 'http_origin_entry_point_url')
            );
        } else if (ORIGIN_STREAM_TYPE_SRT === parseInt(values.origin_stream_type)) {
            Object.assign(errors, validators.urlOptional(values.srt_origin_entry_point_url, 'srt_origin_entry_point_url', undefined, 'srt'));
            Object.assign(errors, validators.urlOptional(values.failover_srt_origin_entry_point_url, 'failover_srt_origin_entry_point_url', undefined, 'srt'));

            if (values.srt_origin_entry_point !== FREE_TEXT && values.failover_srt_origin_entry_point
                && values.srt_origin_entry_point === values.failover_srt_origin_entry_point) {
                Object.assign(errors, {failover_srt_origin_entry_point: 'Failover SRT origin entry point must be different from the SRT origin entry point'});
            }
        }

        if (values.srt_has_scte35 && ORIGIN_STREAM_TYPE_SRT === convertToInt(values.origin_stream_type)
            && (values.override_ad_setup || (!values.srt_ad_splice_config && !values.override_ad_setup && values.srt_origin_entry_point === FREE_TEXT))) {
            Object.assign(errors, validators.required(values.srt_ad_splice_config, 'srt_ad_splice_config'));
        }
    }

    props.formParams.products.forEach((product) => {
        if (values[`product_${product.id}`]) {
            if (values[`${product.id}_1_distribution_usage`]
                || values[`${product.id}_2_distribution_usage`]
                || values[`${product.id}_3_distribution_usage`]
            ) {
                if (values[`${product.id}_1_distribution_usage`]) {
                    errors = checkDistributionUsageErrors(`${product.id}_1`, values, errors);
                }

                if (values[`${product.id}_2_distribution_usage`]) {
                    errors = checkDistributionUsageErrors(`${product.id}_2`, values, errors);
                }

                if (values[`${product.id}_3_distribution_usage`]) {
                    errors = checkDistributionUsageErrors(`${product.id}_3`, values, errors);
                }
            } else {
                Object.assign(errors, validators.required(
                    values[`${product.id}_1_distribution_usage`],
                    `${product.id}_1_distribution_usage`
                ));
                Object.assign(errors, validators.required(
                    values[`${product.id}_2_distribution_usage`],
                    `${product.id}_2_distribution_usage`
                ));
                Object.assign(errors, validators.required(
                    values[`${product.id}_3_distribution_usage`],
                    `${product.id}_3_distribution_usage`
                ));
            }
        }
    });

    return errors;
};

export default validate;
