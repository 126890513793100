import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT_STATUS_PLANNED} from '@constants/variables';
import * as formUtils from '@modules/events/utils/formHelper';
import {srEventMultipleCreateMutation} from '@graphql/events/events';
import {EVENT} from '@constants/resources';

import {validateForEventMultiple} from './validator';

export default function EventMultipleCreationModel() {
    return {
        title: null,
        formName: 'EventMultipleCreation',
        entityDataMapKey: 'EventMultipleCreation',
        validate: validateForEventMultiple,
        resources: EVENT,
        dataMap: {
            EventMultipleCreation: {
                property_licence: null,
                event_preset: null,
                event_status: null,
                description: null,
                notes: null,
            },
        },
        fields: {
            property_licence: {
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    label: 'Property licence',
                    name: 'property_licence',
                    type:'select',
                    required: true,
                },
            },
            event_preset: {
                props: {
                    component: formUtils.renderEventPresetDropdown,
                    label: 'Apply preset',
                    disabled: true,
                    allowClear: true,
                    name: 'event_preset',
                    placeholder: 'Select preset',
                    type: 'select',
                },
            },
            multiple: [
                {
                    description: {
                        dataMapKey: 'event.description',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Description',
                            name: 'description',
                            type: 'text',
                        },
                    },
                },
                {
                    color: {
                        dataMapKey: 'event.color',
                        props: {
                            component: Form.SemanticInput,
                            name: 'color',
                            type: 'color',
                        },
                        defaultValue: '3a87ad',
                    },
                },
            ],
            event_status: {
                props: {
                    component: Form.SemanticSelect,
                    label: 'Status',
                    name: 'event_status',
                    required: true,
                },
                defaultValue: EVENT_STATUS_PLANNED + '',
            },
            coverage: {
                optionsKey: 'coverage',
                props: {
                    className: 'inline',
                    component: Form.SemanticCheckboxList,
                    label: 'Coverage',
                    name: 'coverage',
                    inline: true,
                    hidden: true,
                },
            },
            clip_provider: {
                optionsKey: 'clipProviders',
                props: {
                    className: '--small-width',
                    hidden: true,
                    component: Form.SemanticSelect,
                    label: 'Clip provider',
                    name: 'clip_provider',
                    required: true,
                },
            },
            notes: {
                component: Form.FormRowNotes,
                props: {
                    name: 'notes',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'eventsIndex',
                error: 'eventsIndex',
            },
            text: {...MESSAGES,
                SAVED: () => 'The events have been saved successfully.',
                FORM_ERROR: () => 'The events have not been saved. Please correct the highlighted fields below.',
            },
        },


        query: {
            getOptions: null,
        },
        mutation: {
            createEntity: srEventMultipleCreateMutation,
        },
    };
}
