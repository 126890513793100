import {Button} from 'semantic-ui-react';
import React from 'react';

import {convertToInt} from '@utils/helpers';
import {getGeoRestrictionsForDeviceCategories} from '@utils/countryHelpers';
import {DEVICE_CATEGORY_DESKTOP, DEVICE_CATEGORY_MOBILE, DEVICE_CATEGORY_SMART_TV} from '@constants/variables';

export const getEventBookingDataFromAllSteps = (stepsMethods, bookingId) => {
    const preparedData = Object.assign(
        {},
        stepsMethods.getForms()['step1'].formValues,
        stepsMethods.getForms()['step2'].formValues,
        stepsMethods.getForms()['step3'].formValues
    );

    return Object.assign({}, {
        id: convertToInt(bookingId),
        booking_type: convertToInt(preparedData.booking_type),
        client: convertToInt(preparedData.client),
        distribution: convertToInt(preparedData.distribution),
        client_package: preparedData.client_package ? convertToInt(preparedData.client_package) : null,
        is_hq: preparedData.is_hq || false,
        is_ll: preparedData.is_ll || false,
        price: null === preparedData.price ? null : parseFloat(preparedData.price),
        auto_update_price: null === preparedData.auto_update_price ? false : preparedData.auto_update_price,
        auto_update_geo_restrictions: preparedData.auto_update_geo_restrictions || false,
        invoice_status: !preparedData.invoice_status_toggle ? convertToInt(preparedData.invoice_status) : null,
        booking_geo_restrictions: getGeoRestrictionsForDeviceCategories(preparedData),
    });
};

export const getAdditionalBackButton = (editForm, showPreviousStep) => {
    return !editForm && <Button
        color={'grey'}
        content={'Back'}
        icon={'angle double left'}
        labelPosition={'left'}
        onClick={showPreviousStep}
    />;
};

export const getDeviceNameById = (deviceId) => {
    switch (deviceId) {
        case DEVICE_CATEGORY_DESKTOP:
            return 'desktop';
        case DEVICE_CATEGORY_MOBILE:
            return 'mobile';
        case DEVICE_CATEGORY_SMART_TV:
            return 'smart_tv';
        default:
            return '';
    }
};
