import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import LabelColored from '@appComponents/LabelColored';
import {ORIGIN_STREAM_TYPE_SRT} from '@constants/variables';
import {isInFailover} from '@modules/liveControl/components/LiveControlTable/utils';

export const SrtOriginEntryPointFailoverDetails = ({row}) => {
    if (row.event_content_input_id && ORIGIN_STREAM_TYPE_SRT === row.origin_stream_type_id) {
        if (isInFailover(row) && row.failover_srt_origin_entry_point_url) {
            return <div><LabelColored color='grey' content='In failover'/></div>;
        }

        if (!row.failover_srt_origin_entry_point_url && row.srt_origin_entry_point_url) {
            return (
                <Popup
                    trigger={
                        <Icon data-testid='warning-sign' color='red' name='warning sign'
                            className='--height-100-percent --marginLeft-0-5-em'/>
                    }
                    flowing
                    content='This SRT origin entry point has no failover defined!'
                    hoverable
                />
            );
        }
    }

    return null;
};

SrtOriginEntryPointFailoverDetails.propTypes = {
    row: PropTypes.object.isRequired,
};
