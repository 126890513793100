import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {Segment} from 'semantic-ui-react';
import { isNil as _isNil } from 'lodash';

import {graphql} from 'react-apollo';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {GetRolesForDropdown} from '@graphql/role/query';
import {GetUserDataForUsersList, GetUsersAccessInformationForUsersList} from '@graphql/user/query';
import { hasPrivileges } from '@appComponents/Authorization';
import { SECURITY_PRIVILEGES_READ } from '@constants/variables';
import { USER_ACCESS_INFORMATION } from '@constants/resources';

import UserIndexButtons from '../components/UserIndexButtons';
import UserFilters from '../components/UserFilters';
import UserTable from '../components/UserTable';
import {exportExcel as userExportExcel} from '../utils/export/userExcel';
import {MESSAGE_BOX_USERS_NAME, MESSAGE_BOX_USERS_AFTER_ADD_NAME} from '../utils/variables';

const defaultFilters = {
    search: null,
    roles_id: [],
    is_disabled: null,
};

export const UserIndex = ({
    areFiltersVisible = true,
    DataUser: {
        users = [],
        loading: loadingUsersData,
    },
    DataRoles: {
        roles = [],
        loading: loadingRoles,
    },
    filters,
    loadingRefetch,
    Menu,
}) => {

    React.useEffect(() => {
        Menu.storeMenu(navigation.settings.key);
    }, []);

    let isDisabled = null;

    if (!_isNil(filters.is_disabled)) {
        isDisabled = !!(filters.is_disabled);
    }

    const userAccessPrivileges = {
        [USER_ACCESS_INFORMATION] : SECURITY_PRIVILEGES_READ,
    };

    const {
        data: userAccessData,
        error: userAccessInfoError,
    } = useQueryCustom({
        query: GetUsersAccessInformationForUsersList,
        options: {
            variables: {
                search: filters.search || defaultFilters.search,
                roles_id: filters.roles_id || defaultFilters.roles_id,
                is_disabled: isDisabled,
            },
            skip: !hasPrivileges(userAccessPrivileges),
        },
    });

    const isLoaded = (
            loadingUsersData
            || loadingRoles
            || loadingRefetch
        ),
        filtersProps = {
            loading: isLoaded,
            data: {
                roles: roles,
                isVisible: areFiltersVisible,
            },
        };

    const usersAccessInformation = (!userAccessInfoError && undefined !== userAccessData) ?
        userAccessData.usersAccessInformation :
        [];

    return (
        <div>
            <HeaderRenderer
                buttons={UserIndexButtons}
                exportExcelParams={userExportExcel(users, usersAccessInformation)}
                filters={UserFilters}
                filtersProps={filtersProps}
                filtersButtonName='Users'
                loading={isLoaded}
                messagesBoxNames={[MESSAGE_BOX_USERS_NAME, MESSAGE_BOX_USERS_AFTER_ADD_NAME]}
                pageTitle='Users'
            />
            <Segment basic className='--table'>
                <UserTable
                    data={users}
                    loading={isLoaded}
                />
            </Segment>
        </div>
    );
};

UserIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    DataUser: PropTypes.shape({
        users: PropTypes.array,
        loading: PropTypes.bool.isRequired,
    }),
    DataRoles: PropTypes.shape({
        roles: PropTypes.array,
        loading: PropTypes.bool.isRequired,
    }),
    filters: PropTypes.shape({
        search: PropTypes.string,
        roles_id: PropTypes.arrayOf(PropTypes.string),
        is_disabled: PropTypes.number,
    }),
    queryForRefresh: PropTypes.object,
    loadingRefetch: PropTypes.bool.isRequired,
    Menu: PropTypes.object.isRequired,
};

const UsersWithQuery = compose(
    graphql(GetUserDataForUsersList, {
        options: (props) => {
            let is_disabled = null;

            if (!_isNil(props.filters.is_disabled)) {
                is_disabled = !!(props.filters.is_disabled);
            }

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || defaultFilters.search,
                    roles_id: props.filters.roles_id || defaultFilters.roles_id,
                    is_disabled: is_disabled,
                },
            };
        },
        name: 'DataUser',
    }),
    graphql(GetRolesForDropdown, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            };
        },
        name: 'DataRoles',
    })
)(FilterQueryWrapper(UserIndex, {
    queryForRefresh: 'DataUser',
    filterUrls: ['users.index'],
}));

const mapDispatchToProps = mapModulesToProps(['Menu']);

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Users', defaultFilters),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(UsersWithQuery, defaultFilters)
);
