import {get as _get} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Segment} from "semantic-ui-react";

import HeaderRenderer from "../../app/components/HeaderRenderer";
import RightsScopeIndexButtons from "../components/RightsScopeIndexButtons";
import RightsScopesTable from "../components/RightsScopesTable";

import {exportExcel as rightsScopeIndexExportExcel} from "../utils/export/rightsScopeIndexExcel";
import useQueryCustom from "@utils/hooks/useQueryCustom";

import {GetRightsScopesForTable} from "../../../graphql/rightsScope/query.graphql";

const RightsScopeIndex = ({propertyLicenceId, propertyLicenceTypeId = null}) => {
    const {
        loading: isDataLoading = true,
        data = {},
    } = useQueryCustom({
        query: GetRightsScopesForTable,
        options: {
            variables: {
                id: propertyLicenceId,
            },
        },
    });

    return (isDataLoading || !propertyLicenceTypeId)
        ? (<Segment basic loading={true}/>)
        : (
            <div>
                <HeaderRenderer
                    buttons={RightsScopeIndexButtons}
                    buttonsProps={{propertyLicenceId}}
                    exportExcelParams={rightsScopeIndexExportExcel(
                        data.rightsScopes,
                        propertyLicenceId,
                        propertyLicenceTypeId
                    )}
                    loading={isDataLoading}
                />
                <RightsScopesTable
                    loading={isDataLoading}
                    rightsScopes={data.rightsScopes}
                />
            </div>
        );
};

RightsScopeIndex.propTypes = {
    propertyLicenceId: PropTypes.string.isRequired,
    propertyLicenceTypeId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    propertyLicenceTypeId: _get(state, 'app.entities.propertyLicence.property_licence_type_id', null),
});

export default connect(mapStateToProps)(RightsScopeIndex);
