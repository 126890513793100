import {convertToInt} from '@utils/helpers';
import {getSortingValue} from '@modules/events/utils/eventSchedule';

export const Columns = {
    'event_id': {
        label: 'ID',
        sorting: (row) => (row.event_id ? convertToInt(row.event_id) : 0),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'match_id': {
        label: 'Match ID',
        sorting: (row) => (row.match_id ? convertToInt(row.match_id) : 0),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'start_datetime': {
        label: 'Date/time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'sport_or_content_category_level_1': {
        label: 'Sport /',
        subLabel: '1st level category',
        sorting: (row) => (
            getSortingValue(row.sport_or_content_category_level_1)
        ),
    },
    'category_or_content_category_level_2': {
        label: 'Category /',
        subLabel: '2nd level category',
        sorting: (row) => (
            getSortingValue(row.category_or_content_category_level_2)
        ),
    },
    'tournament_or_content_category_level_3': {
        label: 'Tournament /',
        subLabel: '3rd level category',
        sorting: (row) => (
            getSortingValue(row.tournament_or_content_category_level_3)
        ),
    },
    'venue_or_court': {
        label: 'Venue / Court / TV Channel',
        sorting: (row) => (`${(row.venue_or_court || '')}${(row.tv_channel || '')}`),
    },
    'competitors': {
        label: 'Competitors / Description',
        sorting: (row) => {
            const noCompetitorsText = 'No competitors defined';

            return `${row.competitors && (row.competitors !== noCompetitorsText) ? row.competitors : ''}`;
        },
    },
    'status': {
        label: 'Status',
        sorting: true,
    },
    'coverage': {
        label: 'Coverage',
        content: {singleLine: true},
    },
    'contents': {label: 'Content', content: {singleLine: true}},
    'contribution_types': {label: 'Contribution type', content: {singleLine: true}},
    'encoding_datacenters': {label: 'Datacenter', content: {singleLine: true}},
    'products': {label: 'Product', content: {singleLine: true}},
    'distribution_types': {label: 'Distribution type', content: {singleLine: true}},
    'product_statuses': {label: 'Product status', content: {singleLine: true}},
    'targets': {
        label: 'Targets',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'bookings': {
        label: 'Bookings',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'has_event_report': {
        label: 'Report',
        sorting: (row) => (row.has_event_report ? 1 : 0),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'notes': {label: 'Notes'},
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};
