import PropTypes from "prop-types";
import React, {useState} from 'react';
import {get as _get} from 'lodash';
import {Segment} from "semantic-ui-react";
import {withRouter} from 'react-router';

import AccreditationForm from '../forms/AccreditationForm';
import AccreditationModel from "../forms/AccreditationModel";

import {convertToInt} from "../../../utils/helpers";
import {createForm} from "../../../utils/forms";
import useQueryCustom from '@utils/hooks/useQueryCustom';

import {GetPropertyLicenceForForm} from '../../../graphql/propertyLicence/query.graphql';

const AccreditationEdit = ({match}) => {
    const [contactPerson, setContactPerson] = useState(null);
    const {loading = true, data: propertyLicenceData = {}} = useQueryCustom({
        query: GetPropertyLicenceForForm,
        options: {
            variables: {
                id: match.params.id,
            },
        },
    });

    if (loading) {
        return <Segment basic loading={true}/>;
    }

    const Form = createForm(
        AccreditationModel,
        AccreditationForm,
        {
            id: _get(propertyLicenceData, 'propertyLicence.accreditation_contact_person.id', contactPerson),
            hasAccreditation: _get(propertyLicenceData, 'propertyLicence.has_accreditation', false),
        }
    );

    const accreditationOnSaveCallback = (response) => {
        const data = response.data.createContactPerson || response.data.updateContactPerson;
        setContactPerson(convertToInt(data.id));
    };

    return <Form onSaveCallback={accreditationOnSaveCallback}/>;
};

AccreditationEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number.isRequiredm,
        }),
    }),
};

export default withRouter(AccreditationEdit);
