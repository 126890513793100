import {get as _get, isEmpty as _isEmpty, includes as _includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import Tabs from '@appComponents/Tabs';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {routes} from '@constants/routes';
import {GetPropertyLicenceTechSetupBasicData} from '@graphql/propertyLicenceTechSetup/query';
import useQueryCustom from '@utils/hooks/useQueryCustom';

import {renderTechnicalSetupModalError} from '../utils/technicalSetupHelper';
import {createTabs, renderTechnicalSetupTabsModalError} from '../utils/technicalSetupTabsLayoutHelper';

export const TechnicalSetupTabsLayout = ({createdTechnicalSetupId = null, match: {path, params: {id}}, Menu, Modal}) => {
    const [pathChanged, setPathChanged] = useState(false);

    const technicalSetupRoute = routes.propertyLicences.technicalSetup,
        pathData = React.useMemo(() => {
            const slateRoute = technicalSetupRoute.slates,
                overlayRoute = technicalSetupRoute.overlays;

            if (_includes([
                slateRoute.index.path,
                slateRoute.add.path,
                slateRoute.edit.path,
            ], path)) {
                return Object.assign(
                    {},
                    {activePath: slateRoute.index.path, isExistingTechnicalSetupNeeded: true}
                );
            } else if (_includes([
                overlayRoute.index.path,
                overlayRoute.add.path,
                overlayRoute.edit.path,
            ], path)) {
                return Object.assign(
                    {},
                    {activePath: overlayRoute.index.path, isExistingTechnicalSetupNeeded: true}
                );
            } else if (technicalSetupRoute.products.path === path) {
                return Object.assign(
                    {},
                    {activePath: path, isExistingTechnicalSetupNeeded: true}
                );
            }

            return Object.assign(
                {},
                {activePath: path, isExistingTechnicalSetupNeeded: false}
            );
        }, [path]);

    if (createdTechnicalSetupId && !pathChanged && technicalSetupRoute.index.path !== pathData.activePath) {
        setPathChanged(true);
    }

    const {
            loading = true,
            error,
            data = {},
        } = useQueryCustom({
            query: GetPropertyLicenceTechSetupBasicData,
            options: {variables: {id: id}},
        }),
        existingTechnicalSetupId = _get(data, 'technicalSetups[0].id', null),
        items = createTabs(
            convertToInt(id),
            createdTechnicalSetupId,
            existingTechnicalSetupId,
            !pathChanged
        );

    if (!_isEmpty(error)) {
        Modal.setModal({
            isVisible: true,
            content: renderTechnicalSetupModalError(id),
        });
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.mediaRights.key);
    }, []);

    React.useEffect(() => {
        if (pathData.isExistingTechnicalSetupNeeded && !loading && !existingTechnicalSetupId) {
            Modal.setModal({
                isVisible: true,
                content: renderTechnicalSetupTabsModalError(id),
            });
        }
    }, [loading]);

    return (
        <Tabs items={items} active={pathData.activePath} level={2}/>
    );
};
TechnicalSetupTabsLayout.propTypes = {
    createdTechnicalSetupId: PropTypes.number,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
        path: PropTypes.string.isRequired,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};
TechnicalSetupTabsLayout.displayName = 'TechnicalSetupTabsLayout';

const mapDispatchToProps = mapModulesToProps(['Menu', 'Modal']),
    mapStateToProps = (state) => ({
        createdTechnicalSetupId: state.app.entities.propertyLicenceTechSetup
            ? convertToInt(state.app.entities.propertyLicenceTechSetup.id)
            : null,
    });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicalSetupTabsLayout));
