import React from 'react';

import {showErrorModal, showModal} from '@utils/modal';
import {PackageContentTierGeoRestrictionsLayout} from '@modules/client/forms/PackageContentTierGeoRestrictionsLayout';
import {convertToInt} from '@utils/helpers';
import ContentPackageForm from '@modules/client/forms/ContentPackageForm';
import {getLink} from '@appComponents/Link';


export const openEditGlobeModal = (data) => {
    generateLinkForModals(data, 'clients.packages.content.geoRestrictions');

    showModal({
        size: 'small',
        isVisible: true,
        className: 'client-package-content-geo-modal',
        content: <PackageContentTierGeoRestrictionsLayout
            header={`Client '${data.clientName}' - ${data.clientPackageName} - Content\n${data.parentNames.join(' > ')} > ${data.name}`}
            entityName={`${data.parentNames.join(' > ')} > ${data.name}`}
            bookingTypes={data.ContentTreeData.bookingTypes}
            eventContentVariants={data.ContentTreeData.eventContentVariants}
            productVariants={data.clientProduct?.product?.product_event_content_variants}
            product={data.clientProduct?.product}
            formData={data.clientPackageContentData}
            isActive={data.is_active}
            parentTreeData={data.parents}
            treeActiveStatus={data.treeActiveStatus}
            treeData={data.dataForTable}
            clientId={convertToInt(data.clientId)}
            clientPackageId={convertToInt(data.clientPackageId)}
            reloadTable={data.reloadTable}
            level={data.parentNames.length + 1}
            sport={convertToInt(data.parents.sportId)}
            id={data.id}
            clientPackageName={data.clientPackageName}
            backToIndexUrl={data.backToIndexUrl}
        />,
    });

    data.setState(() => ({
        openEditGlobeModal: true,
    }));
};

export const openEditModal = (data) => {
    generateLinkForModals(data, 'clients.packages.content.index');

    showModal({
        size: 'small',
        isVisible: true,
        className: 'client-package-content-modal',
        content: <ContentPackageForm
            header={`Client '${data.clientName}' - ${data.clientPackageName} - Content\n${data.parentNames.join(' > ')} > ${data.name}`}
            entityName={`${data.parentNames.join(' > ')} > ${data.name}`}
            bookingTypes={data.ContentTreeData.bookingTypes}
            eventContentVariants={data.ContentTreeData.eventContentVariants}
            productVariants={data.clientProduct.product.product_event_content_variants}
            product={data.clientProduct.product}
            formData={data.clientPackageContentData}
            isActive={data.is_active}
            parentTreeData={data.parents}
            treeActiveStatus={data.treeActiveStatus}
            treeData={data.dataForTable}
            clientId={convertToInt(data.clientId)}
            clientPackageId={convertToInt(data.clientPackageId)}
            reloadTable={data.reloadTable}
            level={data.parentNames.length + 1}
            backToIndexUrl={data.backToIndexUrl}
        />,
    });
};

export const generateLinkForModals = (data, path) => {

    if (!data.parents) {
        return openErrorModal(data);
    }

    const urlParams = [];

    data.parents.sportId && urlParams.push('sport_id=' + convertToInt(data.parents.sportId));
    data.parents.content_tier && urlParams.push('content_tier_id=' + convertToInt(data.parents.content_tier));
    data.parents.tournament_category && urlParams.push('tournament_category_id=' + convertToInt(data.parents.tournament_category));
    data.parents.property_licence && urlParams.push('property_licence_id=' + convertToInt(data.parents.property_licence));
    data.parents.tournament && urlParams.push('tournament_id=' + convertToInt(data.parents.tournament));

    const paramArray = urlParams.filter(param => param);
    let params = {};

    if ('clients.packages.content.index' === path) {
        params = {
            id: data.clientId,
            packagesId: data.clientPackageId,
        };
    } else if ('clients.packages.content.geoRestrictions' === path) {
        params = {
            id: data.clientId,
            packageId: data.clientPackageId,
        };
    }

    data.history.push({
        pathname: getLink(path, params),
        search: '?' + paramArray.join('&'),
    });
};

export const openErrorModal = (data) => {
    return showErrorModal({
        header: 'Page not found',
        text: 'Please check the url and try again',
        errorButtonText: 'Back to packages content table',
        hideButtons: false,
        redirectLink: getLink('clients.packages.content.index',
            {
                id: data.clientId,
                packagesId: data.clientPackageId,
            }),
        errorButtonIcon: 'arrow circle left',
    });
};
