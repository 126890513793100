import {find as _find, get as _get, groupBy as _groupBy} from 'lodash';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/order
import React from 'react';
/* eslint import/no-unresolved: 0 */

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {
    DISTRIBUTION_TYPE_LIVE,
    DISTRIBUTION_TYPE_RECORDING,
    PRODUCT_THIRD_PARTY,
    SECURITY_PRIVILEGES_CREATE_READ,
    SECURITY_PRIVILEGES_READ,
    THIRD_PARTY_CLIENT_TYPE_PULL,
    THIRD_PARTY_CLIENT_TYPE_PUSH,
} from '@constants/variables';
import EventsConfigurationEncodingInformation from '@modules/events/components/EventsConfigurationEncodingInformation';
import {showModal} from '@utils/modal';
import {convertToInt, hasLoadingQuery, isUrlParamValid, scrollToEventContent} from '@utils/helpers';
import {ButtonAdd, ButtonExpandCollapse} from '@appComponents/ButtonCollection';
import {refetchQueryByName} from '@utils/apollo';
import {createForm, renderModalError} from '@utils/forms';
import {routes} from '@constants/routes';
import Authorization from '@appComponents/Authorization';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import Link, {getLink} from '@appComponents/Link';
import MessageBox from '@appComponents/MessageBox';
import mapModulesToProps from '@utils/mapModulesToProps';
import * as MESSAGES from '@constants/messages';
import * as RESOURCES from '@constants/resources';
import {
    GetEventContentDetailsForStreamsOutput,
    GetEventContentEncodingConfigurationChanged,
} from '@graphql/eventContent/query';
import {ReleaseAkamaiStreamIdForSpott} from '@graphql/eventContent/mutation';
import * as eventContentGraphQl from '@graphql/events/eventContent';
import {GetThirdPartyClientsForList} from '@graphql/eventContentsThirdParty/query';
import {GetEventContents} from '@graphql/events/query';
import {GetProductsForEventContentForm} from '@graphql/product/query';
import {StreamsEventContentConfigurationTabPlaybackIconQuery} from '@graphql/streams/query';

import {EventContentEncodingWarningMessage} from '../components/EventContentEncodingWarningMessage';
import EventsConfigurationDistributions from '../components/EventsConfigurationDistributions';
import EventsConfigurationInput from '../components/EventsConfigurationInput';
import {EventsConfigurationMatchEventsTable} from '../components/EventsConfigurationMatchEventsTable';
import EventsConfigurationTargetsCdnConfigurationsWrapper from '../components/EventsConfigurationTargetsCdnConfigurationsWrapper';
import EventsConfigurationThirdParty from '../components/EventsConfigurationThirdParty';
import EventsConfigurationThirdPartyClientVodStorages from '../components/EventsConfigurationThirdPartyClientVodStorages';
import EventsContentPanel from '../components/EventsContentPanel';
import EventsContentTitle from '../components/EventsContentTitle';
import EventContentChangeEncodingStatusModel from '../forms/EventContentChangeEncodingStatusModel';
import EventContentChangeEncodingStatusForm from '../forms/EventContentChangeEncodingStatusForm';
import {loadModalEventsContentWizard} from '../forms/EventsContentWizard';
import ThirdPartyClientsForm from '../forms/EventContent/ThirdPartyClientsForm';
import ThirdPartyClientsModel from '../forms/EventContent/ThirdPartyClientsModel';
import {
    headerContent,
    showEndEncodingModal,
    showStartEncodingModal,
} from '../utils/eventsConfigurationEncodingInformation';

const authorizationEventContentCreateReadObject = {
    [RESOURCES.EVENT_CONTENT]: SECURITY_PRIVILEGES_CREATE_READ,
};

const authorizationEventContentReadObject = {
    [RESOURCES.EVENT_CONTENT]: SECURITY_PRIVILEGES_READ,
};

class EventsConfiguration extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        DataEventContent: PropTypes.object,
        GetEventContentEncodingConfigurationChanged: PropTypes.shape({
            changedEncodingConfigurations: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                hasChanged: PropTypes.bool.isRequired,
            })),
            loading: PropTypes.bool.isRequired,
        }),
        DataEventContentThirdParties: PropTypes.object,
        DataProducts: PropTypes.shape({
            products: PropTypes.array,
        }),
        Entity: PropTypes.object,
        event: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.shape({
            hash: PropTypes.string,
        }),
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        modal: PropTypes.object,
        isTvStreaming: PropTypes.bool,
        matchTvStreamingEncoder: PropTypes.object,
        timezone: PropTypes.string,
    };

    static defaultProps = {
        DataEventContent: {},
        DataEventContentThirdParties: {},
        DataProducts: {products: []},
        location: {
            hash: '',
        },
    };

    state = {
        clientCdnConfigurations: [],
        content: [],
        changedEncodingConfigurations: [],
        encodingTargets: [],
        eventContentIdsWithStreams: [],
        eventContents: [],
        eventContentsExpanded: {},
        loading: false,
        loadingStreams: false,
        modalShouldOpen: false,
        scrolledToEventContent: false,
        shouldExpand: true,
        shouldExpandTimestamp: Date.now(),
        thirdParties: {},
        thirdPartyLoading: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataEventContent: {eventContents},
            DataProducts: {products},
            event,
        } = nextProps;

        const nextState = {};

        if (eventContents && eventContents !== prevState.eventContents) {
            nextState.eventContents = eventContents;
            nextState.content = eventContents;

            const eventContentsExpanded = {};

            nextState.content.map((content) => {
                eventContentsExpanded[content.id] = true;
            });

            nextState.eventContentsExpanded = eventContentsExpanded;
        }

        if (nextProps.DataEventContentThirdParties && nextProps.DataEventContentThirdParties.eventContentsThirdParty) {
            const {
                DataEventContentThirdParties: { eventContentsThirdParty },
            } = nextProps;

            nextState.thirdParties = _groupBy(eventContentsThirdParty, function (o) {
                return o.event_content_id;
            });
            nextState.thirdPartyLoading = false;
        }

        nextState.products = products;
        nextState.event = event;

        return nextState;
    }

    componentDidMount() {
        const paths = [
            routes.events.configuration.content.add.path,
            routes.events.configuration.content.edit.path,
            routes.events.configuration.content.input.path,
            routes.events.configuration.content.distribution.path,
            routes.events.configuration.content.thirdPartyClients.path,
            routes.events.configuration.content.changeEncodingStartTime.path,
            routes.events.configuration.content.changeEncodingEndTime.path,
            routes.events.configuration.content.changeEncodingStatus.path,
            routes.events.configuration.content.releaseAkamaiStreamId.path,
            routes.events.configuration.content.broadcastStatusChangelog.path,
        ];

        if (paths.includes(this.props.match.path)) {
            this.setState(() => ({
                modalShouldOpen: true,
            }));
        }

        if (!isUrlParamValid(this.props.match.params.id)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event', routes.events.index.path),
            });
        }

        if (!isUrlParamValid(this.props.match.params.contentId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Event Content',
                    getLink('events.geoRestrictions.index',
                        {id: this.props.match.params.id})),
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.eventContents && this.state.eventContents !== prevState.eventContents) {
            const eventContents = this.state.eventContents;

            if (eventContents) {
                if (!this.hasValidEventContentId(eventContents, this.props.match.params.contentId)) {
                    this.props.Modal.setModal({
                        header: false,
                        isVisible : true,
                        content : renderModalError(
                            'Event content',
                            getLink('events.configuration.index', {id: this.props.match.params.id}),
                            null,
                            'Back'
                        ),
                    });
                }

                this.fetchStreams(eventContents);
            }
        }

        const urlChanged = prevProps.match.path !== this.props.match.path;
        const urlIsNotIndex = this.props.match.path !== routes.events.index.path;
        const checkDigits = isUrlParamValid(this.props.match.params.id);
        const shouldOpenModal = (urlChanged || this.state.modalShouldOpen) && urlIsNotIndex && checkDigits;

        const wizardData = {
            event: this.state.event,
            eventContents: this.state.content,
            products: this.state.products,
        };

        shouldOpenModal && this.loadModal(this.props.match, wizardData);

        const
            modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(getLink('events.configuration.index', {id: this.props.match.params.id}));

        const {
                GetEventContentEncodingConfigurationChanged: {
                    loading: prevLoading,
                },
            } = prevProps,
            {
                GetEventContentEncodingConfigurationChanged: {
                    changedEncodingConfigurations,
                    loading,
                },
            } = this.props;

        if (!loading && loading !== prevLoading && changedEncodingConfigurations) {
            this.setState(() => ({
                changedEncodingConfigurations: changedEncodingConfigurations
                    .filter((eventContent) => eventContent.hasChanged)
                    .map((eventContent) => eventContent.id),
            }));
        }

        this.scrollToEventContent();
    }

    scrollToEventContent = () => {
        if (this.props.location.hash && !this.state.scrolledToEventContent) {
            if (!hasLoadingQuery(this.props.client.queryManager.queries)) {
                scrollToEventContent(
                    this.props.location.hash,
                    () => this.setState(() => ({scrolledToEventContent: true}))
                );
            }
        }
    };

    hasValidEventContentId = (eventContents, contentId) => {
        return !contentId || 0 < eventContents.filter((eventContent) => (
            parseInt(eventContent.id, 10) === parseInt(contentId, 10)
        )).length;
    };

    /**
     * Fetch streams (by event content ids) to determine which event content has stream to display play icon
     * @param eventContents
     */
    fetchStreams = (eventContents) => {
        if (!eventContents.length) {
            return this.setState(() => ({
                loadingStreams: false,
                eventContentIdsWithStreams: [],
            }));
        }

        this.setState(() => ({
            loadingStreams: true,
            eventContentIdsWithStreams: [],
        }));

        this.props.client.query({
            fetchPolicy: 'network-only',
            query: StreamsEventContentConfigurationTabPlaybackIconQuery,
            variables: {
                eventContents: eventContents.map((eventContent) => (parseInt(eventContent.id, 10))),
            },
        }).then((result) => {
            let eventContentsIds = [];

            result.data.streams.map((stream) => (
                eventContentsIds.push(convertToInt(_get(stream, 'encoding_target.distribution.event_content.id', null)))
            ));

            eventContentsIds = eventContentsIds.filter((eventContentId) => {
                return eventContentId;
            });

            this.setState(() => ({
                eventContentIdsWithStreams: eventContentsIds,
                loadingStreams: false,
            }));
        }).catch(() => {
            this.setState({
                eventContentIdsWithStreams: [],
                loadingStreams: true,
            });
        });
    };

    refetchAfterCreate = () => {
        this.props.DataEventContent.refetch();
    };

    setEncodingTargets = (encodingTargets) => {
        this.setState(() => ({
            encodingTargets: encodingTargets,
        }));
    };

    releaseAkamaiStreamIdForSpott = (eventContentId, encodingDataCenterName) => {
        this.props.client.mutate({
            mutation: ReleaseAkamaiStreamIdForSpott,
            variables: {
                event_content: convertToInt(eventContentId),
                encoding_datacenter_name: encodingDataCenterName,
            },
        }).then(() => {
            this.props.Modal.setModal({
                isVisible: false,
                hideButtons: false,
            });
            this.props.MessageBox.addMessage(
                'event',
                'The Akamai stream ID has been released successfully.',
                null,
                'success'
            );
        }).catch((error) => {
            this.props.Modal.setModal({
                isVisible: false,
                hideButtons: false,
            });
            this.props.MessageBox.addMessage(
                'event',
                'An error occurred while trying to release the Akamai stream ID via the Video Platform Controller API.',
                `${error}`,
                'error'
            );
        });
    };

    loadModal = (match, wizardData) => {
        if (!wizardData.event
            || !wizardData.eventContents
            || !this.hasValidEventContentId(wizardData.eventContents, match.params.contentId)
            || !wizardData.products
        ) {
            return null;
        }

        const path = match.path;
        const contentId = convertToInt(match.params.contentId);

        if ((path === routes.events.configuration.content.changeEncodingStatus.path
            || path === routes.events.configuration.content.releaseAkamaiStreamId.path)
            && 0 < wizardData.eventContents.length) {

            const input = wizardData.eventContents.filter((eventContent) => (
                eventContent.id === match.params.contentId && eventContent.has_input
            ));

            this.setState(() => ({
                modalShouldOpen: false,
            }));

            if (0 < input.length) {
                return this.props.client.query({
                    fetchPolicy: 'cache-first',
                    query: GetEventContentDetailsForStreamsOutput,
                    variables: {eventContentId: contentId},
                }).then((response) => {
                    const eventContentDetails = response.data.eventContentDetailsForStreamsOutput;

                    if (path === routes.events.configuration.content.changeEncodingStatus.path) {
                        this.loadModalForm(createForm(
                            EventContentChangeEncodingStatusModel,
                            EventContentChangeEncodingStatusForm,
                            {
                                dataRequest: true,
                                id: contentId,
                            }
                        ), headerContent('Change encoding status', eventContentDetails));
                    }

                    if (path === routes.events.configuration.content.releaseAkamaiStreamId.path) {
                        const encodingDataCenterName = _get(
                            input[0],
                            'event_content_input.encoding_datacenter.name',
                            ''
                        );

                        this.props.Modal.setModalConfirmation({
                            header: headerContent('Release Akamai stream ID (SpOTT)', eventContentDetails),
                            size: 'small',
                            onYes: () => (this.releaseAkamaiStreamIdForSpott(match.params.contentId, encodingDataCenterName)),
                            text: 'Are you sure you want to release the Akamai stream ID?',
                        });
                    }
                });
            } else {
                this.props.Modal.setModal({
                    content : renderModalError(
                        'Event content',
                        getLink('events.configuration.index', {id: this.props.match.params.id}),
                        'This content has no input!',
                        'Back'
                    ),
                    header: false,
                    isVisible : true,
                });
            }
        }

        if (path === routes.events.configuration.content.thirdPartyClients.path
            && isUrlParamValid(match.params.contentId)) {
            const ThirdPartyClientsFormComponent = createForm(
                ThirdPartyClientsModel,
                ThirdPartyClientsForm,
                {
                    eventId: match.params.id,
                    eventContentId: match.params.contentId,
                    propertyLicenceId: this.props.event.property_licence.id,
                    eventContent: wizardData.eventContents.find((eventContent) => (
                        eventContent.id === match.params.contentId
                    )),
                    optionsVariables: {
                        types: [THIRD_PARTY_CLIENT_TYPE_PUSH, THIRD_PARTY_CLIENT_TYPE_PULL],
                    },
                }
            );

            this.setState(() => ({
                modalShouldOpen: false,
            }));

            this.props.Modal.setModal({
                isVisible: true,
                size: 'small',
                content: <ThirdPartyClientsFormComponent />,
            });

            return null;
        }

        if (path === routes.events.configuration.content.add.path) {
            wizardData.asWizard = true;
            wizardData.step = 1;

            this.setState(() => ({
                modalShouldOpen: false,
            }));

            return loadModalEventsContentWizard(wizardData, path, this.props.Modal, this.refetchAfterCreate);
        }

        if (match.params.contentId) {
            wizardData.eventContent = _find(wizardData.eventContents, {'id': match.params.contentId});
        }

        if (wizardData.eventContent) {
            this.setState(() => ({
                modalShouldOpen: false,
            }));

            switch (path) {
                case routes.events.configuration.content.edit.path:
                    wizardData.id = match.params.contentId;
                    wizardData.asWizard = false;

                    return loadModalEventsContentWizard(wizardData, path, this.props.Modal, this.refetchAfterCreate);
                case routes.events.configuration.content.changeEncodingStartTime.path:

                    return showStartEncodingModal({
                        content: wizardData.eventContent,
                        eventData: wizardData.event,
                        timezone: this.props.timezone,
                    });
                case routes.events.configuration.content.changeEncodingEndTime.path:

                    return showEndEncodingModal({
                        content: wizardData.eventContent,
                        eventData: wizardData.event,
                        timezone: this.props.timezone,
                    });
                case routes.events.configuration.content.input.path:
                    wizardData.id = match.params.contentId;
                    wizardData.asWizard = !wizardData.eventContent.event_content_input;
                    wizardData.step = 2;

                    return loadModalEventsContentWizard(wizardData, path, this.props.Modal, this.refetchAfterCreate);
                case routes.events.configuration.content.distribution.path:
                    wizardData.id = match.params.contentId;
                    wizardData.asWizard = 0 >= wizardData.eventContent.distributions.length;
                    wizardData.step = 3;

                    return loadModalEventsContentWizard(wizardData, path, this.props.Modal, this.refetchAfterCreate);
            }
        }
    };

    loadModalForm = (Form, header = null) => {
        this.props.Modal.setModal({
            size: 'small',
            header: false,
            isVisible: true,
            content: (
                <>
                    {header}
                    <Form />
                </>
            ),
        });
    };

    eventsConfigurationButtons = () => {
        return (
            <div className='eventsConfigurationButtons__indexButtons content__buttons'>
                <Authorization authorization={authorizationEventContentCreateReadObject}>
                    <Link
                        name={'events.configuration.content.add'}
                        params={{id: parseInt(this.props.match.params.id, 10)}}
                    >
                        <ButtonAdd>Add new content</ButtonAdd>
                    </Link>
                </Authorization>
                {1 < this.state.content.length && <ButtonExpandCollapse
                    onClick={this.toggleShouldExpand}
                    shouldExpand={this.hasCollapsedContent()}
                />}
            </div>
        );
    };

    toggleShouldExpand = () => {
        this.setState(() => ({
            shouldExpand: this.hasCollapsedContent(),
            shouldExpandTimestamp: Date.now(),
        }));
    };

    hasCollapsedContent = () => (Object.values(this.state.eventContentsExpanded).includes(false));

    hasThirdPartyProduct = (distributions, distributionType) => (
        0 < distributions.filter((distribution) => (
            PRODUCT_THIRD_PARTY === convertToInt(distribution.product.id)
            && distributionType === convertToInt(distribution.distribution_type.id)
        )).length
    );

    hasLiveOrRecordingDistributionType = (distributions) => {
        return 0 < distributions.filter((distribution) => (
            DISTRIBUTION_TYPE_LIVE === convertToInt(distribution.distribution_type.id)
            || DISTRIBUTION_TYPE_RECORDING === convertToInt(distribution.distribution_type.id)
        )).length;
    };

    deleteEventContent = (eventContentId) => {
        this.setState(() => ({loading: true}));

        this.props.client.mutate({
            mutation: gql`${eventContentGraphQl.eventContentDeleteMutation}`,
            variables: {
                id: eventContentId,
            },
        }).then(() => {
            this.props.MessageBox.addMessage(
                'event',
                MESSAGES.DELETED('content'),
                null,
                'success'
            );

            this.setState(() => ({loading: false}));
            refetchQueryByName('EventQuery');
        }).catch((error) => {
            this.props.MessageBox.addMessage(
                'event',
                MESSAGES.NOT_DELETED('content'),
                `${error}`,
                'error'
            );

            this.setState(() => ({loading: false}));
        });
    };

    setContentExpandedState = (id, expanded) => {
        const clonedEventContentsExpanded = Object.assign(this.state.eventContentsExpanded);

        clonedEventContentsExpanded[id] = expanded;

        this.setState(() => ({eventContentsExpanded: clonedEventContentsExpanded}));
    };

    render() {
        const configurationContent = (
            <div>
                <HeaderRenderer
                    buttons={this.eventsConfigurationButtons}
                />
                <MessageBox name='targetsInfo'/>

                <Authorization authorization={authorizationEventContentReadObject}>
                    {this.state.content.map((content) => (
                        <EventsContentPanel
                            id={content.id}
                            key={content.id}
                            setContentExpandedState={this.setContentExpandedState}
                            shouldExpand={this.state.shouldExpand}
                            shouldExpandTimestamp={this.state.shouldExpandTimestamp}
                            title={
                                <EventsContentTitle
                                    data={content}
                                    deleteEventContent={this.deleteEventContent}
                                    encodingTargets={this.state.encodingTargets}
                                    eventData={this.props.event}
                                    eventId={parseInt(this.props.match.params.id, 10)}
                                    hasStream={this.state.eventContentIdsWithStreams.includes(convertToInt(content.id))}
                                    history={this.props.history}
                                    Modal={this.props.Modal}
                                    loading={this.state.loadingStreams}
                                />
                            }
                            subparts={[
                                this.state.changedEncodingConfigurations.includes(convertToInt(content.id))
                                && <EventContentEncodingWarningMessage
                                    encodingStatus={
                                        convertToInt(_get(content, 'event_content_input.encoding_status.id', null))
                                    }
                                    key={`encodingWarning_${content.id}`}
                                />,
                                0 < content.match_events.length && <EventsConfigurationMatchEventsTable
                                    key={`matchEvents_${content.id}`}
                                    loading={this.props.DataEventContent.loading || this.state.loading}
                                    matchEvents={content.match_events}
                                    sport={this.props.event.property_licence.sport.id}
                                />,
                                content.has_input && <EventsConfigurationInput
                                    key={`input_${content.id}`}
                                    content={{content}}
                                    data={content.event_content_input}
                                    eventData={this.props.event}
                                    eventId={parseInt(this.props.match.params.id, 10)}
                                    history={this.props.history}
                                    isTvStreaming={this.props.isTvStreaming}
                                    matchTvStreamingEncoder={this.props.matchTvStreamingEncoder}
                                />,
                                content.has_input && this.hasLiveOrRecordingDistributionType(content.distributions)
                                && <EventsConfigurationEncodingInformation
                                    key={`encodingInformation_${content.id}`}
                                    content={content}
                                    eventData={this.props.event}
                                    encodingTargets={this.state.encodingTargets}
                                />,
                                <EventsConfigurationDistributions
                                    key={`distributions_${content.id}`}
                                    content={{content}}
                                    loading={this.props.DataEventContent.loading || this.state.loading}
                                    data={content.distributions}
                                    eventId={parseInt(this.props.match.params.id,10)}
                                    history={this.props.history}
                                />,
                                0 < content.distributions.length && <EventsConfigurationTargetsCdnConfigurationsWrapper
                                    key={`targetsCdnConfigurationsWrapper_${content.id}`}
                                    content={content}
                                    eventId={parseInt(this.props.match.params.id, 10)}
                                    Modal={this.props.Modal}
                                    MessageBox={this.props.MessageBox}
                                    setEncodingTargets={this.setEncodingTargets}
                                />,
                                this.hasThirdPartyProduct(content.distributions, DISTRIBUTION_TYPE_LIVE)
                                && <EventsConfigurationThirdParty
                                    key={`third_party_${content.id}`}
                                    thirdParties={this.state.thirdParties}
                                    eventContentId={parseInt(content.id, 10)}
                                    eventId={parseInt(this.props.match.params.id, 10)}
                                    loading={this.state.thirdPartyLoading}
                                />,
                                this.hasThirdPartyProduct(content.distributions, DISTRIBUTION_TYPE_RECORDING)
                                && <EventsConfigurationThirdPartyClientVodStorages
                                    distributions={content.distributions}
                                    eventContentId={convertToInt(content.id)}
                                    key={`third_party_client_vod_storage_${content.id}`}
                                />,
                            ]}
                        />
                    ))}
                </Authorization>
            </div>
        );

        return (
            <Segment
                className='--paddingLeft-0 --paddingRight-0'
                basic
                loading={this.props.DataEventContent.loading || this.state.loading}
            >
                {
                    this.hasValidEventContentId(this.state.eventContents, this.props.match.params.contentId)
                        ? configurationContent
                        : null
                }
            </Segment>
        );
    }
}

const mapStateToProps = (state) => ({
    modal: state.modal,
    event: state.app.entities.event,
    timezone: state.timezone.timezone,
});

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'Entity']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(
    graphql(GetEventContents, {
        options : (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy : 'network-only',
                variables: {
                    event: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataEventContent',
    }),
    graphql(GetThirdPartyClientsForList, {
        skip: props => !props.match.params.id,
        options : (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy : 'network-only',
                variables: {
                    id: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataEventContentThirdParties',
    }),
    graphql(GetProductsForEventContentForm, {
        options : () => ({
            fetchPolicy : 'network-only',
            notifyOnNetworkStatusChange: true,
        }),
        name: 'DataProducts',
    }),
    graphql(GetEventContentEncodingConfigurationChanged, {
        options : (props) => ({
            fetchPolicy : 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            variables: {event: convertToInt(props.match.params.id)},
        }),
        name: 'GetEventContentEncodingConfigurationChanged',
    })
)(withApollo(EventsConfiguration))));
