import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {GetMediaConnectForTable, GetFiltersForMediaConnectTable} from '@graphql/mediaConnect/query';
import MediaConnectFilters from '@modules/mediaConnect/components/MediaConnectFilters';
import {exportExcel} from '@modules/mediaConnect/utils/export/mediaConnectExcel';
import MediaConnectTable from '@modules/mediaConnect/components/MediaConnectTable';
import MediaConnectIndexButtons from '@modules/mediaConnect/components/MediaConnectIndexButtons';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    encodingDatacenter: [],
};

export function MediaConnectIndex(props) {
    const filters = props.filters,
        queryVariables = {
            search: filters.search ? filters.search : DEFAULT_FILTERS_VALUES.search,
            encodingDatacenter: filters.encodingDatacenter ? filters.encodingDatacenter : DEFAULT_FILTERS_VALUES.encodingDatacenter,
        };

    const {
        loading: areDataLoading = true,
        data = {},
    } = useQueryCustom({
        query: GetMediaConnectForTable,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
        fetchPolicy: 'no-cache',
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQueryCustom({
        query: GetFiltersForMediaConnectTable,
        fetchPolicy: 'no-cache',
    });

    const {Menu} = props;

    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                messagesBoxNames='mediaConnectMessage'
                pageTitle='MediaConnect origin entry points'
                buttons={MediaConnectIndexButtons}
                exportExcelParams={exportExcel(data.mediaconnectOriginEntryPoints)}
                filters={MediaConnectFilters}
                filtersProps={{
                    loading: areFiltersLoading,
                    data: {
                        encodingDatacenters: dataForFilters.encodingDatacenters || [],
                    },
                }}
                filtersButtonName='mediaConnect'
                loading={areDataLoading}
            />
            <div className='table-container'>
                <MediaConnectTable
                    mediaconnectOriginEntryPoints={data.mediaconnectOriginEntryPoints}
                    loading={areDataLoading}
                />
            </div>

        </div>
    );
}

MediaConnectIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    Menu: PropTypes.object,
};

const MediaConnectFilterWrapped = FilterQueryWrapper(MediaConnectIndex, {
    queryForRefresh: 'GetMediaConnectForTable',
    filterUrls: ['mediaConnect.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'mediaConnect', {...DEFAULT_FILTERS_VALUES}),
});

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(FilterUrlParamsWrapper(MediaConnectFilterWrapped,  {
    search: null,
    encodingDatacenter: [],
}));
