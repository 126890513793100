import React from 'react';
import {Popup} from 'semantic-ui-react';

import {ENCODING_REDUNDANCY_TYPE_MULTI_AZ_ENCODING} from '@constants/variables';
import {IconCodeBranch} from '@appComponents/IconCollection';

import LiveControlActions from '../LiveControlActions';
import {renderTime} from './renderTime';
import {renderBookings, renderCdns, renderDistributions} from './utils';
import {renderEncodingStartTime} from './renderEncodingStartTime';
import {renderEncodingStatus} from './renderEncodingStatus';
import {renderEncodingEndTime} from './renderEncodingEndTime';
import {renderContent} from './renderContent';
import {renderEntryPointName} from './renderEntryPointName';

/**
 *
 * @param column
 * @param row
 * @param timezone
 * @returns {*}
 */
export const liveControlTableRowRenderer = (column, row, timezone) => {
    switch (column) {
        case 'event_content_start_datetime':
            return renderTime(row);
        case 'content':
            return renderContent(row);
        case 'entryPoint':
            return renderEntryPointName(row);
        case 'datacenter':
            return <span>{row.encoding_datacenter_name}
                {
                    (row.encoding_redundancy_type === ENCODING_REDUNDANCY_TYPE_MULTI_AZ_ENCODING
                        && <Popup
                            trigger={<IconCodeBranch className='--rotated-90deg --marginLeft-0-5-em' />}
                            content={'Multi AZ encoding'}/>)
                }
            </span>;
        case 'distributions':
            return renderDistributions(row);
        case 'cdn':
            return <div className='--grid'>{renderCdns(row)}</div>;
        case 'bookings':
            return renderBookings(row);
        case 'encodingStartTime':
            return null !== row.event_content_input_id ? renderEncodingStartTime(row, timezone) : '';
        case 'encodingEndTime':
            return null !== row.event_content_input_id ? renderEncodingEndTime(row, timezone) : '';
        case 'encodingStatus':
            return null !== row.event_content_input_id ? renderEncodingStatus(row) : '';
        case 'actions' :
            return <LiveControlActions row={row}/>;
        default:
            return null;
    }
};
