import {isBoolean as _isBoolean} from 'lodash';
import {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {useQuery} from '@apollo/client';

const DEFAULT_OPTIONS = {
    variables: {},
    fetchPolicy: 'network-only',
    skip: false,
};

function useQueryCustom({
    query,
    options = {},
}) {
    const {loading, error, data, refetch} = useQuery(query, {
        variables: options.variables || DEFAULT_OPTIONS.variables,
        skip: _isBoolean(options.skip) ? options.skip : DEFAULT_OPTIONS.skip,
        fetchPolicy: options.fetchPolicy || DEFAULT_OPTIONS.fetchPolicy,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        const skip = _isBoolean(options.skip)
            ? options.skip
            : DEFAULT_OPTIONS.skip;

        if (!skip) {
            refetch();
        }
    }, [options.refetch]);

    return {loading, error, data, refetch};
}

useQueryCustom.propTypes = {
    query: PropTypes.object.isRequired,
    options: PropTypes.shape({
        fetchPolicy: PropTypes.string,
        refetch: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]),
        skip: PropTypes.bool,
        variables: PropTypes.object,
    }),
};

export default useQueryCustom;
