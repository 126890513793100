import React from 'react';
import {Popup} from 'semantic-ui-react';

import {CompetitorsName} from '@modules/events/views/common/CompetitorsName';
import {CompetitorsAndTypeWrapper} from '@utils/CompetitorsAndTypeWrapper';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import PopupTable from '@appComponents/PopupTable';
import {AvatarTournamentCategory} from '@modules/events/views/common/AvatarTournamentCategory';
import {EventContentType} from '@modules/events/views/common/EventContentType';
import * as RESOURCES from '@constants/resources';
import {EVENT_TYPE_SPORTRADAR, SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import LabelColored from '@appComponents/LabelColored';
import {CopyTextIcon} from '@utils/CopyTextIcon';

/**
 *
 * @param row
 * @returns {[null,null,null]}
 */
export const renderContent = (row) => ([
    renderContentFirstLine(row),
    renderContentSecondLine(row),
    renderContentThirdLine(row),
]);

/**
 *
 * @param row
 * @returns {XML}
 */
const renderContentFirstLine = (row) => {
    /**
     * <div> tag is necessary because of param in Semantic UI <Popup> component (trigger property)
     */
    const contentToRender = (
        <div>
            <CompetitorsAndTypeWrapper>
                <CompetitorsName event={row} />
                <EventContentType event={row} />
            </CompetitorsAndTypeWrapper>
        </div>
    );

    const renderIdWithCopyIcon = (id) => (
        <>
            {id}
            <CopyTextIcon value={id} iconTitle={'Copy ID'} disabled />
        </>
    );

    let rows = [
        {
            header: 'Property licence',
            body: (
                <LinkWithAuthorization
                    link={{
                        name: 'propertyLicences.edit',
                        params: {id: row.property_licence_id},
                    }}
                    newWindow={true}
                    authorization={{
                        [RESOURCES.PROPERTY_LICENCE]: SECURITY_PRIVILEGES_UPDATE,
                    }}
                >
                    {`${row.property_licence_name} - ${row.licensor_name}`}
                </LinkWithAuthorization>
            ),
        },
        {
            header: 'Notes',
            body: (
                row.event_notes
                    ? row.event_notes.split('\n').map((line, index) => (<div key={index}>{line}</div>))
                    : null
            ),
            hidden: !row.event_notes,
        },
        {
            header: 'Main event content',
            body: row.event_content_is_main_content ? 'Yes' : 'No',
        },
        {
            header: 'Event content ID',
            body: renderIdWithCopyIcon(row.event_content_id),
        },
        {
            header: 'Event ID',
            body: renderIdWithCopyIcon(row.event_id),
        },
        {
            header: 'Match ID',
            body: row.match_id ? renderIdWithCopyIcon(row.match_id) : '-',
            hidden: EVENT_TYPE_SPORTRADAR !== row.event_type_id,
        },
    ];

    return (
        <Popup
            className='--noPadding'
            key={`first-line-${row.id}`}
            trigger={contentToRender}
            flowing
            hoverable
        >
            <PopupTable rows={rows}/>
        </Popup>
    );
};

/**
 *
 * @param row
 * @returns {XML}
 */
const renderContentSecondLine = (row) => (
    <AvatarTournamentCategory
        event={row}
        key={`avatarTournamentCategory_${row.id}`}
    />
);

/**
 *
 * @param row
 * @returns {XML}
 */
const renderContentThirdLine = (row) => {
    if (null !== row.event_description) {
        return (
            <LabelColored
                color={null === row.event_color ? '' : row.event_color}
                content={row.event_description}
                key={'label-colored' + row.event_content_id}
            />
        );
    }

    return null;
};
