import React from 'react';
import {Label, Popup} from 'semantic-ui-react';
import {
    get as _get,
    uniqWith as _uniqWith,
    isEqual as _isEqual,
} from 'lodash';
import moment from 'moment';

import {convertToInt, formattedPriceOrEmptyValue} from '@utils/helpers';
import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import {IconCheckmark, IconEdit, IconGlobe, IconShield} from '@appComponents/IconCollection';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {getLink} from '@appComponents/Link';
import EventBookingCancellationIcon from '@modules/booking/views/BookingCancellationIcon';
import {ToggleIsCancelledBooking} from '@graphql/booking/mutation';
import {refetchQueryByName} from '@utils/apollo';
import {
    bookingsFields,
    clientPackageName,
    eventFields,
    geoFields,
    productShortName,
} from '@modules/booking/utils/clientBookingsFields';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup';
import {BOOKING} from '@constants/resources';
import {
    SECURITY_PRIVILEGES_UPDATE,
    EMPTY_VALUE_DASH,
    COMPETITOR_NOT_DEFINED,
    COMPETITOR_NOT_DEFINED_BOTH,
} from '@constants/variables';
import {columnObjectAlignCenter} from '@utils/tableHelpers';
import {rowContainsColumn} from '@modules/events/utils/eventSchedule';

import * as CLIENT_MESSAGES from '../constants/messages';

/**
 * Render data for Invoice Status column
 *
 * @param {Object} row
 *
 * @returns {string|columns.event_invoice_status_name|{sorting, header, label, content}|*}
 */
export const renderInvoiceStatusColumn = (row) => {
    if (row.booking_invoice_status_name && row.product_is_invoiceable) {
        return (
            <Popup
                trigger={<b><i>{row.booking_invoice_status_name}</i></b>}
                content={CLIENT_MESSAGES.CLIENT_BOOKINGS_TABLE_INVOICE_STATUS_TEXT}
            />
        );
    } else if (row.event_invoice_status_name && row.product_is_invoiceable) {
        return row.event_invoice_status_name;
    } else {
        return '';
    }
};

export const getInvoiceStatus = (row) => {
    if (row.booking_invoice_status_name && row.product_is_invoiceable) {
        return row.booking_invoice_status_name;
    } else if (row.event_invoice_status_name && row.product_is_invoiceable) {
        return row.event_invoice_status_name;
    } else {
        return ' ';
    }
};

export const renderContentCategoryLevelColumn = (row, field, level) => {
    if (CONST.EVENT_SPORTRADAR_TYPE === row.event_type) {
        return (row.match_id) ? row[`match_${field}_name`] : row[`sr_event_${field}_name`];
    }

    return row[`content_category_level_${level}_name`];
};


export const getContentTierName = (row) => {
    if (null !== row.av_event_content_tier_name) {
        return row.av_event_content_tier_name;
    } else if (null !== row.sr_event_content_tier_name) {
        return row.sr_event_content_tier_name;
    } else if (null !== row.match_content_tier_name) {
        return row.match_content_tier_name;
    }

    return null;
};

export const getCompetitors = (row) => {
    let competitors = [],
        defaultCompetitors = COMPETITOR_NOT_DEFINED_BOTH;

    if (row.match_id) {
        competitors = getCompetitorsByMatch(row);
    } else if (
        row.event_type === CONST.EVENT_SPORTRADAR_TYPE &&
        (null !== row.sr_event_home_competitor || null !== row.sr_event_away_competitor)
    ) {
        competitors.push(getCompetitorsByField(row, 'sr_event_home_competitor'));
        competitors.push(getCompetitorsByField(row, 'sr_event_away_competitor'));
    } else if (
        row.event_type === CONST.EVENT_NON_SPORTRADAR_TYPE &&
        (null !== row.av_event_home_competitor || null !== row.av_event_away_competitor)
    ) {
        competitors.push(getCompetitorsByField(row, 'av_event_home_competitor'));
        competitors.push(getCompetitorsByField(row, 'av_event_away_competitor'));
    }

    if (1 === _uniqWith(competitors, _isEqual).length && COMPETITOR_NOT_DEFINED === competitors[0]) {
        competitors = [];
    }

    return 0 < competitors.length ? competitors.join(' : ') : defaultCompetitors;
};

const getCompetitorsByMatch = (data) => {
    const competitors = [];

    competitors.push(getCompetitorsByField(data, 'match_home_competitor'));
    competitors.push(getCompetitorsByField(data, 'match_away_competitor'));

    return competitors;
};

const getCompetitorsByField = (data, competitorField) => {
    if (competitorField && null !== data[competitorField]) {
        return data[competitorField];
    } else {
        return COMPETITOR_NOT_DEFINED;
    }
};

export const getBasicClientBookingsColumnsForBookingsTable = (type) => {
    return {
        [`${type}_id`]: {
            label: 'ID',
            sorting: (row) => convertToInt(row[`${type}_id`]),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        ...eventFields,
        'event_content_id': {
            label: 'Content ID',
            sorting: (row) => convertToInt(row.event_content_id),
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        'distribution_id': {
            label: 'Distribution ID',
            sorting: (row) => convertToInt(row.distribution_id),
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        ...bookingsFields,
        'competitors': {
            label: 'Competitors / Description',
            sorting: true,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        'event_content_type_name': {
            label: 'Content',
            sorting: (row) => (`${row.event_content_type_name}${row.event_content_name ? ' - ' + row.event_content_name : ''}`),
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        ...productShortName,
        'distribution_type_name': {
            label: 'Distribution type',
            sorting: true,
            header: {
                align: 'left',
            },
            content: {
                align: 'left',
            },
        },
        ...geoFields,
        'blackoutZones': {label: 'Blackout Zones', sorting: false, className: 'noPadding'},
        ...clientPackageName,
        'is_hq': {
            label: 'HQ',
            sorting: true,
            ...columnObjectAlignCenter,
        },
        'is_ll': {
            label: 'LL',
            sorting: true,
            ...columnObjectAlignCenter,
        },
    };
};

export const clientBookingRowRenderer = (column, row, props) => {
    const geoRestrictions = _get(row, `${props.type}_geo_restrictions`, []),
        deviceCategoriesData = parseDeviceCategories(props.mappedIsoCodes, geoRestrictions);

    const eventContentVariant = {
        id: row.event_content_variant_id,
        name: row.event_content_variant_name,
        short_name: row.event_content_variant_short_name,
    };

    switch (column) {
        case 'id':
        case `${props.type}_id`:
        case 'match_id':
        case 'event_content_id':
        case 'distribution_id':
        case 'content_tier_name':
        case 'product_short_name':
        case `${props.type}_type_name`:
        case 'distribution_type_name':
        case `${props.type}_creation_datetime`:
            return row[column];
        case 'event_id':
            return row.event_id;
        case 'start_datetime':
            return (
                <div>
                    <div>
                        {rowContainsColumn(column, row)
                            ? moment(row.start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
                            : ''
                        }
                    </div>
                    <div>
                        {rowContainsColumn('end_datetime', row)
                            ? moment(row.end_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
                            : ''
                        }
                    </div>
                </div>
            );
        case 'content_category_level_1_name':
            return renderContentCategoryLevelColumn(row, 'sport', 1);
        case 'content_category_level_2_name':
            return renderContentCategoryLevelColumn(row, 'tournament_category', 2);
        case 'content_category_level_3_name':
            return renderContentCategoryLevelColumn(row, 'tournament', 3);
        case 'geo_restrictions_device':
            return renderDeviceCategories(deviceCategoriesData);
        case 'geo_restrictions_country':
            return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesData, props.mappedIsoCodes);
        case 'geo_restrictions_country_count':
            return renderNumberOfCountries(deviceCategoriesData);
        case 'blackoutZones':
            return renderBlackoutZonesTableRowCell(deviceCategoriesData, false);
        case 'competitors':
            return (
                <div>
                    <LinkWithAuthorization
                        resources={RESOURCES.EVENT}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'events.configuration.index',
                            params: {id: convertToInt(row.event_id)},
                        }}
                    >
                        {row.competitors ? row.competitors : null}
                    </LinkWithAuthorization>
                    {rowContainsColumn('event_description', row)
                        ? <div>
                            <Label style={{backgroundColor: `#${row.event_color}`}} size='mini'>
                                {row.event_description}
                            </Label>
                        </div>
                        : null
                    }
                </div>
            );
        case 'event_content_type_name':
            return (
                <>
                    {row.event_content_type_name} {row.event_content_name ? ' - ' + row.event_content_name : ''}
                    <EventContentVariantPopup eventContentVariant={eventContentVariant}/>
                </>
            );
        case 'client_package_name':
            return (
                <LinkWithAuthorization
                    resources={RESOURCES.CLIENT_PACKAGE}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    link={{
                        name: 'clients.packages.edit',
                        params: {
                            id: props.clientId,
                            packagesId: convertToInt(row.client_package_id),
                        },
                    }}
                >
                    {row.client_package_name}
                </LinkWithAuthorization>
            );
        case 'is_hq':
            return row.is_hq ? <IconCheckmark /> : null;
        case 'is_ll':
            return row.is_ll ? <IconCheckmark /> : null;
        case 'price':
            return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
        case 'event_invoice_status_name':
            return renderInvoiceStatusColumn(row);
        case 'actions':
            return (
                <div className='actions-list-icon'>
                    <IconEdit
                        resource={BOOKING}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        size='large'
                        link={getLink(
                            'clients.bookings.edit',
                            {id: props.clientId, eventId: row.event_id, bookingId: row.booking_id}
                        )}
                    />
                    <IconGlobe
                        size='large'
                        link={getLink(
                            'clients.bookings.editGeoRestrictions',
                            {id: props.clientId, bookingId: row.booking_id}
                        )}
                    />
                    <IconShield
                        link={getLink(
                            'clients.bookings.blackoutZones',
                            {id: props.clientId, bookingId: row.booking_id}
                        )}
                        size='large'
                    />
                    <EventBookingCancellationIcon
                        bookingId={row.booking_id}
                        isCancelled={row.is_cancelled}
                        entityName={'booking'}
                        messageBoxName='clientMessage'
                        successCallback={() => {
                            refetchQueryByName('clientBookingsForTable');
                        }}
                        mutationChangeCancellationStatus={ToggleIsCancelledBooking}
                    />
                </div>
            );
        default:
            return null;
    }
};
