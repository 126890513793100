import {getBasicClientBookingsColumnsForBookingsTable} from '@modules/client/utils/clientBookingTable';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

export const columns = {
    ...getBasicClientBookingsColumnsForBookingsTable('offering'),
    'is_hq': {
        label: 'HQ',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'is_ll': {
        label: 'LL',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'price': {
        label: 'Price',
        sorting: row => (null !== row.price)
            ? row.price
            : null,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'booking_status': {
        label: 'Booking status',
        sorting: row => row.booking_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'offering_creation_datetime': {
        label: 'Time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
};
