import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty as _isEmpty} from "lodash";
import {withRouter} from "react-router";

import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {showModal} from '@utils/modal';

import ContactPersonsIndexButtons, {contactPersonIndexButtonsAuthorizationObject} from "../components/ContactPersonsIndexButtons";
import ContactPersonsTable from "../components/ContactPersonsTable";
import HeaderRenderer from "../../app/components/HeaderRenderer";
import mapModulesToProps from "../../../utils/mapModulesToProps";
import ProductionCompanyContactPersonForm from "../forms/ProductionCompanyContactPersonForm";
import ProductionCompanyContactPersonModel from "../forms/ProductionCompanyContactPersonModel";
import ProductionCompanyContactPersonModelEdit from "../forms/ProductionCompanyContactPersonModelEdit";
import useQueryCustom from "@utils/hooks/useQueryCustom";
import {Authorization} from "../../app/components/Authorization";
import {getLink} from "../../app/components/Link";
import {renderProductionCompanyModalError} from "../utils/productionCompanyTabsLayoutHelper";

import {
    CONTACT_PERSON_TYPE_PRODUCTION_COMPANY_CONTACT,
    CONTACT_PERSON_TYPE_VENUE_CONTACT
} from "../../app/constants/variables";
import {routes} from "../../app/constants/routes";

import {GetProductionCompanyContactPersonsForList} from "../../../graphql/contactPerson/query.graphql";

const ContactPersonIndex = ({
    history: {
        push,
    },
    isVisible,
    match: {
        params: {
            id,
            contactPersonId = null
        },
        path
    },
    Modal,
}) => {
    const [modalVisibility, setModalVisibility] = useState(isVisible);
    const [mutationSuccessSubmit, setMutationSuccessSubmit] = useState(null);

    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQueryCustom({
        query: GetProductionCompanyContactPersonsForList,
        options: {
            variables: {
                production_company_id: convertToInt(id),
            },
            refetch: mutationSuccessSubmit,
        },
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: renderProductionCompanyModalError()
        })
    }

    if (!isUrlParamValid(contactPersonId)) {
        showModal({
            isVisible: true,
            content: renderModalError('Contact person', getLink(`productionCompanies.contactPersons.index`, {
                id: id,
            })),
        });
    }

    useEffect(() => {
        if (path !== routes.productionCompanies.contactPersons.index.path) {
            const formData = path === routes.productionCompanies.contactPersons.edit.path
                ? {id, contactPersonId}
                : null;

            loadModal(path, formData);
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            push(getLink(`productionCompanies.contactPersons.index`, {id: id}));
        }
    }, [isVisible]);

    const loadModal = (activePath, params = {}) => {
        const defaultParams = {
            productionCompanyId: id,
            setMutationSuccessSubmit,
            optionsVariables: {
                id: [CONTACT_PERSON_TYPE_PRODUCTION_COMPANY_CONTACT, CONTACT_PERSON_TYPE_VENUE_CONTACT],
            }
        };

        if (routes.productionCompanies.contactPersons.add.path === activePath) {
            return loadModalForm(createForm(
                ProductionCompanyContactPersonModel,
                ProductionCompanyContactPersonForm,
                {
                    ...defaultParams
                }
            ), params);
        } else if (routes.productionCompanies.contactPersons.edit.path === activePath) {
            return loadModalForm(createForm(
                ProductionCompanyContactPersonModelEdit,
                ProductionCompanyContactPersonForm,
                {
                    id: contactPersonId,
                    ...defaultParams
                }
            ), params);
        }
    };

    const loadModalForm = (Form, data) => {
        Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });

        setModalVisibility(true);
    };

    return (
        <div>
            <Authorization authorization={contactPersonIndexButtonsAuthorizationObject}>
                <HeaderRenderer buttons={ContactPersonsIndexButtons} buttonsProps={{id}}/>
            </Authorization>
            <ContactPersonsTable
                contactPersons={data.contactPersons}
                loading={areDataLoading}
            />
        </div>
    );
};

ContactPersonIndex.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    isVisible: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            contactPersonId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = (state) => ({isVisible: state.modal.isVisible});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(ContactPersonIndex));
