import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Field} from 'redux-form';
import {useQuery} from '@apollo/client';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {GetCountryIsoCodesForDropdown} from '@graphql/country/query';
import {GetSportsForDropdown} from '@graphql/sport/query';
import {LMT, STATUS} from '@constants/staticDropdowns';

import {showErrorNotificationMessage} from '../utils/lcrChannels';

const LcrChannelFilters = ({
    restoreFilterValues,
    restoreFilterBooleanValues,
    filters,
    loading: loadingFilters,
}) => {

    const {
        data: {
            countries,
        } = {},
        error: errorFetchingCountriesData,
        loading: isCountriesLoading = true,
    } = useQuery(GetCountryIsoCodesForDropdown);

    const {
        data: {
            sports,
        } = {},
        error: errorFetchingSportsData,
        loading: isSportsDataLoading = true,
    } = useQuery(GetSportsForDropdown);

    useMemo(() => {
        errorFetchingCountriesData && errorFetchingSportsData && showErrorNotificationMessage();
    }, [errorFetchingCountriesData, errorFetchingSportsData]);

    const loading = (isCountriesLoading || isSportsDataLoading) || loadingFilters;

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(filters.LcrChannel, 'search') || ''}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='has_lmt_support'
                placeholder='LMT'
                options={LMT}
                setValue={restoreFilterBooleanValues(filters.LcrChannel, 'has_lmt_support', null, 'LMT filters value is not valid')}
                selection
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='countries'
                options={countries}
                placeholder='Country'
                search
                setValue={restoreFilterValues(filters.LcrChannel, 'countries')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='sports'
                options={sports}
                placeholder='Sport'
                search
                setValue={restoreFilterValues(filters.LcrChannel, 'sports')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='status'
                placeholder='Status'
                options={STATUS}
                setValue={restoreFilterBooleanValues(filters.LcrChannel, 'status', 1, 'Status filters value is not valid')}
                selection
            />

        </div>
    );
};

LcrChannelFilters.propTypes = {
    filters: PropTypes.shape({
        LcrChannel: PropTypes.object.isRequired,
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
    restoreFilterBooleanValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(LcrChannelFilters, {name: 'LcrChannel', initialValues: {status: 1}});
