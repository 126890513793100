import {isEmpty as _isEmpty} from 'lodash';

import {FORM_ERROR} from '@constants/messages';

export function renderFormErrorMessages() {
    return FORM_ERROR('SRT origin entry point');
}

export const handleAdSpliceConfigField = (formInstance, prevProps) => {
    const adSupportScte = prevProps.formValues.has_scte35 !== formInstance.props.formValues.has_scte35;

    if (adSupportScte) {
        const hasScte = formInstance.props.formValues.has_scte35;

        formInstance.setField('ad_splice_config', {
            hidden: !hasScte,
            required: hasScte,
        });
    }
};

export const prepareDataForSubmitForm = (data, model) => {
    return (undefined === data)
        ? null
        : ({
            ...model.dataMap[model.entityDataMapKey],
            id: data.id || null,
            name: data.name,
            description: data.description || null,
            url: data.url,
            previewUrl: data.preview_url || null,
            has_cc: !!data.has_cc,
            hasScte35: !!data.has_scte35,
            adSpliceConfig: data.has_scte35 ? data.ad_splice_config : null,
            tournaments: data.tournaments,
            contentCategories: data.content_categories,
        });
};

export const checkAndMapIds = (items) => {
    if (!_isEmpty(items) && 'object' === typeof items[0]) {
        return items.map(item => item.id);
    }

    return items;
};
