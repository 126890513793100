import {connect} from "react-redux";
import React from "react";
import {Route} from "react-router";
import PropTypes from "prop-types"
import {Dimmer, Loader} from 'semantic-ui-react';

import useQueryCustom from '@utils/hooks/useQueryCustom';
import {convertToInt} from '@utils/helpers';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';

import * as actions from "../../../actions/";
import Authenticate from "../views/Authenticate"
import NotAuthorized from "../views/NotAuthorized"
import {isAuthorized, hasPrivileges} from "../components/Authorization"

/**
 * @ignore
 */

function RedirectRouteHandler({
    history,
    match,
    query,
    entityName,
    redirect,
}) {
    const {
        data,
        error,
        loading: isDataLoading = true,
    } = useQueryCustom({
        query,
        options: {
            variables: {
                id: convertToInt(match.params.id),
            },
        },
    });

    if (isDataLoading && !error) {
        return (
            <Dimmer inverted active>
                <Loader/>
            </Dimmer>
        );
    }

    redirect(history, data) ||
        showErrorModal({
            header: 'Page not found',
            redirectLink: getLink('dashboard'),
            text: `The requested ${entityName} could not be found.`,
            errorButtonIcon: 'home',
            errorButtonText: 'Go to home page',
        });

    return null;
}

export const RouteHandler = (props) => {
    const {privileges, resource, authorization, userPrivileges} = props;

    if (props.public) {
        return <Route {...props} exact/>
    }

    let combinedResources = props.combinedResources ? props.combinedResources : false;

    if (authorization) {
        if (!hasPrivileges(authorization)) {
            return React.createElement(NotAuthorized);
        }
    }

    if (!authorization && (!privileges || !resource || !isAuthorized(resource, privileges, userPrivileges, combinedResources))) {
        return React.createElement(NotAuthorized)
    }

    return (
        props.validatingAuth || !props.loggedIn ? (
            React.createElement(Authenticate, props)
        ) : (
            <Route {...props} exact/>
        )
    );
};

RedirectRouteHandler.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    query: PropTypes.shape({
        definitions: PropTypes.arrayOf(
            PropTypes.shape({
                0: PropTypes.shape({
                    name: PropTypes.shape({
                        value: PropTypes.string.isRequired,
                    }),
                }),
            })),
    }),
    entityName: PropTypes.string,
    redirect: PropTypes.func,
};

RouteHandler.propTypes = {
    authorization: PropTypes.object,
    combinedResources: PropTypes.bool,
    loggedIn: PropTypes.bool,
    privileges: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.array,
    ]),
    public: PropTypes.bool,
    resource: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.array,
    ]),
    userPrivileges: PropTypes.object,
    validatingAuth: PropTypes.bool,
};

/**
 * @ignore
 */
const mapStateToProps = (state) => {
    return {
        validatingAuth: state.app.security.validatingAuth,
        loggedIn: state.app.security.loggedIn,
        userPrivileges: state.app.security.user.privileges
    }
};

/**
 * @ignore
 */
const mapDispatchToProps = (dispatch) => {
    return {
        authenticationCheck: () => {
            dispatch(actions.authenticationCheck())
        },
    }
};

export default RedirectRouteHandler;
export const RouterHandlerWithRedux = connect(mapStateToProps, mapDispatchToProps)(RouteHandler);
