import {isEmpty as _isEmpty, isNumber as _isNumber} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import navigation from '@constants/navigation';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import {
    GetEncodingJobProfilesForTable,
    GetEncodingJobProfileForFormDropdowns,
} from '@graphql/encodingJobProfile/query';

import {encodingJobProfileModalError} from '../utils/indexHelpers';
import {exportExcel} from '../utils/export/encodingJobProfileIndexExcel';
import EncodingJobProfileTable from '../components/EncodingJobProfileTable';
import EncodingJobProfileFilters from '../components/EncodingJobProfileFilters';

export const DEFAULT_FILTERS_VALUES = {
    search: null,
    products: [],
    distributionTypes: [],
    hasDrmSupport: null,
    hasMultiAudioSupport: null,
    isDefault: null,
    isDisabled: null,
};

const EncodingJobProfileIndex = ({areFiltersVisible = true, filters = {}, Menu, Modal}) => {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        product: filters.products
            ? filters.products.map(Number)
            : DEFAULT_FILTERS_VALUES.products,
        distributionType: filters.distributionTypes
            ? filters.distributionTypes.map(Number)
            : DEFAULT_FILTERS_VALUES.distributionTypes,
        hasDrmSupport: _isNumber(filters.hasDrmSupport)
            ? !!filters.hasDrmSupport
            : DEFAULT_FILTERS_VALUES.hasDrmSupport,
        hasMultiAudioSupport: _isNumber(filters.hasMultiAudioSupport)
            ? !!filters.hasMultiAudioSupport
            : DEFAULT_FILTERS_VALUES.hasMultiAudioSupport,
        isDefault: _isNumber(filters.isDefault)
            ? !!filters.isDefault
            : DEFAULT_FILTERS_VALUES.isDefault,
        isDisabled: _isNumber(filters.isDisabled)
            ? !!filters.isDisabled
            : DEFAULT_FILTERS_VALUES.isDisabled,
    };

    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQueryCustom({
        query: GetEncodingJobProfilesForTable,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQueryCustom({
        query: GetEncodingJobProfileForFormDropdowns,
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: encodingJobProfileModalError(),
        });
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(data.encodingJobProfiles)}
                filters={EncodingJobProfileFilters}
                filtersButtonName='encodingJobProfiles'
                filtersProps={{
                    loading: areFiltersLoading,
                    data: {
                        products: dataForFilters.productsDropdown || DEFAULT_FILTERS_VALUES.products,
                        distributionTypes: dataForFilters.distributionTypes || DEFAULT_FILTERS_VALUES.distributionTypes,
                        isVisible: areFiltersVisible,
                    },
                }}
                loading={areDataLoading}
                messagesBoxNames='encodingJobProfile'
                pageTitle='Encoding job profiles'
            />
            <EncodingJobProfileTable
                data={data.encodingJobProfiles}
                loading={areDataLoading}
            />
        </div>
    );
};
EncodingJobProfileIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    filters: PropTypes.shape({
        search: PropTypes.string,
        products: PropTypes.array,
        distributionTypes: PropTypes.array,
        isDefault: PropTypes.number,
        isDisabled: PropTypes.number,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};

const EncodingJobProfileIndexWrapped = FilterQueryWrapper(EncodingJobProfileIndex, {
    queryForRefresh: 'GetEncodingJobProfilesForTable',
    filterUrls: ['encodingJobProfile.index'],
});

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'encodingJobProfiles', DEFAULT_FILTERS_VALUES),
});

export default connect(mapStateToProps, mapModulesToProps(['Menu', 'Modal']))(
    FilterUrlParamsWrapper(EncodingJobProfileIndexWrapped, DEFAULT_FILTERS_VALUES)
);
