import React from 'react';
import {get as _get} from 'lodash';

import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {IconCheckmark} from '@appComponents/IconCollection';
import {formattedPriceOrEmptyValue} from '@utils/helpers';
import {EMPTY_VALUE_DASH} from '@constants/variables';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';

export const rowRenderer = (column, row, data) => {
    const offeringGeoRestrictions = _get(row, 'offering_geo_restrictions', []),
        deviceCategoriesCountries = parseDeviceCategories(data.mappedIsoCodes, offeringGeoRestrictions);

    switch (column) {
        case 'offeringId':
            return row.id;
        case 'countries':
            return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesCountries, data.mappedIsoCodes);
        case 'numberOfCountries':
            return renderNumberOfCountries(deviceCategoriesCountries);
        case 'blackoutZones':
            return renderBlackoutZonesTableRowCell(deviceCategoriesCountries, false);
        case 'deviceCategories':
            return renderDeviceCategories(deviceCategoriesCountries);
        case 'client':
            return (
                <LinkWithAuthorization
                    resources={RESOURCES.CLIENT}
                    privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    link={{
                        name: 'clients.edit',
                        params: {id: row.client.id},
                    }}
                >
                    {row.client.name}
                </LinkWithAuthorization>
            );
        case 'is_hq':
            return row.is_hq ? <IconCheckmark/> : null;
        case 'is_ll':
            return row.is_ll ? <IconCheckmark/> : null;
        case 'price':
            return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
        case 'time':
            return row.creation_datetime;
        case 'distributionType':
            return row.distribution.distribution_type.name;
        case 'content':
            return <EventContentVariantPopupWithName eventContent={row.distribution.event_content}/>;
        case 'client_package':
            return renderClientPackage(row);
        case 'product':
            return row.distribution.product.short_name;
        default:
            return null;
    }
};

const renderClientPackage = (row) => {
    if (row.client_package) {
        return (
            <LinkWithAuthorization
                resources={RESOURCES.CLIENT_PACKAGE}
                privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                link={{
                    name: 'clients.packages.edit',
                    params: {id: row.client.id, packagesId: row.client_package.id},
                }}
            >
                {row.client_package.name}
            </LinkWithAuthorization>
        );
    }

    return '-';
};
