import React, {useEffect} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import useQueryCustom from '@utils/hooks/useQueryCustom';
import {GetFiltersDataForSrtOriginEntryPoints} from '@graphql/srtOriginEntryPoints/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {prepareTournamentsForDropdown} from '@utils/tournament';
import {prepareContentCategoriesForDropdown} from '@utils/contentCategories';
import {prepareAndSortDropdownData} from '@modules/srtOriginEntryPoint/utils/filterHelpers';

const SrtOriginEntryPointsFilters = ({filters, loading: isDataLoading, restoreFilterValues}) => {
    const {
        data: {tournaments = [], contentCategories = []} = {},
        loading: areFiltersLoading = true,
        error: dataError = {},
    } = useQueryCustom({
        query: GetFiltersDataForSrtOriginEntryPoints,
    });

    const loading = (areFiltersLoading || isDataLoading) && _isEmpty(dataError);

    useEffect(() => {
        if (!_isEmpty(dataError)) {
            showErrorModal({
                header: 'Error with filters',
                redirectLink: getLink(`${routes}.srtOriginEntryPoints.index`),
                text: 'There was an error while fetching the filters data.',
                errorButtonIcon: 'arrow circle left',
                errorButtonText: 'Back to list',
            });
        }
    }, [dataError]);

    return (
        <div className='filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(filters.srtOriginEntryPoints, 'search')}
            />
            <Field
                component={Form.ReduxSelect}
                className='long'
                name='tournaments'
                placeholder='Tournament'
                options={prepareAndSortDropdownData(tournaments, prepareTournamentsForDropdown)}
                disabled={loading}
                loading={loading}
                setValue={restoreFilterValues(filters.srtOriginEntryPoints, 'tournaments')}
                search
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                className='long'
                name='contentCategories'
                placeholder='Content category'
                options={prepareAndSortDropdownData(contentCategories, prepareContentCategoriesForDropdown)}
                disabled={loading}
                loading={loading}
                setValue={restoreFilterValues(filters.srtOriginEntryPoints, 'contentCategories')}
                search
                multiple
                selection
            />
        </div>
    );
};

SrtOriginEntryPointsFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(SrtOriginEntryPointsFilters, {name: 'srtOriginEntryPoints'});

