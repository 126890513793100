import Form from '@appComponents/ReduxFormControls';
import {SRT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {adSpliceConfigField, adSupportSCTEField} from '@modules/adSplice/form/fieldsModel';
import {
    CreateSrtOriginEntryPoint,
    DeleteSrtOriginEntryPoint,
    UpdateSrtOriginEntryPoint,
} from '@graphql/srtOriginEntryPoints/mutation';
import {GetOptionsForForm, GetSrtOriginEntryPointForForm} from '@graphql/srtOriginEntryPoints/query';

import {renderFormErrorMessages} from '../utils/helpers';
import validate from './validator';

export default function SrtOriginEntryPointModel() {
    return {
        entityDataMapKey: 'srtOriginEntryPoint',
        entityLabel: 'SRT origin entry point',
        formName: 'srtOriginEntryPoint',
        label: 'SrtOriginEntryPoint',
        resources: SRT_ORIGIN_ENTRY_POINT,
        showChangelog: true,
        validate: validate,
        title: null,
        dataMap: {
            srtOriginEntryPoint: {
                id: '',
                name: '',
                description: '',
                url: '',
                previewUrl: null,
                closeCaptioning: false,
                adSupport: false,
                adSpliceConfig: '',
                tournaments: [],
                contentCategories: [],
            },
        },
        messages: {
            boxName: {
                delete: 'srtOriginEntryPointMessage',
                error: 'srtOriginEntryPointMessageError',
                success: 'srtOriginEntryPointMessage',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderFormErrorMessages,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'srtOriginEntryPoint.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'srtOriginEntryPoint.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            description: {
                dataMapKey: 'srtOriginEntryPoint.description',
                props: {
                    component: Form.SemanticInput,
                    label: 'Description',
                    type: 'input',
                    name: 'description',
                },
            },
            url: {
                dataMapKey: 'srtOriginEntryPoint.url',
                props: {
                    component: Form.SemanticInput,
                    label: 'URL',
                    name: 'url',
                    required: true,
                    type: 'input',
                },
            },
            previewUrl: {
                dataMapKey: 'srtOriginEntryPoint.preview_url',
                props: {
                    component: Form.SemanticInput,
                    label: 'Preview URL',
                    name: 'preview_url',
                    type: 'input',
                    className: '--long-width',
                    allowClear: true,
                },
            },
            closeCaptioning: {
                dataMapKey: 'srtOriginEntryPoint.has_cc',
                props: {
                    component: Form.SemanticInput,
                    label: 'Close Captioning',
                    name: 'has_cc',
                    type: 'toggle',
                },
            },
            adSupport: {
                dataMapKey: 'srtOriginEntryPoint.has_scte35',
                props: {
                    ...adSupportSCTEField('has_scte35'),
                },
            },
            adSpliceConfig: {
                dataMapKey: 'srtOriginEntryPoint.ad_splice_config.id',
                optionsKey: 'adSpliceConfigs',
                props: {
                    ...adSpliceConfigField('ad_splice_config'),
                },
            },
            tournaments: {
                dataMapKey: 'srtOriginEntryPoint.tournaments',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Tournaments',
                    name: 'tournaments',
                    placeholder: 'Select tournament',
                    allowClear: true,
                    multiple: true,
                },
            },
            contentCategories: {
                dataMapKey: 'srtOriginEntryPoint.content_categories',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Content categories',
                    name: 'content_categories',
                    placeholder: 'Select content category',
                    allowClear: true,
                    multiple: true,
                },
            },
        },
        mutation: {
            createEntity: CreateSrtOriginEntryPoint,
            deleteEntity: DeleteSrtOriginEntryPoint,
            updateEntity: UpdateSrtOriginEntryPoint,
        },
        query: {
            getOptions: GetOptionsForForm,
            getEntity: GetSrtOriginEntryPointForForm,
        },
    };
}
