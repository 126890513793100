import React, {useMemo} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import {RenderBackToListButton} from '@appComponents/ButtonCollection';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {GetSrtOriginEntryPointForForm} from '@graphql/srtOriginEntryPoints/query';
import {createForm, renderModalError} from '@utils/forms';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';

import SrtOriginEntryPointModel from '../forms/SrtOriginEntryPointModel';
import SrtOriginEntryPointForm from '../forms/SrtOriginEntryPointForm';

export const SrtOriginEntryPointEdit = ({
    match: {
        params: {id = null},
    },
}) => {
    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('SRT Origin Entry Point', routes.srtOriginEntryPoints.index.path),
        });
    }

    const Form = useMemo(
        () => (createForm(
            SrtOriginEntryPointModel, SrtOriginEntryPointForm, {id: convertToInt(id)})
        ),
        [id]
    );

    return (
        <div>
            <HeaderRenderer
                buttons={RenderBackToListButton}
                buttonsProps={{name: 'srtOriginEntryPoints.index'}}
                messagesBoxNames='srtOriginEntryPointMessageError'
                pageTitle={<TitleFromQuery
                    query={GetSrtOriginEntryPointForForm}
                    variables={{id}}
                    errorBackLink={routes.srtOriginEntryPoints.index.path}
                    path={'srtOriginEntryPoint.name'}
                    entity={'SRT origin entry point'}
                />}
            />
            <Form/>
        </div>
    );
};

SrtOriginEntryPointEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};
SrtOriginEntryPointEdit.displayName = 'SrtOriginEntryPointEdit';

export default withRouter(SrtOriginEntryPointEdit);
